import React from "react";
import { cookie } from "./CookiData";
import Cookies from "./Cookies";

const CookieSetting = () => {
  return (
    <div>
      <div className=" container mx-auto ">
        <div>
          {cookie.map((c, i) => (
            <Cookies c={c} i={i} />
          ))}
        </div>
        <div>
          <div className="flex justify-center items-center gap-4 flex-col">
            <div className="flex flex-col gap-3">
              {" "}
              <a
                className="text-blue-400 hover:text-blue-700 link"
                target="_blank"
                rel="noreferrer"
                href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
                {" "}
                Cookie settings in Internet Explorer
              </a>
              <a
                className="text-blue-400 hover:text-blue-700 link"
                target="_blank"
                rel="noreferrer"
                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
                {" "}
                Cookie settings in Firefox
              </a>
              <a
                className="text-blue-400 hover:text-blue-700 link"
                target="_blank"
                rel="noreferrer"
                href="https://support.google.com/chrome/answer/95647">
                {" "}
                Cookie settings in Chrome
              </a>
              <a
                className="text-blue-400 hover:text-blue-700 link"
                target="_blank"
                rel="noreferrer"
                href="https://support.apple.com/en-in/guide/safari/sfri11471/mac">
                {" "}
                Cookie settings in Safari
              </a>
            </div>
          </div>

          <p className=" my-5">
            <b>More information:</b> To find out more about cookies, including
            how to see what cookies have been set and how to manage and delete
            them, visit{" "}
            <a
              className="text-blue-400 hover:text-blue-700 link"
              target="_blank"
              rel="noreferrer"
              href="https://www.allaboutcookies.org/">
              www.allaboutcookies.
            </a>{" "}
            org. You can learn more about opting out of receiving interest-based
            ads from other companies at{" "}
            <a
              className="text-blue-400 hover:text-blue-700 link"
              target="_blank"
              rel="noreferrer"
              href="https://optout.aboutads.info/">
              optout.aboutads.info{" "}
            </a>{" "}
            and
            <a
              className="text-blue-400 hover:text-blue-700 link"
              target="_blank"
              rel="noreferrer"
              href="https://www.networkadvertising.org/choices">
              {" "}
              www.networkadvertising.org/choices.In{" "}
            </a>
            addition, certain third party advertising networks, like Facebook
            (pixels) and Google, permit users to opt out of or customize
            preferences associated with your internet browsing. To learn more
            about this feature from Google, click here. For further information
            about the Facebook Pixel,{" "}
            <a
              className="text-blue-400 hover:text-blue-700 link"
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/business/help/651294705016616">
              click here.
            </a>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CookieSetting;
