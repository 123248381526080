import React, { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { BiArrowBack } from "react-icons/bi";
import { MdOutlineDone } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../../config/firebaseConfig/firebaseConfig";

import InputText from "../../../Shared/InputText";
import EducationHistory from "./EducationHistory";
import EmploymentHistory from "./EmploymentHistory";
import { serverUrl } from "../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { SingleUserProfileGet } from "../../../../hooks/useUser";
const Experience = () => {
  const { firebaseUser, currentUser } = useContext(globalFunctionOneContext);
  const { data, isLoading, error, refetch } = SingleUserProfileGet(
    currentUser?.PersonID
  );

  const [fileValue, setFileValue] = useState("");
  const [showPageEducation, setShowPageEducation] = useState(false);
  const [showJobPage, setShowJobPage] = useState(false);
  const [educationInfo, setEducationInfo] = useState({});
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  const {
    register,
    handleSubmit: handleSubmits,
    formState: { errors },
  } = useForm();
  const [uploadStatus, setUploadStatus] = useState("");
  const [images, setImages] = useState("");

  const [userTopSkill, setUserTopSkill] = useState([]);
  console.log("userTop Skill", userTopSkill);
  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setFileValue(value);
  };
  useEffect(() => {
    if (fileValue) {
      console.log(fileValue);
    }
  }, [fileValue]);

  // form submit function
  const onSubmits = async (data) => {
    // data.preventDefault();
    const topSkills = {};
    let test = true;
    userTopSkill.map((s) => {
      if (data[s] === null) {
        return (test = false);
      }
      topSkills[s] = data[s];
    });
    if (test === false) {
      return;
    }
    const skillString = JSON.stringify(topSkills);
    // console.log("s", skillString);
    // console.log("hallo", data);
    // const formData = new FormData();
    // formData.append("report_people", data.report_people);
    // formData.append("full_time_job_experience", data.full_time_job_experience);
    // formData.append("employment_history", data.employment_history[0]);
    // formData.append("education_history", data.education_history[0]);
    // formData.append("resume", data.resume[0]);
    // formData.append("selenium", data.selenium);
    // formData.append("top_skill", skillString);
    const report_people = data.report_people;
    const full_time_job_experience = data.full_time_job_experience;
    const top_skill = skillString;
    const userData = {
      report_people,
      full_time_job_experience,
      top_skill,
    };
    // console.log(educationInfo);

    if (educationInfo) {
      const res = await fetch(`${serverUrl}/userProfile/user-education`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          uid: firebaseUser?.uid,
        },
        body: JSON.stringify(educationInfo),
      });

      const educationHistoryDataSaved = await res.json();
      if (
        educationHistoryDataSaved?.status === "success" &&
        firebaseUser?.uid &&
        report_people &&
        full_time_job_experience &&
        top_skill
      ) {
        fetch(`${serverUrl}/userProfile/freelancer-user-experience`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            uid: firebaseUser?.uid,
          },
          body: JSON.stringify(userData),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data?.status === "success") {
              navigate("/user/profile/from/extra/freelancer/form");
              console.log(data);
              // setUploadStatus(data);
            }
          });
      } else {
        console.log("education history saved fail");
      }
    }
  };
  let expYear = [];
  let currentYear = 15;
  for (let i = 0; i <= currentYear; i++) {
    expYear.push(i + " years");
  }

  return (
    <div className="my-10">
      {/* <img src={img} alt="" /> */}
      {/* <form>
        <EducationHistory
          register={register}
          showPageEducation={showPageEducation}
          setShowPageEducation={setShowPageEducation}
          educationInfo={educationInfo}
          setEducationInfo={setEducationInfo}
        />
      </form> */}

      {/* row 2 */}
      <form>
        <EmploymentHistory
          register={register}
          showJobPage={showJobPage}
          setShowJobPage={setShowJobPage}
        />
      </form>

      {/* 3rd row */}
      <form onSubmit={handleSubmits(onSubmits)}>
        <EducationHistory
          register={register}
          showPageEducation={showPageEducation}
          setShowPageEducation={setShowPageEducation}
          educationInfo={educationInfo}
          setEducationInfo={setEducationInfo}
        />
        <div>
          <h2 className="uppercase font-semibold mt-10">🥇 Your Experience</h2>
          <div className="divider mb-2 mt-0"></div>
          <div className="w-full">
            <label className="label">
              <span className="label-text">
                How many years do you have a full time experience?
              </span>
            </label>
            <select
              {...register("full_time_job_experience")}
              className="select select-bordered  w-[50%] "
            >
              <option disabled value="" selected>
                Select Option
              </option>
              {expYear.map((option, i) => (
                <option
                  key={i}
                  selected={data?.result?.full_time_job_experience === option}
                  value={option}
                >
                  {option}
                </option>
              ))}
            </select>

            <label className="label">
              <span className="label-text">
                How many people report to you ? (Directly or Indirectly)
              </span>
            </label>
            <select
              {...register("report_people")}
              className="select select-bordered w-[50%]  "
            >
              <option disabled value="" selected>
                Select Option
              </option>
              <option
                selected={data?.result?.report_people === "Not managing a team"}
                value="Not managing a team"
              >
                Not managing a team
              </option>
              <option
                selected={data?.result?.report_people === "Less than 10 people"}
                value="Less than 10 people"
              >
                Less than 10 people
              </option>
              <option
                selected={data?.result?.report_people === "More than 10 people"}
                value="More than 10 people"
              >
                More than 10 people
              </option>
            </select>
          </div>
          <InputText
            userSkill={userTopSkill}
            setUserSkill={setUserTopSkill}
            register={register}
            dropDown={true}
          />
        </div>
        <div>
          <h2 className="uppercase font-semibold mt-10">
            📋 Upload Resume{" "}
            <span className="text-slate-300 text-[10px]">
              (You can upload later)
            </span>{" "}
          </h2>
          <div className="divider mb-2 mt-0"></div>
          <span className="text-primary">{fileValue.split("\\")[2]}</span>
          <div className="flex  items-center w-full">
            <label
              htmlFor="dropzone-file"
              className="flex flex-col items-center w-[75%] h-40 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                <svg
                  aria-hidden="true"
                  className="mb-3 w-10 h-10 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG or GIF (MAX. 800x400px)
                </p>
              </div>
              <input
                {...register("resume")}
                onChange={handleInputValue}
                type="file"
                id="dropzone-file"
                className="hidden"
              />
            </label>
          </div>
        </div>
        <div className="flex justify-end gap-4 my-4">
          <button
            // onClick={() => navigate(-1)}
            className="btn btn-sm flex gap-2 items-center btn-primary"
          >
            <BiArrowBack />
            <span>Back</span>
          </button>
          <button
            type="submit"
            className="btn btn-sm flex gap-2 items-center btn-primary"
          >
            {" "}
            <MdOutlineDone />
            <span>save</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Experience;
