import React from "react";
import HashLoader from "react-spinners/HashLoader";

const WindowsLoadingSpinner = () => {
  return (
    <div className="flex h-full justify-center items-center">
      <HashLoader color={"#fd03dc"} loading={true} size={150} />
    </div>
  );
};

export default WindowsLoadingSpinner;
