import React, { useContext, useState } from "react";
import Left from "../../FreelancerTemplate/Left";
import Right from "../../FreelancerTemplate/Right";
import { globalFunctionOneContext } from "../../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { CurrentClientHireAllFreelancerData } from "../../../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import { Link } from "react-router-dom";
import WindowsLoadingSpinner from "../../../../Shared/WindowsLoadingSpinner/WindowsLoadingSpinner";
import {
  serverImageUrl,
  serverUrl,
} from "../../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import ModalBody from "../../../../Shared/ModalBody/ModalBody";
import { toast } from "react-toastify";

const Hired = () => {
  const {
    currentUser,
    firebaseUser,
    allMessageFriendList,
    allMessageFriendListRefetch,
    singleUserRoomIdFiendFromCurrentFriendList,
  } = useContext(globalFunctionOneContext);
  const [currentPersonDAta, setCurrentPersonDAta] = useState({});
  const [inviteMessageM, setInviteMessageM] = useState(false);
  const [subMenuOpenAndClose, setSubMenuOpenAndClose] = useState(false);

  const { data, refetch, isLoading } = CurrentClientHireAllFreelancerData(
    currentUser?.PersonID,
    10
  );

  console.log(data?.data, "All hire Freelancer data");

  const handelSendMessage = async (e) => {
    e.preventDefault();
    const message = e.target.sendMessage.value;
    const roomId = singleUserRoomIdFiendFromCurrentFriendList(
      currentPersonDAta?.PersonID,
      allMessageFriendList?.data
    );
    const messageUserCreateDAta = {
      clientPersonId: firebaseUser?.uid,
      freelancerPersonId: currentPersonDAta?.PersonID,
      message: message,
      messageRequestId: firebaseUser?.uid,
      roomId,
    };

    const res = await fetch(`${serverUrl}/client/message/user/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // authorization: `bearer ${localStorage.getItem("access_Token")}`,
      },
      body: JSON.stringify(messageUserCreateDAta),
    });
    const data = await res.json();
    if (data?.status === "success") {
      e.target.reset();
      allMessageFriendListRefetch();
      setInviteMessageM(false);
      toast.success("Your message send successful !!!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Your message successful no't send !!!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  if (!currentUser || isLoading) {
    return (
      <div className="h-[455px]">
        <WindowsLoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-lg text-primary flex justify-end">30 invites left</h2>
      <div>
        {data?.data &&
          data?.data.map((proposal, i) => (
            <div
              key={i}
              className="grid grid-cols-1 mt-10 pb-10 border-b-2 border-gray-300 md:grid-cols-3"
            >
              <Left proposal={proposal} />
              <Right proposal={proposal}>
                <div className="mt-4 flex items-center gap-2 wrap relative">
                  <button
                    className="capitalize btn btn-primary btn-outline btn-sm"
                    onClick={() => {
                      setSubMenuOpenAndClose(false);
                      setInviteMessageM(true);
                      setCurrentPersonDAta(proposal);
                    }}
                  >
                    Message
                  </button>
                  <Link
                    to={`/dashboard/client/jobs/hire/freelancer/view/contract/${proposal?.jobId}/${proposal?.PersonID}`}
                    className="capitalize btn btn-primary btn-sm"
                  >
                    View Contract
                  </Link>
                </div>
              </Right>
            </div>
          ))}
        {!data?.data?.length && !isLoading && (
          <div className="h-[500px] flex justify-center items-center">
            <h1 className="font-bold text-2xl">
              There is nothing to show on the hire freelancer list
            </h1>
          </div>
        )}
      </div>

      <ModalBody
        onClose={() => setInviteMessageM(false)}
        isVisible={inviteMessageM}
        customOpacityLayer="bg-opacity-0"
        customClass="card w-full mx-10 md:max-w-md md:mx-auto bg-[#fff] p-4"
        modalCloseCustomButtonAdd={true}
      >
        <div className="flex justify-center sm:justify-between items-start sm:flex-row flex-col-reverse  gap-5 md:gap-40 ">
          <div>
            <div className="flex flex-col sm:flex-row justify-center md:justify-start items-start gap-5">
              <div className="avatar online">
                <div className="w-16 rounded-full">
                  <img
                    src={`${serverImageUrl}/${currentPersonDAta?.profilePhoto}`}
                    alt="This is profile pic"
                    className="rounded-full"
                  />
                </div>
              </div>
              <div>
                <h1 className="font-medium text-lg">
                  {currentPersonDAta?.firstName} {currentPersonDAta?.lastName}
                </h1>
                <p className="text-sm">{currentPersonDAta?.profile_title}</p>
              </div>
            </div>
          </div>
          <button
            className="btn-secondary btn btn-sm btn-circle place-self-end sm:place-self-start"
            onClick={() => {
              setInviteMessageM(false);
              setCurrentPersonDAta({});
            }}
          >
            ✕
          </button>
        </div>

        <form className="px-3 mt-10 mb-5" onSubmit={handelSendMessage}>
          <input
            className="w-full py-2 px-5 rounded-lg focus:border-secondary outline-none border"
            placeholder="Type Your Message"
            name="sendMessage"
          />

          <div className="flex justify-end items-center">
            <button className="btn btn-sm bg-secondary mt-5 px-5" type="submit">
              Send
            </button>
          </div>
        </form>
      </ModalBody>
    </div>
  );
};

export default Hired;
