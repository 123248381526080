/* import React, { createContext, useContext, useEffect, useReducer } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { PostJobActionTypes } from "../state/PostJobState/PostJobActionTypes";
import { auth } from "../config/firebaseConfig/firebaseConfig";
import {
  initialState,
  PostJobReducer,
} from "../state/PostJobState/PostJobReducer";
import { serverUrl } from "../hooks/AllServerUrlControl/useAllServerUrlControl";

// Create the context with no arguments
const POST_JOB_CONTEXT = createContext();

const JobPostProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PostJobReducer, initialState);
  // const [effectRecall, setEffectRecall] = useState(false);
  const [user] = useAuthState(auth);
  useEffect(() => {
    dispatch({ type: PostJobActionTypes.FETCHING_START });
    fetch(`${serverUrl}/client/postingJob`, {
      headers: { uid: user?.uid },
    })
      .then((res) => res.json())
      .then((data) =>
        dispatch({
          type: PostJobActionTypes.FETCHING_SUCCESS,
          payload: data.result,
        })
      );
  }, [user?.uid]);

  const value = {
    state,
    dispatch,
  };

  return (
    // wrap the children inside the context provider
    <POST_JOB_CONTEXT.Provider value={value}>
      {children}
    </POST_JOB_CONTEXT.Provider>
  );
};

export const useJobPost = () => {
  // access the context
  const context = useContext(POST_JOB_CONTEXT);
  if (!context) {
    throw new Error("useJobPost must be used within a JobPostProvider");
  }
  return context;
};

export default JobPostProvider;
 */

import React, { createContext, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useQuery } from "react-query";
import { PostJobActionTypes } from "../state/PostJobState/PostJobActionTypes";
import { auth } from "../config/firebaseConfig/firebaseConfig";
import {
  initialState,
  PostJobReducer,
} from "../state/PostJobState/PostJobReducer";
import { serverUrl } from "../hooks/AllServerUrlControl/useAllServerUrlControl";
import { useEffect } from "react";
import Loading from "../component/Loading/Loading";
import { useReducer } from "react";

// Create the context with no arguments
const POST_JOB_CONTEXT = createContext();

const JobPostProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PostJobReducer, initialState);
  const [user] = useAuthState(auth);

  const { data, isLoading, isError, refetch } = useQuery(
    "postingJob",
    async () => {
      const response = await fetch(`${serverUrl}/client/postingJob`, {
        headers: { uid: user?.uid },
      });
      const data = await response.json();
      return data.result;
    },
    {
      enabled: !!user?.uid, // Only fetch data if user.uid is available
    }
  );

  if (isLoading) {
    // Handle loading state if needed
    <Loading />;
  }

  if (isError) {
    // Handle error state if needed
    console.log(isError);
  }
  // console.log(data);
  useEffect(() => {
    if (data) {
      dispatch({
        type: PostJobActionTypes.FETCHING_SUCCESS,
        payload: data,
      });
    }
  }, [data]);

  const value = {
    state,
    dispatch,
    refetch,
  };

  return (
    // wrap the children inside the context provider
    <POST_JOB_CONTEXT.Provider value={value}>
      {children}
    </POST_JOB_CONTEXT.Provider>
  );
};

export const useJobPost = () => {
  // access the context
  const context = useContext(POST_JOB_CONTEXT);
  if (!context) {
    throw new Error("useJobPost must be used within a JobPostProvider");
  }
  return context;
};

export default JobPostProvider;
