import { Link } from "react-router-dom";
import WithdrawalMethodsCard from "./WithdrawalMethodsCard";
const Step1 = ({
  accountBalance,
  withdrawModalEnable,
  methods,
  addMethod,
  isLoading,
  refetch,
}) => {
  return (
    <div>
      {methods.length <= 0 && !isLoading ? (
        <div>
          <h2 className="text-2xl font-semibold">Get Paid</h2>
          <div className=" mt-5 card w-full px-4 bg-base-100 border border-gray-100 drop-shadow-xl">
            <div className="card-body">
              <h2 className="card-title">Available Balance</h2>
              <p className="text-primary">₹{accountBalance}</p>
              <div className="alert bg-red-100 shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="stroke-current flex-shrink-0 w-6 h-6">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  <p>
                    To withdraw earning please update your{" "}
                    <span className="link">tax information</span> for more
                    details read our{" "}
                    <Link className="link" target="_blank" to="/faq">
                      FAQs
                    </Link>
                  </p>
                </div>
              </div>
              <div className="my-10">
                <h2 className=" text-3xl text-center">
                  To withdraw earning,first you need to set up a withdrawal
                  method
                </h2>
                <p className="mt-5 text-center">
                  It may take up to 3 days activate your withdrawal method
                </p>
                <div className="mt-5 text-center">
                  <label
                    htmlFor="paymentAndBillingModal"
                    onClick={addMethod}
                    className="btn btn-primary rounded-full">
                    Add method
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <WithdrawalMethodsCard
          accountBalance={accountBalance}
          withdrawModalEnable={withdrawModalEnable}
          refetch={refetch}
          addMethod={addMethod}
          methods={methods}
        />
      )}
    </div>
  );
};
export default Step1;
