import React from "react";

import { RiEarthFill } from "react-icons/ri";
const SingleMethod = ({ removePMD, m, removeId, removedLoading }) => {
  // console.log("payment list", m);

  return (
    <div className="">
      <div className="my-5">
        <div>
          <div className="flex gap-2 items-center justify-between">
            <div className="flex items-center gap-6">
              <div>
                <RiEarthFill className="text-4xl text-secondary" />
              </div>
              <div>
                <h3 className="text-lg font-semibold  ">
                  Direct to local Bank (INR) Account ending in{" "}
                  {m?.accountNumber.slice(-4)}
                  <button className="btn btn-active btn-ghost btn-sm">
                    Prepared
                  </button>
                </h3>
                {m.PaymentAccountStatus ? (
                  <span className="text-success">Active</span>
                ) : (
                  <span className="text-error">inactive</span>
                )}
              </div>
            </div>
            <div className=" flex- gap-2">
              <button
                onClick={() => removePMD(m?.id)}
                className="btn-sm btn  btn-link text-error btn-error"
              >
                {removedLoading && removeId === m.id ? "Loading..." : "Removed"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleMethod;
