import React, { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { StepperContext } from "../../../../../context/StepContext";
import Loading from "../../../../Loading/Loading";
import { serverUrl } from "../../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { auth } from "../../../../../config/firebaseConfig/firebaseConfig";
import { globalFunctionOneContext } from "../../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const Final = ({ setR, loading, pUpdating, user }) => {
  const { firebaseUser, currentUser } = useContext(globalFunctionOneContext);

  const { userData, setUserData } = useContext(StepperContext);
  const hasRunEffect = useRef(true);

  // console.log(userData);
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (userData && hasRunEffect.current) {
  //     hasRunEffect.current = false;
  //     const {
  //       accountType,
  //       country,
  //       email,
  //       firstName,
  //       lastName,
  //       password,
  //       rules,
  //       username,
  //     } = userData;

  //     fetch(`${serverUrl}/userProfile`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         // uid: PersonID,
  //       },
  //       body: JSON.stringify({
  //         accountType,
  //         firstName,
  //         lastName,
  //         country,
  //         email,
  //         password,
  //         rules,
  //         username,
  //       }),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         console.log(data);
  //         signInWithEmailAndPassword(auth, email, password)
  //           .then((userCredential) => {
  //             // Signed in
  //             const user = userCredential.user;
  //           })
  //           .catch((error) => {
  //             const errorCode = error.code;
  //             const errorMessage = error.message;
  //           });
  //       });
  //   }
  // }, [userData]);

  // const [
  //   createUserWithEmailAndPassword,
  //   user,
  //   loading,
  //   error,
  // ] = useCreateUserWithEmailAndPassword(auth);

  useEffect(() => {
    if (userData && hasRunEffect.current) {
      hasRunEffect.current = false;
      const {
        accountType,
        country,
        email,
        firstName,
        lastName,
        password,
        rules,
        username,
      } = userData;
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;

          if (user) {
            console.log(user);
            updateProfile(auth.currentUser, {
              displayName: firstName + lastName,
            })
              .then(() => {
                verifyEmail();
                fetch(`${serverUrl}/userProfile`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    // uid: PersonID,
                  },
                  body: JSON.stringify({
                    PersonID: user?.uid,
                    accountType,
                    firstName,
                    lastName,
                    country,
                    email,
                    password,
                    rules,
                    username,
                  }),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    console.log(data);
                  });
              })
              .catch((error) => {
                // An error occurred
                // ...
              });
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
        });
    }
  }, [userData]);

  const verifyEmail = () => {
    sendEmailVerification(auth.currentUser).then(() => {
      alert("pleas check your email and verify.");
    });
  };

  // console.log(user);

  const onClick = () => {
    switch (userData.accountType) {
      case "client":
        navigate("/dashboard");
        break;
      case "talent_manager":
        navigate("/dashboard");
        break;
      case "Freelancer":
        navigate("/dashboard");
        break;
      default:
        navigate("/");
        break;
    }

    setR(false);
  };
  if (user) {
    console.log(user);
  }

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="flex flex-col justify-center">
      <div>
        {firebaseUser && (
          <>
            <h2 className="text-center">
              Complete your profile and get hired.
            </h2>
            <div className="flex justify-center mt-8">
              <button className="btn btn-secondary" onClick={onClick}>
                Go To Dashboard
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Final;
