import React from "react";

const DescribeJob = ({ describeJobIda, setDescribeJobIda }) => {
  const arr1 = [
    { tableText: "Seed (1-10)", name: "radio-1" },
    { tableText: "Early (11-50)", name: "radio-2" },
    { tableText: "Mid-Size (51-200)", name: "radio-3" },
    { tableText: "Large (201-500)", name: "radio-4" },
    { tableText: "Very-Large (501-1000)", name: "radio-5" },
    { tableText: "Massive (1000+)", name: "radio-6" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDescribeJobIda({ ...describeJobIda, [name]: value });
  };
  if (describeJobIda) {
    console.log(describeJobIda);
  }
  return (
    <div>
      <h2 className="uppercase font-semibold mt-10">
        🔱 Describe your Ideal Job{" "}
        <span className="text-[10px] text-slate-300">(Optional)</span>
      </h2>
      <div className="divider mb-2 mt-0"></div>
      <h2>Would you work at companies of these size?</h2>
      <div className="overflow-x-auto mt-4">
        <table className="table w-full">
          <thead>
            <tr>
              <th className="bg-base-100"></th>
              <th className="bg-base-100">IDEAL</th>
              <th className="bg-base-100">YES</th>
              <th className="bg-base-100">NO</th>
            </tr>
            <span></span>
          </thead>

          <tbody>
            {arr1.map((a, i) => (
              <tr key={i}>
                <th>{a.tableText}</th>
                <td>
                  <input
                    required
                    onChange={handleChange}
                    value="Ideal"
                    type="radio"
                    name={a.tableText}
                    className="radio radio-primary radio-sm"
                  />
                </td>
                <td>
                  <input
                    required
                    onChange={handleChange}
                    value="Yes"
                    type="radio"
                    name={a.tableText}
                    className="radio radio-primary radio-sm"
                  />
                </td>
                <td>
                  <input
                    required
                    onChange={handleChange}
                    value="No"
                    type="radio"
                    name={a.tableText}
                    className="radio radio-primary radio-sm"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DescribeJob;
