import React from "react";

const Analytics = () => {
  return (
    <div>
      <h2>Analytics</h2>
    </div>
  );
};

export default Analytics;
