import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import style from "./Setting.module.css";

const Setting = () => {
  const [settingLinkActive, setSettingLinkActive] = useState(1);

  const settingLinkList = [
    {
      id: 1,
      linkText: "My Info",
      linkPath: "/dashboard/settings",
    },
    {
      id: 2,
      linkText: "Edit My Profile",
      linkPath: "/user/profile/from",
    },
    {
      id: 3,
      linkText: "Billing and Payments",
      linkPath: "/dashboard/settings/billing_payment",
    },
    {
      id: 4,
      linkText: "Password and Security",
      linkPath: "#",
    },
    {
      id: 5,
      linkText: "Identity verification",
      linkPath: "/dashboard/settings/identity_verification",
    },
    {
      id: 6,
      linkText: "Account Settings",
      linkPath: "#",
    },
    {
      id: 7,
      linkText: "Notification Setting",
      linkPath: "#",
    },
    {
      id: 8,
      linkText: "Manage Jobs",
      linkPath: "#",
    },
    {
      id: 9,
      linkText: "Manage Services",
      linkPath: "#",
    },
    {
      id: 10,
      linkText: "Invoices",
      linkPath: "#",
    },
    {
      id: 11,
      linkText: "Disputes",
      linkPath: "#",
    },
    {
      id: 12,
      linkText: "Switch Accounts",
      linkPath: "#",
    },
    {
      id: 13,
      linkText: "Package",
      linkPath: "#",
    },
  ];
  return (
    <div className="grid gap-5 grid-cols-1 md:grid-cols-3 pt-5">
      <div>
        <ul className="pl-10 py-3">
          {settingLinkList &&
            settingLinkList.map((link) => (
              <li key={link?.id}>
                <Link
                  to={link?.linkPath}
                  className={`${style.setting_link_wraper} ${
                    settingLinkActive === link?.id
                      ? style.setting_link_acctive
                      : ""
                  }`}
                  onClick={() => setSettingLinkActive(link?.id)}
                >
                  {link?.linkText}
                </Link>
              </li>
            ))}
        </ul>
      </div>
      <div className="h-[90vh] overflow-y-auto col-span-2">
        <Outlet />
      </div>
    </div>
  );
};

export default Setting;

/* <div className="grid gap5 grid-cols-1 md:grid-cols-3 pt-5">
      <div>
        <ul className="pl-10 py-3">
          {settingLinkList &&
            settingLinkList.map((link) => (
              <li key={link?.id}>
                <Link
                  to={link?.linkPath}
                  className={`${style.setting_link_wraper} ${
                    settingLinkActive === link?.id
                      ? style.setting_link_acctive
                      : ""
                  }`}
                  onClick={() => setSettingLinkActive(link?.id)}
                >
                  {link?.linkText}
                </Link>
              </li>
            ))}
        </ul>
      </div>
      <div className="border col-span-2">
        <h1>02</h1>
      </div>
    </div> */
