import React, { useContext, useEffect, useState } from "react";
import { GuestModeUserData } from "../../hooks/useAllFreelancer";
import Left from "../Client/Jobs/FreelancerTemplate/Left";

import { BsThreeDots } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { globalFunctionOneContext } from "../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { Link } from "react-router-dom";
import ModalBody from "../Shared/ModalBody/ModalBody";
import {
  serverImageUrl,
  serverUrl,
} from "../../hooks/AllServerUrlControl/useAllServerUrlControl";
import ScheduleInterview from "../Shared/ScheduleInterview/ScheduleInterview";
import { ClientAllMessageUser } from "../../hooks/useAllMessage";
import { toast } from "react-toastify";
import Right from "../Client/Jobs/inviteFreelancer/Search/SearchResult/Right";

const GuestModeFreelancer = () => {
  const { currentUser, setInviteFreelancerDataCount } = useContext(
    globalFunctionOneContext
  );

  const { data, isLoading, error } = GuestModeUserData("Freelancer", 10);

  useEffect(() => {
    if (data?.result) {
      setInviteFreelancerDataCount(data?.result?.length);
    }
  }, [data?.result, setInviteFreelancerDataCount]);

  //   This is loading function
  if (isLoading) {
    return <div>Loading ............</div>;
  }
  return (
    <div className="pt-10">
      {data?.data &&
        data?.data.map((proposal, i) => (
          <div
            key={i}
            className="grid grid-cols-1 mt-10 pb-10 border-b-2 border-gray-300 md:grid-cols-3 shadow-lg"
          >
            <Left proposal={proposal} />
            <Right freelancer={proposal} />
          </div>
        ))}
    </div>
  );
};

export default GuestModeFreelancer;
