const BoardData = {
  defaultValue1: "----All India—-",
  allIndiaBoard: [
    "CBSE",
    "CISCE (ICSE/ ISC)",
    "Diploma National Open School",
    "IB (International Baccalaureate)",
  ],
  defaultValue2: "---State Board---",
  stateBoard: [
    "Andorra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Nagaland",
    "Orissa",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttaranchal",
    "Uttar Pradesh",
    "West Bengal",
    "Other",
  ],
  schoolMedium: [
    "Assamese",
    "Bangla",
    "Dogri",
    "Gujarati",
    "Hindi",
    "Kashmiri",
    "Kannada",
    "Konkani",
    "Maithili",
    "Malayalam",
    "Manipuri",
    "Punjabi",
    "Tamil",
    "Telugu",
    "Sindhi",
    "Urdu",
    "Other",
  ],
  marks: [
    "<40%",
    "40- 44.9%",
    "45- 49.9%",
    "50- 54.9%",
    "55- 59.9%",
    "60-69.9%",
    "70- 74.9%",
    "75- 79.9%",
    "80- 84.9%",
    "85- 89.9%",
    "90- 94.9%",
    "95- 99.9%",
    "100%",
  ],
};

export default BoardData;
