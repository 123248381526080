import React from "react";
import { useState } from "react";

const Blog = ({ content, search }) => {
  const { title, description } = content;
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="shadow-xl rounded-lg px-5 py-4">
      <h2 className="text-3xl font-bold">
        {title.length > 40 ? title.slice(0, 40) : title}
        {title.length > 40 ? <span title={title}>...</span> : ""}
      </h2>
      <p className="text-base text-gray-700 font-semibold">
        {showMore ? description : `${description.substring(0, 250)}`}
        <span
          className="cursor-pointer font-bold"
          onClick={() => setShowMore(!showMore)}>
          {showMore ? " Show less" : " Show more..."}
        </span>
      </p>
    </div>
  );
};

export default Blog;
