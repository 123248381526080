import React, { useContext } from "react";
import {
  AllDeclineFreelancerData,
  AllProposalsData,
} from "../../../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import { globalFunctionOneContext } from "../../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import {
  serverImageUrl,
  serverUrl,
} from "../../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import Left from "../../FreelancerTemplate/Left";
import Right from "../../FreelancerTemplate/Right";
import { toast } from "react-toastify";
import WindowsLoadingSpinner from "../../../../Shared/WindowsLoadingSpinner/WindowsLoadingSpinner";

const DeclineFreelancer = () => {
  const { currentUser, firebaseUser } = useContext(globalFunctionOneContext);

  // AllProposalsData data function
  const { data, refetch, isLoading } = AllDeclineFreelancerData(
    currentUser?.PersonID,
    10
  );

  const handelDeclineFreelancerRestore = async (proposal) => {
    const { jobId, PersonID } = proposal;
    const userData = {
      jobId,
      freelancerId: PersonID,
      freelancerDecline: "No",
    };
    console.log(userData);
    const res = await fetch(`${serverUrl}/client/freelancer/decline`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });
    const result = await res.json();
    if (result?.status === "success") {
      refetch();
      toast.success("This freelancer Restore complete!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error("This freelancer Restore failed!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  if (!currentUser || isLoading) {
    return (
      <div className="h-[455px]">
        <WindowsLoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      {data?.result && (
        <>
          {data?.result.length > 0 ? (
            <>
              {data?.result &&
                data?.result.map((proposal, i) => (
                  <div
                    key={i}
                    className="grid grid-cols-1 mt-10 pb-10 border-b-2 border-gray-300 md:grid-cols-3"
                  >
                    <Left proposal={proposal} />
                    <Right proposal={proposal}>
                      <div className="mt-4 flex items-center gap-2 wrap relative">
                        <button
                          className="btn btn-outline capitalize btn-primary btn-sm"
                          onClick={() =>
                            handelDeclineFreelancerRestore(proposal)
                          }
                        >
                          Restore freelancer
                        </button>
                      </div>
                    </Right>
                  </div>
                ))}
            </>
          ) : (
            <div className="h-[500px] flex justify-center items-center">
              <h1 className="font-bold text-2xl">
                There is nothing to show on the decline freelancer list
              </h1>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DeclineFreelancer;
