import React, { useEffect, useState } from "react";
import "./placeorder.css";
import "./order.css";
import axios from "axios";
import { serverUrl } from "../../../hooks/AllServerUrlControl/useAllServerUrlControl.js";

const PlaceOrder = (props) => {
  const id = "ldohgzf1itbjt9o4vv9";
  const [phpVariable, setPhpVariable] = useState("");
  const [job, setJob] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      console.log("namstaaa");
      try {
        // Make the API request to the PHP script
        const response = await axios.get(`${serverUrl}/client/pays`);
        const param1 = response.data;
        setPhpVariable(param1);
        // Update the state with the PHP variable value
        const response2 = await axios.get(
          `${serverUrl}/client/single_postingJob/${param1}`
        );
        const param2 = response2.data.result;
        setJob(param2);
        // await fetch(
        //     `http://localhost:8080/api/v1/client/single_postingJob/${phpVariable}`
        // )
        //     .then((res) => res.json())
        //     .then((data) => setJob(data.result));
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handlePay = async () => {
    console.log("hiiiii");
    try {
      axios
        .put(`${serverUrl}/client/update_postingJob/${id}`, job.PersonID)
        .then((response) => {
          // Handle the response
          console.log("Data updated successfully:", response.data);
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error updating data:", error);
        });
      // console.log(response.message);
      // navigate('/success');
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="checkout">
      <div className="ordodd">
        <h1>Checkout</h1>
      </div>
      <div className="outer-container">
        <div className="inner-container-1">
          <div className="title">
            <h4>Billing details</h4>
          </div>
          <form>
            <div className="form-row d-flex gap-3 m-3">
              <div className="form-group  col-md">
                <label for="inputEmail4">First Name</label>
                <input
                  type="text"
                  className="form-control border border-gray rounded-0"
                  id="inputEmail4"
                />
              </div>
              <div className="form-group col-md ">
                <label for="inputPassword4">Last Name</label>
                <input
                  type="text"
                  className="form-control border border-gray rounded-0"
                  id="inputPassword4"
                />
              </div>
            </div>
            <div className="form-group m-3">
              <label for="inputAddress">Job detail</label>
              <input
                type="text"
                className="form-control border border-gray rounded-0"
                id="inputAddress"
              />
            </div>
            <div className="form-group m-3">
              <label for="inputAddress2">Price ( in $ )</label>
              <input
                type="number"
                className="form-control border border-gray rounded-0"
                id="inputAddress2"
              />
            </div>
            <div className="form-group m-3">
              <label for="inputCity">Company email</label>
              <input
                type="text"
                className="form-control border border-gray rounded-0"
                id="inputCity"
              />
            </div>
            <div className="form-group m-3">
              <label for="inputState">Company Address</label>
              <input
                type="text"
                className="form-control border border-gray rounded-0"
                id="inputState"
              />
            </div>
          </form>
          <div className="title">
            <h4>Additional information</h4>
          </div>
          <div className="form-group m-3">
            <label for="inputAdd">Order notes (optional)</label>
            <textarea
              type="text"
              className="form-control h-30 border border-gray rounded-0"
              id="inputAdd"
              placeholder="Notes about your order, e.g. special notes for delivery."
            />
          </div>
        </div>
        <div className="inner-container-2">
          <div className="title">
            <h4>Job payment details</h4>
          </div>
          <div className="Placeorder">
            <div className="order">
              <table className="shop_table">
                <tbody>
                  <tr>
                    <td className="product-name">CLIENT ID</td>
                    <td className="product-total">{job.PersonID}</td>
                  </tr>
                  <tr className="cart-item">
                    <td className="product-name">{job.job_categories}</td>
                    <td className="product-total">$ {job.minimum_price}</td>
                  </tr>
                  <tr className="sub-total">
                    <td className="product-name">Sub Total</td>
                    <td className="product-total">{job.minimum_price}</td>
                  </tr>
                  <tr className="sub-total">
                    <td className="product-name">Total</td>
                    <td className="product-total">$ {job.minimum_price}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="wc_payment_methods">
              <div className="payment_method_ccavenue">
                <div>CCAvenue</div>
                <div>
                  <img
                    src="https://elite-professionals.in/wp-content/plugins/ccavanue-woocommerce-payment-getway/images/logo.png"
                    alt="CCAvenue"
                  />
                </div>
              </div>
              <div className="payment_box">
                <p>
                  Pay by Credit / Debit card / Internet Banking through CCAvenue
                  Secure Servers.
                </p>
              </div>
              <div className="privacy-policy-text">
                <p>
                  Your personal data will be used to process your order, support
                  your experience throughout this website, and for other
                  purposes described in our{" "}
                  <a
                    href="https://elite-professionals.in/?page_id=3"
                    className="woocommerce-privacy-policy-link"
                    target="_blank"
                  >
                    privacy policy
                  </a>
                  .
                </p>
                <div className="d-flex gap-10">
                  <button
                    type="submit"
                    className="butt btn btn-primary"
                    id="button"
                    onClick={handlePay}
                  >
                    Make Payment
                  </button>
                  <button
                    type="submit"
                    className="butt btn btn-primary"
                    id="button"
                  >
                    Cancel Payment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceOrder;
