import React from "react";
import ReviewSlider from "./ReviewSlider";

const FreelancerReview = () => {
  return (
    <div className="bg-[#EBF1F7] category-bg">
      <div className="container mx-auto pb-20 md:pb-40">
        <div className="pt-32">
          <h2 className="text-center text-4xl font-bold mb-2">
            Top <span className="text-primary">Freelancers</span>
          </h2>
          <p className="text-center">
            Highly skilled and talented top Freelancer
          </p>
        </div>
        <div className="my-6">
          <ReviewSlider />
        </div>
      </div>
    </div>
  );
};

export default FreelancerReview;
