import React, { useContext, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { DashboardClientJobsNavbar } from "../../../../hooks/NavbarAllFunction/useNavbarAllFunction";

const HireNavbar = () => {
  const { userCurrentLocationPath } = useContext(globalFunctionOneContext);
  const { hireMenuActive } = DashboardClientJobsNavbar(userCurrentLocationPath);

  // hire menu list
  const hireMenuList = [
    {
      id: 1,
      listText: "Hire",
      path: "/dashboard/client/jobs/hire",
    },
    // {
    //   id: 2,
    //   listText: "Offers",
    //   path: "/dashboard/client/jobs/hire/offer",
    // },
  ];

  return (
    <div>
      <div className="border-b-2 py-1 mb-2">
        <ul className="flex gap-4 my-2 cursor-pointer">
          {hireMenuList &&
            hireMenuList.map((list) => (
              <li key={list?.id}>
                <Link
                  to={list?.path}
                  className={`${
                    hireMenuActive === list?.id
                      ? "font-bold border-b-2 border-black"
                      : ""
                  }`}
                >
                  {list?.listText}
                </Link>
              </li>
            ))}
        </ul>
      </div>
      <Outlet />
    </div>
  );
};

export default HireNavbar;
