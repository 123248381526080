import { useQuery } from "react-query";
import { serverUrl } from "./AllServerUrlControl/useAllServerUrlControl";
import { useContext } from "react";
import { globalFunctionOneContext } from "../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

export const useAllMessage = (client) => {
  const { data, isLoading, refetch, error } = useQuery(["message"], () =>
    fetch(`${serverUrl}/client/get_invite_message`, {
      method: "GET",
    }).then((res) => {
      return res.json();
    })
  );
  // console.log(data);
  return { data, refetch, isLoading };
};

// All freelancer message get function
export const FreelancerAllMessageUser = (freelancer) => {
  const { firebaseUser } = useContext(globalFunctionOneContext);

  const {
    data = [],
    isLoading,
    refetch,
    error,
  } = useQuery(["freelancerAllMessage", firebaseUser?.uid], () => {
    if (firebaseUser) {
      fetch(`${serverUrl}/freelancer/all-message?userId=${firebaseUser?.uid}`, {
        method: "GET",
      }).then((res) => {
        return res.json();
      });
    }
  });

  return {
    data: data || [],
    refetch,
    isLoading,
    error,
  };
};

// all client message get function

export const ClientAllMessageUser = () => {
  const { firebaseUser } = useContext(globalFunctionOneContext);

  const {
    data = [],
    isLoading,
    refetch,
    error,
  } = useQuery(["clientAllMessageData", firebaseUser?.uid], () => {
    if (firebaseUser) {
      fetch(`${serverUrl}/client/all-message?userId=${firebaseUser?.uid}`, {
        method: "GET",
      }).then((res) => {
        return res.json();
      });
    }
  });

  return { data: data || [], refetch, isLoading };
};

export const GetSingleJob = (id) => {
  const {
    data = [],
    isLoading,
    refetch,
    error,
  } = useQuery(["SingleJob", id], () => {
    if (id) {
      fetch(`${serverUrl}/client/single_postingJob/${id}`, {
        method: "GET",
      }).then((res) => {
        return res.json();
      });
    }
  });

  return { data: data || [], refetch, isLoading, error };
};
