import React, { useRef, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { AiFillEye } from "react-icons/ai";
import { BsPencilFill } from "react-icons/bs";
import { GiTireIronCross } from "react-icons/gi";
import { GoVerified } from "react-icons/go";
import { VscUnverified } from "react-icons/vsc";
import { RiChatPrivateFill } from "react-icons/ri";
import { TbPinnedOff } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { MdPayment } from "react-icons/md";

import Swal from "sweetalert2";
import { useJobPost } from "../../../../context/JobPostProvider";
import { PostJobActionTypes } from "../../../../state/PostJobState/PostJobActionTypes";
import PostJob from "../../PostJob/PostJob";
import { serverUrl } from "../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import axios from "axios";

const RightSlide = ({ job }) => {
  const [updateModal, setUpdateModal] = useState(false);
  const [updateId, setupdateId] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  // const path = location.pathname;
  // console.log(path);

  const { dispatch, refetch } = useJobPost();
  // console.log("d", postJobs);

  const { job_id, PersonID, payment_status } = job;

  const removeJob = (jobId, PersonID) => {
    return {
      type: PostJobActionTypes.DELETE_POST_JOB,
      payload: { id: jobId, PersonID },
    };
  };

  const inputRef = useRef(null);

  const handleCopyClick = async () => {
    try {
      if ("clipboard" in navigator) {
        // clipboard API supported
        await navigator.clipboard.writeText(inputRef.current.value);
        toast.success("Text copied to clipboard");
      } else {
        // clipboard API not supported
        inputRef.current.select();
        document.execCommand("copy");
        toast.success("Text copied to clipboard");
      }
    } catch (err) {
      toast.error("Failed to copy text: ", err);
    }
  };

  const removeProduct = async (jobId, PersonID) => {
    try {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (confirm.isConfirmed) {
        const response = await fetch(
          `${serverUrl}/client/delete_postingJob/${jobId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              uid: PersonID,
            },
          }
        );
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        const data = await response.json();
        console.log(data);
        dispatch(removeJob(jobId, PersonID));
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        refetch();
      }
    } catch (error) {
      console.error(error);
      Swal.fire("Error", "Could not delete the product", "error");
    }
  };
  const handlePayment = async () => {
    try {
      // Make the POST request using axios
      const response = await axios.post(`${serverUrl}/client/pay`, {
        id: job_id,
      });

      // Handle the response from the PHP server
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
    console.log("donneeee");
    navigate(`//elite-professionals.in/code.php`);
  };

  return (
    <>
      <div className="border-l-2 border-gray-200 px-2 py-2">
        <div>
          <ul className="text-secondary">
            {!payment_status && (
              <li
                onClick={handlePayment}
                className="flex gap-1 items-center cursor-pointer"
              >
                <MdPayment /> <span> Complete Payment</span>
              </li>
            )}
            <li>
              <label
                htmlFor="post_job"
                onClick={() => {
                  setUpdateModal(!updateModal);
                  setupdateId(job_id);
                }}
                className="flex gap-1 items-center cursor-pointer"
              >
                <BsPencilFill />
                <span>Edit posting </span>
              </label>
            </li>
            <li
              onClick={() => navigate(`/jobs/job/${job_id}`)}
              className="flex gap-1 items-center cursor-pointer"
            >
              <AiFillEye /> <span> View posting </span>
            </li>
            <li className="flex gap-1 items-center cursor-pointer">
              <TbPinnedOff /> <span> Reuse posting </span>
            </li>
            <li
              onClick={() => removeProduct(job_id, PersonID)}
              className="flex gap-1 items-center cursor-pointer"
            >
              <GiTireIronCross /> <span> Remove posting </span>
            </li>
            <li className="flex gap-1 items-center cursor-pointer">
              <RiChatPrivateFill /> <span> Make private </span>
            </li>
          </ul>
        </div>
        <div className="divider m-0"></div>
        <div className="flex gap-2">
          <div>
            <h2 className="card-title"> About the client</h2>
            {payment_status ? (
              <div className="flex items-center gap-2">
                <GoVerified className="text-primary" />
                <p>Payment method verified</p>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <VscUnverified className="text-secondary text-xl" />
                <p>Payment not verified</p>
              </div>
            )}

            <h2 className="card-title">Peru</h2>
            <h3>Lima 2:39 pm</h3>
            <p>Member since Jan 2,2023</p>
          </div>
          <div>
            <button className="btn btn-sm shadow-md btn-circle no-animation btn-outline btn-base-100,">
              <BsPencilFill />
            </button>
          </div>
        </div>
        <div className="divider"></div>
        <div>
          <h2>Job link</h2>
          <input
            ref={inputRef}
            type="text"
            className="input input-bordered input-sm w-full"
            value={`http://www.hire.elite-professionals.in/jobs/job/${job_id}`}
          />
          <label
            onClick={handleCopyClick}
            className=" ml-2 btn btn-sm no-animation btn-outline btn-primary mt-2"
          >
            Copy link
          </label>
        </div>
      </div>
      <Toaster position="bottom-right" reverseOrder={true} />
      {updateModal && (
        <PostJob post={updateModal} setPost={setUpdateModal} ID={updateId} />
      )}
    </>
  );
};

export default RightSlide;
