import React, { useContext, useState } from "react";
import { SingleUserProfileGet } from "../../../../hooks/useUser";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const SalaryExpectation = () => {
  const { firebaseUser, currentUser } = useContext(globalFunctionOneContext);

  const [currentCtc, setCurrentCtc] = useState("");
  const { data, isLoading, error, refetch } = SingleUserProfileGet(
    currentUser?.PersonID
  );

  // one change input value get
  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setCurrentCtc(value);
  };
  // console.log(currentCtc);
  const arr1 = [
    {
      inputTitle: "What is your current CTC?",
    },
    { inputTitle: "How much of your $2.4LPA CTC is fixed?" },
    { inputTitle: "What is your Minimum Expected Total CTC?" },
    {
      inputTitle:
        "Of The total expected CTC , how much do you expect as a fixed?",
    },
  ];
  return (
    <div>
      <h2 className="uppercase font-semibold mt-5">💰 Salary Expectations</h2>
      <div className="divider mb-2 mt-0"></div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">What is your current CTC?</span>
        </label>
        <label className="input-group input-group-sm">
          <span>$</span>
          <input
            type="number"
            onChange={handleInputValue}
            name="currentCtc"
            placeholder="0.00"
            defaultValue={data?.result?.currentCtc}
            className="input input-bordered input-sm"
          />
          <span>LPA</span>
        </label>
        <label className="label">
          <span className="label-text">
            How much of your CTC {currentCtc ? currentCtc : ""} is fixed?
          </span>
        </label>
        <label className="input-group input-group-sm">
          <span>$</span>
          <input
            name="fixedCtc"
            type="number"
            defaultValue={data?.result?.fixedCtc}
            placeholder={currentCtc ? currentCtc : "0.00"}
            className="input input-bordered input-sm"
          />
          <span>LPA</span>
        </label>
        <label className="label">
          <span className="label-text">
            What is your Minimum Expected Total CTC?
          </span>
        </label>
        <label className="input-group input-group-sm">
          <span>$</span>
          <input
            name="expectedCtc"
            type="number"
            placeholder="0.00"
            defaultValue={data?.result?.expectedCtc}
            className="input input-bordered input-sm"
          />
          <span>LPA</span>
        </label>
        <label className="label md:w-[50%] sm:w-full">
          <span className="label-text">
            Of The total expected CTC , how much do you expect as a fixed?
          </span>
        </label>
        <label className="input-group input-group-sm">
          <span>$</span>
          <input
            name="muchExpectedCtc"
            type="number"
            placeholder="0.00"
            defaultValue={data?.result?.muchExpectedCtc}
            className="input input-bordered input-sm"
          />
          <span>LPA</span>
        </label>
      </div>
    </div>
  );
};

export default SalaryExpectation;
