import useRazorpay from "react-razorpay";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from "axios";
import { useEffect, useState } from "react";
import { auth } from "../../../config/firebaseConfig/firebaseConfig";
import { useJobPost } from "../../../context/JobPostProvider";
import {
  serverUrl,
  serverRoot,
} from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import Swal from "sweetalert2";

function RazorPay({ amount, job_id, postData, post, setPost, children }) {
  const [pdf, setPdf] = useState("");
  const serverAddress = serverRoot;
  const invoicePath = pdf;
  const invoiceUrl = `${serverAddress}${invoicePath}`;

  // user info
  //auth ngrok http 3000 --authtoken=2UNpDaks5BILubj1shMoV63zxxR_5skkJM7EsVrBfvFHytMhw
  const [user] = useAuthState(auth);

  const {
    state: { postJobs, loading, error },
    refetch,
  } = useJobPost();

  /*const [paymentStatus, setPaymentStatus] = useState(""); */
  const [Razorpay] = useRazorpay();

  // get payment status
  let inr = Number(amount);

  const cardDetails = (response) => {
    // console.log(response);
    fetch(`${serverUrl}/payment/cardDetail/${response}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        personID: user.uid,
        job_id,
      }),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.success) {
            refetch();
            if (postData) {
              setPost(!post);
            }
            setPdf(data.data.invoiceLink);
            console.log(data);
          }
        })
      )
      .catch((error) => {
        // Handle any errors that occurred during the API request
        console.error("Error while making API request:", error);
      });
  };

  useEffect(() => {
    if (pdf) {
      console.log(pdf);

      // Open the invoice PDF in a new tab and check if it was blocked

      // const newWindow = window.open(invoiceUrl, "_blank");

      // Check if the popup was blocked

      Swal.fire({
        icon: "success",
        title: "success",
        text: "your payment has been successfully! invoice send your email, check it",
      });
    }
  }, [pdf, invoiceUrl]);

  const loadscript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const handlePayment = async () => {
    // create api integration
    let orderId = "oD" + Math.random() * Math.floor(Math.random() * Date.now());
    const res = await loadscript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      return alert("Razorpay SDK failed to Load. Are you online");
    }
    let paymentRes = {
      order_id: orderId,
      amount: Math.round(inr + (inr / 100) * 12),
      currency: "INR",
      payment_capture: 1,
    };
    let result = await axios.post(
      `${serverUrl}/payment/createorder`,
      paymentRes
    );
    // start

    if (!result.data.data) {
      return alert("Server error, Are you online");
    } else {
      const options = {
        key: "rzp_test_QoR2FszVsoVnHH",
        currency: result.data.data.currency,
        amount: result.data.data.amount * 100,
        order_id: result.data.id,
        name: "payment test",
        description: "Test Transaction",
        image:
          "https://media.istockphoto.com/id/1313644269/vector/gold-and-silver-circle-star-logo-template.jpg?s=612x612&w=0&k=20&c=hDqCI9qTkNqNcKa6XS7aBim7xKz8cZbnm80Z_xiU2DI=",
        handler: async function (response) {
          if (response.razorpay_payment_id) {
            cardDetails(response.razorpay_payment_id);
          }
          /*   if (result) {
            const finalList = {
              OrderId: orderId,
              payment: result.data.method,
              addressId: newCart.addressId,
              shippingPrice: newCart.shippingPrice,
              total: result.data.amount / 100,
              cart: newCart.cart,
              status: result.data.status,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
            };
          } */
        },
        prefill: {
          name: postJobs[0]?.companyName,
          email: postJobs[0]?.companyEmail,
          contact: "+88 54545454",
        },
        notes: {
          address: "payment test",
          serviceFee: (inr / 100) * 10,
          gst: (inr / 100) * 2,
          cost: inr,
          company: postJobs[0]?.companyName,
        },
        theme: {
          color: "#3399cc",
        },
      };
      const rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });
      rzp1.open();
    }
    console.log("company info", {
      name: postJobs[0].companyName,
      email: postJobs[0]?.companyEmail,
      serviceFee: (inr / 100) * 10,
      gst: (inr / 100) * 2,
      cost: inr,
    });
    // end
  };
  return (
    <div>
      <span
        // disabled={!id}
        onClick={() => handlePayment()}
        className="btn btn-primary"
        style={
          {
            // display: job.payment_status || !flag ? "none" : "block",
          }
        }>
        {children}
      </span>
    </div>
  );
}

export default RazorPay;
