import React, { useState } from "react";
import SearchHeader from "./SearchResult/SearchHeader";

import SearchResult from "./SearchResult/SearchResult";

const Search = () => {
  const [searchInput, setSearchInput] = useState("");

  return (
    <div>
      <SearchHeader setSearchInput={setSearchInput} />
      <SearchResult searchInput={searchInput} />
    </div>
  );
};

export default Search;
