import React from "react";

const DrawerBodyWLg = ({
  onClose,
  isVisible,
  children,
  identityId,
  drawerWrapperContainer,
  zIndex,
  customButton,
}) => {
  return (
    <section
      className={`fixed ${
        drawerWrapperContainer
          ? drawerWrapperContainer
          : "top-16 right-0 w-[70%]"
      }`}
      style={{ zIndex: zIndex }}
    >
      <div className="drawer drawer-end">
        <input id={identityId} type="checkbox" className="drawer-toggle" />

        <div className="drawer-side">
          <label
            htmlFor={identityId}
            className="drawer-overlay"
            style={{ background: "transparent" }}
          ></label>
          <div className="p-4 w-full h-full bg-[#fff]  text-base-content mb-10 shadow-lg">
            {!customButton && (
              <button
                className="btn-primary btn btn-sm btn-outline btn-circle place-self-end"
                onClick={() => onClose()}
              >
                ✕
              </button>
            )}
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DrawerBodyWLg;
