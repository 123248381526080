import React from "react";
import { SiGeocaching } from "react-icons/si";
const SearchInput = () => {
  // Window Loading
  return (
    <div>
      <h2>Filter Project By</h2>
      <div className="divider"></div>
      <div>
        <div className="form-control">
          <label className="label">
            <span className="label-text font-bold">Search By Geo Location</span>
          </label>
          <div className="input-group">
            <input
              type="text"
              placeholder="Geo location"
              className="input input-bordered w-full"
            />
            <button className="btn btn-square btn-outline  btn-secondary">
              <SiGeocaching className="text-secondary" />
            </button>
          </div>
          <label className="label mt-4">
            <span className="label-text font-bold">Start your search</span>
          </label>
          <div className="input-group">
            <input
              type="text"
              placeholder="Type keyword"
              className="input input-bordered w-full"
            />
            <button className="btn btn-square btn-outline  btn-secondary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
