import React, { useContext, useEffect, useState } from "react";
import { StepperContext } from "../../../../../context/StepContext";

const Account = ({ err, setErr }) => {
  const [emailError, setEmailError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { userData, setUserData } = useContext(StepperContext);
  const pattern =
    /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  useEffect(() => {
    const { firstName, lastName, username, email } = userData;
    const result = pattern.test(email);
    if (!firstName || !lastName || !username || !email || result === false) {
      setErr(true);
    } else {
      setErr(false);
    }

    if (email && result === false) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
    }
  }, [setErr, userData, pattern]);

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-2 gap-4">
        <div className="form-control">
          <div className="input-group">
            <select className="select select-bordered">
              <option>Mr</option>
              <option>Miss</option>
            </select>
            <input
              onChange={handleChange}
              value={userData["firstName"] || ""}
              name="firstName"
              type="text"
              placeholder="First Name"
              className="input input-bordered w-full max-w-xs"
            />
          </div>
        </div>

        <div className="form-control md:max-w-full sm:max-w-xs ">
          <input
            onChange={handleChange}
            value={userData["lastName"] || ""}
            required
            name="lastName"
            placeholder="Last Name"
            className="input input-bordered md:max-w-full sm:max-w-xs"
          />
          {/* <label className="label">
           <span className="label-text-alt">Alt label</span>
         </label> */}
        </div>
        <div className="form-control md:max-w-full sm:max-w-xs ">
          <input
            onChange={handleChange}
            value={userData["username"] || ""}
            name="username"
            required
            type="text"
            placeholder="Username"
            className="input input-bordered md:max-w-full sm:max-w-xs"
          />
          {/* <label className="label">
           <span className="label-text-alt">Alt label</span>
         </label> */}
        </div>

        <div className="form-control md:max-w-full sm:max-w-xs  relative">
          <input
            onChange={handleChange}
            value={userData["email"] || ""}
            name="email"
            type="email"
            required
            placeholder="Email"
            className="input input-bordered md:max-w-full sm:max-w-xs"
          />

          {emailError && (
            <label className="label">
              <span className="label-text-alt text-error">{emailError}</span>
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

export default Account;
