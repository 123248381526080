import React, { useContext, useEffect, useState } from "react";
import { GiSelfLove } from "react-icons/gi";
import InviteJobModal from "../../InviteJobModal/InviteJobModal";
import { globalFunctionOneContext } from "../../../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { serverUrl } from "../../../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { toast } from "react-toastify";
import {
  AllSaveFreelancerDataCount,
  AllSaveFreelancerId,
} from "../../../../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";

const Right = ({ freelancer }) => {
  const { currentUser, Loading, userCurrentLocationPath } = useContext(
    globalFunctionOneContext
  );
  const [modalTwo, setModalTwo] = useState(false);
  const [singleFreelancer, setSingleFreelancer] = useState(null);
  const [freelancerId, setFreelancerId] = useState(null);
  // console.log(jobApplyData);

  // get AllSaveFreelancerData count data get function
  const {
    data: allSaveFreelancerDataCount,
    refetch: saveFreelancerDataRefetch,
    isLoading: allSaveFreelancerDataCountLoading,
  } = AllSaveFreelancerDataCount(currentUser?.PersonID);

  const { data, refetch, isLoading } = AllSaveFreelancerId(
    currentUser?.PersonID,
    10
  );

  // handelSaveFreelancer
  const handelSaveFreelancer = async (freelancerId) => {
    setFreelancerId(freelancerId);
    const userData = {
      clientId: currentUser?.PersonID,
      freelancerId,
    };

    if (currentUser?.PersonID && userData?.freelancerId) {
      const res = await fetch(`${serverUrl}/client/save/freelancer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          uid: currentUser?.PersonID,
        },
        body: JSON.stringify(userData),
      });

      const data = await res.json();
      if (data?.status === "success") {
        refetch();
        saveFreelancerDataRefetch();
        toast.success("This freelancer save successful !!!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("This freelancer save not successful !!!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  // save freelancer data loop function
  useEffect(() => {
    if (data?.result) {
      const saveData = data?.result.find(
        (freelancerData) =>
          freelancerData?.freelancerId === freelancer?.PersonID
      );
      setSingleFreelancer(saveData);
    }
  }, [data?.result, freelancer?.PersonID]);

  // loading
  if (isLoading) {
    return <div>loading Right ........</div>;
  }

  return (
    <>
      <div>
        <div className="mt-4 flex items-center gap-2 wrap">
          <button
            className="btn btn-circle btn-primary btn-outline btn-sm "
            data-tooltip="saved"
            onClick={() => handelSaveFreelancer(freelancer?.PersonID)}
            disabled={
              singleFreelancer?.freelancerId === freelancer?.PersonID ||
              freelancerId === freelancer?.PersonID
            }
          >
            <GiSelfLove className="text-xl" />
          </button>
          {/* <button className="btn btn-outline btn-primary btn-sm">Hire</button> */}

          {!(userCurrentLocationPath === "/guest/mode/freelancer") && (
            <label
              onClick={() => setModalTwo(!modalTwo)}
              htmlFor="my-modal-5"
              className="capitalize btn btn-primary btn-sm"
            >
              Invite to job
            </label>
          )}
        </div>
      </div>

      {modalTwo && (
        <InviteJobModal
          freelancer={freelancer}
          modalTwo={modalTwo}
          setModalTwo={setModalTwo}
        />
      )}
    </>
  );
};

export default Right;
