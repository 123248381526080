import React, { useContext } from "react";
import { SingleUserProfileGet } from "../../../../hooks/useUser";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const BiggestAchievement = () => {
  const { firebaseUser, currentUser } = useContext(globalFunctionOneContext);

  const { data, isLoading, error, refetch } = SingleUserProfileGet(
    currentUser?.PersonID
  );

  return (
    <div>
      <h2 className="uppercase font-semibold mt-10">🥇 Biggest Achievement</h2>
      <div className="divider mb-2 mt-0"></div>
      <div className="form-control">
        <label className="label">
          <span className="label-text font-semibold">
            What is your biggest Achievement
          </span>
        </label>
        <textarea
          required
          name="achievement"
          defaultValue={data?.result?.bigest_achivement}
          className="textarea textarea-bordered h-24 sm:w-full md:w-[70%]"
          placeholder="Explain about your biggest achievement"
        ></textarea>
      </div>
    </div>
  );
};

export default BiggestAchievement;
