import React, { useEffect, useState } from "react";
import { IoIosArrowUp } from "react-icons/io";
import { serverUrl } from "../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { FiSearch } from "react-icons/fi";
import { BiCurrentLocation } from "react-icons/bi";

const LeftSide = ({ jobs, setJobs }) => {
  //
  /*const fixedpric { label: 'Less than $500', value: 'lt500' },
    { label: '$500 - $1,000', value: '500To1000' },
    { label: '$1,000 - $5,000', value: '1000To5000' },
    { label: '$5,000 - $10,000', value: '5000To10000' },
    { label: 'More than $10,000', value: 'gt10000' },
    { label: 'Custom amount', value: 'custom' }, */
  const [jobTitle, setjobTitle] = useState("");
  const [location, setLocation] = useState("");
  const [project_location_type, setProject_location_type] = useState("");
  const [jobDuration, setJobDuration] = useState("");
  const [expected, setExpected] = useState("");
  const [project_level, setProject_level] = useState("");
  const [skillsSearchValue, setSkillsSearchValue] = useState("");
  const [allSkillsSearchValue, setAllSkillsSearchValue] = useState([]);
  const [skillSerchDatabas, setSkillSerchDatabas] = useState("");

  // job type  menu array
  const jobType = ["Remote", "Onsite", " Partial Onsite"];

  // Job Durations menu Array
  const job_durations = [
    "01 to 03 months",
    "03 to 06 months",
    "more than 6 months",
    "Less than a month",
    "Less than a weak",
  ];
  // job filtering all category
  useEffect(() => {
    if (
      jobTitle ||
      location ||
      project_location_type ||
      project_level ||
      expected ||
      skillSerchDatabas ||
      jobDuration
    ) {
      fetch(
        `${serverUrl}/client/getTotalJob_filter?job_title=${jobTitle}&JobLocation=${location}&project_location_type=${project_location_type}&project_level=${project_level}&expected=${expected}&skillSerchDatabas=${skillSerchDatabas}&job_duration=${jobDuration}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            setJobs(data.result);
          }
        });
    }
  }, [
    jobTitle,
    location,
    project_location_type,
    expected,
    project_level,
    allSkillsSearchValue,
    skillSerchDatabas,
    setJobs,
    jobDuration,
  ]);

  const project_levels = ["basic level", "expensive", " medium level"];

  // HandelJobSearchFilter
  const HandelJobSearchFilter = (e) => {
    e.preventDefault();
    const from = e.target;
    const jobTitleSearch = from.jobTitleSearch?.value;
    const jobLocationSearch = from.jobLocationSearch?.value;
    console.log(jobTitleSearch, jobLocationSearch);
    if (jobLocationSearch) {
      setLocation(jobLocationSearch);
    } else {
      setLocation("");
    }
  };

  return (
    <div>
      <div className="card  bg-base-100 h-[89vh] overflow-auto  border border-gray-200">
        <div className="card-body ">
          <form onSubmit={HandelJobSearchFilter}>
            {/* Job title filter input */}
            <div>
              {/* filtration title */}
              {/* <h2 className="flex gap-2 text-lg font-semibold justify-between items-center">
                <span>Search start your job</span>
              </h2> */}
              {/* filtration job title */}
              {/* <div className="flex justify-center items-center relative mt-3 mb-5">
                <input
                  type="text"
                  name="jobTitleSearch"
                  placeholder="Search Your job"
                  className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc] pr-14 rounded-md input-sm"
                />
                <button
                  type="submit"
                  className="text-[#fd03dc] absolute top-0 right-0 w-12 h-full text-lg rounded-l-lg flex justify-center items-center border-l-2"
                >
                  <FiSearch />
                </button>
              </div> */}
            </div>
            {/* Job title filter input */}
            <div>
              {/* filtration title */}
              {/* <h2 className="flex gap-2 text-lg font-semibold justify-between items-center">
                <span>Job location search</span>
              </h2> */}
              {/* filtration job title */}
              {/* <div className="flex justify-center items-center relative mt-3 mb-5">
                <input
                  type="text"
                  name="jobLocationSearch"
                  placeholder="Search Your job location"
                  className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc] pr-14 rounded-md input-sm"
                />
                <button
                  type="submit"
                  className="text-[#fd03dc] absolute top-0 right-0 w-12 h-full text-lg rounded-l-lg flex justify-center items-center border-l-2"
                >
                  <BiCurrentLocation />
                </button>
              </div> */}
            </div>
          </form>
          {/* 1st filter */}
          <div>
            {/* filtration title */}
            <h2 className="flex gap-2 text-lg font-semibold justify-between items-center">
              <span>Experience level</span>
              <span>
                <IoIosArrowUp />
              </span>
            </h2>
            {/* filtration item */}
            <div className="form-control">
              {project_levels.map((data, i) => (
                <label
                  key={i}
                  className="cursor-pointer gap-2 label justify-start"
                >
                  <input
                    type="radio"
                    name="radio-1"
                    value=""
                    className="radio radio-sm"
                    onClick={() => setProject_level(data)}
                  />
                  <span className="label-text">{data}</span>
                </label>
              ))}
            </div>
          </div>

          {/* 2nd filter */}
          <div>
            <div className="divider"></div>
            <h2 className="flex gap-2 text-lg font-semibold justify-between items-center">
              <span> Job duration </span>
              <span>
                {" "}
                <IoIosArrowUp />{" "}
              </span>
            </h2>

            <div className="form-control">
              {job_durations.map((data, i) => (
                <label
                  key={i}
                  className="cursor-pointer gap-2 label justify-start"
                >
                  <input
                    type="radio"
                    name="radio-2"
                    value=""
                    className="radio radio-sm"
                    onClick={() => setJobDuration(data)}
                  />
                  <span className="label-text">{data}</span>
                </label>
              ))}
            </div>
          </div>
          {/* 3rd filter */}
          <div>
            <div className="divider"></div>
            <h2 className="flex gap-2 text-lg font-semibold justify-between items-center">
              <span> Job location </span>
              <span>
                <IoIosArrowUp />
              </span>
            </h2>

            <div className="form-control">
              <input />

              {jobType &&
                jobType.map((data, i) => (
                  <label
                    key={i}
                    className="cursor-pointer gap-2 label justify-start"
                  >
                    <input
                      type="radio"
                      name="radio-3"
                      value=""
                      className="radio radio-sm"
                      onClick={() => setProject_location_type(data)}
                    />
                    <span className="label-text">{data}</span>
                  </label>
                ))}
            </div>
          </div>
          {/* 4th filter */}
          <div>
            <div className="divider"></div>
            <h2 className="flex gap-2 text-lg font-semibold justify-between items-center">
              <span> Fixed price </span>
              <span>
                <IoIosArrowUp />
              </span>
            </h2>

            <div className="form-control">
              <label className="cursor-pointer gap-2 label justify-start">
                <input type="radio" name="radio-4" className="radio radio-sm" />
                <span className="label-text">Less than $500</span>
              </label>
              <label className="cursor-pointer gap-2 label justify-start">
                <input type="radio" name="radio-4" className="radio radio-sm" />
                <span className="label-text">$500 - $1,000</span>
              </label>
              <label className="cursor-pointer gap-2 label justify-start">
                <input type="radio" name="radio-4" className="radio radio-sm" />
                <span className="label-text">$1,000 - $5,000</span>
              </label>
              <label className="cursor-pointer gap-2 label justify-start">
                <input type="radio" name="radio-4" className="radio radio-sm" />
                <span className="label-text">$5,000 - $10,000</span>
              </label>
              <label className="cursor-pointer gap-2 label justify-start">
                <input type="radio" name="radio-4" className="radio radio-sm" />
                <span className="label-text">More than $10,000</span>
              </label>
            </div>
          </div>
          {/* end the filtration */}
        </div>
      </div>
    </div>
  );
};

export default LeftSide;
