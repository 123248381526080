import React from "react";

import { BsFillPlayFill } from "react-icons/bs";

import "./homePage.css";
import { useNavigate } from "react-router-dom";
import Feedback from "./feedback/Feedback";
import CategoriesHome from "./categories/CategoriesHome";
import FreelancerReview from "./FreelancerReview/FreelancerReview";
import FooterBanner from "./banner/FooterBanner";
import LatestJob from "./latestJob/LatestJob";

const HomePage = () => {
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    const select = e.target.select.value;
    switch (select) {
      case "Job":
        navigate("/freelancer/jobs");
        break;
      case "Client":
        break;
      case "Freelancer":
        navigate("/guest/mode/freelancer");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="bg-opacity-95 gradient-pink-purple-95">
        <div className="max-w-6xl mx-auto pt-5 md:pt-52">
          <div className=" h-screen bg-[right_top_2rem] bg-[length:530px_315px] bg-no-repeat bg">
            <div className="py-5">
              <div className="w-full md:w-1/2">
                <h1 className="text-base-100 font-semibold">
                  <span className="text-[50px]">Most Powerful Directory </span>
                  <span className="text-[40px]">
                    Available for Service Providers
                  </span>
                </h1>
              </div>
              <div className="my-2">
                {/* need to write input box and select box */}
                <div className="">
                  <form
                    onSubmit={onSubmit}
                    className="glass md:w-3/5 p-4 rounded-md shadow-xl">
                    <fieldset className="flex">
                      <div className="border border-r-0 bg-white border-gray-300 rounded-md rounded-r-none p-2 w-full">
                        I am looking for
                      </div>

                      <select
                        className="border  rounded-none border-gray-300 p-2 w-full cursor-pointer"
                        name="select">
                        <option>Job</option>
                        {/* <option>Client</option> */}
                        <option>Freelancer</option>
                      </select>
                      <button
                        type="submit"
                        className=" btn btn-primary btn-md px-2 no-animation rounded-l-none text-white p-2 rounded-md">
                        Search
                      </button>
                    </fieldset>
                  </form>
                </div>
              </div>
              <div className="flex gap-2 wt-videoholder mt-7 ">
                <div className="wt-videoshow  bg-white rounded-full p-2">
                  <a href="#" className="relative">
                    <BsFillPlayFill className="text-primary" size={40} />
                  </a>
                </div>
                <div className="text-base-100">
                  <h2 className="card-title">See For Yourself!</h2>
                  <p>How it works & experience the ultimate joy.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*categories*/}
      <CategoriesHome />
      {/* <LatestJob /> */}
      {/* freelancer review */}
      <FreelancerReview />

      {/* feedback */}
      <Feedback />
      {/* banner */}
      <FooterBanner />
    </>
  );
};

export default HomePage;
