import React from "react";

export const dummyReview = [
  {
    name: "Alice",
    country: "USA",
    rating: 4.5,
    countryCode: "US",
  },
  {
    name: "Bob",
    country: "UK",
    rating: 4.7,
    countryCode: "GB",
  },
  {
    name: "Charlie",
    country: "Canada",
    rating: 4.2,
    countryCode: "CA",
  },
  {
    name: "David",
    country: "Australia",
    rating: 4.8,
    countryCode: "AU",
  },
  {
    name: "Eve",
    country: "France",
    rating: 4.6,
    countryCode: "FR",
  },
  {
    name: "Frank",
    country: "Germany",
    rating: 4.3,
    countryCode: "DE",
  },
  {
    name: "Grace",
    country: "India",
    rating: 4.9,
    countryCode: "IN",
  },
  {
    name: "Harry",
    country: "Japan",
    rating: 4.4,
    countryCode: "JP",
  },
  {
    name: "Irene",
    country: "China",
    rating: 4.1,
    countryCode: "CN",
  },
  {
    name: "Jack",
    country: "Brazil",
    rating: 4.0,
    countryCode: "BR",
  },
];
