import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import profileImage from "../../media/Information tab-bro.png";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
const Dashboard = () => {
  const { windowLoading } = useContext(globalFunctionOneContext);

  // window loading
  if (windowLoading) {
    return <div>hello Window Loading ..........</div>;
  }
  return (
    <div className="max-w-5xl mx-auto">
      <div className="card w-full my-10 mx-2 border border-gray-200 rounded-lg">
        <div className="card-body">
          <div className="grid grid-cols-4 gap-4 ">
            <div>
              <img className="w-full h-40" src={profileImage} alt="" />
            </div>
            <div className="col-span-2">
              <h2 className="card-title">Your skill are high demand</h2>
              <p className="mt-4">
                Will project catalog, you set the scope,timeline and price so
                clients can come to you.
              </p>
              <div className="mt-4">
                <button className="btn btn-primary ">
                  Go to My Project Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 2nd */}
      <Outlet />
    </div>
  );
};

export default Dashboard;
