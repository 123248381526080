import React, { useContext, useEffect, useState } from "react";
import {
  BsArrowRight,
  BsCurrencyRupee,
  BsLink45Deg,
  BsPlusLg,
} from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import ModalBody from "../../Shared/ModalBody/ModalBody";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import {
  FreelancerContractMilestoneDetails,
  FreelancerRequestSingleJobMilestoneGet,
} from "../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import { ImBin, ImLocation2 } from "react-icons/im";
import { FiEdit } from "react-icons/fi";
import { serverUrl } from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { toast } from "react-toastify";
import DrawerBodyWLg from "../../Shared/DrawerBody/DrawerBodyWLg";

const MyJobsContractDetails = () => {
  const {
    currentUser,
    handelCustomDrawerOpenCls,
    setHandelCustomDrawerOpenCls,
    setUserCurrentLocationPathParameter,
  } = useContext(globalFunctionOneContext);
  const parameter = useParams();
  const [myJobMenuActiveTracker, setMyJobMenuActiveTracker] = useState(1);
  const [handelSubmitWorkModal, setHandelSubmitWorkModal] = useState(false);
  const [handelAddMilestoneModal, setHandelAddMilestoneModal] = useState(false);
  const [handelEditMilestoneModal, setHandelEditMilestoneModal] =
    useState(false);
  const [handelSubmitWorkFile, setHandelSubmitWorkFile] = useState(null);
  const [handelEditMilestoneData, setHandelEditMilestoneData] = useState(null);

  // This is freelancer My jobs route menu list
  const menuItems = [
    {
      id: 1,
      url: "#",
      text: "Overview",
    },
    {
      id: 2,
      url: "#",
      text: "Message",
    },
    {
      id: 3,
      url: "#",
      text: "Details",
    },
  ];

  // This is single milestone details  get function
  const {
    data: singleMilestoneDetails,
    refetch: singleMilestoneDetailsRefetch,
    isLoading: singleMilestoneDetailsLoading,
  } = FreelancerContractMilestoneDetails(parameter?.id);

  // // this is single job all milestone get function
  const {
    data: FreelancerRequestSingleJobMilestone,
    refetch: FreelancerRequestSingleJobMilestoneRefetch,
    isLoading: FreelancerRequestSingleJobMilestoneLoading,
  } = FreelancerRequestSingleJobMilestoneGet(
    currentUser?.PersonID,
    singleMilestoneDetails?.result?.jobId
  );

  // This function is freelancer milestone work submit function
  const handelFreelancerMilestoneWorkSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    if (handelSubmitWorkFile?.name) {
      const formDataToSend = new FormData();
      formDataToSend.append("freelancerWorkSubmitFile", handelSubmitWorkFile);
      const res = await fetch(`${serverUrl}/milestone/work/submit/freelancer`, {
        method: "POST",
        headers: {
          id: parameter?.id,
          Accept: "multipart/form-data",
        },
        body: formDataToSend,
      });
      const data = await res.json();
      if (data?.status === "success") {
        form.reset();
        setHandelSubmitWorkFile();
        setHandelSubmitWorkModal(false);
        toast.success("Your milestone work submit is successful", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Your milestone work submit failed", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  // this is freelancer request milestone
  const handelFreelancerRequestMilestone = async (e) => {
    e.preventDefault();
    const form = e.target;
    const milestoneDescription = form.milestoneDescription.value;
    const milestoneAmount = form.milestoneAmount.value;
    const deadline = form.deadline.value;
    const milestoneRequestMessage = form.milestoneRequestMessage.value;

    if (
      singleMilestoneDetails?.result?.jobId &&
      currentUser?.PersonID &&
      singleMilestoneDetails?.result?.PersonID
    ) {
      const freelancerMilestoneRequest = {
        jobId: singleMilestoneDetails?.result?.jobId,
        milestoneDescription,
        milestoneDeadline: deadline,
        milestoneAmount,
        milestoneRequestState: "request",
        milestoneRequestPersonId: currentUser?.PersonID,
        milestoneRequestMessage,
        milestoneReceiverPersonId: singleMilestoneDetails?.result?.PersonID,
      };
      console.log(freelancerMilestoneRequest, "freelancerMilestoneRequest");

      const res = await fetch(`${serverUrl}/milestone/request/freelancer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          // authorization: `bearer ${localStorage.getItem("access_Token")}`,
        },
        body: JSON.stringify(freelancerMilestoneRequest),
      });
      const data = await res.json();
      if (data?.status === "success") {
        setHandelAddMilestoneModal(false);
        toast.success("Your milestone request is successful", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Your milestone request failed", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  // single milestone edit
  const handelFreelancerRequestMilestoneEdit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const milestoneDescription = form.milestoneDescription.value;
    const milestoneAmount = form.milestoneAmount.value;
    const milestoneDeadline = form.deadline.value;

    const userData = {
      milestoneDescription,
      milestoneDeadline,
      milestoneAmount,
    };

    if (handelEditMilestoneData?.id) {
      const res = await fetch(
        `${serverUrl}/milestone//freelancer/request/milestone/update/${handelEditMilestoneData?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );
      const data = await res.json();
      if (data?.status === "success") {
        FreelancerRequestSingleJobMilestoneRefetch();
        setHandelEditMilestoneModal(false);
        toast.success("Milestone update success", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Milestone update fail", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  // single milestone delete
  const deleteSingleMilestone = async (id) => {
    console.log(id);
    const res = await fetch(
      `${serverUrl}/milestone//freelancer/request/milestone/delete/${id}`,
      {
        method: "DELETE",
      }
    );
    const data = await res.json();
    if (data?.status === "success") {
      FreelancerRequestSingleJobMilestoneRefetch();
      toast.success("Milestone delete success", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error("Milestone delete fail", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // this is handel usParameter
  useEffect(() => {
    setUserCurrentLocationPathParameter(parameter);
  }, [parameter, setUserCurrentLocationPathParameter]);

  if (
    !currentUser ||
    singleMilestoneDetailsLoading ||
    FreelancerRequestSingleJobMilestoneLoading
  ) {
    return <div>Freelancer job contract details page loading ...........</div>;
  }

  return (
    <div className="mt-5">
      <div className="max-w-[1200px] mx-auto">
        <div className="card px-2 w-full bg-base-100 shadow-xl mt-10">
          <div className="card-body">
            <div className="flex justify-between items-center">
              <div className="flex justify-start items-start gap-4">
                <div className="avatar">
                  <div className="w-14 rounded-full">
                    <img
                      src="https://i.ibb.co/MBkf6JK/My-Logo.jpg"
                      alt="My-Logo"
                    />
                  </div>
                </div>
                <div>
                  <h1 className="font-bold">
                    {singleMilestoneDetails?.result?.firstName +
                      singleMilestoneDetails?.result?.lastName}
                  </h1>
                  <p className="flex justify-start items-center">
                    <ImLocation2 />
                    <span>{singleMilestoneDetails?.result?.JobLocation}</span>
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <ul className="flex justify-start items-center">
                {menuItems &&
                  menuItems.map((li) => (
                    <li className="hover:bg-[#eef1fe]" key={li?.id}>
                      <Link
                        to={li?.url}
                        className={`flex flex-col px-2 mx-3 pb-3 cursor-pointer ${
                          myJobMenuActiveTracker === li?.id
                            ? "front-bold my_job_menu_list"
                            : ""
                        }`}
                        onClick={() => setMyJobMenuActiveTracker(li?.id)}
                      >
                        <p className={`${li?.hidden ? "opacity-0" : ""}`}>
                          {li?.subText}
                        </p>
                        <p>{li?.text}</p>
                      </Link>
                    </li>
                  ))}
              </ul>
              <hr className="mb-5 border-t-2 border-gray-300" />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
              <div className="col-span-2 border border-gray-300 rounded-lg p-5">
                <h1 className="text-2xl font-medium">Milestone Timeline</h1>
                <div className="milestone_timeline_container_wrapper border-l border-gray-400 mx-5 mt-5 py-5 pl-10 relative">
                  <p className="text-md font-medium">
                    {singleMilestoneDetails?.result?.milestoneDescription}
                  </p>
                  <p className="text-md font-medium">22 04.50 14 UTC 2023</p>
                  <p className="flex justify-start items-center text-md font-bold my-3">
                    <BsCurrencyRupee />
                    {singleMilestoneDetails?.result?.depositAmount}
                  </p>
                  <p className="flex justify-start items-center">
                    <FaCalendarAlt />
                    <span className="ml-2">
                      {(() => {
                        const date = new Date(
                          singleMilestoneDetails?.result?.dateLine
                        );
                        const monthNames = [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ];
                        const year = date.getFullYear() % 100;
                        const month = monthNames[date.getMonth()];
                        const day = date.getDate();

                        return `${month} ${day} ${year}`;
                      })()}
                    </span>
                  </p>
                  <button
                    className="btn btn-outline btn-primary btn-sm my-5"
                    onClick={() => setHandelSubmitWorkModal(true)}
                  >
                    Submit Work
                  </button>
                  <button className="btn btn-circle btn-outline btn-primary btn-sm absolute top-0 -left-4 bg-white">
                    1
                  </button>
                  <div className="absolute bottom-0 -left-4 flex justify-start items-center">
                    <button
                      type="button"
                      className="btn btn-circle btn-primary btn-sm"
                      onClick={() => setHandelAddMilestoneModal(true)}
                    >
                      <BsPlusLg className="text-lg font-bold" />
                    </button>
                    <p
                      className="text-primary ml-4 cursor-pointer"
                      onClick={() => setHandelAddMilestoneModal(true)}
                    >
                      Propose a new milestone
                    </p>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="manageMilestoneFreelancer"
                    onClick={() => setHandelCustomDrawerOpenCls(true)}
                    className="btn btn-outline btn-primary btn-sm my-5"
                  >
                    Manage milestone
                  </label>
                </div>
              </div>
              <div className="border border-gray-300 rounded-lg p-5">
                <h1 className="text-2xl font-medium">Earnings</h1>
                <div className="h-3 w-full bg-gray-200 rounded-full my-5">
                  <div
                    className="h-3 bg-primary rounded-l-full"
                    style={{ width: "10%" }}
                  ></div>
                </div>
                <ul className="pl-5 mt-3">
                  <li className="flex justify-between items-center w-full gap-4 my-1 relative">
                    <span className="absolute top-2 -left-5 bg-green-500 w-3 h-3 rounded-full"></span>
                    <p>Received</p>
                    <p className="text-right">$ 0.00 </p>
                  </li>
                  <li className="flex justify-between items-center w-full gap-4 my-1 relative">
                    <p>Funded (Escrow Protect on) Project</p>
                    <p className="text-right whitespace-nowrap">$ 38.156.39</p>
                    <span className="absolute top-2 -left-5 bg-gray-600 w-3 h-3 rounded-full"></span>
                  </li>
                  <li className="flex justify-between items-center w-full gap-4 my-1 relative">
                    <p>Project Price</p>
                    <p className="text-right">$ 5590,637.25</p>
                    <span className="absolute top-2 -left-5 bg-gray-200 w-3 h-3 rounded-full"></span>
                  </li>
                </ul>
              </div>
            </div>
            <hr className="my-3 border-t border-gray-300" />
          </div>
        </div>
      </div>

      {handelCustomDrawerOpenCls && (
        <DrawerBodyWLg
          identityId={"manageMilestoneFreelancer"}
          zIndex={"99"}
          onClose={() => setHandelCustomDrawerOpenCls(false)}
          isVisible={handelCustomDrawerOpenCls}
          customButton={true}
        >
          <div>
            <button
              className="btn-primary btn btn-sm btn-outline btn-circle place-self-end"
              onClick={() => {
                setHandelCustomDrawerOpenCls(!handelCustomDrawerOpenCls);
              }}
            >
              ✕
            </button>
            <h2 className="font-bold text-xl my-2 ml-5 mt-10">
              Request milestone changes
            </h2>

            <div className="mt-10 ml-10">
              <ol type="1" style={{ listStyleType: "decimal" }}>
                {FreelancerRequestSingleJobMilestone?.result &&
                  FreelancerRequestSingleJobMilestone?.result.length > 0 &&
                  FreelancerRequestSingleJobMilestone?.result.map(
                    (milestone, i) => (
                      <li key={i}>
                        <div className="flex justify-between items-center">
                          <div className="flex justify-between items-end w-[30%]">
                            <div>
                              <p>
                                <span>{milestone?.milestoneDescription}</span>
                                {milestone?.milestoneState &&
                                  milestone?.milestoneState === "active" && (
                                    <span className="btn btn-outline btn-primary btn-xs ml-3">
                                      Active
                                    </span>
                                  )}
                                {milestone?.milestoneState &&
                                  milestone?.milestoneState === "complete" && (
                                    <span className="btn btn-outline btn-primary btn-xs ml-3">
                                      complete
                                    </span>
                                  )}
                                {milestone?.milestoneState &&
                                  milestone?.milestoneState ===
                                    "workSubmitted" && (
                                    <span className="btn btn-outline btn-primary btn-xs ml-3">
                                      Check work
                                    </span>
                                  )}
                              </p>
                              <p className="flex justify-start items-center">
                                <BsCurrencyRupee />
                                <span>{milestone?.milestoneAmount}</span>
                                <span className="ml-1">(founded)</span>
                              </p>
                            </div>
                            <div>
                              <p className="ml-5">
                                {(() => {
                                  const date = new Date(
                                    milestone?.milestoneDeadline
                                  );
                                  const monthNames = [
                                    "Jan",
                                    "Feb",
                                    "Mar",
                                    "Apr",
                                    "May",
                                    "Jun",
                                    "Jul",
                                    "Aug",
                                    "Sep",
                                    "Oct",
                                    "Nov",
                                    "Dec",
                                  ];
                                  const year = date.getFullYear() % 100;
                                  const month = monthNames[date.getMonth()];
                                  const day = date.getDate();

                                  return `${month} ${day} ${year}`;
                                })()}
                              </p>
                            </div>
                          </div>
                          <div>
                            <button
                              type="button"
                              className="btn btn-circle btn-outline btn-sm btn-primary mx-2"
                              onClick={() => {
                                setHandelEditMilestoneData(milestone);
                                setHandelEditMilestoneModal(true);
                              }}
                            >
                              <FiEdit />
                            </button>
                            <button
                              type="button"
                              className="btn btn-circle btn-sm btn-primary mx-2"
                              onClick={() =>
                                deleteSingleMilestone(milestone?.id)
                              }
                            >
                              <ImBin />
                            </button>
                            {milestone?.milestonePaymentState &&
                              milestone?.milestonePaymentState === "No" && (
                                <button
                                  className="btn btn-outline btn-primary btn-sm rounded-full"
                                  // onClick={() => {
                                  //   setHandelMilestoneModal(true);
                                  // }}
                                >
                                  Pay Now
                                </button>
                              )}
                            {milestone?.milestonePaymentState &&
                              milestone?.milestonePaymentState === "yes" && (
                                <button className="btn btn-outline btn-primary btn-sm rounded-full px-7">
                                  Paid
                                </button>
                              )}
                          </div>
                        </div>
                        <hr className="my-3 border-t border-gray-300" />
                      </li>
                    )
                  )}
                {FreelancerRequestSingleJobMilestone?.result &&
                  FreelancerRequestSingleJobMilestone?.result.length === 0 && (
                    <div className="flex justify-center items-center font-bold text-2xl h-[500px]">
                      <h1>No data found!</h1>
                    </div>
                  )}
              </ol>
            </div>
          </div>
        </DrawerBodyWLg>
      )}

      <ModalBody
        onClose={() => setHandelSubmitWorkModal(false)}
        isVisible={handelSubmitWorkModal}
        customOpacityLayer="bg-opacity-0"
        customClass="card w-full mx-10 md:max-w-xl md:mx-auto bg-[#fff] rounded-lg p-4"
        modalCloseCustomButtonAdd={true}
      >
        <form onSubmit={handelFreelancerMilestoneWorkSubmit}>
          <div className="flex justify-between items-center px-4 py-2">
            <div>
              <h1 className="text-2xl font-medium">Submit work for payment</h1>
            </div>
            <button
              type="button"
              className="btn-primary btn btn-sm btn-circle place-self-end sm:place-self-start"
              onClick={() => setHandelSubmitWorkModal(false)}
            >
              ✕
            </button>
          </div>
          <div className="px-5">
            <h4 className="text-lg my-2 font-medium">
              {singleMilestoneDetails?.result?.milestoneDescription}
            </h4>
            <div className="flex justify-start items-center my-2">
              <p className="flex justify-start items-center">
                <BsCurrencyRupee />
                {singleMilestoneDetails?.result?.depositAmount}
              </p>
              <button
                type="button"
                className="btn btn-outline btn-primary btn-xs rounded-full ml-3"
              >
                {singleMilestoneDetails?.result?.milestoneState}
              </button>
            </div>
            <p className="flex justify-start items-center my-2">
              <FaCalendarAlt />
              <span className="ml-2">
                {(() => {
                  const date = new Date(
                    singleMilestoneDetails?.result?.dateLine
                  );
                  const monthNames = [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ];
                  const year = date.getFullYear() % 100;
                  const month = monthNames[date.getMonth()];
                  const day = date.getDate();

                  return `${month} ${day} ${year}`;
                })()}
              </span>
            </p>
            <p>
              Your payment will be released once{" "}
              {singleMilestoneDetails?.result?.firstName +
                singleMilestoneDetails?.result?.lastName}{" "}
              approves your work.{" "}
            </p>
            <hr className="my-3 border-t border-gray-300" />

            <h5 className="text-lg">Request a payment for this milestone</h5>
            {/* <p className="flex justify-start items-center my-2">
              <BsCurrencyRupee />
              {singleMilestoneDetails?.result?.depositAmount}
            </p> */}

            <div className="form-control mb-5">
              <label
                className="label font-medium cursor-pointer"
                htmlFor="message"
              >
                Message to{" "}
                {singleMilestoneDetails?.result?.firstName +
                  singleMilestoneDetails?.result?.lastName}
              </label>

              <textarea
                id="message"
                className="textarea textarea-bordered h-24 w-full resize-none focus:outline-non  focus:outline-none focus:border-[#fd03dc] rounded-md"
                placeholder="Enter your message"
              ></textarea>
            </div>
            <p>Include a file (optional)</p>
            <div className="flex justify-start items-center mt-3">
              <label
                htmlFor="freelancerSubmitWork"
                className="btn btn-outline btn-primary btn-sm"
              >
                <BsLink45Deg />
                <span>Attach file</span>
                <input
                  type="file"
                  name="freelancerSubmitWork"
                  id="freelancerSubmitWork"
                  className="opacity-0 hidden"
                  onChange={(e) => setHandelSubmitWorkFile(e.target.files[0])}
                />
              </label>
              {handelSubmitWorkFile?.name ? (
                <p className="ml-3">{handelSubmitWorkFile?.name}</p>
              ) : (
                <p className="ml-3">Up to 10 MB</p>
              )}
            </div>
          </div>

          <div className="my-5 px-5 flex justify-end items-start">
            <div>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  setHandelSubmitWorkModal(false);
                  setHandelSubmitWorkFile(null);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary btn-sm btn-outline ml-5"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </ModalBody>

      <ModalBody
        onClose={() => setHandelAddMilestoneModal(false)}
        isVisible={handelAddMilestoneModal}
        customOpacityLayer="bg-opacity-0"
        customClass="card w-full mx-10 md:max-w-xl md:mx-auto bg-[#fff] rounded-lg p-4"
        modalCloseCustomButtonAdd={true}
      >
        <form onSubmit={handelFreelancerRequestMilestone}>
          <div className="flex justify-between items-center px-4 py-2">
            <div>
              <h1 className="text-2xl font-medium">Add Milestone</h1>
            </div>
            <button
              type="button"
              className="btn-primary btn btn-sm btn-circle place-self-end sm:place-self-start"
              onClick={() => setHandelAddMilestoneModal(false)}
            >
              ✕
            </button>
          </div>
          <div className="px-5">
            <div className="form-control mb-5">
              <label
                className="label cursor-pointer font-bold"
                htmlFor="milestoneName"
              >
                Name of Milestone
              </label>

              <input
                id="milestoneName"
                name="milestoneDescription"
                type="text"
                className="input input-bordered input-sm w-full resize-none focus:outline-non  focus:outline-none focus:border-[#fd03dc] rounded-md"
                placeholder="Enter your milestone name"
              />
            </div>
            <div className="form-control mb-5">
              <label
                className="label cursor-pointer font-bold"
                htmlFor="milestoneAmount"
              >
                Amount
              </label>

              <input
                id="milestoneAmount"
                name="milestoneAmount"
                type="number"
                className="input input-bordered input-sm w-[50%] resize-none focus:outline-non  focus:outline-none focus:border-[#fd03dc] rounded-md"
                placeholder="₹ 00.00"
              />
            </div>
            <div className="form-control mb-5">
              <label
                className="label justify-start cursor-pointer"
                htmlFor="deadline"
              >
                <span className="font-bold">Due Date</span>
                <span className="text-sm ml-2 text-gray-400">( Option )</span>
              </label>

              <input
                id="deadline"
                name="deadline"
                className="input input-bordered input-sm w-full resize-none focus:outline-non  focus:outline-none focus:border-[#fd03dc] rounded-md"
                type="date"
              />
              <p className="text-medium text-gray-400 text-sm mt-1">
                All dales and times are based on IST
              </p>
            </div>
            <div className="form-control mb-5">
              <label
                className="label justify-start cursor-pointer"
                htmlFor="message"
              >
                <span className="font-bold">Description</span>
                <span className="text-sm ml-2 text-gray-400">( Option )</span>
              </label>

              <textarea
                id="message"
                name="milestoneRequestMessage"
                className="textarea textarea-bordered h-24 w-full resize-none focus:outline-non  focus:outline-none focus:border-[#fd03dc] rounded-md"
                placeholder="Enter your message"
              ></textarea>
            </div>
          </div>

          <div className="my-5 px-5 flex justify-end items-start">
            <div>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => setHandelAddMilestoneModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary btn-sm btn-outline ml-5"
              >
                Request Milestone
              </button>
            </div>
          </div>
        </form>
      </ModalBody>

      <ModalBody
        onClose={() => setHandelEditMilestoneModal(false)}
        isVisible={handelEditMilestoneModal}
        customOpacityLayer="bg-opacity-0"
        customClass="card w-full mx-10 md:max-w-xl md:mx-auto bg-[#fff] rounded-lg p-4"
        modalCloseCustomButtonAdd={true}
      >
        <form onSubmit={handelFreelancerRequestMilestoneEdit}>
          <div className="flex justify-between items-center px-4 py-2">
            <div>
              <h1 className="text-2xl font-medium">Update Milestone</h1>
            </div>
            <button
              type="button"
              className="btn-primary btn btn-sm btn-circle place-self-end sm:place-self-start"
              onClick={() => setHandelEditMilestoneModal(false)}
            >
              ✕
            </button>
          </div>
          <div className="px-5">
            <div className="form-control mb-5">
              <label
                className="label cursor-pointer font-bold"
                htmlFor="milestoneName"
              >
                Name of Milestone
              </label>

              <input
                id="milestoneName"
                name="milestoneDescription"
                type="text"
                className="input input-bordered input-sm w-full resize-none focus:outline-non  focus:outline-none focus:border-[#fd03dc] rounded-md"
                placeholder="Enter your milestone name"
                required
                defaultValue={handelEditMilestoneData?.milestoneDescription}
              />
            </div>
            <div className="form-control mb-5">
              <label
                className="label cursor-pointer font-bold"
                htmlFor="milestoneAmount"
              >
                Amount
              </label>

              <input
                id="milestoneAmount"
                name="milestoneAmount"
                type="number"
                className="input input-bordered input-sm w-[50%] resize-none focus:outline-non  focus:outline-none focus:border-[#fd03dc] rounded-md"
                placeholder="₹ 00.00"
                required
                defaultValue={handelEditMilestoneData?.milestoneAmount}
              />
            </div>
            <div className="form-control mb-5">
              <label
                className="label justify-start cursor-pointer"
                htmlFor="deadline"
              >
                <span className="font-bold">Due Date</span>
                <span className="text-sm ml-2 text-gray-400">( Option )</span>
              </label>

              <input
                id="deadline"
                name="deadline"
                className="input input-bordered input-sm w-full resize-none focus:outline-non  focus:outline-none focus:border-[#fd03dc] rounded-md"
                type="date"
                required
                defaultValue={handelEditMilestoneData?.milestoneDeadline}
              />
              <p className="text-medium text-gray-400 text-sm mt-1">
                All dales and times are based on IST
              </p>
            </div>
            <div className="form-control mb-5">
              <label
                className="label justify-start cursor-pointer"
                htmlFor="message"
              >
                <span className="font-bold">Description</span>
                <span className="text-sm ml-2 text-gray-400">( Option )</span>
              </label>

              <textarea
                id="message"
                className="textarea textarea-bordered h-24 w-full resize-none focus:outline-non  focus:outline-none focus:border-[#fd03dc] rounded-md"
                placeholder="Enter your message"
              ></textarea>
            </div>
          </div>

          <div className="my-5 px-5 flex justify-end items-start">
            <div>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => setHandelEditMilestoneModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary btn-sm btn-outline ml-5"
              >
                Save & Update
              </button>
            </div>
          </div>
        </form>
      </ModalBody>
    </div>
  );
};

export default MyJobsContractDetails;
