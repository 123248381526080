import React, { useContext, useEffect, useState } from "react";
import style from "./Setting.module.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../config/firebaseConfig/firebaseConfig";

import Loading from "../Loading/Loading";

import { globalFunctionOneContext } from "../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

import axios from "axios";
import { serverUrl } from "../../hooks/AllServerUrlControl/useAllServerUrlControl";

import { toast } from "react-toastify";
import { SingleUserProfileGet } from "../../hooks/useUser";
const IdentityVerification = () => {
  const { currentUser } = useContext(globalFunctionOneContext);
  const [load, setLoad] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [identityCheck, setIdentityCheck] = useState([]);

  // handle file reset
  const { data, isLoading, error } = SingleUserProfileGet(
    currentUser?.PersonID
  );

  const [user, loding] = useAuthState(auth);

  const handleFailedCount = (name) => {
    const identityFilter = identityCheck?.filter(
      (id) => id.verificationType === name && id.verificationStatus === "deny"
    ).length;
    return identityFilter;
  };
  // checking user document submission
  useEffect(() => {
    // personid: user?.uid,
    setPageLoad(true);
    axios
      .get(`${serverUrl}/identity/verificationStatus`, {
        headers: {
          personid: user?.uid,
        },
      })
      .then((res) => {
        if (res?.data) {
          // console.log("data:", res?.data.result);
          setIdentityCheck(res?.data.result);
          setPageLoad(false);
        }
      })
      .catch((err) => console.log(err));
  }, [user?.uid, load]);
  // console.log(identityCheck);

  // Handle form submission
  const handleSubmit = async (e, fileType) => {
    e.preventDefault();

    const DocumentFile = e?.target.DocumentFile?.files[0]
      ? e?.target.DocumentFile?.files[0]
      : "";
    const documentnumber = e?.target?.documentnumber?.value
      ? e?.target?.documentnumber?.value
      : "";
    const formData = {
      DocumentFile,
      documentnumber,
      verificationType: fileType,
    };

    setLoad(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("verificationType", formData.verificationType);
      formDataToSend.append("documentnumber", formData.documentnumber);
      formDataToSend.append("DocumentFile", formData.DocumentFile);

      // Make a POST request to the backend
      const response = await axios.post(
        `${serverUrl}/identity/document`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            personid: user.uid,
          },
        }
      );
      // console.log(response);
      // Check the response and handle it accordingly
      if (!response) {
        return <Loading />;
      }
      if (response.status === 200) {
        // Success: Display a success message or redirect to another page

        toast.success(response.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        e.target.reset();
        setLoad(false);
      } else {
        // Handle other possible responses or errors
        console.error(
          "Error uploading verification information: ",
          response.data
        );
      }
    } catch (error) {
      console.error("Error uploading verification information: ", error);
      // Handle other errors, such as network issues
    }
  };
  if (isLoading || !currentUser || loding || pageLoad) {
    return <Loading />;
  }

  const inputData = [
    {
      id: 1,
      title: "verify your phone number",
      name: "documentnumber",
      verificationType: "PhoneNumber",
      role: "booth",
      type: "text",
      placeholder: "Verify your phone number",
      required: true,
    },
    {
      id: 2,
      title: " Upload Aadhar Card document",
      name: "DocumentFile",
      verificationType: "Aadhar Card",
      role: "booth",
      required: true,
    },
    {
      id: 3,
      title: " Upload PAN card document",
      name: "DocumentFile",
      verificationType: "PAN card document",
      role: "booth",
      required: true,
    },
    {
      id: 4,
      title: " Upload Bank Account document",
      name: "DocumentFile",
      verificationType: "Bank Account document",
      role: "booth",
      required: true,
    },
    {
      id: 5,
      title: " Upload 10th certificate document",
      name: "DocumentFile",
      verificationType: "10th certificate",
      role: "freelancer",
      required: true,
    },
    {
      id: 6,
      title:
        data?.result?.registerType === "On Behalf of Company/Business"
          ? " Upload GST verification document"
          : " Upload GST verification document is optional",
      name: "DocumentFile",
      verificationType: "GST verification",
      role: "client",
      required:
        data?.result?.registerType === "On Behalf of Company/Business"
          ? true
          : false,
    },
    {
      id: 7,
      title: "write CIN number",
      name: "documentnumber",
      verificationType: "CINNumber",
      role: "client",
      type: "text",
      placeholder:
        data?.result?.registerType === "On Behalf of Company/Business"
          ? "CIN number"
          : "CIN is optional number",
      required:
        data?.result?.registerType === "On Behalf of Company/Business"
          ? true
          : false,
    },
  ];

  return (
    <div className="">
      <div className="">
        <h2 className="card-title">Upload Identity Information</h2>
        <hr />

        {inputData?.map((data, i) => {
          if (
            data.role === "booth" ||
            currentUser.accountType.toLowerCase() === data.role.toLowerCase()
          ) {
            // Find the corresponding identity check for the current data
            const identityCheckForData = identityCheck.find(
              (check) => check.verificationType === data.verificationType
            );

            if (!identityCheckForData) {
              // If there is no identity check for this verificationType, render the form
              return (
                <form
                  key={i * 5 - 1}
                  onSubmit={(e) => handleSubmit(e, data?.verificationType)}>
                  <div className="card w-full bg-base-100 shadow-xl">
                    <div className="card-body">
                      {/* start */}
                      <div className="card bg-base-100 shadow-xl">
                        <div
                          className={`${style.setting_link_wraper} px-3 card-body bg-gray-100 ${style.setting_link_acctive}`}>
                          <p>{data.title}</p>
                        </div>
                        <div className="w-[90%] border border-gray-300 file-input-primary mx-auto my-5 p-2 ">
                          <input
                            required={data.required}
                            id={data.id}
                            name={data.name}
                            placeholder={data?.placeholder && data.placeholder}
                            className={
                              data?.type
                                ? "block input input-primary w-full"
                                : "file-input w-full border-none file-input-primary"
                            }
                            type={data?.type ? data?.type : "file"}
                          />
                        </div>
                        <div className="px-2 my-3">
                          <p>
                            Update all the latest changes made by you, by just
                            clicking on "save & update button"
                          </p>
                          <div className="card-actions justify-end">
                            <button disabled={load} className="btn btn-primary">
                              {load ? "loading..." : "save & update"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              );
            }

            // Check the verification status for the current data
            const verificationStatus = identityCheckForData.verificationStatus;

            if (verificationStatus === "pending") {
              // Render the "verification pending" message for matching verificationType
              return (
                <div className="card flex justify-center items-center w-full my-10 bg-base-100 shadow-xl">
                  <div className="card-body">
                    <p className="font-bold text-gray-500 text-center">
                      Your {data.verificationType} verification is pending.
                    </p>
                  </div>
                </div>
              );
            } else if (verificationStatus === "approved") {
              // Render the "verification success" message for matching verificationType
              return (
                <div className="card flex justify-center items-center w-full my-10 bg-base-100 shadow-xl">
                  <div className="card-body">
                    <p className="font-bold text-green-500 text-center">
                      Your {data.verificationType} verification is successful.
                    </p>
                  </div>
                </div>
              );
            } else if (verificationStatus === "deny") {
              // Render the input form for matching verificationType with "failed" status
              return (
                <form
                  key={i * 5 - 1}
                  onSubmit={(e) => handleSubmit(e, data?.verificationType)}>
                  <div className="card w-full bg-base-100 shadow-xl">
                    <div className="card-body">
                      {/* start */}
                      <div className="card bg-base-100 shadow-xl">
                        <div
                          className={`${style.setting_link_wraper} px-3 card-body bg-gray-100 ${style.setting_link_acctive}`}>
                          <p className="text-red-400">
                            Your verification{" "}
                            {handleFailedCount(data?.verificationType)} Time
                            Failed Please Re {data.title}
                          </p>
                        </div>
                        <div className="w-[90%] border border-gray-300 file-input-primary mx-auto my-5 p-2 ">
                          <input
                            required
                            id={data.id}
                            name={data.name}
                            placeholder={data?.placeholder && data.placeholder}
                            className={
                              data?.type
                                ? "block input input-primary w-full"
                                : "file-input w-full border-none file-input-primary"
                            }
                            type={data?.type ? data?.type : "file"}
                          />
                        </div>
                        <div className="px-2 my-3">
                          <p>
                            Update all the latest changes made by you, by just
                            clicking on "save & update button"
                          </p>
                          <div className="card-actions justify-end">
                            <button disabled={load} className="btn btn-primary">
                              {load ? "loading..." : "save & update"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              );
            } else {
              // Render the input form for non-matching verificationType
              return (
                <form
                  key={i * 5 - 1}
                  onSubmit={(e) => handleSubmit(e, data?.verificationType)}>
                  <div className="card w-full bg-base-100 shadow-xl">
                    <div className="card-body">
                      {/* start */}
                      <div className="card bg-base-100 shadow-xl">
                        <div
                          className={`${style.setting_link_wraper} px-3 card-body bg-gray-100 ${style.setting_link_acctive}`}>
                          <p>{data.title}</p>
                        </div>
                        <div className="w-[90%] border border-gray-300 file-input-primary mx-auto my-5 p-2 ">
                          <input
                            required
                            id={data.id}
                            name={data.name}
                            placeholder={data?.placeholder && data.placeholder}
                            className={
                              data?.type
                                ? "block input input-primary w-full"
                                : "file-input w-full border-none file-input-primary"
                            }
                            type={data?.type ? data?.type : "file"}
                          />
                        </div>
                        <div className="px-2 my-3">
                          <p>
                            Update all the latest changes made by you, by just
                            clicking on "save & update button"
                          </p>
                          <div className="card-actions justify-end">
                            <button disabled={load} className="btn btn-primary">
                              {load ? "loading..." : "save & update"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              );
            }
          }
        })}
      </div>
    </div>
  );
};

export default IdentityVerification;
/*   <div className="card flex justify-center items-center w-full my-10 bg-base-100 shadow-xl">
          <div className="card-body">
            <p className="font-bold text-gray-500 text-center">
              Your Document verification is pending.
            </p>
          </div>
        </div> */
/*
Note - For client information
While profile filling when client is as a company then the company CIN (Company Identification No. ) will be appear to the filled.
otherwise if client is individual then CIN no. fileld not be displayed
*/
/*   for (let data of inputData) {
    if (identityCheck.length > 0) {
      for (let formData of identityCheck) {
        console.log("data", formData);
      }
    }
  } */
/* // Handle file input changes
  /*   const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  }; */
// Handle text input changes
/* const handleTextInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    /*  setFormData({
      ...formData,
      [name]: value,
    }); */
//}; */ */

/*   /*  const [formData, setFormData] = useState({
    PhoneNumber: "",
    GSTVerification: null,
    TenthCertificate: null,
    BankVerification: null,
    PANCard: null,
    AadharCard: null,
    CINNumber: "",
  }); */
/*

 /*    <form
                key={i * 5 - 1}
                onSubmit={(e) => handleSubmit(e, data?.verificationType)}>
                <div className="card w-full bg-base-100 shadow-xl">
                  <div className="card-body">
                    // {/* start */
//       <div className="card bg-base-100 shadow-xl">
//         <div
//           className={`${style.setting_link_wraper} px-3 card-body bg-gray-100 ${style.setting_link_acctive}`}>
//           <p>{data.title}</p>
//         </div>
//         <div className="w-[90%] border border-gray-300 file-input-primary mx-auto my-5 p-2 ">
//           <input
//             required
//             id={data.id}
//             name={data.name}
//             placeholder={data?.placeholder && data.placeholder}
//             className={
//               data?.type
//                 ? "block input input-primary w-full"
//                 : "file-input w-full border-none file-input-primary"
//             }
//             type={data?.type ? data?.type : "file"}
//           />
//         </div>
//         <div className="px-2 my-3">
//           <p>
//             Update all the latest changes made by you, by just
//             clicking on "save & update button"
//           </p>
//           <div className="card-actions justify-end">
//             <button disabled={load} className="btn btn-primary">
//               {load ? "loading..." : "save & update"}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </form> */
