import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { serverUrl } from "../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { useAuthState } from "react-firebase-hooks/auth";
import { app, auth } from "../../config/firebaseConfig/firebaseConfig";

const EmailVerifyPage = () => {
  const { mode, code, key, lang } = useParams();

  const [verificationResult, setVerificationResult] = useState(null);
  const [user] = useAuthState(auth);

  useEffect(() => {
    // Call the function to make the request
    console.log(code, key);
  }, [mode, code, key, lang]);

  useEffect(() => {
    if (user) {
      // Check if the URL contains the 'oobCode' query parameter
      const urlParams = new URLSearchParams(window.location.search);
      const oobCode = urlParams.get("oobCode");

      if (oobCode) {
        // Apply the verification code to complete the email verification
        app
          .auth()
          .applyActionCode(oobCode)
          .then(() => {
            // Email verified successfully
            console.log("Email verified");
            // You can redirect the user to a success page or do anything else here
          })
          .catch((error) => {
            // Handle any errors that occurred during verification
            console.error("Error verifying email:", error.message);
          });
      }
    }
  }, [user]);

  const handleVerification = async () => {
    try {
      // Make the POST request to your server endpoint using axios
      // const res = await fetch(
      //   `${serverUrl}/userProfile/verifyEmail/${email}/${verificationCode}`,
      //   {
      //     method: "get",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   }
      // );
      // // Parse the JSON response
      // const data = await res.json();
      // console.log(data, "Tis is data");
      // Handle the server response
      // setVerificationResult(data);
    } catch (error) {
      // Handle errors if any
      console.error("Error verifying email:", error);
    }
  };

  return (
    <div className="flex justify-center items-center">
      <div className="card w-96 glass">
        <div className="card-body">
          <h2 className="card-title">Your Email Verify</h2>
          <p>How to park your car at your garage?</p>
          <p>Click Continue Button</p>
          <div className="card-actions justify-end">
            <button className="btn btn-primary" onClick={handleVerification}>
              Continue
            </button>
          </div>
          {verificationResult && (
            <p>Verification Result: {verificationResult.message}</p>
          )}
        </div>
      </div>
    </div>
  );
};
export default EmailVerifyPage;

// import React, { useEffect, useState } from "react";
// import { TextEncoder, TextDecoder } from "text-encoding-utf-8"; // Import the text-encoding-utf-8 library
// import { serverUrl } from "../../hooks/AllServerUrlControl/useAllServerUrlControl";

// const EmailVerifyPage = () => {
//   const [password, setPassword] = useState("");

//   const handlePasswordChange = (event) => {
//     setPassword(event.target.value);
//   };

//   const sendPasswordToBackend = async () => {
//     try {
//       const hexCode = await convertToHex(password);
//       await sendToBackend(hexCode);
//       alert("Password sent successfully!");
//     } catch (error) {
//       console.error("Error sending password:", error.message);
//       alert("Failed to send password!");
//     }
//   };

//   const convertToHex = (password) => {
//     const encoder = new TextEncoder();
//     const data = encoder.encode(password);
//     const hexCode = Array.prototype.map
//       .call(data, (byte) => {
//         return ("0" + (byte & 0xff).toString(16)).slice(-2);
//       })
//       .join("");
//     return hexCode;
//   };

//   const sendToBackend = async (hexCode) => {
//     console.log(hexCode, "mamun khan");
//     const response = await fetch(`${serverUrl}/userProfile/verifyEmail`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ password: hexCode }),
//     });
//     const data = response.json();
//     console.log(data);
//   };

//   return (
//     <div>
//       <input
//         type="password"
//         placeholder="Enter your password"
//         value={password}
//         onChange={handlePasswordChange}
//       />
//       <button onClick={sendPasswordToBackend}>Send Password</button>
//     </div>
//   );
// };

// export default EmailVerifyPage;
