import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { arr } from "./dummySliderObj";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import "swiper/styles.css";

// import required modules
import { Pagination } from "swiper/modules";

const Slider = () => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          "@0.00": {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          "@0.75": {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          "@1.00": {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          "@1.50": {
            slidesPerView: 2,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {arr.map((a, i) => (
          <SwiperSlide key={i}>
            <div className="">
              <div className="my-3 px-3">
                <div className="shadow-xl text-gray-400 px-8 text-xl py-6 relative my-10">
                  {a.content}
                  <div className="arrow"></div>
                </div>
                <img
                  className="w-[70px]"
                  decoding="async"
                  src="https://elite-professionals.in/wp-content/uploads/2019/06/client2-1.jpg"
                  alt=""
                  data-no-retina=""
                />
                <div className="author">
                  <h5 className="font-semibold text-gray-600 text-base ">
                    {a.name}
                  </h5>
                  <p>{a.position}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default Slider;
