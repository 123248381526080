import React, { useContext, useEffect, useState } from "react";
import style from "./TalentManagerForm.module.css";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import DropFilesInput from "../../../state/DropFilesInput/DropFilesInput";
import { AiOutlineClose } from "react-icons/ai";
import TextAreaEditor from "../../Shared/TextAreaEditor/TextAreaEditor";
import {
  serverImageUrl,
  serverUrl,
} from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { useNavigate } from "react-router-dom";
import { SingleUserProfileGet } from "../../../hooks/useUser";
import {
  CommonFormBackBtn,
  CommonFormSubmitBtn,
} from "../../Shared/AllCommonButton/AllCommonButton";

const TalentManagerPersonalDetailsForm = () => {
  const {
    imageUrlLocalHost,
    setImageUrlLocalHost,
    oneFile,
    setOneFile,
    setFileDropLimitNumber,
    firebaseUser,
    currentUser,
    databaseSingleUserRefetch,
  } = useContext(globalFunctionOneContext);
  const [content, setContent] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const navigate = useNavigate();

  // JoditEditor config
  const [config, setConfig] = useState({
    minHeight: 400,
    placeholder: "Detail about yourself Start typings...",
    insertImage: false,
    showXPathInStatusbar: false,
    buttons:
      "bold,italic,underline,strikethrough,eraser,ul,ol,font,fontsize,paragraph,classSpan,lineHeight,superscript,subscript,file,image,spellcheck,cut,copy,source,paste,hr,link,find",
  });
  const { data, isLoading, error, refetch } = SingleUserProfileGet(
    currentUser?.PersonID
  );

  // HandelPersonalDetails
  const HandelPersonalDetails = async (e) => {
    e.preventDefault();
    const form = e.target;
    const firstName = form.firstName.value;
    const lastName = form.lastName.value;
    const companyName = form.companyName.value;
    const empId = form.empId.value;
    const cityName = form.cityName.value;
    const stateName = form.stateName.value;
    const personalEmail = form.personalEmail.value;
    const companyEmail = form.companyEmail.value;
    const dateOfBirth = form.dateOfBirth.value;

    const formData = new FormData();
    formData.append("profilePhoto", oneFile);
    formData.append("companyName", companyName);
    formData.append("empId", empId);
    formData.append("cityName", cityName);
    formData.append("stateName", stateName);
    formData.append("personalEmail", personalEmail);
    formData.append("companyEmail", companyEmail);
    formData.append("dateOfBirth", dateOfBirth);
    formData.append("detailAbutYourself", content);

    if (content.length <= 1000) {
      setErrorMessage(false);
      fetch(`${serverUrl}/userProfile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          uid: firebaseUser?.uid,
        },
        body: JSON.stringify({
          PersonID: currentUser?.PersonID,
          accountType: currentUser?.accountType,
          firstName,
          lastName,
          country: currentUser?.country,
          email: currentUser?.email,
          password: currentUser?.password,
          rules: currentUser?.rules,
          username: currentUser?.username,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data?.status === "success") {
            databaseSingleUserRefetch();
          }
        });
      // Make the server request using formData
      const res = await fetch(
        `${serverUrl}/talent-manager-profile/personal-details-data`,
        {
          method: "POST",
          headers: { Accept: "multipart/form-data", uid: firebaseUser?.uid },
          Credential: "include",
          body: formData,
        }
      );
      const data = await res.json();

      if (data?.status === "success") {
        console.log(data);
        form.reset();
        setOneFile(null);
        setImageUrlLocalHost("");
        refetch();
        navigate("/user/profile/from/social/talent/manager/form");
      }
    } else {
      setErrorMessage(true);
    }
  };

  // handel drop file input change
  useEffect(() => {
    setFileDropLimitNumber(1);
    setContent(data?.result?.detailAbutYourself);
  }, [setFileDropLimitNumber, data]);

  const yourDetailsInputArray = [
    {
      id: 1,
      inputName: "firstName",
      inputType: "text",
      placeholder: "Enter your first Name",
      inputClassName:
        "input input-bordered w-full focus:outline-none focus:border-[#fd03dc]",
    },
    {
      id: 2,
      inputName: "lastName",
      inputType: "text",
      placeholder: "Enter your last Name",
      inputClassName:
        "input input-bordered w-full focus:outline-none focus:border-[#fd03dc]",
    },
    {
      id: 3,
      inputName: "companyName",
      inputType: "text",
      placeholder: "Enter your company Name",
      inputClassName:
        "input input-bordered w-full focus:outline-none focus:border-[#fd03dc]",
    },
    {
      id: 4,
      inputName: "empId",
      inputType: "text",
      placeholder: "Enter your emp id",
      inputClassName:
        "input input-bordered w-full focus:outline-none focus:border-[#fd03dc]",
    },
    {
      id: 5,
      inputName: "cityName",
      inputType: "text",
      placeholder: "Enter your cityName Name",
      inputClassName:
        "input input-bordered w-full focus:outline-none focus:border-[#fd03dc]",
    },
    {
      id: 6,
      inputName: "stateName",
      inputType: "text",
      placeholder: "Enter your state Name",
      inputClassName:
        "input input-bordered w-full focus:outline-none focus:border-[#fd03dc]",
    },
    {
      id: 7,
      inputName: "personalEmail",
      inputType: "email",
      placeholder: "Enter your personal email",
      inputClassName:
        "input input-bordered w-full focus:outline-none focus:border-[#fd03dc]",
    },
    {
      id: 8,
      inputName: "companyEmail",
      inputType: "email",
      placeholder: "Enter your company email",
      inputClassName:
        "input input-bordered w-full focus:outline-none focus:border-[#fd03dc]",
    },
  ];

  return (
    <div>
      <form className="px-4" onSubmit={HandelPersonalDetails}>
        <h1 className={style.form_hading}>Your Details</h1>
        <div className="grid gap-5 grid-cols-1 md:grid-cols-2 mt-5">
          {yourDetailsInputArray.map((inputData) => (
            <input
              key={inputData?.id}
              type={inputData?.inputType}
              name={inputData?.inputName}
              placeholder={inputData?.placeholder}
              className={inputData?.inputClassName}
              defaultValue={
                data?.result?.[inputData?.inputName] ||
                currentUser?.[inputData?.inputName]
              }
              required
            />
          ))}
        </div>

        <h1 className={`${style.form_hading} my-5`}>Date of birth</h1>
        <div className="grid gap-5 grid-cols-1 md:grid-cols-2 mt-5">
          <input
            type="date"
            name="dateOfBirth"
            defaultValue={data?.result?.dateOfBirth}
            placeholder="Enter your date of Birth  Name"
            className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc]"
            required
          />
        </div>

        <div className="my-10">
          <DropFilesInput />

          {imageUrlLocalHost && (
            <div className="mt-5 flex justify-start relative">
              <img
                src={imageUrlLocalHost}
                alt="imageUrlLocalHost"
                className="rounded w-60 h-60 mt-5"
              />
              <i
                className="text-xl text-center cursor-pointer border rounded-full bg-slate-200 hover:bg-gray-50 absolute top-0 left-56 p-1"
                onClick={() => {
                  setImageUrlLocalHost("");
                  setOneFile("");
                }}
              >
                <AiOutlineClose />
              </i>
            </div>
          )}
          {!imageUrlLocalHost && data?.result?.profilePhoto && (
            <div className="mt-5 flex justify-start relative">
              <img
                src={` ${serverImageUrl}/${data?.result?.profilePhoto}`}
                alt="imageUrlLocalHost"
                className="rounded w-60 h-60 mt-5"
              />
            </div>
          )}
        </div>
        <div className="my-10">
          <h1 className={`${style.form_hading} my-5`}>Detail about yourself</h1>
          <TextAreaEditor
            content={content}
            setContent={setContent}
            config={config}
            setConfig={setConfig}
          />
          {errorMessage && (
            <p className="text-[#ff0000]">Please enter you 1000 character</p>
          )}
        </div>

        {/* submit & Back button wraper  */}
        <div className="flex justify-end items-center w-full mt-10">
          <div>
            <CommonFormBackBtn
              type={"button"}
              BtnText={"Back"}
              className={"mr-5 px-10 py-3 text-sm"}
            />
            <CommonFormSubmitBtn
              type={"submit"}
              BtnText={"Save & Update"}
              className={"px-3 py-3 text-sm"}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default TalentManagerPersonalDetailsForm;
