import React, { useContext, useEffect, useRef, useState } from "react";
import Loading from "../../Loading/Loading";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import MessageAllFriendList from "./MessageAllFriendList";
import SelectRoomAllMessageList from "./SelectRoomAllMessageList";

const MessageDashboard = () => {
  const {
    currentUser,
    allMessageFriendList,
    allMessageFriendListRefetch,
    allMessageFriendListLoading,
  } = useContext(globalFunctionOneContext);
  const [handelUserSelectMessageRoom, setHandelUserSelectMessageRoom] =
    useState({});

  // window loading
  if (!currentUser || allMessageFriendListLoading) {
    return <Loading />;
  }
  return (
    <div className="container mx-auto">
      <div className="flex gap-4 h-screen">
        {/* this is left site section start */}
        {/* message user list start */}
        <div className="w-1/4  h-full border border-gray-200  card bg-base-100 shadow-xl">
          <MessageAllFriendList
            allMessageFriendList={allMessageFriendList}
            setHandelUserSelectMessageRoom={setHandelUserSelectMessageRoom}
          />
        </div>
        {/* user select message room all message show start */}

        {/* this is right site section but right site section is 2 part one part left and right part */}
        <div className="card w-3/4  border border-gray-200  bg-base-100 h-full overflow-y-auto shadow-xl">
          {/* currentUserAccountTypeFreelancer */}
          <SelectRoomAllMessageList
            handelUserSelectMessageRoom={handelUserSelectMessageRoom}
            allMessageFriendListRefetch={allMessageFriendListRefetch}
          />
        </div>
      </div>
    </div>
  );
};

export default MessageDashboard;
