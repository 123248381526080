import React from "react";
import style from "./ModalBody.module.css";

const ModalBody = ({
  onClose,
  isVisible,
  children,
  customClass,
  modalCloseCustomButtonAdd,
  customOpacityLayer,
}) => {
  if (!isVisible) return null;

  const handelCloseModal = (e) => {
    if (e.target.id === "modal_wrapper") onClose();
  };

  return (
    <div
      style={{ zIndex: "999" }}
      className={`fixed inset-0 flex justify-center items-center  ${
        customOpacityLayer
          ? customOpacityLayer
          : "Obg-black bg-opacity-25 backdrop-blur-sm"
      }`}
      onClick={handelCloseModal}
      id="modal_wrapper">
      <div
        className={`${style.modalContent} ${
          customClass ? customClass : "card max-w-full mx-auto bg-[#fff]"
        }`}>
        {!modalCloseCustomButtonAdd && (
          <button
            className="btn-primary btn btn-sm btn-circle place-self-end"
            onClick={() => onClose()}>
            ✕
          </button>
        )}
        <div className="text-start">{children}</div>
      </div>
    </div>
  );
};

export default ModalBody;
