import React, { useContext } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import {
  HandelClientFreelancerHireAllDataCount,
  HandelClientProposalAllDataCount,
} from "../../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import Loading from "../../../Loading/Loading";

const HomeTwo = () => {
  const { currentUser, clientMessageNotification } = useContext(
    globalFunctionOneContext
  );

  const {
    data: ClientFreelancerHireAllDataCount,
    isLoading: ClientFreelancerHireAllDataCountLoading,
    refetch: ClientFreelancerHireAllDataCountRefetch,
  } = HandelClientFreelancerHireAllDataCount(currentUser?.PersonID);

  const {
    data: ClientProposalAllDataCount,
    isLoading: ClientProposalAllDataCountLoading,
    refetch: ClientProposalAllDataCountRefetch,
  } = HandelClientProposalAllDataCount(currentUser?.PersonID);

  // This is loading
  if (
    clientMessageNotification ||
    ClientProposalAllDataCountLoading ||
    ClientFreelancerHireAllDataCountLoading
  ) {
    return (
      <div className="h-[455px]">
        <Loading />
      </div>
    );
  }
  return (
    <div className="mt-10 md:w-[60%] w-full mx-0 md:mx-auto">
      <div className="card border-2 rounded-lg border-gray-200  w-full bg-base-100">
        <div className="card-body">
          <div className="flex justify-between gap-4 mb-12">
            <h2 className="card-title">Your Posting</h2>
            <h3 className="font-bold text-primary">See all postings</h3>
          </div>
          <div>
            <div className="flex justify-between gap-4">
              <h2 className="font-bold">Internal Factors Designer</h2>
              <button className="btn items-center btn-sm btn-outline font-bold btn-primary">
                <BiDotsHorizontalRounded />
              </button>
            </div>
            <div className="grid md:grid-cols-4 grid-cols-2 gap-4">
              <div>
                <h3>Hourly - public</h3>
                <p>Created 43 minutes ago</p>
              </div>
              <div>
                <h3>{ClientProposalAllDataCount?.result}</h3>
                <p>Proposals</p>
              </div>
              <div>
                <h3>
                  <span>
                    {clientMessageNotification ? clientMessageNotification : 0}
                  </span>
                </h3>
                <p>Messaged</p>
              </div>
              <div>
                <h3>{ClientFreelancerHireAllDataCount?.result}</h3>
                <p>Hired</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* collapse  */}
      <div className="mt-20 mb-4">
        <div
          tabIndex={0}
          className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box"
        >
          <input type="checkbox" />
          <div className="collapse-title text-xl font-medium">
            How to work with talent
          </div>
          <div className="collapse-content">
            <p>
              Connect with a talent community that numbers in the millions, fast
              and at no cost.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTwo;
