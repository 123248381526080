import { useAuthState } from "react-firebase-hooks/auth";
import { useQuery } from "react-query";
import { auth } from "../../config/firebaseConfig/firebaseConfig";

const EmailVerifyd = () => {
  const [user] = useAuthState(auth);
  console.log(user);

  const { isLoading, isError, data } = useQuery("isEmailVerified", async () => {
    const currentUser = auth.currentUser;
    const emailVerified = currentUser?.emailVerified;
    return emailVerified;
  });

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="card w-96 bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title"> </h2>
          <p className="text-xl font-bold text-primary text-center">
            Please verify Your Email
          </p>
          <p className="text-secondary text-sm text-center">
            <span>"{user?.email}" </span>
            Email Already send Check inbox or spam folder . If you are
            successfully verified your email reload this page.
          </p>
          {isLoading && <p>Loading...</p>}
          {isError && <p>Error loading email verification status</p>}
        </div>
      </div>
    </div>
  );
};

export default EmailVerifyd;
