import React, { useState, useEffect } from "react";

const TimeDisplay = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="text-center text-gray-400 font-semibold">
      <h2 className="text-xl my-2">
        {time.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })}
      </h2>
      <h3 className="text-lg mb-2">
        {time.toLocaleDateString(undefined, {
          weekday: "long",
          month: "long",
          day: "numeric",
        })}
      </h3>
      <p>No members clocked in now</p>
    </div>
  );
};

export default TimeDisplay;
