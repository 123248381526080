import React, { useContext, useEffect, useState } from "react";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { serverUrl } from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import {
  AllJobInvitedData,
  HandelCurrentFreelancerSavedAllJobsGetApplyData,
  HandelFreelancerSavedJobAllDataCount,
} from "../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import JobCard from "../../Shared/JobCard/JobCard";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import WindowsLoadingSpinner from "../../Shared/WindowsLoadingSpinner/WindowsLoadingSpinner";

const MyJobsSaved = () => {
  const { currentUser, userCurrentLocationPath } = useContext(
    globalFunctionOneContext
  );
  const [dataLimit, setDataLimit] = useState(10);

  // All job invited Data get function
  const { data, refetch, isLoading } = AllJobInvitedData(
    `${serverUrl}/client/all/invited/Jobs?freelancerPersonId=${currentUser?.PersonID}`
  );

  // handel freelancer All job bookMark function
  const {
    data: CurrentFreelancerAllSavedJobsData,
    isLoading: FreelancerAllSavedJobsDataGetLoading,
    refetch: freelancerAllSavedJobDataGetRefetch,
  } = HandelCurrentFreelancerSavedAllJobsGetApplyData(
    currentUser?.PersonID,
    dataLimit
  );
  // Freelancer all saved job count function
  const {
    data: FreelancerJobSavedJobDataCount,
    isLoading: FreelancerJobSavedJobDataCountLoading,
    refetch: freelancerJobSavedJobDataCountRefetch,
  } = HandelFreelancerSavedJobAllDataCount(currentUser?.PersonID);

  useEffect(() => {
    console.log(
      CurrentFreelancerAllSavedJobsData?.result,
      "CurrentFreelancerAllSavedJobsData"
    );
  }, [CurrentFreelancerAllSavedJobsData]);

  console.log(userCurrentLocationPath);

  if (
    !currentUser ||
    isLoading ||
    FreelancerAllSavedJobsDataGetLoading ||
    FreelancerJobSavedJobDataCountLoading
  ) {
    return (
      <div className="h-[455px]">
        <WindowsLoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <div
        className={`${
          userCurrentLocationPath === "/dashboard/my/save/jobs"
            ? "max-w-[70%] mx-auto mt-10"
            : ""
        }`}
      >
        <JobCard
          jobs={CurrentFreelancerAllSavedJobsData?.result}
          data={data}
          refetch={refetch}
          isLoading={isLoading}
          freelancerAllSavedJobDataGetRefetch={
            freelancerAllSavedJobDataGetRefetch
          }
          freelancerJobSavedJobDataCountRefetch={
            freelancerJobSavedJobDataCountRefetch
          }
        />
        {!CurrentFreelancerAllSavedJobsData?.result.length && (
          <div className="w-full h-[26rem] flex justify-center items-start">
            <div className="w-[19rem] h-[19rem] pt-5">
              {/* This is saved job svg icon code */}
              {/* <div className="css-93v7zi eu4oa1w0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 115 79"
                aria-hidden="true"
                dataTestid="ipl-illustration-spot"
              >
                <defs>
                  <filter id="ipl-illustrations-spot-6-7" x="0%" y="0%">
                    <feTurbulence
                      baseFrequency="2.5"
                      numOctaves="4"
                      result="noise"
                      seed="14"
                    ></feTurbulence>
                    <feComponentTransfer in="noise" result="opacity">
                      <feFuncA slope="0.6" type="linear"></feFuncA>
                    </feComponentTransfer>
                    <feBlend
                      in="opacity"
                      in2="SourceGraphic"
                      mode="overlay"
                      result="blend"
                    ></feBlend>
                  </filter>
                  <filter
                    id="ipl-illustrations-spot-6-6"
                    width="73.632"
                    height="51.958"
                    x="22.373"
                    y="26.569"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                  >
                    <feFlood
                      floodOpacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feBlend
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    ></feBlend>
                    <feColorMatrix
                      in="SourceAlpha"
                      result="hardAlpha"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="0.5" dy="0.5"></feOffset>
                    <feGaussianBlur stdDeviation="0.25"></feGaussianBlur>
                    <feComposite
                      in2="hardAlpha"
                      k2="-1"
                      k3="1"
                      operator="arithmetic"
                    ></feComposite>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                      in2="shape"
                      result="effect1_innerShadow_4384_8747"
                    ></feBlend>
                  </filter>
                  <filter
                    id="ipl-illustrations-spot-6-5"
                    width="15.263"
                    height="38.895"
                    x="73.691"
                    y="26.569"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                  >
                    <feFlood
                      floodOpacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feBlend
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    ></feBlend>
                    <feColorMatrix
                      in="SourceAlpha"
                      result="hardAlpha"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="1" dy="1"></feOffset>
                    <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                    <feComposite
                      in2="hardAlpha"
                      k2="-1"
                      k3="1"
                      operator="arithmetic"
                    ></feComposite>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                      in2="shape"
                      result="effect1_innerShadow_4384_8747"
                    ></feBlend>
                  </filter>
                  <filter
                    id="ipl-illustrations-spot-6-4"
                    width="74.132"
                    height="12.047"
                    x="22.373"
                    y="15.522"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                  >
                    <feFlood
                      floodOpacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feColorMatrix
                      in="SourceAlpha"
                      result="hardAlpha"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="0.5" dy="0.5"></feOffset>
                    <feGaussianBlur stdDeviation="0.25"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_4384_8747"
                    ></feBlend>
                    <feBlend
                      in="SourceGraphic"
                      in2="effect1_dropShadow_4384_8747"
                      result="shape"
                    ></feBlend>
                    <feColorMatrix
                      in="SourceAlpha"
                      result="hardAlpha"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="1" dy="1"></feOffset>
                    <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                    <feComposite
                      in2="hardAlpha"
                      k2="-1"
                      k3="1"
                      operator="arithmetic"
                    ></feComposite>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                      in2="shape"
                      result="effect2_innerShadow_4384_8747"
                    ></feBlend>
                  </filter>
                  <linearGradient
                    id="ipl-illustrations-spot-6-3"
                    x1="81.878"
                    x2="106.063"
                    y1="62.194"
                    y2="45.108"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#9D2B6B"></stop>
                    <stop offset="1" stopColor="#E867A8"></stop>
                  </linearGradient>
                  <linearGradient
                    id="ipl-illustrations-spot-6-2"
                    x1="59.415"
                    x2="59.624"
                    y1="15.065"
                    y2="27.75"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#237EA3"></stop>
                    <stop offset="1" stopColor="#1D465C"></stop>
                  </linearGradient>
                  <clipPath id="ipl-illustrations-spot-6-1">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      d="M105.512 0 53.757 15.521H22.373v9.412L7.831 29.295 0 78.026h114.383L105.512 0z"
                    ></path>
                  </clipPath>
                  <pattern
                    id="ipl-illustrations-spot-6-0"
                    width="50"
                    height="40"
                    x="0"
                    y="0"
                    patternTransform="scale(.3)"
                    patternUnits="userSpaceOnUse"
                  >
                    <path
                      d="M0 0h50v40H0z"
                      filter="url(#ipl-illustrations-spot-6-7)"
                    ></path>
                  </pattern>
                </defs>
                <g clipPath="url(#ipl-illustrations-spot-6-1)">
                  <path
                    fill="#F7C8B8"
                    d="M7.83 29.295 105.513 0l8.871 78.026H0l7.83-48.731z"
                  ></path>
                  <g filter="url(#ipl-illustrations-spot-6-6)">
                    <path
                      fill="#fff"
                      d="M22.373 26.569h73.132v51.458H22.373z"
                    ></path>
                  </g>
                  <path
                    fill="#1F5F7B"
                    d="M28.666 39.992H59.01v3.077H28.666v-3.077zm0 12.866h20.276v3.076H28.666v-3.076zm17.619-6.434H28.666V49.5h17.619v-3.076z"
                  ></path>
                  <g filter="url(#ipl-illustrations-spot-6-5)">
                    <path
                      fill="url(#ipl-illustrations-spot-6-3)"
                      d="M73.691 26.569v37.894l7.24-7.97 7.023 6.222-.69-36.147H73.691z"
                    ></path>
                  </g>
                  <g filter="url(#ipl-illustrations-spot-6-4)">
                    <path
                      fill="url(#ipl-illustrations-spot-6-2)"
                      d="M22.373 15.522h73.132v11.046H22.373V15.522z"
                    ></path>
                  </g>
                  <path
                    fill="#B6D7EE"
                    d="M32.817 21.093a2.076 2.076 0 1 1-4.15 0 2.076 2.076 0 0 1 4.15 0zm6.804 0a2.076 2.076 0 1 1-4.151 0 2.076 2.076 0 0 1 4.151 0zm4.729 2.077a2.076 2.076 0 1 0 0-4.152 2.076 2.076 0 0 0 0 4.151z"
                  ></path>
                </g>
                <rect
                  clipPath="url(#ipl-illustrations-spot-6-1)"
                  width="100%"
                  height="100%"
                  fill="url(#ipl-illustrations-spot-6-0)"
                  style={{ mixBlendMode: "overlay" }}
                />
              </svg>
            </div> */}
              <div
                style={{
                  maxWidth: "300px",
                  height: "300px",
                }}
                className="css-93v7zi eu4oa1w0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 115 79"
                  aria-hidden="true"
                  dataTestid="ipl-illustration-spot"
                >
                  <defs>
                    <filter id="ipl-illustrations-spot-6-7" x="0%" y="0%">
                      <feTurbulence
                        baseFrequency="2.5"
                        numOctaves="4"
                        result="noise"
                        seed="14"
                      ></feTurbulence>
                      <feComponentTransfer in="noise" result="opacity">
                        <feFuncA slope="0.6" type="linear"></feFuncA>
                      </feComponentTransfer>
                      <feBlend
                        in="opacity"
                        in2="SourceGraphic"
                        mode="overlay"
                        result="blend"
                      ></feBlend>
                    </filter>
                    <filter
                      id="ipl-illustrations-spot-6-6"
                      width="73.632"
                      height="51.958"
                      x="22.373"
                      y="26.569"
                      colorInterpolationFilters="sRGB"
                      filterUnits="userSpaceOnUse"
                    >
                      <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                      ></feFlood>
                      <feBlend
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      ></feBlend>
                      <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      ></feColorMatrix>
                      <feOffset dx="0.5" dy="0.5"></feOffset>
                      <feGaussianBlur stdDeviation="0.25"></feGaussianBlur>
                      <feComposite
                        in2="hardAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                      ></feComposite>
                      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
                      <feBlend
                        in2="shape"
                        result="effect1_innerShadow_4384_8747"
                      ></feBlend>
                    </filter>
                    <filter
                      id="ipl-illustrations-spot-6-5"
                      width="15.263"
                      height="38.895"
                      x="73.691"
                      y="26.569"
                      colorInterpolationFilters="sRGB"
                      filterUnits="userSpaceOnUse"
                    >
                      <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                      ></feFlood>
                      <feBlend
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      ></feBlend>
                      <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      ></feColorMatrix>
                      <feOffset dx="1" dy="1"></feOffset>
                      <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                      <feComposite
                        in2="hardAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                      ></feComposite>
                      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
                      <feBlend
                        in2="shape"
                        result="effect1_innerShadow_4384_8747"
                      ></feBlend>
                    </filter>
                    <filter
                      id="ipl-illustrations-spot-6-4"
                      width="74.132"
                      height="12.047"
                      x="22.373"
                      y="15.522"
                      colorInterpolationFilters="sRGB"
                      filterUnits="userSpaceOnUse"
                    >
                      <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                      ></feFlood>
                      <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      ></feColorMatrix>
                      <feOffset dx="0.5" dy="0.5"></feOffset>
                      <feGaussianBlur stdDeviation="0.25"></feGaussianBlur>
                      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
                      <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_4384_8747"
                      ></feBlend>
                      <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_4384_8747"
                        result="shape"
                      ></feBlend>
                      <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      ></feColorMatrix>
                      <feOffset dx="1" dy="1"></feOffset>
                      <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                      <feComposite
                        in2="hardAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                      ></feComposite>
                      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
                      <feBlend
                        in2="shape"
                        result="effect2_innerShadow_4384_8747"
                      ></feBlend>
                    </filter>
                    <linearGradient
                      id="ipl-illustrations-spot-6-3"
                      x1="81.878"
                      x2="106.063"
                      y1="62.194"
                      y2="45.108"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#9D2B6B"></stop>
                      <stop offset="1" stopColor="#E867A8"></stop>
                    </linearGradient>
                    <linearGradient
                      id="ipl-illustrations-spot-6-2"
                      x1="59.415"
                      x2="59.624"
                      y1="15.065"
                      y2="27.75"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#237EA3"></stop>
                      <stop offset="1" stopColor="#1D465C"></stop>
                    </linearGradient>
                    <clipPath id="ipl-illustrations-spot-6-1">
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#fff"
                        d="M105.512 0 53.757 15.521H22.373v9.412L7.831 29.295 0 78.026h114.383L105.512 0z"
                      ></path>
                    </clipPath>
                    <pattern
                      id="ipl-illustrations-spot-6-0"
                      width="50"
                      height="40"
                      x="0"
                      y="0"
                      patternTransform="scale(.3)"
                      patternUnits="userSpaceOnUse"
                    >
                      <path
                        d="M0 0h50v40H0z"
                        filter="url(#ipl-illustrations-spot-6-7)"
                      ></path>
                    </pattern>
                  </defs>
                  <g clipPath="url(#ipl-illustrations-spot-6-1)">
                    <path
                      fill="#6499E9"
                      d="M7.83 29.295 105.513 0l8.871 78.026H0l7.83-48.731z"
                    ></path>
                    <g filter="url(#ipl-illustrations-spot-6-6)">
                      <path
                        fill="#fff"
                        d="M22.373 26.569h73.132v51.458H22.373z"
                      ></path>
                    </g>
                    <path
                      fill="#5837D0"
                      d="M28.666 39.992H59.01v3.077H28.666v-3.077zm0 12.866h20.276v3.076H28.666v-3.076zm17.619-6.434H28.666V49.5h17.619v-3.076z"
                    ></path>
                    <g filter="url(#ipl-illustrations-spot-6-5)">
                      <path
                        fill="#9D44C0"
                        d="M73.691 26.569v37.894l7.24-7.97 7.023 6.222-.69-36.147H73.691z"
                      ></path>
                    </g>
                    <g filter="url(#ipl-illustrations-spot-6-4)">
                      <path
                        fill="#5837D0"
                        d="M22.373 15.522h73.132v11.046H22.373V15.522z"
                      ></path>
                    </g>
                    <path
                      fill="#B6D7EE"
                      d="M32.817 21.093a2.076 2.076 0 1 1-4.15 0 2.076 2.076 0 0 1 4.15 0zm6.804 0a2.076 2.076 0 1 1-4.151 0 2.076 2.076 0 0 1 4.151 0zm4.729 2.077a2.076 2.076 0 1 0 0-4.152 2.076 2.076 0 0 0 0 4.151z"
                    ></path>
                  </g>
                  <rect
                    clipPath="url(#ipl-illustrations-spot-6-1)"
                    width="100%"
                    height="100%"
                    fill="url(#ipl-illustrations-spot-6-0)"
                    style={{ mixBlendMode: "overlay" }}
                  />
                </svg>
                <div className="text-center">
                  <p className="my-3 font-medium">No jobs saved yet</p>
                  <p className="text-sm text-gray-500">
                    Jobs you save appear here.
                  </p>
                  <p className="text-primary my-5 font-medium">
                    Not seeing a job?
                  </p>
                  <Link
                    to="/freelancer/jobs"
                    className="btn btn-outline btn-primary btn-sm rounded-md normal-case	"
                  >
                    <span>Find Jobs</span>
                    <BsArrowRight className="text-xl ml-1" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyJobsSaved;
