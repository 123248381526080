import React from "react";
import Slider from "./slider/Slider";

const Feedback = () => {
  return (
    <div className=" my-10 container mx-auto px-10">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 items-center">
        <div className="">
          <h3 className="text-lg font-semibold">What Clients Speak</h3>
          <h2 className="text-4xl font-bold">
            Our Client Opinion Matters a Lot
          </h2>
          <div className="flex gap-2 my-10">
            <div className="border w-2  border-red-500"></div>
            <div className="border w-4  border-red-500"></div>
            <div className="border w-2  border-red-500"></div>
          </div>
          <p className="text-gray-400 text-base">
            Client feedback helps businesses determine what the preferences of
            their client are.
          </p>
        </div>
        <div className="grid-cols-1 md:col-span-2 ">
          <div>
            <Slider />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
