import React, { useContext, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { SingleUserProfileGet } from "../../../../hooks/useUser";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const CompleteProfile = ({ register }) => {
  const { firebaseUser, currentUser } = useContext(globalFunctionOneContext);

  const navigate = useNavigate();
  const [fileInput, setFileInput] = useState("");
  const { data, isLoading, error, refetch } = SingleUserProfileGet(
    currentUser?.PersonID
  );

  /// arr1
  const arr1 = [
    {
      inputTitle: "How did you hear about us ?",
      titleTwo: [
        {
          id: 1,
          option: "Select Option",
        },
        {
          id: 2,
          option: "Word of Mouth",
        },
        {
          id: 3,
          option: "Job Portal",
        },
        {
          id: 4,
          option: "Advertisement",
        },
      ],
      name: "hear_aboutUs",
    },
    {
      inputTitle: "Where are you in your job search ?",
      titleTwo: [
        {
          id: 1,
          option: "Select Option",
        },
        {
          id: 2,
          option: "Open to new opportunities",
        },
        {
          id: 3,
          option: "Variety of opportunities",
        },
      ],
      name: "job_search",
    },
  ];

  // arr2
  const arr2 = [
    {
      inputTitle: "When will you be ready to start interviewing with company?",
      name: "ready_to_interview",
    },
    {
      inputTitle: "Why do you want to work in our company?",
      name: "hidden_form",
    },
  ];

  return (
    <div>
      <h2 className="uppercase font-semibold mt-10">🙌 Complete</h2>
      <div className="divider mb-2 mt-0"></div>
      <div>
        <span className="my-4">Profile Picture</span>
        {fileInput && (
          <div className="flex gap-4">
            <img src={fileInput} alt="Profile_picture" />
            <AiFillDelete className="text-xl text-red-600" />
          </div>
        )}
        <div className="flex my-4 items-center w-full">
          <label
            htmlFor="profile_pic"
            className="flex flex-col justify-center items-center w-full max-w-xs h-36 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            <div className="flex flex-col justify-center items-center pt-5 pb-6">
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                SVG, PNG, JPG (MAX. 800x400px)
              </p>
            </div>
            <input
              onChange={(e) => setFileInput(e.target.value)}
              {...register("image")}
              id="profile_pic"
              type="file"
              accept="image/*"
              multiple={false}
              className="hidden"
            />
          </label>
        </div>

        {arr1.map((a, i) => (
          <div
            key={i + 1 * 20 + 1 - 3}
            className="form-control w-full max-w-xs"
          >
            <label className="label">
              <span className="label-text">{a.inputTitle}</span>
            </label>
            <select
              {...register(a.name)}
              className="select select-bordered select-sm w-full max-w-xs"
            >
              {a.titleTwo.map((selectOption) => (
                <option
                  key={selectOption?.id}
                  value={selectOption.option}
                  disabled={selectOption?.id === 1}
                  selected={
                    selectOption?.id === 1 ||
                    data?.result?.[a.name] === selectOption?.option
                  }
                >
                  {selectOption.option}
                </option>
              ))}
            </select>
          </div>
        ))}

        <span className="text-slate-300 text-[12px] mb-4">
          This information is not displayed to Employers
        </span>

        {arr2.map((a, i) => (
          <div
            key={i - 1 + 20 * 1 + 3}
            className="form-control w-full max-w-xs"
          >
            <label className="label">
              <span className="label-text">{a.inputTitle}</span>
            </label>
            <input
              {...register(a.name)}
              type="text"
              placeholder="Type here"
              defaultValue={data?.result?.[a.name]}
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
        ))}

        <span className="text-slate-300 text-[12px]">
          Companies in this list will not see on your profile
        </span>
      </div>
    </div>
  );
};

export default CompleteProfile;
