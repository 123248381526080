import React, { useState } from "react";
import Links from "./Links";
import ScheduleModule from "./ScheduleModule";
import Users from "./Users";

const RightTalentManager = ({ data }) => {
  const [s, setS] = useState(false);

  return (
    <>
      {data?.map((data) => (
        <div
          key={data?.PersonID}
          className="card mt-10 md:mt-20 w-full bg-base-100 shadow-lg my-4"
        >
          <div className="card-body">
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-2">
                <Users data={data} />
              </div>
              <div className="flex">
                <div className="divider lg:divider-horizontal"></div>
                <Links data={data} s={s} setS={setS} />
              </div>
            </div>
          </div>
        </div>
      ))}

      {s && <ScheduleModule setS={setS} s={s} />}
    </>
  );
};

export default RightTalentManager;
