import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { MdOutlineDone } from "react-icons/md";
import BoardData from "./BoardData";
import { auth } from "../../../../config/firebaseConfig/firebaseConfig";
import { serverUrl } from "../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { SingleUserProfileGet } from "../../../../hooks/useUser";
const EducationHistory = ({
  register,
  showPageEducation,
  setShowPageEducation,
  educationInfo,
  setEducationInfo,
}) => {
  const [user] = useAuthState(auth);
  const PersonID = user?.uid;
  const [gradingValue, setGradingValue] = useState(0);
  // const [greadValuError, setGradeValueError] = useState("");
  const [schoolValue, setSchoolValue] = useState(false);

  // single user profile data
  const { data, isLoading, error, refetch } = SingleUserProfileGet(user?.uid);

  const addEducationOption = [
    "10th",
    "12th",
    "Doctorate/PHD",
    "Master/Post Graduation",
    "Graduation/Diploma",
    "Other",
  ];
  
  const UniversityOption = [
    "Chhattisgarh Swami Vivekanand Technical University",
    "Pandit Sundarlal Sharma (Open) University",
    "Indraprastha Institute of Information Technology",
    "Dr. Babasaheb Ambedkar Open University",
    "Gujarat Technological University",
    "Bhagat Phool Singh Mahila Vishwavidyalaya",
    "Himachal Pradesh Technical University",
    "Patna University",
    "Magadh University",
    "Veer Kunwar Singh University",
    "Gauhati University",
    "Delhi Technological University",
    "Goa University",
    "St. Stephen's College, DU",
    "Other",
  ];

  const courseOption = {
    "Graduation/Diploma": ["B.tech", "BCA", "BDS", "B.Sc", "Other"],
    "Master/Post Graduation": [
      "M.tech",
      "MCA",
      "M COM",
      "MBA/ MMS",
      "MDS",
      "M.Sc",
      "Other",
    ],
    "Doctorate/PHD": ["courses", "."],
  };
  const spOption = {
    "Graduation/Diploma": {
      "B.tech": [
        "Computer",
        "Aviation",
        "Electrical",
        "Electronics/Communication",
        "Marin",
        "Mechanical",
        "Mining",
        "Mineral",
        "Textile",
      ],
      BCA: ["Computers", "Others Specialization"],
      "B COM": ["Commerce", "Others Specialization"],
      "BBA/BMS": ["Management", "Others Specialization"],

      BDS: ["Dentistry", "Others Specialization"],
      "B.Sc": [
        "Chemistry",
        "Botany",
        "Bio Chemistry",
        "Agriculture",
        "Biology",
        "Environmental Science",
        "Electronics",
        "Computer",
        "Geology",
        "Zoology",
        "Food Technology",
      ],
    },
    "Master/Post Graduation": {
      "M.tech": [
        "Computer",
        "Aviation",
        "Electrical",
        "Electronics/ Communication",
        "Marin",
        "Mechanical",
        "Mining",
        "Mineral",
        "Textile",
      ],
      MCA: ["Computers", "Others Specialization"],
      "M COM": ["Commerce", " Others Specialization"],
      "MBA/ MMS": ["Management", "Others Specialization"],
      MDS: ["Dentistry", "Others Specialization"],
      "M.Sc": [
        "Chemistry",
        "Botany",
        "Bio Chemistery",
        "Agriculture",
        "Biology",
        "Environmental Science",
        "Electronics",
        "Computer",
        "Geology",
        "Zoology",
        "Food Technology",
      ],
    },
    "Doctorate/PHD": {
      courses: [
        "Ph.D/Doctorate",
        "Agriculture",
        "Anthropology",
        "Automobile",
        "Aviation",
        "Agriculture",
        "Biology",
        "Environmental Science",
        "Electronics",
        "Computer",
        "Geology",
        "Zoology",
        "Food Technology",
      ],
    },
  };

  const courseType = [
    { name: "courseType", value: "Full-Time" },
    { name: "courseType", value: "Part-Time" },
    { name: "courseType", value: "Correspondence/Distance learning" },
  ];
  const gradings = [
    "Scale 10 Grading System",
    "Scale 4 Grading System",
    "% marks of 100 maximum",
    "Course requires a pass",
  ];

  const boardData = BoardData;

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setEducationInfo({ ...educationInfo, [name]: value });
  };

  // grading value set
  useEffect(() => {
    if (educationInfo.gradingSystem === "Scale 10 Grading System") {
      setGradingValue(10);
    } else if (educationInfo.gradingSystem === "Scale 4 Grading System") {
      setGradingValue(4);
    } else if (educationInfo.gradingSystem === "% marks of 100 maximum") {
      setGradingValue(100);
    }
  }, [educationInfo.gradingSystem]);
  useEffect(() => {
    if (educationInfo.addEducation === "10th") {
      setSchoolValue(true);
    } else if (educationInfo.addEducation === "12th") {
      setSchoolValue(true);
    } else {
      setSchoolValue(false);
    }
  }, [educationInfo.addEducation]);
  // dyanamic year option
  let studyYear = [];
  let currentYear = new Date().getFullYear();
  for (let i = 1950; i <= currentYear; i++) {
    studyYear.push(i);
  }

  const fullEducationData = () => {
    console.log(educationInfo);
    // fetch(`${serverUrl}/userProfile/user-education`, {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     uid: PersonID,
    //   },
    //   body: JSON.stringify(educationInfo),
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     console.log(data);
    //   });
  };
  return (
    <div>
      <label htmlFor="drop">
        <h2 className="uppercase font-semibold mt-10">🎓 Education History</h2>
        <div className="divider mb-2 mt-0"></div>
        <div className="cursor-pointer flex gap-2">
          <p>Add Degree</p>
          <label className="swap swap-rotate">
            {/* <!-- this hidden checkbox controls the state --> */}
            <input
              onClick={() => setShowPageEducation(!showPageEducation)}
              id="drop"
              type="checkbox"
            />
            {/* <!-- hamburger icon --> */}
            <div className="swap-off fill-current">
              <AiOutlinePlusSquare />
            </div>

            {/* <!-- close icon --> */}
            <div className="swap-on fill-current">
              <AiOutlineMinusSquare />
            </div>
          </label>
        </div>
      </label>

      {/* showing page  */}
      {showPageEducation && (
        <div>
          <div className="form-control w-full ">
            <div>
              <label className="label">
                <span className="label-text">Education</span>
              </label>
              <select
                onChange={handleInputValue}
                name="addEducation"
                className="select w-[50%] select-bordered"
              >
                <option disabled value="" selected>
                  Select Education
                </option>

                {addEducationOption.map((option, i) => (
                  <option
                    key={i}
                    selected={data?.result?.addEducation === option}
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </select>
              {/*   <label className="label">
                <span className="label-text-alt">Alt label</span>
              </label> */}
              {educationInfo?.addEducation === "Other" && (
                <>
                  <div>
                    <h2 className="menu-title mt-5">Enter Education</h2>
                    <label className="label cursor-pointer flex gap-2 items-center">
                      <input
                        onChange={handleInputValue}
                        name="otherEducation"
                        type="text"
                        defaultValue={data?.result?.otherEducation}
                        className="input w-[50%] input-bordered "
                      />
                    </label>
                  </div>
                </>
              )}
            </div>

            {schoolValue === false && (
              <>
                <div>
                  <label className="label">
                    <span className="label-text">University/Institute</span>
                  </label>
                  <select
                    onChange={handleInputValue}
                    name="addUniversity"
                    className="select w-[50%] select-bordered"
                  >
                    <option disabled value="" selected>
                      Select University/Institute
                    </option>

                    {UniversityOption.map((option, i) => (
                      <option
                        key={i}
                        selected={data?.result?.addUniversity === option}
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                  {/*   <label className="label">
                <span className="label-text-alt">Alt label</span>
              </label> */}
                  {educationInfo?.addUniversity === "Other" && (
                    <>
                      <div>
                        <h2 className="menu-title mt-5">
                          Enter University/Institute
                        </h2>
                        <label className="label cursor-pointer flex gap-2 items-center">
                          <input
                            onChange={handleInputValue}
                            name="otherUniversity"
                            type="text"
                            defaultValue={data?.result?.otherUniversity}
                            className="input w-[50%] input-bordered "
                          />
                        </label>
                      </div>
                    </>
                  )}
                </div>
                <div>
                  <label className="label">
                    <span className="label-text">course</span>
                  </label>
                  <select
                    onChange={handleInputValue}
                    name="course"
                    className="select w-[50%] select-bordered"
                  >
                    <option disabled value="" selected>
                      Select course
                    </option>

                    {courseOption[educationInfo.addEducation] &&
                      courseOption[educationInfo.addEducation]?.map(
                        (option, i) => (
                          <option
                            key={i}
                            selected={data?.result?.course === option}
                            value={option}
                          >
                            {option}
                          </option>
                        )
                      )}
                  </select>
                  {/*   <label className="label">
                <span className="label-text-alt">Alt label</span>
              </label> */}
                  {educationInfo?.course === "Other" && (
                    <>
                      <div>
                        <h2 className="menu-title mt-5">Enter course</h2>
                        <label className="label cursor-pointer flex gap-2 items-center">
                          <input
                            onChange={handleInputValue}
                            name="otherCourse"
                            type="text"
                            defaultValue={data?.result?.otherCourse}
                            className="input w-[50%] input-bordered "
                          />
                        </label>
                      </div>
                    </>
                  )}
                </div>
                {educationInfo.course !== "Other" && (
                  <div>
                    <label className="label">
                      <span className="label-text">Specialization</span>
                    </label>
                    <select
                      onChange={handleInputValue}
                      name="courseSpecialization"
                      className="select w-[50%] select-bordered"
                    >
                      <option disabled value="" selected>
                        Select Specialization
                      </option>

                      {spOption[educationInfo?.addEducation] &&
                        spOption[educationInfo?.addEducation][
                          educationInfo?.course
                        ]?.map((option, i) => (
                          <option
                            key={i}
                            selected={
                              data?.result?.courseSpecialization === option
                            }
                            value={option}
                          >
                            {option}
                          </option>
                        ))}
                    </select>
                    {/*   <label className="label">
                <span className="label-text-alt">Alt label</span>
              </label> */}
                  </div>
                )}

                <div>
                  <h2 className="menu-title mt-4">Course Type</h2>
                  <div className="flex items-center gap-2">
                    {courseType.map((type, i) => (
                      <label
                        key={i}
                        className="label cursor-pointer flex gap-2 items-center"
                      >
                        <input
                          type="radio"
                          name={type.name}
                          defaultValue={data?.result?.employmentType}
                          className="radio checked:bg-primary"
                          checked={data?.result?.employmentType === type?.value}
                        />
                        <span className="label-text">{type.value}</span>
                      </label>
                    ))}
                  </div>
                </div>
                {/* course duration */}
                <div>
                  <h2 className="mt-4 menu-title">Course Duration</h2>

                  <div className="flex gap-3 items-center">
                    <div>
                      <select
                        onChange={handleInputValue}
                        name="courStartingYear"
                        className="select w-full select-bordered"
                      >
                        <option disabled value="" selected>
                          Starting Year
                        </option>
                        {studyYear?.map((option, i) => (
                          <option
                            key={i}
                            selected={data?.result?.courStartingYear === option}
                            value={option}
                          >
                            {option}
                          </option>
                        ))}
                      </select>
                      {/*  <label className="label">
                    <span className="label-text-alt">Alt label</span>
                  </label> */}
                    </div>
                    <h2 className="menu-title">To</h2>
                    <div>
                      <select
                        onChange={handleInputValue}
                        name="courseEndingYear"
                        className="select w-full select-bordered"
                      >
                        <option disabled value="" selected>
                          Ending Year
                        </option>
                        {studyYear?.map((option, i) => (
                          <option
                            key={i}
                            selected={data?.result?.courseEndingYear === option}
                            value={option}
                          >
                            {option}
                          </option>
                        ))}
                      </select>
                      {/*  <label className="label">
                    <span className="label-text-alt">Alt label</span>
                  </label> */}
                    </div>
                  </div>
                </div>
                {/* grading system */}
                <div>
                  <label className="label">
                    <span className="label-text">Grading System</span>
                  </label>
                  <select
                    onChange={handleInputValue}
                    name="gradingSystem"
                    className="select w-[50%] select-bordered"
                  >
                    <option disabled value="" selected>
                      Select Grading System
                    </option>
                    {gradings.map((grade, i) => (
                      <option
                        key={i}
                        selected={data?.result?.gradingSystem === grade}
                        value={grade}
                      >
                        {grade}
                      </option>
                    ))}
                  </select>
                  {/*  <label className="label">
                <span className="label-text-alt">Alt label</span>
              </label> */}
                </div>
                {!educationInfo.gradingSystem ||
                  (educationInfo.gradingSystem !== "Course requires a pass" && (
                    <div>
                      <label className="label">
                        <span className="label-text">mark</span>
                      </label>
                      <input
                        onChange={handleInputValue}
                        type="number"
                        min={1}
                        max={gradingValue}
                        name="marks"
                        defaultValue={data?.result?.marks}
                        className="input input-bordered w-[50%]"
                      />
                      {/*  <label className="label">
                <span className="label-text-alt">Alt label</span>
              </label> */}
                    </div>
                  ))}
              </>
            )}
            {schoolValue && (
              <>
                <div>
                  <label className="label">
                    <span className="label-text">Board</span>
                  </label>
                  <select
                    onChange={handleInputValue}
                    name="boardName"
                    className="select w-[50%] select-bordered"
                  >
                    <option disabled value="" selected>
                      Select Board
                    </option>
                    <option disabled value="">
                      {boardData.defaultValue1}
                    </option>

                    {boardData?.allIndiaBoard?.map((option, i) => (
                      <option
                        key={i}
                        selected={data?.result?.boardName === option}
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                    <option disabled value="">
                      {boardData.defaultValue2}
                    </option>
                    {boardData?.stateBoard?.map((option, i) => (
                      <option
                        key={i}
                        selected={data?.result?.boardName === option}
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                  {/*   <label className="label">
                <span className="label-text-alt">Alt label</span>
              </label> */}
                  {educationInfo?.boardName === "Other" && (
                    <>
                      {""}
                      <div>
                        <h2 className="menu-title mt-5">
                          Enter Your board Name
                        </h2>
                        <label className="label cursor-pointer flex gap-2 items-center">
                          <input
                            onChange={handleInputValue}
                            name="otherBoard"
                            type="text"
                            defaultValue={data?.result?.otherBoard}
                            className="input w-[50%] input-bordered "
                          />
                        </label>
                      </div>
                    </>
                  )}
                </div>

                <div>
                  <label className="label">
                    <span className="label-text"> Passing out Year</span>
                  </label>
                  <select
                    onChange={handleInputValue}
                    name="schoolPassingYear"
                    className="select w-[50%] select-bordered"
                  >
                    <option disabled value="" selected>
                      Select Passing Year
                    </option>
                    {studyYear?.map((option, i) => (
                      <option
                        key={i}
                        selected={data?.result?.schoolPassingYear === option}
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                  {/*   <label className="label">
                <span className="label-text-alt">Alt label</span>
              </label> */}
                </div>

                <div>
                  <label className="label">
                    <span className="label-text">School Medium</span>
                  </label>
                  <select
                    onChange={handleInputValue}
                    name="schoolMedium"
                    className="select w-[50%] select-bordered"
                  >
                    <option disabled value="" selected>
                      Select Medium
                    </option>

                    {boardData?.schoolMedium?.map((option, i) => (
                      <option
                        key={i}
                        selected={data?.result?.schoolMedium === option}
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                  {/*   <label className="label">
                <span className="label-text-alt">Alt label</span>
              </label> */}
                  {educationInfo?.schoolMedium === "Other" && (
                    <>
                      <div>
                        <h2 className="menu-title mt-5">Enter School Medium</h2>
                        <label className="label cursor-pointer flex gap-2 items-center">
                          <input
                            onChange={handleInputValue}
                            name="otherMedium"
                            type="text"
                            defaultValue={data?.result?.otherMedium}
                            className="input w-[50%] input-bordered "
                          />
                        </label>
                      </div>
                    </>
                  )}
                </div>

                <div>
                  <label className="label">
                    <span className="label-text">Total Marks</span>
                  </label>
                  <select
                    onChange={handleInputValue}
                    name="schoolMarks"
                    className="select w-[50%] select-bordered"
                  >
                    <option disabled value="" selected>
                      Select Total Marks
                    </option>

                    {boardData?.marks?.map((option, i) => (
                      <option
                        key={i}
                        selected={data?.result?.schoolMarks === option}
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                  {/*   <label className="label">
                <span className="label-text-alt">Alt label</span>
              </label> */}
                </div>
                <div>
                  <label className="label">
                    <span className="label-text">English Marks</span>
                  </label>
                  <input
                    onChange={handleInputValue}
                    name="englishMarks"
                    placeholder="marks (out of 100)"
                    defaultValue={data?.result?.englishMarks}
                    className="input w-[50%] input-bordered"
                  />
                  {/*   <label className="label">
                <span className="label-text-alt">Alt label</span>
              </label> */}
                </div>
                <div>
                  <label className="label">
                    <span className="label-text">Math Marks</span>
                  </label>
                  <input
                    onChange={handleInputValue}
                    name="MathMarks"
                    placeholder="marks (out of 100)"
                    defaultValue={data?.result?.MathMarks}
                    className="input w-[50%] input-bordered"
                  />
                  {/*   <label className="label">
                <span className="label-text-alt">Alt label</span>
              </label> */}
                </div>
              </>
            )}
            {/* <div className="flex justify-end gap-4 my-4">
              <div
                onClick={fullEducationData}
                className="btn btn-sm flex gap-2 items-center btn-primary"
              >
                <MdOutlineDone />
                <span>Save</span>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default EducationHistory;
