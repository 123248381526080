import React, { useContext, useEffect, useState } from "react";
import {
  useSignInWithEmailAndPassword,
  useSignInWithFacebook,
  useSignInWithGoogle,
} from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../Loading/Loading";
import { auth } from "../../config/firebaseConfig/firebaseConfig";
import { globalFunctionOneContext } from "../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const Login = () => {
  const { currentUser, windowLoading } = useContext(globalFunctionOneContext);
  const [signInWithEmailAndPassword, rUser, loading, error] =
    useSignInWithEmailAndPassword(auth);
  const [signInWithFacebook, fUser, fLoading, fError] =
    useSignInWithFacebook(auth);
  const [showPassword, setShowPassword] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  if (windowLoading) {
    return <Loading />;
  }
  const handleLogin = async (e) => {
    e.preventDefault();
    // console.log(e.target.password.value);
    const email = e.target.email.value;
    const role = e.target.role.value;
    const password = e.target.password.value;
    await signInWithEmailAndPassword(email, password);
    console.log({ email, role, password });
  };

  let { from } = location.state || { from: { pathname: "/" } };

  if (currentUser) {
    navigate(from, { replace: true });
  }

  return (
    <div className="container h-[80vh] flex justify-center items-center">
      <div className="card max-w-md bg-base-100 shadow-xl">
        <div className="card-body">
          <p className="text-center font-bold">
            Please Login Your Account.If don't create account Please Register
            your Account
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
