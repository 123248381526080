import React, { useContext } from "react";
import { globalFunctionOneContext } from "../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const DropFilesInput = () => {
  const { handelOnFileDrop, handelMultiFileDrop, fileDropLimitNumber } =
    useContext(globalFunctionOneContext);

  return (
    <div className="w-full border-2 border-dashed rounded-md mt-5 flex justify-center md:justify-start items-center px-2.5 py-1.5">
      <label htmlFor="profile_photo" className="file_select_btn">
        Select File
      </label>
      <div className="flex-auto py-3 relative">
        <p className="text-gray-500 md:pl-10 text-lg">
          Drop files here to upload
        </p>
        {fileDropLimitNumber && fileDropLimitNumber === 1 && (
          <input
            type="file"
            id="profile_photo"
            className="absolute w-full h-full opacity-0 top-0 left-0"
            onChange={(e) => handelOnFileDrop(e)}
            accept="image/png, image/jpeg"
          />
        )}
        {fileDropLimitNumber && fileDropLimitNumber === 2 && (
          <input
            type="file"
            id="profile_photo"
            className="absolute w-full h-full opacity-0 top-0 left-0 bg-black"
            onChange={(e) => handelMultiFileDrop(e, 2)}
            accept="image/png, image/jpeg"
          />
        )}
      </div>
    </div>
  );
};

export default DropFilesInput;
