import React, { useContext } from "react";
import { Link, Outlet } from "react-router-dom";
import { DashboardClientJobsNavbar } from "../../../hooks/NavbarAllFunction/useNavbarAllFunction";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const JobsNavbar = () => {
  const { userCurrentLocationPath } = useContext(globalFunctionOneContext);
  const { active } = DashboardClientJobsNavbar(userCurrentLocationPath);

  const menuList = [
    {
      id: 1,
      linkText: "View Job Post",
      path: "/dashboard/client/jobs",
    },
    {
      id: 2,
      linkText: "Invite Freelancer",
      path: "/dashboard/client/jobs/inviteFreelancer",
    },
    {
      id: 3,
      linkText: `Review Proposal`,
      path: "/dashboard/client/jobs/reviewProposal",
    },
    {
      id: 4,
      linkText: "Hire",
      path: "/dashboard/client/jobs/hire",
    },
  ];

  return (
    <div>
      <div className=" mb-4 btn-group grid grid-cols-4 px-2">
        {menuList &&
          menuList.map((list) => (
            <Link
              key={list?.id}
              to={list?.path}
              className={`btn  ${
                active === list?.id ? `btn-primary` : `btn-outline `
              } rounded-l-lg`}
            >
              {list?.linkText}
            </Link>
          ))}
      </div>

      <Outlet />
    </div>
  );
};

export default JobsNavbar;
