import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import {
  serverImageUrl,
  serverUrl,
} from "../../../../hooks/AllServerUrlControl/useAllServerUrlControl";

const HireViewContract = () => {
  const {
    firebaseUser,
    currentUser,
    Loading,
    windowLoading,
    setWindowLoading,
    setUserCurrentLocationPathParameter,
  } = useContext(globalFunctionOneContext);
  const [viewOfferData, setViewOfferData] = useState({});
  const parameter = useParams();
  // const { jobId, freelancerId } = useParams();

  // set Global state query id  function
  useEffect(() => {
    setUserCurrentLocationPathParameter(parameter);

    fetch(
      `${serverUrl}/client/jobs/view/offer/${parameter?.jobId}/${parameter?.freelancerId}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === "success") {
          console.log(data?.data, "data?.data");
          setViewOfferData(data?.data);
          //   calculateTotalAmount(data?.data?.minimum_price);
          //   setCurrentPersonDAta(data?.data);
          //   setScheduleCandidate(data?.data);
        }
      });
  }, [parameter, setUserCurrentLocationPathParameter]);

  if (!viewOfferData?.job_categories || !currentUser) {
    return <div>loading ........</div>;
  }
  return (
    <div>
      <div className="bg-gray-100 py-10 mt-10">
        <h2 className="text-center text-xl mb-10">
          You're protected by{" "}
          <span className="text-primary font-medium">
            Hire Payment Protection.
          </span>{" "}
          Only pay for the work you authorize
        </h2>
        <div className="sm:px-10">
          <div className="card bg-white border border-gray-300 rounded-md max-w-full mx-auto ">
            <div className="grid grid-cols-1 md:grid-cold-2 lg:grid-cols-6 ">
              <div className="border-r border-gray-300 lg:col-span-4 py-5">
                <div className="grid grid-cols-1 mg:grid-cols-2  mg:px-10 px-5">
                  <h2 className="text-2xl font-medium">Summary</h2>
                  <ul className="col-span-2">
                    <li className="my-5 font-medium text-sm s:text-lg grid gap-2 grid-cols-2">
                      <span>Job Category</span>
                      <span>{viewOfferData?.job_categories}</span>
                    </li>
                    <li className="my-5 font-medium text-sm s:text-lg grid gap-2 grid-cols-2">
                      <span>Project Start Date</span>
                      <span>{viewOfferData?.project_start_date}</span>
                    </li>
                    <li className="my-5 font-medium text-sm s:text-lg grid gap-2 grid-cols-2">
                      <span>Project Expire Date</span>
                      <span>{viewOfferData?.project_expire_date}</span>
                    </li>
                    <li className="my-5 font-medium text-sm s:text-lg grid gap-2 grid-cols-2">
                      <span>Project Deadline</span>
                      <span>{viewOfferData?.project_deadline_date}</span>
                    </li>
                    <li className="my-5 font-medium text-sm s:text-lg grid gap-2 grid-cols-2">
                      <span>Project Fixted Price</span>
                      <span>₹ {viewOfferData?.minimum_price}</span>
                    </li>
                  </ul>
                  <h2 className="text-2xl font-medium">Details</h2>
                  <ul className="col-span-2">
                    <li className="my-5 font-medium text-sm s:text-lg grid gap-2 grid-cols-2">
                      <span>Hire by</span>
                      <span>
                        {currentUser?.firstName + currentUser?.lastName}
                      </span>
                    </li>
                    <li className="my-5 font-medium text-sm s:text-lg grid gap-2 grid-cols-2">
                      <span>Contract ID</span>
                      <span>{viewOfferData?.contractId}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-span-2">
                <div className="px-5 mg:px-10 mt-5">
                  <h2 className="text-2xl my-5 font-medium">
                    About The Talent
                  </h2>

                  <div className="flex flex-col sm:flex-row justify-center sm:justify-start items-start gap-5">
                    <div className="avatar online">
                      <div className="w-16 rounded-full">
                        <img
                          src={`${serverImageUrl}/${viewOfferData?.profilePhoto}`}
                          alt="My-Logo"
                          className="rounded-full"
                        />
                      </div>
                    </div>
                    <div>
                      <h1 className="font-medium text-lg">
                        {viewOfferData?.firstName} {viewOfferData?.lastName}
                      </h1>
                      <p className="text-sm">{viewOfferData?.profile_title}</p>
                    </div>
                  </div>

                  <div className="my-5">
                    <p>₹ 0 Total earned</p>
                  </div>

                  {/* <h1 className="font-medium text-lg my-5">About the client</h1>

              <div className="my-5 flex justify-start items-center gap-5">
                <button className="bg-[#15bbf0] hover:bg-[#0fa3d4] px-2 uppercase font-medium rounded text-white duration-300 ease-in-out">
                  Plus
                </button>
                <p className="text-[#15bbf0]">Hire puls client</p>
              </div> */}

                  {/* <div className="my-5 flex justify-start items-center gap-3">
                <p className="w-5 h-5 p-2 rounded-full bg-[#15a800] text-white text-center flex justify-center items-center text-sm">
                  &#10003;
                </p>
                <p>Payment method verify</p>
              </div>

              <div className="my-5">
                <p className="font-bold">Nauru</p>
                <p>Yaren District :21 pm</p>
              </div> */}
                  {/* <div className="my-5">
                <p className="font-bold">1 job posted</p>
                <p>0% hire rate. 1 open job</p>
              </div> */}
                  {/* <div className="my-5">
                <p>Member jun 23,2021</p>
              </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*
    <ModalBody
      onClose={() => setInviteMessageM(false)}
      isVisible={inviteMessageM}
      customOpacityLayer="bg-opacity-0"
      customClass="card w-full mx-10 md:max-w-md md:mx-auto bg-[#fff] p-4"
      modalCloseCustomButtonAdd={true}
    >
      <div className="flex justify-center sm:justify-between items-start sm:flex-row flex-col-reverse  gap-5 md:gap-40 ">
        <div>
          <div className="flex flex-col sm:flex-row justify-center md:justify-start items-start gap-5">
            <div className="avatar online">
              <div className="w-16 rounded-full">
                <img
                  src={`${serverImageUrl}/${currentPersonDAta?.profilePhoto}`}
                  alt="This is profile pic"
                  className="rounded-full"
                />
              </div>
            </div>
            <div>
              <h1 className="font-medium text-lg">
                {currentPersonDAta?.firstName} {currentPersonDAta?.lastName}
              </h1>
              <p className="text-sm">{currentPersonDAta?.profile_title}</p>
            </div>
          </div>
        </div>
        <button
          className="btn-secondary btn btn-sm btn-circle place-self-end sm:place-self-start"
          onClick={() => {
            setInviteMessageM(false);
            setCurrentPersonDAta({});
          }}
        >
          ✕
        </button>
      </div>
      <form className="px-3 mt-10 mb-5" onSubmit={handelSendMessage}>
        <input
          className="w-full py-2 px-5 rounded-lg focus:border-secondary outline-none border"
          placeholder="Type Your Message"
          name="sendMessage"
        />

        <div className="flex justify-end items-center">
          <button className="btn btn-sm bg-secondary mt-5 px-5" type="submit">
            Send
          </button>
        </div>
      </form>
    </ModalBody>

    <ModalBody
      onClose={() => setHandelScheduleInterviewModal(false)}
      isVisible={handelScheduleInterviewModal}
      customOpacityLayer="bg-opacity-0"
      customClass="card w-full md:max-w-xl md:mx-auto bg-[#fff] p-5"
      modalCloseCustomButtonAdd={false}
    >
      <div className="h-[550px] overflow-y-auto">
        <ScheduleInterview
          scheduleCandidate={scheduleCandidate}
          setHandelScheduleInterviewModal={setHandelScheduleInterviewModal}
        />
      </div>
    </ModalBody> */}
    </div>
  );
};

export default HireViewContract;
