import React, { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import Form from "./Form.js";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../config/firebaseConfig/firebaseConfig.js";
import { serverUrl } from "../../../hooks/AllServerUrlControl/useAllServerUrlControl.js";
import axios from "axios";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne.js";
import { toast } from "react-toastify";
import RazorPay from "../payment/RazorPay.js";
import { useJobPost } from "../../../context/JobPostProvider.js";

const PostJob = ({ post, setPost, ID }) => {
  const [amount, setAmount] = useState("");
  const { refetch } = useJobPost();
  const {
    project_expire_date,
    project_deadline_date,
    project_start_date,
    setProject_expire_date,
    setProject_deadline_date,
    setProject_start_date,
    setIsValidDate,
  } = useContext(globalFunctionOneContext);

  const { register, handleSubmit, formState, setValue } = useForm();
  const [job, setJob] = useState({});
  const [userSkill, setUserSkill] = useState([]);
  const [id, setId] = useState("");
  const [flag, setFeatureFlag] = useState(false);

  const dynamicInputData = {
    userSkill,
    setUserSkill,
  };
  const [user, loading, error] = useAuthState(auth);

  // const {
  //   RecallEffect: { effectRecall, setEffectRecall },
  // } = useJobPost();

  const fetch_data = async () => {
    await fetch(`${serverUrl}/client/single_postingJob/${ID}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setJob(data.result);
        setValue("job_title", data.result.job_title);
        setValue("job_duration", data.result.job_duration);
        setValue("freelancer_type", data.result.freelancer_type);
        setValue("project_location_type", data.result.project_location_type);
        setValue("english_level", data.result.english_level);
        setValue(
          "years_of_experience_preferred",
          data.result.years_of_experience_preferred
        );
        setValue("project_expire_date", data.result.project_expire_date);
        setValue("project_start_date", data.result.project_start_date);
        setValue("project_deadline_date", data.result.project_deadline_date);
        setValue("fixed_project", data.result.fixed_project);
        setValue("minimum_price", data.result.minimum_price);
        setValue("maximum_price", data.result.maximum_price);
        setValue("job_details", data.result.job_details);
        setValue("JobLocation", data.result.JobLocation);
        setValue("job_categories", data.result.job_categories);
        setValue("language", data.result.language);
        setValue("project_level", data.result.project_level);
        setValue("projectFile", data.result.projectFile[0]);
        setValue("userSkill", data.result.skills_required);
        //setUserSkill(data.result.skills_required);
      });
  };
  const navigate = useNavigate();

  const featchflag = async () => {
    const response = await axios.get(`${serverUrl}/client/feature`);
    setFeatureFlag(response.data);
  };

  // console.log(flag);

  useEffect(() => {
    featchflag();
    if (ID) {
      fetch_data();
    }
  }, [ID]);

  const onSubmit = async (data) => {
    // event.preventDefault();
    const allData = {
      ...data,
      userSkill,
    };

    if (project_start_date && project_expire_date && project_deadline_date) {
      const formData = new FormData();
      formData.append("job_title", data.job_title);
      formData.append("skills_required", JSON.stringify(allData.userSkill));
      formData.append("job_duration", data.job_duration);
      formData.append("freelancer_type", data.freelancer_type);
      formData.append("project_location_type", data.project_location_type);
      formData.append("english_level", data.english_level);
      formData.append(
        "years_of_experience_preferred",
        data.years_of_experience_preferred
      );
      formData.append("project_start_date", project_start_date);
      formData.append("project_expire_date", project_expire_date);
      formData.append("project_deadline_date", project_deadline_date);
      formData.append("fixed_project", data.fixed_project);
      formData.append("minimum_price", data.minimum_price);
      // formData.append("maximum_price", data.maximum_price);
      formData.append("job_details", data.job_details);
      formData.append("JobLocation", data.JobLocation);
      formData.append("job_categories", data.job_categories);
      formData.append("language", data.language);
      formData.append("project_level", data.project_level);
      formData.append("projectFile", data.projectFile[0]);
      // form submission code goes
      setJob(formData);
      console.log(job);
      if (ID) {
        formData.append("id", ID);
      }
      fetch(
        `${serverUrl}/client/postJob`,

        {
          method: "POST",
          body: formData,
          headers: {
            uid: user.uid,
            Accept: "multipart/form-data",
            Credential: "include",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            // setEffectRecall(true);
            // console.log(effectRecall);
            refetch();
            // setPost(!post);
            setProject_expire_date("");
            setProject_deadline_date("");
            setProject_start_date("");
            setId(data.result);

            toast.success("🦄 your job post is successful !!!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.error(
              "🦄 your job post is not successful please try again !!!",
              {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          }
        });
    } else {
      setIsValidDate({
        error: true,
        errorMessages:
          "Check your project start date and Expire date and Deadline date",
        errorNumber: 1,
      });
    }
  };

  /*  const handlePayment = async () => {
    try {
      // Make the POST request using axios
      const response = await axios.post(`${serverUrl}/client/pay`, { id: id });

      // Handle the response from the PHP server
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
    console.log("donneeee");
    setPost(!post);
    navigate(`//elite-professionals.in/code.php`);
  }; */

  return (
    <>
      {/* Put this part before </body> tag */}
      <input type="checkbox" id="post_job" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box w-11/12 max-w-5xl">
          <div className="flex justify-end">
            <button
              onClick={() => {
                setPost(!post);
                setProject_expire_date("");
                setProject_deadline_date("");
                setProject_start_date("");
                setIsValidDate({
                  error: false,
                  errorMessages: "",
                  errorNumber: 0,
                });
              }}
              className="btn btn-sm inline btn-primary"
            >
              x
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Form
              setAmount={setAmount}
              dynamicInputData={dynamicInputData}
              register={register}
            />

            <div className="my-2 flex gap-10">
              {/* <input
                className="input input-primary"
                type="text"
                {...register("key")}
              /> */}

              <button
                type="submit"
                disabled={
                  !formState.isValid ||
                  userSkill.length === 0 ||
                  !project_start_date ||
                  !project_expire_date ||
                  !project_deadline_date
                }
                className="btn btn-primary"
              >
                save & update
              </button>
              {id && (
                <RazorPay
                  postData={"true"}
                  post={post}
                  setPost={setPost}
                  job_id={id}
                  amount={amount}
                >
                  Make A Payment
                </RazorPay>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PostJob;
