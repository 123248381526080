import React from "react";
import { BsPeople } from "react-icons/bs";
import { GrSchedules } from "react-icons/gr";
import { IoMdTime } from "react-icons/io";
import { IoLocationOutline, IoTimerSharp } from "react-icons/io5";
import { MdOutlineDashboard } from "react-icons/md";
import { SiInstructables } from "react-icons/si";
import { TbReportSearch } from "react-icons/tb";
import { MdMyLocation, MdOutlineEdit, MdTimer } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
import { TbPlugConnected } from "react-icons/tb";

import { CiLocationArrow1 } from "react-icons/ci";

import { Link, Outlet } from "react-router-dom";
import TimSheetDashboardNavbar from "./timesheetNavbar/TimSheetDashboardNavbar";

const TimeCounter = () => {
  const timeSheetNavbar = [
    {
      icon: <MdOutlineDashboard className="text-2xl" />,
      path: "/dashboard/time-sheet-dashboard",
      title: "Dashboard",
    },
    {
      icon: <IoMdTime className="text-2xl" />,
      path: "/dashboard/time-sheet-dashboard/time-sheet",
      title: "TimeSheets",
    },
    {
      icon: <CiLocationArrow1 className="text-2xl" />,
      path: "#",
      title: "Live Location",
    },
    {
      icon: <MdTimer className="text-2xl" />,
      path: "#",
      title: "Time Off",
    },
    {
      icon: <TbReportSearch className="text-2xl" />,
      path: "#",
      title: "Reports",
    },
  ];
  const timeSheetSettingNavbar = [
    {
      icon: <BsPeople className="text-2xl" />,
      path: "#",
      title: "People",
    },
    {
      icon: <IoTimerSharp className="text-2xl" />,
      path: "#",
      title: "Time Tracking",
    },
    {
      icon: <GrSchedules className="text-2xl" />,
      path: "#",
      title: "Work schedules",
    },
    {
      icon: <SiInstructables className="text-2xl" />,
      path: "#",
      title: "Time OF & Holidays",
    },
    {
      icon: <MdOutlineEdit className="text-2xl" />,
      path: "#",
      title: "Activities & Projects",
    },
    {
      icon: <CiSettings className="text-2xl" />,
      path: "#",
      title: "Organization",
    },
    {
      icon: <TbPlugConnected className="text-2xl" />,
      path: "#",
      title: "Integration",
    },
  ];

  return (
    <div>
      <div
        style={{ height: "calc(100vh - 64px)" }}
        className="drawer drawer-mobile">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
          <TimSheetDashboardNavbar>
            <Outlet />
          </TimSheetDashboardNavbar>
        </div>
        <div className="drawer-side">
          <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
          <ul className="menu p-1 w-48 bg-base-100 text-base-content">
            {/* <!-- Sidebar content here --> */}
            {timeSheetNavbar.map((item, index) => (
              <>
                {/* create item with navbar add Link tag*/}
                <li key={index + 2 * 5}>
                  <Link className="p1" to={item.path}>
                    {item.icon} {item.title}
                  </Link>
                </li>
              </>
            ))}
            <div className="divider"></div>
            <div className="collapse collapse-arrow border border-base-300 border-none">
              <input type="checkbox" className="peer" />
              <div className="collapse-title">Settings</div>
              <div className="collapse-content">
                {timeSheetSettingNavbar.map((item, index) => (
                  <>
                    {/* create item with navbar add Link tag*/}
                    <li key={index + 2 * 5}>
                      <Link className="p-2" href={item.path}>
                        {item.icon} {item.title}
                      </Link>
                    </li>
                  </>
                ))}
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TimeCounter;
