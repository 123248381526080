import React, { useContext } from "react";
import style from "./TalentManagerForm.module.css";
import { BsInstagram, BsGithub, BsLink45Deg, BsYoutube } from "react-icons/bs";
import { FaLinkedinIn, FaFacebookF } from "react-icons/fa";
import { GrStackOverflow } from "react-icons/gr";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { serverUrl } from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { useNavigate } from "react-router-dom";
import { SingleUserProfileGet } from "../../../hooks/useUser";
import {
  CommonFormBackBtn,
  CommonFormSubmitBtn,
} from "../../Shared/AllCommonButton/AllCommonButton";

const TalentManageSocialProfileForm = () => {
  const { firebaseUser, currentUser } = useContext(globalFunctionOneContext);
  const navigate = useNavigate();

  const { data, isLoading, error, refetch } = SingleUserProfileGet(
    currentUser?.PersonID
  );
  console.log(data?.result?.userIdentity);

  // HandelSocialProfile
  const HandelSocialProfile = async (e) => {
    e.preventDefault();
    const form = e.target;
    const linkedIn = form.linkedIn.value;
    const facebook = form.facebook.value;
    const github = form.github.value;
    const stackOverflow = form.stackOverflow.value;
    const instagram = form.instagram.value;
    const youtube = form.youtube.value;
    const othersLink = form.othersLink.value;

    const socialProfileUrlData = {
      linkedIn,
      facebook,
      github,
      stackOverflow,
      instagram,
      youtube,
      othersLink,
    };

    // console.log(socialProfileUrlData);

    const res = await fetch(
      `${serverUrl}/talent-manager-profile/social-profile-data`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json", uid: firebaseUser?.uid },
        Credential: "include",
        body: JSON.stringify(socialProfileUrlData),
      }
    );
    const data = await res.json();
    if (data?.status === "success") {
      console.log(data);
      form.reset();
      refetch();
      navigate("/user/profile/from/upload/id/talent/manager/form");
    }
  };

  const socialInputArray = [
    {
      id: 1,
      inputType: "url",
      inputName: "linkedIn",
      inputPlaceholder: "Enter your Linkedin Link",
      inputClassName:
        "input input-bordered w-full focus:outline-none focus:border-[#fd03dc] pl-14 rounded-md",
      inputIcon: <FaLinkedinIn className="text-xl" />,
      inputIconColor: "text-[#0177B5]",
    },
    {
      id: 2,
      inputType: "url",
      inputName: "facebook",
      inputPlaceholder: "Enter your Facebook Link",
      inputClassName:
        "input input-bordered w-full focus:outline-none focus:border-[#fd03dc] pl-14 rounded-md",
      inputIconColor: "text-[#3B5998]",
      inputIcon: <FaFacebookF className="text-xl" />,
    },
    {
      id: 3,
      inputType: "url",
      inputName: "github",
      inputPlaceholder: "Enter your Github  Link",
      inputClassName:
        "input input-bordered w-full focus:outline-none focus:border-[#fd03dc] pl-14 rounded-md",
      inputIconColor: "",
      inputIcon: <BsGithub className="text-xl" />,
    },
    {
      id: 4,
      inputType: "url",
      inputName: "stackOverflow",
      inputPlaceholder: "Enter your Stack overflow Link",
      inputClassName:
        "input input-bordered w-full focus:outline-none focus:border-[#fd03dc] pl-14 rounded-md",
      inputIconColor: "text-[#fd03dc]",
      inputIcon: <GrStackOverflow className="text-xl" />,
    },
    {
      id: 5,
      inputType: "url",
      inputName: "instagram",
      inputPlaceholder: "Enter your Instagram  Link",
      inputClassName:
        "input input-bordered w-full focus:outline-none focus:border-[#fd03dc] pl-14 rounded-md",
      inputIconColor: "text-[#C53081]",
      inputIcon: <BsInstagram className="text-xl" />,
    },
    {
      id: 6,
      inputType: "url",
      inputName: "youtube",
      inputPlaceholder: "Enter your Youtube Link",
      inputClassName:
        "input input-bordered w-full focus:outline-none focus:border-[#fd03dc] pl-14 rounded-md",
      inputIconColor: "text-[#FF0000]",
      inputIcon: <BsYoutube className="text-2xl" />,
    },
    {
      id: 7,
      inputType: "url",
      inputName: "othersLink",
      inputPlaceholder: "Enter your Others Link",
      inputClassName:
        "input input-bordered w-full focus:outline-none focus:border-[#fd03dc] pl-14 rounded-md",
      inputIconColor: "text-[#5A5B5C] ",
      inputIcon: <BsLink45Deg className="text-xl" />,
    },
  ];

  return (
    <div>
      <form className="px-4" onSubmit={HandelSocialProfile}>
        <h1 className={style.form_hading}>Social profile</h1>
        <div className="grid gap-5 grid-cols-1 md:grid-cols-2 mt-5">
          {socialInputArray.map((input) => (
            <div
              key={input?.id}
              className="flex justify-center items-center relative"
            >
              <input
                type={input?.inputType}
                name={input?.inputName}
                placeholder={input?.inputPlaceholder}
                className={input?.inputClassName}
                defaultValue={data?.result?.[input?.inputName]}
              />
              <span
                className={`${input?.inputIconColor} absolute top-0 left-0 w-12 h-full text-lg rounded-l-lg flex justify-center items-center border-r-2`}
              >
                {input && input?.inputIcon}
              </span>
            </div>
          ))}
        </div>

        {/* submit & Back button wraper  */}
        <div className="flex justify-end items-center w-full mt-10">
          <div>
            <CommonFormBackBtn
              type={"button"}
              BtnText={"Back"}
              className={"mr-5 px-10 py-3 text-sm"}
            />
            <CommonFormSubmitBtn
              type={"submit"}
              BtnText={"Save & Update"}
              className={"px-3 py-3 text-sm"}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default TalentManageSocialProfileForm;
