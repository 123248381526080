import React, { useContext, useEffect, useRef, useState } from "react";
import { BsCurrencyRupee, BsDownload, BsThreeDots } from "react-icons/bs";
import { ImLocation2 } from "react-icons/im";
import ModalBody from "../../Shared/ModalBody/ModalBody";
import { AiOutlineClose } from "react-icons/ai";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import {
  HandelCurrentClientMilestonesJobList,
  JobAllMilestoneGet,
} from "../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import { useNavigate } from "react-router-dom";
import { SingleUserProfileGet } from "../../../hooks/useUser";
import {
  serverImageUrl,
  serverUrl,
} from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { toast } from "react-toastify";

const Contract = () => {
  const { currentUser } = useContext(globalFunctionOneContext);
  // get single freelancer user profile
  const { data: currentUserProfile, isLoading: currentUserProfileLoading } =
    SingleUserProfileGet(currentUser?.PersonID);
  const [handelAddMilestoneModal, setHandelAddMilestoneModal] = useState(false);
  const [handelCreateMilestoneBtn, setHandelCreateMilestoneBtn] =
    useState(true);
  const [handelModalInputFieldGroup, setHandelModalInputFieldGroup] =
    useState(false);
  const [subMenuOpenAndClose, setSubMenuOpenAndClose] = useState(false);
  const [subMenuOpenId, setSubMenuOpenId] = useState(0);
  const [clickCurrentJobsDetails, setClickCurrentJobsDetails] = useState(null);
  const [customMilestoneAmountAddModal, setCustomMilestoneAmountAddModal] =
    useState(false);
  const [handelMilestoneFromError, setHandelMilestoneFromError] = useState(0);
  const [totalRemainingAmount, setTotalRemainingAmount] = useState(0);
  const [handelAmountInputFocus, setHandelAmountInputFocus] = useState(false);
  const [handelDataOrder, setHandelDataOrder] = useState("DESC");
  const navigate = useNavigate();
  const { data, refetch, isLoading } = HandelCurrentClientMilestonesJobList(
    currentUser?.PersonID,
    handelDataOrder
  );

  const handelCreateMilestone = (job) => {
    // console.log(job, "jobs");
  };
  // This is all milestone get function
  const {
    data: allMIlestoneLIst,
    refetch: allMIlestoneLIstRefetch,
    isLoading: allMIlestoneLIstLoading,
  } = JobAllMilestoneGet(clickCurrentJobsDetails?.job_id);

  // this is create milestone data post server function
  const handelCreateMilestoneForm = async (e) => {
    e.preventDefault();
    setHandelCreateMilestoneBtn(true);
    const from = e.target;
    const defaultMilestoneDescription =
      from?.defaultMilestoneDescription?.value;
    const defaultDueDateUTC = from?.defaultDueDateUTC?.value;
    const defaultDepositAmount = from?.defaultDepositAmount?.value;
    const milestoneDescription = from?.milestoneDescription?.value;
    const dueDateUTC = from?.dueDateUTC?.value;
    const depositAmount = from?.depositAmount?.value;
    const milestoneUserData = {
      jobId: clickCurrentJobsDetails?.job_id,
      milestoneDescription: defaultMilestoneDescription || milestoneDescription,
      dateLine: defaultDueDateUTC || dueDateUTC,
      depositAmount: defaultDepositAmount || depositAmount,
      milestonePaymentState: "No",
      milestoneState: "noActive",
    };
    console.log(milestoneUserData, "milestoneUserData");
    if (
      milestoneUserData?.milestoneDescription &&
      milestoneUserData?.dateLine &&
      milestoneUserData?.depositAmount &&
      milestoneUserData?.jobId &&
      totalRemainingAmount >= Number(milestoneUserData?.depositAmount)
    ) {
      const req = await fetch(`${serverUrl}/milestone/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(milestoneUserData),
      });
      const data = await req.json();
      if (data?.status === "success") {
        allMIlestoneLIstRefetch();
        setHandelCreateMilestoneBtn(false);
        setHandelModalInputFieldGroup(false);
        from.reset();
        toast.success("Milestone create successful !!!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setHandelCreateMilestoneBtn(true);
        toast.error("Milestone create failed !!!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  // handel user create milestone dedLine
  const handelUserCreateMilestoneDeadline = (e) => {
    const enteredDate = new Date(e.target.value);
    const currentDate = new Date();
    if (enteredDate < currentDate) {
      e.target.value = "";
      setHandelMilestoneFromError("dueDateUTC");
    } else {
      setHandelMilestoneFromError("");
    }
  };

  // handel user create milestone amount
  const handelUserMilestoneAmount = (e) => {
    setHandelAmountInputFocus(false);
    const enteredValue = Number(e.target.value);
    if (Number(clickCurrentJobsDetails?.minimum_price) >= enteredValue) {
      setHandelMilestoneFromError("");
    } else {
      e.target.value = ""; // Clear the input value using state
      setHandelMilestoneFromError("depositAmount");
    }
    if (totalRemainingAmount >= enteredValue) {
      setHandelMilestoneFromError("");
    } else {
      e.target.value = ""; // Clear the input value using state
      setHandelMilestoneFromError("depositAmount");
    }
  };

  const handelOrderByDataFromDB = (e) => {
    const value = e.target.value;
    if (value === "Ascending") {
      setHandelDataOrder("ASC");
    } else if (value === "Descending") {
      setHandelDataOrder("DESC");
    }
  };

  useEffect(() => {
    console.log(handelDataOrder);
  }, [handelDataOrder]);

  // useEffect(() => {
  //   if (allMIlestoneLIst?.result) {
  //     for (const data of allMIlestoneLIst.result) {
  //       const projectAmount = Number(data?.minimum_price);
  //       const milestoneAmount = Number(data?.depositAmount);
  //       if (!isNaN(projectAmount) && !isNaN(milestoneAmount)) {
  //         const allMilestoneAmountSum = totalRemainingAmount + milestoneAmount;

  //         setTotalRemainingAmount(allMilestoneAmountSum);
  //         console.log({
  //           projectAmount,
  //           totalRemainingAmount,
  //           milestoneAmount,
  //           allMilestoneAmountSum,
  //         });

  //         const newTotal = projectAmount - totalRemainingAmount;

  //         console.log(newTotal, "new remaining value");
  //         setTotalRemainingAmount(newTotal);
  //       } else {
  //         if (allMIlestoneLIst?.resultStatus === "No") {
  //           setTotalRemainingAmount(projectAmount);
  //         }
  //       }
  //     }
  //   }
  // }, [allMIlestoneLIst, customMilestoneAmountAddModal]);

  useEffect(() => {
    console.log(allMIlestoneLIst, "this is current job all milestone");
  }, [allMIlestoneLIst]);

  useEffect(() => {
    let sumTotalMilestoneAmount = 0;
    if (allMIlestoneLIst?.result) {
      for (const data of allMIlestoneLIst.result) {
        const projectAmount = Number(data?.minimum_price);
        const milestoneAmount = Number(data?.depositAmount);
        if (!isNaN(projectAmount) && !isNaN(milestoneAmount)) {
          sumTotalMilestoneAmount += milestoneAmount;

          const newTotal = projectAmount - sumTotalMilestoneAmount; // Use the original value here

          console.log(
            newTotal,
            totalRemainingAmount,
            milestoneAmount,
            "new remaining value"
          );
          setTotalRemainingAmount(newTotal);
        } else {
          if (allMIlestoneLIst?.resultStatus === "No") {
            setTotalRemainingAmount(projectAmount);
          }
        }
      }
    }
  }, [allMIlestoneLIst, clickCurrentJobsDetails, allMIlestoneLIstLoading]);

  // milestone default and custom amount change function
  const handleRadioChange = (event) => {
    console.log(event.target.value);
    if (event.target.value === "customAmount") {
      setCustomMilestoneAmountAddModal(true);
    } else {
      setCustomMilestoneAmountAddModal(false);
    }
  };

  // console.log(allMIlestoneLIst, "allMIlestoneLIst");

  if (!currentUser || currentUserProfileLoading || isLoading) {
    return <div>loading .......</div>;
  }
  return (
    <div className="w-[1300px] mx-auto mt-20">
      <form className="px-5">
        <div className="flex justify-between items-center">
          <div className="flex justify-center items-center relative w-full">
            <input
              type="text"
              className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc] pr-14 rounded-md"
            />
            <span
              className={`bg-primary text-white absolute top-0 right-0 w-12 h-full text-lg rounded-r-lg flex justify-center items-center border-r-2 cursor-pointer`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </span>
          </div>
          <button
            className="btn btn-primary btn-outline btn-sm ml-3"
            type="button"
            onClick={() => setHandelAddMilestoneModal(true)}
          >
            Search Milestone
          </button>
        </div>

        <div className="flex justify-between items-center mt-5">
          <div className="flex justify-start items-center">
            <h6 className="text-lg font-medium">Short By</h6>
            <select
              onChange={handelOrderByDataFromDB}
              className="select border-gray-300 max-w-xs focus:outline-none focus:border-primary mx-3"
            >
              <option disabled selected>
                Sorting
              </option>
              <option>Ascending</option>
              <option>Descending</option>
            </select>
            <select className="select border-gray-300 max-w-xs focus:outline-none focus:border-primary">
              <option disabled selected>
                Select Date
              </option>
              <option>Game of Thrones</option>
              <option>Lost</option>
              <option>Breaking Bad</option>
              <option>Walking Dead</option>
            </select>
          </div>

          <div className="flex justify-start items-center cursor-pointer">
            <p className="text-primary mr-3">
              <BsDownload />
            </p>
            <p className="text-primary">Download Csv</p>
          </div>
        </div>
      </form>
      <div className="card px-2 w-full bg-base-100 shadow-xl my-10">
        <div>
          <ul>
            {data?.result &&
              data?.result?.length > 0 &&
              data?.result.map((job, i) => (
                <li>
                  <div
                    onClick={(e) => {
                      // Check if the click target is not the button or div or h1 or p or svg or ul or li eit
                      if (
                        e.target.nodeName !== "BUTTON" &&
                        e.target.nodeName !== "svg" &&
                        e.target.nodeName !== "P" &&
                        e.target.nodeName !== "IMG" &&
                        e.target.nodeName !== "UL" &&
                        e.target.nodeName !== "LI" &&
                        e.target.nodeName !== "H1"
                      ) {
                        navigate(
                          `/dashboard/client/contract/details/${job?.job_id}`
                        );
                      }
                    }}
                    className="flex justify-between items-center px-3 py-2 rounded cursor-pointer hover:bg-gray-200"
                  >
                    <div className="flex justify-start items-end w-[30%]">
                      <div className="flex justify-start items-start gap-4">
                        <div className="avatar">
                          <div className="w-14 rounded-full">
                            <img
                              src={`${serverImageUrl}/${currentUserProfile?.result?.profilePhoto}`}
                              alt="My-Logo"
                            />
                          </div>
                        </div>
                        <div>
                          <h1 className="font-bold">{job?.job_title}</h1>
                          <p className="font-medium"></p>
                        </div>
                      </div>
                    </div>
                    <p className="ml-5 flex justify-start items-center">
                      <ImLocation2 /> <span>{job?.JobLocation}</span>
                    </p>
                    <div>
                      <p className="font-medium">{job?.minimum_price}</p>
                      <p className="font-medium">
                        adipisicing elit. Accusantium, alias quod!
                      </p>
                      <p className="font-medium">
                        adipisicing elit. Accusantium, alias quod!
                      </p>
                    </div>
                    <div className="relative">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          setHandelAddMilestoneModal(true);
                          handelCreateMilestone(job);
                          setClickCurrentJobsDetails(job);
                          allMIlestoneLIstRefetch();
                        }}
                      >
                        Create Milestone
                      </button>
                      <button
                        className="btn btn-circle btn-outline btn-primary btn-sm ml-5"
                        onClick={() => {
                          setSubMenuOpenAndClose(!subMenuOpenAndClose);
                          setSubMenuOpenId(i);
                        }}
                      >
                        <BsThreeDots className="text-lg" />
                      </button>

                      {subMenuOpenAndClose && subMenuOpenId === i && (
                        <div className="absolute top-12 right-0 border w-70 min-h-40 rounded bg-gray-50 z-[9]">
                          <ul className="submenu_top_left_arrow">
                            <li className="flex justify-between items-start">
                              <button
                                className="btn btn-outline capitalize btn-primary btn-xs rounded"
                                onClick={() => setSubMenuOpenAndClose(false)}
                              >
                                <AiOutlineClose className="text-black text-md" />
                              </button>
                              <h1 className="mr-2">Select</h1>
                            </li>

                            {/* <li
                              className="border-y border-gray-200 hover:bg-primary hover:border-primary cursor-pointer py-1 px-3 my-2 duration-300 ease-out"
                              onClick={() => setSubMenuOpenAndClose(false)}
                            >
                              Message
                            </li> */}

                            <li
                              className="border-y border-gray-200 hover:bg-primary hover:border-primary cursor-pointer py-1 px-3 my-2 duration-300 ease-out"
                              onClick={() => setSubMenuOpenAndClose(false)}
                            >
                              Proposal new milestones
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  <hr className="my-3 border-t border-gray-300" />
                </li>
              ))}
            {data?.result && data?.result?.length === 0 && (
              <div className="h-[430px] flex justify-center items-center text-2xl font-bold">
                <li>No data found</li>
              </div>
            )}
          </ul>
        </div>
      </div>

      <ModalBody
        onClose={() => setHandelAddMilestoneModal(false)}
        isVisible={handelAddMilestoneModal}
        customOpacityLayer="bg-opacity-0"
        customClass="card w-full mx-10 md:max-w-[900px] md:mx-auto bg-[#fff] p-5 rounded-md h-[580px] "
        modalCloseCustomButtonAdd={true}
      >
        <div className="overflow-y-auto h-[580px]">
          <div className="flex justify-end items-start">
            <button
              className="btn-primary btn btn-sm btn-circle place-self-end"
              onClick={() => setHandelAddMilestoneModal(false)}
            >
              ✕
            </button>
          </div>
          {!allMIlestoneLIst?.result && <div>modal loading ......</div>}
          {allMIlestoneLIst?.result && (
            <form onSubmit={handelCreateMilestoneForm}>
              <div className="my-5 p-5" key={data}>
                <p className="font-bold mb-4">
                  You're protected by
                  <span className="text-primary">
                    Hire Payment Protection.
                  </span>{" "}
                  Only pay for the work you authorize
                </p>
                <p className="font-bold">Payment Option</p>
                <p className="font-medium">Fixed price budget</p>
                <p className="label-text font-medium flex justify-start items-center">
                  <BsCurrencyRupee /> {clickCurrentJobsDetails?.minimum_price}
                </p>

                <p className="mb-4 text-sm font-medium text-gray-400">
                  Pay as project milestones are completed
                </p>
                <p className="font-bold">Remaining budget of your project</p>
                <p className="label-text font-medium flex justify-start items-center">
                  <BsCurrencyRupee /> {totalRemainingAmount}
                </p>
                <p className="mb-4 font-medium text-gray-400 text-sm">
                  This is the price you and Eimer have agreed upon
                </p>
                <p className="font-bold">Deposit funds onto Escrow</p>
                <p className="mb-3 font-medium text-gray-400 text-sm">
                  Escrow is a neutral holding place that protects your deposit
                  until work is approved
                </p>

                <div className="form-control">
                  <label className="label cursor-pointer justify-start py-1">
                    <input
                      type="radio"
                      name="radio-10"
                      className="radio radio-primary"
                      checked={!customMilestoneAmountAddModal}
                      value="default"
                      onClick={handleRadioChange}
                    />
                    <span className="label-text ml-2 font-medium flex justify-start items-center">
                      Deposit <BsCurrencyRupee />
                      {totalRemainingAmount} for the whole project
                    </span>
                  </label>
                </div>
                <div className="form-control">
                  <label className="label cursor-pointer justify-start py-1">
                    <input
                      type="radio"
                      name="radio-10"
                      value="customAmount"
                      className="radio radio-primary"
                      checked={customMilestoneAmountAddModal}
                      onClick={handleRadioChange}
                    />
                    <span className="label-text ml-2 font-medium">
                      Deposit a lesser amount to cover the first milestone
                    </span>
                  </label>
                </div>
                {!customMilestoneAmountAddModal && (
                  <div>
                    <hr className="border-t border-gray-400 mt-5" />
                    <div>
                      <h3 className="text-2xl font-medium my-2">
                        Project Milestones
                      </h3>
                      <p className="font-medium text-gray-400 text-sm">
                        Add project milestones and pay in installments as oath
                        milestone is completed to your satisfaction
                      </p>

                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 my-5">
                        <div>
                          <label
                            htmlFor="defaultMilestoneDescription"
                            className="font-bold mb-2 cursor-pointer"
                          >
                            Milestone Description
                          </label>
                          <input
                            id="defaultMilestoneDescription"
                            type="text"
                            name="defaultMilestoneDescription"
                            className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc] rounded-md"
                            placeholder="Enter your milestone description"
                            value={clickCurrentJobsDetails?.job_title}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="defaultDueDateUTC"
                            className="font-bold mb-2 cursor-pointer"
                          >
                            Due Date UTC
                          </label>
                          <input
                            id="defaultDueDateUTC"
                            type="date"
                            name="defaultDueDateUTC"
                            className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc] rounded-md"
                            defaultValue={
                              clickCurrentJobsDetails?.project_expire_date
                            }
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="defaultDepositAmount"
                            className="font-bold mb-2 cursor-pointer"
                          >
                            Deposit Amount
                          </label>
                          <input
                            id="defaultDepositAmount"
                            type="text"
                            name="defaultDepositAmount"
                            className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc] rounded-md"
                            placeholder="Enter your deposit amount"
                            // value={clickCurrentJobsDetails?.minimum_price}
                            value={totalRemainingAmount}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {customMilestoneAmountAddModal && (
                  <div>
                    <hr className="border-t border-gray-400 mt-5" />
                    <div>
                      <h3 className="text-2xl font-medium my-2">
                        Project Milestones
                      </h3>
                      <p className="font-medium text-gray-400 text-sm">
                        Add project milestones and pay in installments as oath
                        milestone is completed to your satisfaction
                      </p>
                      {allMIlestoneLIst?.result &&
                        allMIlestoneLIst?.resultStatus === "Yes" &&
                        allMIlestoneLIst?.result.map((data) => (
                          <div key={data?.id}>
                            <div>
                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 my-5">
                                <div>
                                  <label className="font-bold mb-2 cursor-pointer">
                                    Milestone Description
                                  </label>
                                  <input
                                    type="text"
                                    className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc] rounded-md"
                                    placeholder="Enter your milestone description"
                                    required
                                    disabled
                                    value={data?.milestoneDescription}
                                  />
                                </div>
                                <div>
                                  <label className="font-bold mb-2 cursor-pointer">
                                    Due Date UTC
                                  </label>
                                  <input
                                    type="date"
                                    className={`input input-bordered w-full focus:outline-none focus:border-[#fd03dc] rounded-md`}
                                    required
                                    value={data?.dateLine}
                                    disabled
                                  />
                                </div>

                                <div>
                                  <label className="font-bold mb-2 cursor-pointer">
                                    Deposit Amount
                                  </label>
                                  <label className="input-group input-group-md">
                                    <span
                                      className={`border-y border-r-0 border-l`}
                                    >
                                      <BsCurrencyRupee />
                                    </span>
                                    <input
                                      type="number"
                                      className={`input input-bordered numberInputArrowHidden  w-full focus:outline-none focus:border-l-[#FFFFFF]  focus:border-y-[#fd03dc] focus:border-r-[#fd03dc]  rounded-md`}
                                      placeholder="Enter your deposit amount"
                                      value={data?.depositAmount}
                                      disabled
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                      {allMIlestoneLIst?.result &&
                        allMIlestoneLIst?.resultStatus === "Yes" &&
                        handelModalInputFieldGroup && (
                          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 my-5">
                            <div>
                              <label
                                htmlFor="milestoneDescription"
                                className="font-bold mb-2 cursor-pointer"
                              >
                                Milestone Description
                              </label>
                              <input
                                id="milestoneDescription"
                                type="text"
                                name="milestoneDescription"
                                className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc] rounded-md"
                                placeholder="Enter your milestone description"
                                required
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="dueDateUTC"
                                className="font-bold mb-2 cursor-pointer"
                              >
                                Due Date UTC
                              </label>
                              <input
                                id="dueDateUTC"
                                type="date"
                                name="dueDateUTC"
                                className={`input input-bordered ${
                                  handelMilestoneFromError === "dueDateUTC" &&
                                  "border border-red-600"
                                } w-full focus:outline-none focus:border-[#fd03dc] rounded-md`}
                                required
                                onChange={(e) =>
                                  handelUserCreateMilestoneDeadline(e)
                                }
                              />
                              {handelMilestoneFromError === "dueDateUTC" && (
                                <p className="text-xs text-red-600">
                                  You are entering the wrong date.
                                </p>
                              )}
                            </div>

                            <div>
                              <label
                                htmlFor="depositAmount"
                                className="font-bold mb-2 cursor-pointer"
                              >
                                Deposit Amount {totalRemainingAmount}
                              </label>
                              <label className="input-group input-group-md">
                                <span
                                  className={`border-y border-r-0 border-l ${
                                    handelAmountInputFocus &&
                                    "border-y-[#fd03dc] border-l-[#fd03dc]"
                                  } ${
                                    handelMilestoneFromError ===
                                      "depositAmount" &&
                                    "border-y-red-600 border-l-red-600"
                                  }`}
                                >
                                  <BsCurrencyRupee />
                                </span>

                                <input
                                  id="depositAmount"
                                  type="number"
                                  name="depositAmount"
                                  className={`input input-bordered numberInputArrowHidden ${
                                    handelMilestoneFromError ===
                                      "depositAmount" &&
                                    "border-l-[#FFFFFF]  border-y-red-600 border-r-red-600"
                                  } w-full focus:outline-none focus:border-l-[#FFFFFF]  focus:border-y-[#fd03dc] focus:border-r-[#fd03dc]  rounded-md`}
                                  placeholder="Enter your deposit amount"
                                  required
                                  onFocus={() => {
                                    setHandelMilestoneFromError("");
                                    setHandelAmountInputFocus(true);
                                  }}
                                  onBlur={(e) => handelUserMilestoneAmount(e)}
                                />
                              </label>
                              {handelMilestoneFromError === "depositAmount" && (
                                <p className="text-xs text-red-600">
                                  You are paying more than your project amount.
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      {allMIlestoneLIst?.result &&
                        allMIlestoneLIst?.resultStatus === "No" &&
                        !handelModalInputFieldGroup && (
                          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 my-5">
                            <div>
                              <label
                                htmlFor="milestoneDescription"
                                className="font-bold mb-2 cursor-pointer"
                              >
                                Milestone Description
                              </label>
                              <input
                                id="milestoneDescription"
                                type="text"
                                name="milestoneDescription"
                                className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc] rounded-md"
                                placeholder="Enter your milestone description"
                                required
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="dueDateUTC"
                                className="font-bold mb-2 cursor-pointer"
                              >
                                Due Date UTC
                              </label>
                              <input
                                id="dueDateUTC"
                                type="date"
                                name="dueDateUTC"
                                className={`input input-bordered ${
                                  handelMilestoneFromError === "dueDateUTC" &&
                                  "border border-red-600"
                                } w-full focus:outline-none focus:border-[#fd03dc] rounded-md`}
                                required
                                onChange={(e) =>
                                  handelUserCreateMilestoneDeadline(e)
                                }
                              />
                              {handelMilestoneFromError === "dueDateUTC" && (
                                <p className="text-xs text-red-600">
                                  You are entering the wrong date.
                                </p>
                              )}
                            </div>

                            <div>
                              <label
                                htmlFor="depositAmount"
                                className="font-bold mb-2 cursor-pointer"
                              >
                                Deposit Amount
                              </label>
                              <label className="input-group input-group-md">
                                <span
                                  className={`border-y border-r-0 border-l ${
                                    handelAmountInputFocus &&
                                    "border-y-[#fd03dc] border-l-[#fd03dc]"
                                  } ${
                                    handelMilestoneFromError ===
                                      "depositAmount" &&
                                    "border-y-red-600 border-l-red-600"
                                  }`}
                                >
                                  <BsCurrencyRupee />
                                </span>
                                <input
                                  id="depositAmount"
                                  type="number"
                                  name="depositAmount"
                                  className={`input input-bordered numberInputArrowHidden ${
                                    handelMilestoneFromError ===
                                      "depositAmount" &&
                                    "border-l-[#FFFFFF]  border-y-red-600 border-r-red-600"
                                  } w-full focus:outline-none focus:border-l-[#FFFFFF]  focus:border-y-[#fd03dc] focus:border-r-[#fd03dc]  rounded-md`}
                                  placeholder="Enter your deposit amount"
                                  required
                                  onFocus={() => {
                                    setHandelMilestoneFromError("");
                                    setHandelAmountInputFocus(true);
                                  }}
                                  onBlur={(e) => {
                                    setHandelAmountInputFocus(false);
                                    if (
                                      Number(
                                        clickCurrentJobsDetails?.minimum_price
                                      ) >= e.target.value
                                    ) {
                                      setHandelMilestoneFromError("");
                                      console.log(e.target.value);
                                    } else {
                                      console.log(e.target.value, "else");
                                      e.target.value = "";
                                      setHandelMilestoneFromError(
                                        "depositAmount"
                                      );
                                    }
                                  }}
                                />
                              </label>
                              {handelMilestoneFromError === "depositAmount" && (
                                <p className="text-xs text-red-600">
                                  You are paying more than your project amount.
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                )}
                {customMilestoneAmountAddModal && (
                  <p
                    className={`font-medium text-sm text-primary cursor-pointer `}
                    onClick={() => {
                      if (allMIlestoneLIst?.resultStatus === "Yes") {
                        setHandelModalInputFieldGroup(true);
                        setHandelCreateMilestoneBtn(true);
                      }
                    }}
                  >
                    + Add Milestone
                  </p>
                )}
              </div>

              <hr className="border-t border-gray-400" />
              <div className="my-5 px-5 flex justify-end items-start">
                <div>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      setHandelAddMilestoneModal(false);
                      setHandelCreateMilestoneBtn(true);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm btn-outline ml-5"
                    disabled={!handelCreateMilestoneBtn}
                  >
                    Create Milestone
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </ModalBody>
    </div>
  );
};

export default Contract;
