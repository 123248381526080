import React from "react";
import Collapse from "./Collapse";
import SearchInput from "./SearchInput";

const LeftTalentManager = () => {
  // Window loading
  return (
    <div className="mt-5  col-span-1">
      <div>
        <div className="card mt-20 w-full bg-base-100 shadow-lg">
          <div className="card-body">
            <SearchInput />
            <Collapse />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftTalentManager;
