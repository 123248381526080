import React from "react";

const AddLocation = ({ register }) => {
  return (
    <div>
      <div className="form-control w-full">
        <input
          {...register("JobLocation", { required: true })}
          type="text"
          placeholder="Type your location"
          className="input input-bordered w-full input-primary"
        />
        {/*  <label className="label">
          <span className ="label-text-alt">Alt label</span>
        </label> */}
      </div>
    </div>
  );
};

export default AddLocation;
