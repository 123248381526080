import React, { useContext } from "react";
import { Link, Outlet } from "react-router-dom";
import { DashboardClientJobsNavbar } from "../../../../hooks/NavbarAllFunction/useNavbarAllFunction";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const InviteNavbar = ({
  clientInviteAllFreelancerDataCount,
  allSaveFreelancerDataCount,
}) => {
  const { userCurrentLocationPath } = useContext(globalFunctionOneContext);
  const { activeInvite } = DashboardClientJobsNavbar(userCurrentLocationPath);

  const menuList = [
    {
      id: 1,
      linkText: "Search",
      path: "/dashboard/client/jobs/inviteFreelancer",
    },
    {
      id: 2,
      linkText: `Invite Freelancer (${
        clientInviteAllFreelancerDataCount?.count
          ? clientInviteAllFreelancerDataCount?.count
          : 0
      })`,
      path: "/dashboard/client/jobs/inviteFreelancer/freelancerInviteList",
    },
    // {
    //   id: 3,
    //   linkText: "My Hires",
    //   path: "/dashboard/client/jobs/inviteFreelancer/freelancerHire",
    // },
    {
      id: 4,
      linkText: `Saved (${
        allSaveFreelancerDataCount?.count
          ? allSaveFreelancerDataCount?.count
          : 0
      })`,
      path: "/dashboard/client/jobs/inviteFreelancer/saved",
    },
  ];

  return (
    <div className="border-b-2 py-1">
      <ul className="flex gap-4 my-2 cursor-pointer mb-4">
        {menuList &&
          menuList.map((list) => (
            <li key={list?.id}>
              <Link
                to={list?.path}
                className={`${
                  activeInvite === list?.id
                    ? "font-bold border-b-2 border-black"
                    : ""
                }`}
              >
                {list?.linkText}
              </Link>
            </li>
          ))}
      </ul>

      <Outlet />
    </div>
  );
};

export default InviteNavbar;
