import { useQuery } from "react-query";
import { serverUrl } from "./AllServerUrlControl/useAllServerUrlControl";

export const useAllFreelancer = () => {
  const { isLoading, error, data } = useQuery({
    queryKey: ["freelancers"],
    queryFn: async () => {
      const res = await fetch(`${serverUrl}/userProfile?userType=Freelancer`, {
        headers: {
          "Content-Type": "application/json",
          // authorization: `bearer ${localStorage.getItem("access_Token")}`,
        },
      });
      const data = await res.json();
      return data;
    },
  });

  return { data, isLoading, error };
};
export const GuestModeUserData = (type, limit) => {
  const {
    isLoading,
    error,
    data = [],
  } = useQuery({
    queryKey: [type],

    queryFn: async () => {
      const res = await fetch(
        `${serverUrl}/userProfile/guest/mode/user?userType=${type}&limit=${limit}`,
        {
          headers: {
            "Content-Type": "application/json",

            // authorization: `bearer ${localStorage.getItem("access_Token")}`,
          },
        }
      );
      const data = await res.json();
      return data;
    },
  });

  return { data, isLoading, error };
};
