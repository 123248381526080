import React, { useState } from "react";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { auth } from "../../config/firebaseConfig/firebaseConfig";

const Forget = () => {
  const [email, setEmail] = useState("");
  const [sendPasswordResetEmail, sending, error] =
    useSendPasswordResetEmail(auth);
  //
  const forgetPasswordResetEmail = async () => {
    await sendPasswordResetEmail(email);
    if (error) {
      toast.error((error.message = "Invalid Email"));
    } else {
      toast.success(
        "Email send successfully please check your inbox or spam folder"
      );
    }
  };
  const navigate = useNavigate();
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="card max-w-lg mx-auto bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">FIND YOUR ACCOUNT</h2>
          <div className="divider mt-0"></div>
          <p>Please put your email address and click to send button</p>
          <div className="form-control w-full md:sm:max-w-full sm:sm:max-w-xs ">
            <label className="label">
              <span className="label-text">Email</span>
            </label>
            <input
              type="text"
              required
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="input input-bordered w-full md:sm:max-w-full sm:sm:max-w-xs"
            />
            {/* <label className="label">
              <span className="label-text-alt">Alt label</span>
            </label> */}
            {sending && <h1>Loading...</h1>}
          </div>
          <div className="card-actions justify-end">
            <button
              disabled={sending}
              className="btn btn-primary btn-outline"
              onClick={forgetPasswordResetEmail}
            >
              Reset password
            </button>
            <button
              disabled={sending}
              className="btn btn-primary btn-outline"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forget;
