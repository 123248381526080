import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { JobAllMilestoneGet } from "../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";

const ContractDetailsIndex = () => {
  const {
    currentUser,
    userCurrentLocationPathParameter,
    setUserCurrentLocationPathParameter,
    userCurrentLocationPath,
  } = useContext(globalFunctionOneContext);
  const [myJobMenuActiveTracker, setMyJobMenuActiveTracker] = useState(1);
  const [handelAddMilestoneModal, setHandelAddMilestoneModal] = useState(false);
  const parameter = useParams();
  const {
    data: allMIlestoneLIst,
    refetch: allMIlestoneLIstRefetch,
    isLoading: allMIlestoneLIstLoading,
  } = JobAllMilestoneGet(parameter?.id);

  // This is freelancer My jobs route menu list
  const menuItems = [
    {
      id: 1,
      url: `/dashboard/client/contract/details/${parameter?.id}`,
      text: "Milestone & payment",
    },
    {
      id: 2,
      url: `/dashboard/client/contract/details/${parameter?.id}/feedback`,
      text: "Feedback",
    },
    {
      id: 3,
      url: `/dashboard/client/contract/details/${parameter?.id}/manage/freelancer`,
      text: "Manage Freelancer",
    },
  ];

  // this is handel usParameter
  useEffect(() => {
    setUserCurrentLocationPathParameter(parameter);
    switch (userCurrentLocationPath) {
      case `/dashboard/client/contract/details/${parameter?.id}/manage/freelancer`:
        setMyJobMenuActiveTracker(3);
        break;

      default:
        setMyJobMenuActiveTracker(1);
        break;
    }
  }, [parameter, setUserCurrentLocationPathParameter, userCurrentLocationPath]);

  if (allMIlestoneLIstLoading || !currentUser) {
    return <div>contract details Loading ......</div>;
  }

  return (
    <div className="flex justify-center items-center">
      <div className="w-[1300px] my-5">
        <div className="card px-2 w-full bg-base-100 shadow-xl">
          <div className="card-body">
            <div className="flex justify-end items-center">
              {/* <div className="flex justify-start items-start gap-4">
                  <div className="avatar">
                    <div className="w-14 rounded-full">
                      <img
                        src="https://i.ibb.co/MBkf6JK/My-Logo.jpg"
                        alt="My-Logo"
                      />
                    </div>
                  </div>
                  <div>
                    <h1 className="font-bold">Sk Mamun Khan</h1>
                    <p>4.43 pm wed</p>
                  </div>
                </div> */}
              <div className="flex justify-end items-center gap-2">
                <button className="btn btn-primary rounded-full btn-sm">
                  PlayNow
                </button>
                <button
                  className="btn btn-outline btn-primary btn-sm rounded-full"
                  onClick={() => setHandelAddMilestoneModal(true)}
                >
                  Give Bonus
                </button>
                <button className="btn btn-outline btn-primary btn-sm rounded-full">
                  Cancel Contract
                </button>
              </div>
            </div>
            <div className="mt-5">
              <ul className="flex justify-start items-center">
                {menuItems &&
                  menuItems.map((li) => (
                    <li className="hover:bg-[#eef1fe]" key={li?.id}>
                      <Link
                        to={li?.url}
                        className={`flex flex-col px-2 mx-3 pb-3 cursor-pointer ${
                          myJobMenuActiveTracker === li?.id
                            ? "front-bold my_job_menu_list"
                            : ""
                        }`}
                        onClick={() => setMyJobMenuActiveTracker(li?.id)}
                      >
                        <p className={`${li?.hidden ? "opacity-0" : ""}`}>
                          {li?.subText}
                        </p>
                        <p>{li?.text}</p>
                      </Link>
                    </li>
                  ))}
              </ul>
              <hr className="mb-5 border-t-2 border-gray-300" />
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractDetailsIndex;
