import React from "react";
import { GoSettings } from "react-icons/go";

const SearchHeader = ({ setSearchInput }) => {
  return (
    <div>
      {" "}
      <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-7">
        <div className="md:col-span-6 form-control mr-2">
          <div className="input-group">
            <input
              type="text"
              onChange={(e) => setSearchInput(e.target.value)}
              name="search_title"
              placeholder="Search…"
              className="input input-sm w-full input-bordered"
            />
            <button className="btn btn-square btn-sm btn-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div>
          <button className="btn w-full btn-sm flex gap-2 btn-outline btn-circle text-md btn-primary">
            <GoSettings className="text-xl" />
            filter
          </button>
        </div>
      </div>
      <h2 className="text-primary font-bld text-md">Advanced Search</h2>
      <h2 className="text-primary font-bld text-md text-right">
        30 invites left
      </h2>
    </div>
  );
};

export default SearchHeader;
