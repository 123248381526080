import React, { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { BiArrowBack } from "react-icons/bi";
import { MdOutlineDone } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CompleteProfile from "./CompleteProfile";
import { auth } from "../../../../config/firebaseConfig/firebaseConfig";
import { serverUrl } from "../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const Complete = () => {
  const {
    firebaseUser,
    databaseSingleUserRefetch,
    serverDataPutParamsAllData,
    setConfirmAlertValue,
  } = useContext(globalFunctionOneContext);
  const [uploadStatus, setUploadStatus] = useState("");
  // const [user] = useAuthState(auth);
  // const PersonID = user?.uid;
  // console.log(firebaseUser?.uid);
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    // console.log(data);
    const formData = new FormData();
    formData.append("image", data.image[0]);
    formData.append("hear_aboutUs", data.hear_aboutUs);
    formData.append("hidden_form", data.hidden_form);
    formData.append("job_search", data.job_search);
    formData.append("ready_to_interview", data.ready_to_interview);

    console.log(formData, data.image[0]);

    fetch(`${serverUrl}/userProfile/freelancer-user-profile-compleat`, {
      method: "POST",
      body: formData,
      headers: { Accept: "multipart/form-data", uid: firebaseUser?.uid },
      Credential: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === "success") {
          console.log(serverDataPutParamsAllData);
          fetch(
            `${serverUrl}/userProfile/single-user-update/${firebaseUser?.uid}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                // authorization: `bearer ${localStorage.getItem("access_Token")}`,
              },
            }
          )
            .then((res) => res.json())
            .then((data) => {
              console.log(data);

              if (data?.status === "success") {
                databaseSingleUserRefetch();
                setConfirmAlertValue(true);
                navigate("/dashboard/complete_profile");
              }
            });
        }

        console.log(data);
      });
  };

  // console.log(serverDataPutParamsAllData);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CompleteProfile register={register} />
        <div className="flex justify-end gap-4 my-4">
          <button
            onClick={() => navigate(-1)}
            className="btn btn-sm flex gap-2 items-center btn-primary"
          >
            <BiArrowBack />
            <span>Back</span>
          </button>
          <button
            type="submit"
            className="btn btn-sm flex gap-2 items-center btn-primary"
          >
            <MdOutlineDone />
            <span>save</span>
          </button>
        </div>
      </form>
      {uploadStatus && <h1>{uploadStatus}</h1>}
    </div>
  );
};

export default Complete;
