import React from "react";
import { CiSearch } from "react-icons/ci";

const TimeSheetDashboard = () => {
  const daysOfWeek = [
    {
      name: "Search..",
      searchIcon: <CiSearch />,
    },
    {
      name: "M",
      date: "2",
    },
    {
      name: "T",
      date: "3",
    },
    {
      name: "W",
      date: "4",
    },
    {
      name: "T",
      date: "5",
    },
    {
      name: "F",
      date: "6",
    },
    {
      name: "S",
      date: "7",
    },
    {
      name: "S",
      date: "8",
    },
    {
      name: "Total",
    },
  ];

  const workTrackData = [
    {
      profilePic:
        "https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg",
      name: "Sk Mamun Khan",
      Monday: "8h 6m",
      Tuesday: "8h 6m",
      Wednesday: "8h 6m",
      Thursday: "8h 6m",
      Friday: "8h 6m",
      Saturday: "",
      Sunday: "",
    },
    {
      profilePic:
        "https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg",
      name: "Neetu",
      Monday: "8h 6m",
      Tuesday: "8h 6m",
      Wednesday: "8h 6m",
      Thursday: "8h 6m",
      Friday: "8h 6m",
      Saturday: "8h 6m",
      Sunday: "",
    },
    {
      profilePic:
        "https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg",
      name: "Md: Zishan Khan",
      Monday: "8h 6m",
      Tuesday: "8h 6m",
      Wednesday: "8h 6m",
      Thursday: "8h 6m",
      Friday: "8h 6m",
      Saturday: "",
      Sunday: "8h 6m",
    },
    {
      profilePic:
        "https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg",
      name: "Sk Mamun Khan",
      Monday: "8h 6m",
      Tuesday: "8h 6m",
      Wednesday: "8h 6m",
      Thursday: "8h 6m",
      Friday: "8h 6m",
      Saturday: "8h 6m",
      Sunday: "",
    },
    {
      profilePic:
        "https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg",
      name: "Neetu",
      Monday: "8h 6m",
      Tuesday: "8h 6m",
      Wednesday: "8h 6m",
      Thursday: "8h 6m",
      Friday: "8h 6m",
      Saturday: "",
      Sunday: "",
    },
    {
      profilePic:
        "https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg",
      name: "Md: Zishan Khan",
      Monday: "8h 6m",
      Tuesday: "8h 6m",
      Wednesday: "8h 6m",
      Thursday: "8h 6m",
      Friday: "8h 6m",
      Saturday: "",
      Sunday: "8h 6m",
    },
  ];

  return (
    <div className="flex flex-col">
      <div>
        <div className="px-10">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                {daysOfWeek &&
                  daysOfWeek.map((data) => (
                    <th
                      className={`px-6 py-3 ${
                        data?.searchIcon
                          ? "flex justify-start items-center gap-5 py-6"
                          : ""
                      }`}
                    >
                      {data?.searchIcon && <span>{data?.searchIcon}</span>}
                      <h1>{data?.name}</h1>
                      <h1>{data?.date}</h1>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {workTrackData &&
                workTrackData.map((data) => (
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap flex justify-start items-center gap-5">
                      <div className="avatar">
                        <div className="w-12 rounded-full">
                          <img
                            src={data?.profilePic}
                            alt="this is person Profile Pic"
                          />
                        </div>
                      </div>
                      <h1 className="font-bold text-md">{data?.name}</h1>
                    </td>
                    <td className="py-4 text-center whitespace-nowrap">
                      {data?.Monday}
                    </td>
                    <td className="py-4 text-center whitespace-nowrap">
                      {data?.Tuesday}
                    </td>
                    <td className="py-4 text-center whitespace-nowrap">
                      {data?.Wednesday}
                    </td>
                    <td className="py-4 text-center whitespace-nowrap">
                      {data?.Thursday}
                    </td>
                    <td className="py-4 text-center whitespace-nowrap">
                      {data?.Friday}
                    </td>
                    <td className="py-4 text-center whitespace-nowrap">
                      <button
                        type="button"
                        className="btn btn-xs bg-gray-400 px-6 rounded-md"
                      >
                        Offline
                      </button>
                      <h1>{data?.Saturday}</h1>
                    </td>
                    <td className="py-4 text-center whitespace-nowrap">
                      <button
                        type="button"
                        className="btn btn-xs bg-gray-400 px-6 rounded-md"
                      >
                        Offline
                      </button>
                      <h1>{data?.Sunday}</h1>
                    </td>
                    <td className="py-4 text-center whitespace-nowrap">
                      8hour
                    </td>
                  </tr>
                ))}
              <tr>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="avatar">
                    <div className="w-12 rounded-full">
                      <img
                        src="https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg"
                        alt="this is person Profile Pic"
                      />
                    </div>
                  </div>
                </td>
                <td className="py-4 text-center whitespace-nowrap">
                  Project A
                </td>
                <td className="py-4 text-center whitespace-nowrap">8</td>
                <td className="py-4 text-center whitespace-nowrap">8</td>
                <td className="py-4 text-center whitespace-nowrap">
                  2022-01-01
                </td>
                <td className="py-4 text-center whitespace-nowrap">
                  Project A
                </td>
                <td className="py-4 text-center whitespace-nowrap">
                  <button
                    type="button"
                    className="btn btn-xs bg-gray-400 px-10 py-1 rounded-md"
                  >
                    Offline
                  </button>
                </td>
                <td className="py-4 text-center whitespace-nowrap">
                  <button
                    type="button"
                    className="btn btn-xs bg-gray-400 px-10 py-1 rounded-md"
                  >
                    Offline
                  </button>
                </td>
                <td className="py-4 text-center whitespace-nowrap">8hour</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* <h1 className="text-2xl font-bold mb-4">Demo TimeSheet</h1>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Date
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Project
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Hours
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          <tr>
            <td className="px-6 py-4 whitespace-nowrap">2022-01-01</td>
            <td className="px-6 py-4 whitespace-nowrap">Project A</td>
            <td className="px-6 py-4 whitespace-nowrap">8</td>
          </tr>
          <tr>
            <td className="px-6 py-4 whitespace-nowrap">2022-01-02</td>
            <td className="px-6 py-4 whitespace-nowrap">Project B</td>
            <td className="px-6 py-4 whitespace-nowrap">6</td>
          </tr>
          <tr>
            <td className="px-6 py-4 whitespace-nowrap">2022-01-03</td>
            <td className="px-6 py-4 whitespace-nowrap">Project C</td>
            <td className="px-6 py-4 whitespace-nowrap">7</td>
          </tr>
        </tbody>
      </table> */}
    </div>
  );
};

export default TimeSheetDashboard;
