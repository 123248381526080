import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import PaymentAccountInfo from "./PaymentAccountInfo";
import { PhoneNumberUtil } from "google-libphonenumber";
import BankInfo from "./BankInfo";

const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneValid = (phone) => {
  try {
    return PhoneNumberUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};
const Step2 = ({
  formData,
  setFormData,
  setStep,
  searchIfsc,
  setSearchIfsc,
}) => {
  const [searchIfscError, setSearchIfscError] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  const [phoneError, setPhoneError] = useState("");
  const [previewFormSubmitted, setPreviewFormSubmitted] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const handleCountryChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    // console.log(selectedOption);
  };
  const handleSearchIfscCode = (e) => {
    e.preventDefault();
    setSearchLoading(true);
    const search = e.target.ifsc.value;

    fetch(`https://ifsc.razorpay.com/${search}`)
      .then((res) => res.json())
      .then((data) => {
        setSearchLoading(false);
        if (Object.keys(data).length > 0 && data !== "Not Found") {
          setSearchIfsc(data);
          // setFormData({ ...formData, ifsc: search, bankName: data?.Bank });
          setSearchIfscError("");
        } else {
          setSearchIfscError("Invalid IFSC code");
          setSearchIfsc({});
        }
      })
      .catch((err) => {
        console.log(err.message);
        setSearchLoading(false);
        setSearchIfscError("Invalid IFSC code");
        setSearchIfsc({});
      });
  };
  const handleBillingInfoSubmit = (e) => {
    e.preventDefault();

    const accountNumber = e.target.accountNumber.value;
    const bankAccountType = e.target.bankAccountType.value;
    const panNumber = e.target.panNumber.value;
    const firstName = e.target.firstName.value;
    const address = e.target.address.value;
    const country = selectedOption.label;
    const phoneNumber = e.target.phone.value;
    const accountName = e.target.accountName.value;
    const lastName = e.target.lastName.value;

    const data = {
      accountName,
      accountNumber,
      bankAccountType,
      panNumber,
      firstName,
      lastName,
      country,
      address,
      phoneNumber,
    };
    console.log(data);
    setFormData(data);
    setStep(3);
    // Check if the input is valid, and if not, prevent submission and show the error.
    /* const isValidPhone = isPhoneValid(phone);
    if (!isValidPhone) {
      return setPhoneError("Phone is not valid");
    } else {
      // Input is valid, you can proceed with form submission logic here.
      setPreviewFormSubmitted(true);
      // Reset the error message
      setPhoneError("");
      const data = {
        accountNumber,
        accountType,
        panNumber,
        firstName,
        address,
        country,
        phone,
      };
      console.log(data);
    } */
  };

  return (
    <div>
      <div>
        <div>
          <h2 className="card-title">
            Set Up withdrawal: Direct to local bank{" "}
          </h2>
          <p>
            send founds directly to your bank{" "}
            <span className="link text-accent">
              Learn how this payment methods Works
            </span>{" "}
          </p>
          <div>
            {" "}
            <div className="card w-full my-10 bg-base-100 shadow-xl border border-gray-100">
              <div className="card-body ">
                <h2 className="card-title">Bank information</h2>
                <form onSubmit={handleSearchIfscCode}>
                  <div className="flex gap-8 flex-wrap items-end ">
                    <div className="form-control w-full max-w-xs">
                      <label htmlFor="ifsc" className="label">
                        <span className="label-text">IFSC code</span>
                      </label>
                      <input
                        id="ifsc"
                        type="text"
                        name="ifsc"
                        placeholder="Type here"
                        className="input input-sm input-bordered w-full max-w-xs"
                      />
                    </div>

                    <button className="btn btn-outline btn-sm btn-primary">
                      search
                    </button>
                  </div>
                </form>
                {searchLoading && (
                  <div>
                    <h2>Loading...</h2>
                  </div>
                )}
                {searchIfscError && (
                  <div>
                    <h2>{searchIfscError}</h2>
                  </div>
                )}

                {Object.keys(searchIfsc).length > 0 && (
                  <div className="mt-7">
                    {" "}
                    {/* bank information */}
                    <BankInfo extraElement={false} searchIfsc={searchIfsc} />
                    {/* Account Information Form */}
                    <div className="mt-7 w-full lg:w-8/12">
                      <h2 className="card-title mb-7">
                        Account holder bank information
                      </h2>

                      <form onSubmit={handleBillingInfoSubmit}>
                        <PaymentAccountInfo
                          selectedOption={selectedOption}
                          handleCountryChange={handleCountryChange}
                          phoneError={phoneError}
                          previewFormSubmitted={previewFormSubmitted}
                          setPhoneError={setPhoneError}
                        />
                        <div className="flex gap-8 flex-wrap mt-8">
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              setSearchIfsc({});
                              setStep(1);
                            }}>
                            back
                          </button>
                          <button
                            // onClick={() => }
                            type="submit"
                            className="btn btn-sm btn-primary">
                            next
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
