import {
  useAuthState,
  useSendEmailVerification,
  useUpdateEmail,
} from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../../../config/firebaseConfig/firebaseConfig";

const EmailVerification = () => {
  const [sendEmailVerification, sending, error] =
    useSendEmailVerification(auth);
  const [user] = useAuthState(auth);
  console.log(user);
  const navigate = useNavigate();
  const [updateEmail, updating, eError] = useUpdateEmail(auth);

  if (!sending || !error) {
    navigate("/");
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    console.log(email);
    await updateEmail(email);
    await sendEmailVerification(email);
  };
  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <input type="email" name="email" className="input input-primary" />
        {updating && <p>Sending...</p>}
        {eError && <p>{error?.message}</p>}
        <button>Verify email</button>
      </form>
    </div>
  );
};

export default EmailVerification;
