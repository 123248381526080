import React from "react";

const Cookies = ({ c, i }) => {
  return (
    <ol>
      <li>
        <h2>
          {" "}
          <span className="font-semibold pr-3">{i + 1}</span>
          <span>{c?.title}</span>
        </h2>
        <p className="mt-3 px-4">{c?.content}</p>
        {c?.tableContent && (
          <div key={i + 8 * 5} className="bg-white shadow-md rounded my-6">
            <div className="overflow-x-auto">
              <table className="min-w-max w-full table-auto">
                <thead>
                  <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th className="py-3 px-6 text-center">Category</th>
                    <th className="py-3 px-6 text-center">Description</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                  {c.tableContent.map((t, i) => (
                    <tr
                      className="border-b border-gray-200 hover:bg-gray-100"
                      key={i}>
                      <td className="py-3 px-6 text-left border-r border-gray-400 whitespace-pre-line">
                        {t.category}
                      </td>
                      <td className="py-3 px-6 text-left overflow-x-auto whitespace-pre-line md:max-w-[300px] max-w-none">
                        {t.description}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {c?.link &&
          c?.link.map((l, i) => (
            <div
              key={i + 9 * 1 + 4}
              className=" my-2 px-5 flex gap-2 items-center">
              <h2 className="font-semibold text-base">{l.title}</h2>
              <a
                href={l.href}
                className="link text-blue-500 hover:text-blue-800"
                target="_blank"
                rel="noreferrer">
                {l.href}
              </a>
            </div>
          ))}
      </li>
    </ol>
  );
};

export default Cookies;
