import React from "react";

const Prices = ({ register, setAmount }) => {
  return (
    <div className="grid grid-cols-2 justify-between gap-4">
      <div>
        <input
          {...register("fixed_project", { required: true })}
          type="text"
          value="Fixed Project"
          className="input input-bordered input-primary w-full"
        />
      </div>
      {/* currently we are working only on fixed project */}
      {/* <div>
        <select
          {...register("fixed_project", { required: true })}
          className="select select-primary select-bordered select-md w-full"
        >
          <option value="" selected>
          <option value="Fixed Project" selected>
            Select Project
          </option>
          <option value="Fixed project">Fixed project</option>
          <option value="Hourly based project">Hourly based project</option>
        </select>
      </div>
      </div> */}
      <div>
        <input
          {...register("minimum_price", { required: true })}
          onChange={(e) => setAmount(e.target.value)}
          type="text"
          placeholder="Work price ($)"
          className="input input-bordered input-primary w-full"
        />
      </div>
      {/* <div>
        <input
          {...register("maximum_price", { required: true })}
          type="text"
          placeholder="maximum price ($)"
          className="input input-bordered input-primary w-full"
        />
      </div> */}
    </div>
  );
};

export default Prices;
