import React from "react";

const Interview = () => {
  return (
    <div>
      <h2>Interview</h2>
    </div>
  );
};

export default Interview;
