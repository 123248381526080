import React from "react";
import { FaSlackHash } from "react-icons/fa";
import { BsLinkedin, BsInstagram, BsTwitter, BsFacebook } from "react-icons/bs";
import { Link } from "react-router-dom";

import { serverUrl } from "../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { Toaster, toast } from "react-hot-toast";
const Footer = () => {
  const onSubmit = (e) => {
    e.preventDefault();
    const email = e.target.subscribeEmail.value;
    console.log(email);
    fetch(`${serverUrl}/subscribe/create_subscriber`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ email }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.status === "success") {
          toast.success("Congratulation Successfully subscribed");
        }
      })
      .catch((error) => console.error("Error:", error));

    e.target.reset();
  };
  return (
    <>
      <div className="footer_bg  pt-96 md:pt-52">
        {/* 1st section */}
        <footer className="container items-start mx-auto px-4 mt-16 grid md:grid-cols-3   place-items-start md:place-items-center gap-3 sm:grid-cols-1 text-white pb-14">
          <div>
            <span className="footer-title">
              <FaSlackHash className="text-7xl" />
            </span>
            <p className="text-justify pr-4">
              Hire-elite-professional.com is a job search engine. We are not an
              agent or representative of any Employer. Registered trademarks are
              the property of their respective owners who do not sponsor or
              endorse this website.
            </p>
            <div className="flex gap-3 my-4 text-xl">
              <a
                href="https://web.facebook.com/profile.php?id=100080941016386&_rdc=1&_rdr"
                target="_blank"
                rel="noreferrer">
                <BsFacebook />
              </a>
              <Link to="/">
                <BsTwitter />
              </Link>
              <a
                href="https://www.instagram.com/eliteprofessionals2"
                target="_blank"
                rel="noreferrer">
                <BsInstagram />
              </a>
              <a
                href="https://www.linkedin.com/in/elite-professionals-7795b0236"
                target="_blank"
                rel="noreferrer">
                <BsLinkedin />
              </a>
            </div>
          </div>
          <div className="self-start">
            <span className="footer-title"> Search By Location</span>
            <div className="flex flex-col gap-3">
              <p>
                {/*    */}
                <Link to="/" className="link transition">
                  {" "}
                  Jobs In Australia{" "}
                </Link>{" "}
              </p>
              <p>
                {" "}
                <a className="link transition"> Jobs In Canada</a>
              </p>
              <p>
                <a className="link transition"> Jobs In England </a>{" "}
              </p>
              <p>
                <a className="link transition"> Jobs In India </a>{" "}
              </p>
              <p>
                <a className="link transition"> Jobs In Turkey </a>{" "}
              </p>
              <p>
                <a className="link transition"> +view all </a>{" "}
              </p>
              <p>
                <a className="link transition"> </a>{" "}
              </p>
            </div>
          </div>
          <div className="self-start">
            <span className="footer-title">Recent Posted Jobs</span>
            <div className=" flex flex-col gap-3">
              <div className=" flex gap-3 items-center text-[14px] w-full">
                <figure className="">
                  <img
                    src="https://work.elite-professionals.in/wp-content/uploads/2022/05/How-to-Start-Hiring-1-50x50.png"
                    alt="Avatar"
                  />
                </figure>
                <div className="">
                  <div className="">
                    <a href="https://work.elite-professionals.in/employer/juan-butler/">
                      {" "}
                      Juan Butler{" "}
                    </a>
                  </div>
                  <h4 className="font-semibold">
                    <a href="https://work.elite-professionals.in/project/forest-pathology-professor/">
                      Forest Pathology Professor
                    </a>
                  </h4>
                  <span>Canada</span>
                </div>
              </div>
              <div className=" flex gap-3 items-center text-[14px] w-full">
                <figure className="">
                  <img
                    src="https://work.elite-professionals.in/wp-content/uploads/2022/05/Limitless-Experience-01-01-50x50.jpg"
                    alt="Avatar"
                  />
                </figure>
                <div className="">
                  <div className="">
                    <a href="https://work.elite-professionals.in/employer/juan-butler/">
                      {" "}
                      Charlotte Morton{" "}
                    </a>
                  </div>
                  <h4 className="font-semibold">
                    <a href="https://work.elite-professionals.in/project/forest-pathology-professor/">
                      Forest Ecology Professor
                    </a>
                  </h4>
                  <span>United Emirates</span>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* second section */}

        <footer className="mx-auto grid grid-cols-1 md:grid-cols-2 justify-around gap-9  p-4 bg-black bg-opacity-30 text-neutral-content place-items-center ">
          <div className="items-center grid-flow-col hidden md:block">
            <FaSlackHash className="text-7xl" />
          </div>
          <div className="grid-flow-col w-full gap-4  ">
            <form onSubmit={onSubmit} className="w-full">
              <div className="mx-auto w-[80%] flex  flex-col md:flex-row  gap-3 md:gap-0">
                <input
                  type="email"
                  className="md:rounded-none w-full focus:outline-none p-2.5 z-20 text-sm text-gray-900 bg-gray-50 rounded-lg md:rounded-l-lg ring-0 focus:border-red-400 border"
                  name="subscribeEmail"
                  placeholder="Add Your Email"
                  required
                />
                <button
                  type="submit"
                  className="p-2.5 w-full md:w-28 text-sm font-medium text-white bg-pink-600 bg-opacity-90 gradient-pink-purple-95 rounded-lg md:rounded-r-lg md:rounded-none">
                  SUBSCRIBE
                </button>
              </div>
            </form>
          </div>
        </footer>
        {/* 3rd section */}
        <footer className=" footer px-10 pt-5   text-white mt-10 pb-20 bg-transparent border-base-300">
          <div className="items-center grid-flow-col mx-auto md:mx-0">
            <p>Copyright © 2023 Hire, All Right Reserved</p>
          </div>
          <div className="mx-auto md:mx-0 md:place-self-center md:justify-self-end">
            <div className="grid grid-flow-col gap-4">
              <Link to="/terms&condition" target="_blank">
                Terms of Service
              </Link>
              <Link to="/cookieSetting" target="_blank">
                Cookie Setting
              </Link>

              <a>How it work</a>
              <a>News</a>
              <a>About</a>
              <Link to="/privacy&policy" target="_blank">
                Privacy Policy
              </Link>
              <Link to="/faq" target="_blank">
                FAQ
              </Link>
            </div>
          </div>
        </footer>
      </div>
      <Toaster position="top-right" reverseOrder={false} />;
    </>
  );
};
//
export default Footer;
