import React from "react";
import { AiFillDelete } from "react-icons/ai";
const AddSkill = ({
  userSkill,
  setUserSkill,
  register,
  dropDown,
  placeholder,
  name,
}) => {
  const handleKeyDown = (e) => {
    // e.preventDefault();
    // If user did not press enter key, return

    if (e.key !== "Enter") {
      // e.preventDefault();
      return;
    }
    // Get the value of the input
    const value = e.target.value;
    // If the value is empty, return
    if (!value.trim()) return;
    // Add the value to the tags array
    setUserSkill([...userSkill, value]);
    // Clear the input
    e.target.value = "";
  };
  function removeTag(index) {
    setUserSkill(userSkill.filter((el, i) => i !== index));
  }
  return (
    <>
      {/*

 */}
      <div>
        <div
          className={` input-bordered input input-primary p-1 w-full rounded-lg mt-4 flex items-center flex-wrap gap-2 `}
        >
          <input
            onKeyDownCapture={handleKeyDown}
            // {...register(name)}
            type="text"
            className="tags-input"
            placeholder={`${placeholder ? placeholder : "Type Your Skill"}`}
          />
        </div>
        {userSkill.map((tag, index) => (
          <div
            className="inline-flex mx-2 items-center justify-between bg-green-100 gap-2 my-3  p-2 rounded-xl"
            key={index}
          >
            <span className="text">{tag}</span>
            <span
              className="inline-flex h-5 w-5 text-red-600 rounded-[50%] justify-center items-center ml-1 cursor-pointer text-xl"
              onClick={() => removeTag(index)}
            >
              <AiFillDelete />
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default AddSkill;
