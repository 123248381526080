import React, { useState } from "react";
import {
  BsClock,
  BsFillCalendarEventFill,
  BsFillClockFill,
} from "react-icons/bs";
import { FiCornerRightDown } from "react-icons/fi";
import { BiCloudDownload } from "react-icons/bi";
import { FaUserGraduate, FaLanguage } from "react-icons/fa";
import { GiAlarmClock, GiSkills } from "react-icons/gi";
import { GrMapLocation } from "react-icons/gr";
import { TiBook } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import FreelancerExperienceIcon from "../../../Assect/img/Freelancer_experience.jpg";
import moneyIcon from "../../../Assect/img/Project_price.jpg";
import {
  serverImageUrl,
  serverUrl,
} from "../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import RazorPay from "../../payment/RazorPay";

const LeftSlide = ({ job, hr, button }) => {
  // console.log(job, "post job preview");
  const [modal, seModal] = useState(false);
  const navigate = useNavigate();

  const {
    JobLocation,
    PersonID,
    english_level,
    fixed_project,
    freelancer_type,
    job_id,
    job_categories,
    job_details,
    job_duration,
    job_title,
    language,
    maximum_price,
    minimum_price,
    payment_status,
    projectFile,
    project_deadline_date,
    project_expire_date,
    project_start_date,
    project_level,
    project_location_type,
    skills_required,
    years_of_experience_preferred,
  } = job;

  // console.log(job, "This is job ");
  // JavaScript
  const handelJobFileDownload = (url) => {
    const fileName = url.split("/").pop();
    const downloadLink = document.createElement("a");
    downloadLink.href = `${serverUrl}/userProfile/download/${url}`;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  // job information
  // language
  // minimum_price,
  // payment_status,
  // project_deadline_date,
  // project_expire_date,
  // project_start_date,

  // freelancher info
  // years_of_experience_preferred
  // skills_required
  // project_location_type
  // freelancer_type
  // english_level,

  return (
    <div className="col-span-3 mb-4">
      {/* Job Title and location and post time  */}
      <div className="pt-4 px-5">
        <h2 className="card-title">
          <span className="text-secondary">{job_title}</span>
          {/* <!-- Update the onclick event without extra parentheses --> */}

          <span
            className="cursor-pointer"
            onClick={() => handelJobFileDownload(projectFile)}
          >
            <BiCloudDownload className="text-lg" />
          </span>
        </h2>
        <p>Posted 31 minutes ago</p>
        <div className="flex gap-2 items-center text-secondary">
          <GrMapLocation className="text-secondary" />
          <p>{JobLocation}</p>
        </div>
      </div>

      <div className="divider"></div>

      {/* Job Description  */}
      <div className="pt-4 px-5">
        {/* <h2 className="card-title">Needs to hire 7 Freelancers</h2> */}
        <p>{job_details}</p>
      </div>
      <div className="divider"></div>

      {/* list start  */}
      <div className="px-5 mb-5">
        <div className=" flex gap-2">
          <div>
            <TiBook className="mt-1 text-xl" />
          </div>
          <div>
            <h2 className="font-bold flex justify-start items-center">
              Project Length
            </h2>
            <ul className="list-disc px-5">
              <li>This project is of {job_duration}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="px-5">
        <div className=" flex gap-2">
          <div>
            <BsFillClockFill className="mt-1" />
            {/* <BsClock className="mt-1" /> */}
          </div>
          <div>
            <h2 className="font-bold flex justify-start items-center">
              Project Deadline
            </h2>
            <ul className="list-disc px-5">
              <li>The project will start on {project_start_date}</li>
              <li>The project will expire on {project_expire_date}</li>
              <li>The project will deadline on {project_deadline_date}</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="px-5 mt-5">
        <div className=" flex gap-2">
          <div>
            <TiBook className="mt-1 text-xl" />
          </div>
          <div>
            <h2 className="font-bold flex justify-start items-center">
              Project
            </h2>
            <ul className="list-disc px-5">
              <li>The project will be on {project_level}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="px-5 mt-5">
        <div className=" flex gap-2">
          <div>
            <GiSkills className="mt-1" />
          </div>
          <div>
            <h2 className="font-bold flex justify-start items-center">
              Required skills
            </h2>

            <div>
              {skills_required &&
                JSON.parse(skills_required).map((skill, i) => (
                  <button
                    key={i}
                    className="btn btn-secondary btn-sm ml-2 mt-4 btn-outline"
                  >
                    {skill}
                  </button>
                ))}
            </div>
          </div>
        </div>
      </div>
      {/* list End  */}

      <div className="divider"></div>
      <div className="flex gap-5 flex-wrap pt-4 px-5">
        {/* <div className=" flex gap-2 ">
          <div>
            <BsFillCalendarEventFill className="mt-1" />
          </div>
          <div>
            <h2 className="font-bold flex justify-start items-center">
              Project Length
              <FiCornerRightDown className="mt-2 ml-2" />
            </h2>
            <span>{job_duration}</span>
          </div>
        </div>
        <div className=" flex gap-2">
          <div>
            <BsClock className="mt-1" />
          </div>
          <div>
            <h2 className="font-bold flex justify-start items-center">
              Project Start & End <FiCornerRightDown className="mt-2 ml-2" />
            </h2>
            <p>The project will start on {project_start_date}</p>
            <p>The project will expire on {project_expire_date}</p>
            <p>The project will deadline on {project_deadline_date}</p>
          </div>
        </div> */}

        {/* <div className=" max-w-[220px] flex gap-2 ">
          <div>
            <FaHeadSideVirus className="mt-1" />
          </div>
          <div>
            <h2 className="font-bold flex justify-start items-center">
              Project <FiCornerRightDown className="mt-2 ml-2" />
            </h2>
            <span>{project_level}</span>
          </div>
        </div> */}

        <div className=" flex gap-2 ">
          <div>
            <img
              src={moneyIcon}
              alt="FreelancerExperienceIcon"
              className="w-5 h-4 mt-1"
            />
          </div>
          <div>
            <h2 className="font-bold">Project price</h2>
            <p>₹ {minimum_price}</p>
          </div>
        </div>

        <div className=" flex gap-2 ">
          <div>
            <img
              src={FreelancerExperienceIcon}
              alt="FreelancerExperienceIcon"
              className="w-4 h-4"
            />
          </div>
          <div>
            <h2 className="font-bold">Freelancer experience</h2>
            <span>{years_of_experience_preferred} year</span>
          </div>
        </div>

        <div className=" flex gap-2 ">
          <div>
            <TiBook className="mt-1 text-xl" />
          </div>
          <div>
            <h2 className="font-bold">Project Type</h2>
            <p>{fixed_project}</p>
          </div>
        </div>

        <div className=" flex gap-2 ">
          <div>
            <FaUserGraduate className="mt-1" />
          </div>
          <div>
            <h2 className="font-bold">Freelancer Type</h2>
            <span>{freelancer_type}</span>
          </div>
        </div>

        <div className=" flex gap-2 ">
          <div>
            <FaLanguage className="mt-1 text-xl" />
          </div>
          <div>
            <h2 className="font-bold">language</h2>
            <p>{language}</p>
            <p>English should be {english_level}</p>
          </div>
        </div>
      </div>
      <div className="divider"></div>

      <div className="px-5">
        <h2 className="font-bold text-lg">Company</h2>
        <div className="flex justify-start items-start gap-3 my-5">
          <div className="w-20 h-full">
            {job?.companyLogo && (
              <img
                src={`${serverImageUrl}/${job?.companyLogo}`}
                alt=""
                className="rounded-lg w-full h-full"
              />
            )}
            {!job?.companyLogo && job?.companyName && (
              <div className="bg-primary w-20 h-20 rounded-full font-bold flex justify-center items-center text-2xl text-gray-200">
                <span>{job?.companyName?.slice(0, 2)}</span>
              </div>
            )}
          </div>
          <div>
            <h2 className="font-medium text-base">{job?.companyName}</h2>
            <h5 className="text-xs">{job?.companyEmail}</h5>
            <Link
              to={job?.companyUrl}
              className="text-secondary underline text-sm"
            >
              {job?.companyUrl}
            </Link>
          </div>
        </div>
      </div>

      {button && <div className="divider"></div>}
      <div className="pt-4 px-5">
        {!payment_status ? (
          <>
            {button && (
              <RazorPay amount={minimum_price} job_id={job_id}>
                Complete Payment
              </RazorPay>
            )}
          </>
        ) : (
          <button
            className="m-2 btn btn-primary"
            onClick={() => {
              navigate(`/dashboard/client/jobs/inviteFreelancer`);
            }}
          >
            Invite Freelancer
          </button>
        )}
      </div>
    </div>
  );
};

export default LeftSlide;
