import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import JobPostProvider from "../../../../context/JobPostProvider";
import Loading from "../../../Loading/Loading";
import LeftSlide from "../Job/LeftSlide";
import RightSlide from "../Job/RightSlide";
import { serverUrl } from "../../../../hooks/AllServerUrlControl/useAllServerUrlControl";

const FullJobDetails = () => {
  const { id } = useParams();
  const [hide, setHide] = useState(true);
  const {
    data: job,
    isLoading,
    refetch,
  } = useQuery(["job", id], () =>
    fetch(`${serverUrl}/client/single_postingJob/${id}`, {
      method: "GET",
    }).then((res) => {
      return res.json();
    })
  );

  if (isLoading) {
    return <Loading />;
  }
  const { result } = job;
  console.log(result);
  return (
    <JobPostProvider>
      <div>
        <div className="card my-6 w-full bg-base-100">
          <div className="card-body p-2">
            <div className="grid md:grid-cols-4 sm:grid-cols-1 border-2 rounded-lg border-gray-200 p-2">
              <LeftSlide job={result} h="hr" />
              <RightSlide job={result} refetch={refetch} hide={hide} />
            </div>
          </div>
        </div>
      </div>
    </JobPostProvider>
  );
};

export default FullJobDetails;
