import React from "react";

//import css module
import "react-languages-select/css/react-languages-select.css";
const JobLanguage = ({ register }) => {
  const languageList = [
    { name: "Arabic", localName: "العربية" },
    { name: "Bengali", localName: "বাংলা" },
    { name: "Chinese", localName: "中文" },
    { name: "German", localName: "German" },
    { name: "English", localName: "English" },
    { name: "Spanish", localName: "Español" },
    { name: "Persian", localName: "پارسی" },
    { name: "French", localName: "Français" },
    { name: "Greek", localName: "ελληνική" },
    { name: "Guarani", localName: "Avañe'ẽ" },
    { name: "Hindi", localName: "हिंदुस्तानी" },
    { name: "Italian", localName: "Italiano" },
    { name: "Korean", localName: "한국어" },
    { name: "Malay", localName: "Melayu" },
    { name: "Dutch", localName: "Nederlandse" },
    { name: "Portuguese", localName: "Português" },
    { name: "Romanian", localName: "Română" },
    { name: "Russian", localName: "русский" },
    { name: "Albanian", localName: "shqiptar" },
    { name: "Serbo-Croatian", localName: "Српско-хрватски" },
    { name: "Swedish", localName: "Swedish" },
    { name: "Swahili", localName: "Kiswahili" },
    { name: "Tamil", localName: "தமிழ்" },
    { name: "Turkish", localName: "Türk" },
  ];
  // onSelectLanguage()
  return (
    <div>
      <select
        className="select select-primary w-full"
        {...register("language", {
          required: {
            value: true,
            massage: "select language required",
          },
        })}
      >
        <option selected> Select Language </option>
        {languageList.map((l, i) => (
          <option key={i} value={l.name}>
            <span>{l.name}</span> <span className="ml-2">{l.localName}</span>{" "}
          </option>
        ))}
      </select>
    </div>
  );
};

export default JobLanguage;
