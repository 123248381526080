import { PostJobActionTypes } from "./PostJobActionTypes";

export const initialState = {
  loading: false,
  postJobs: [],
  error: false,
};

export const PostJobReducer = (state = initialState, action) => {
  switch (action.type) {
    case PostJobActionTypes.FETCHING_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case PostJobActionTypes.FETCHING_SUCCESS:
      return {
        ...state,
        loading: false,
        postJobs: action.payload,
        error: false,
      };
    case PostJobActionTypes.FETCHING_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case PostJobActionTypes.DELETE_POST_JOB:
      return {
        ...state,
        postJobs: state.postJobs?.filter(
          (product) =>
            product.PersonID === action.payload.PersonID &&
            product.id !== action.payload.id
        ),
      };

    default:
      return state;
  }
};
