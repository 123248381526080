
import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import Rating from "react-rating";

const FreelancerRating = ({ rating }) => {
  return (
    <div>
      {" "}
      <Rating
        initialRating={rating}
        emptySymbol={<AiOutlineStar className="text-sm" />}
        fullSymbol={
          <AiFillStar className="text-sm" style={{ color: "goldenrod" }} />
        }
        readonly></Rating>{" "}
      <span className="text-sm">{rating}/5</span>
    </div>
  );
};

export default FreelancerRating;
