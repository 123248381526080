// server url all
// This is localhost server url
// export const serverUrl = "http://localhost:8080/api/v1";
// export const serverRoot = "http://localhost:8080/";

// This is hire server url
export const serverUrl = "https://hire.server.elite-professionals.in/api/v1";
export const serverRoot = "https://hire.server.elite-professionals.in/";

// This is vercel server url
// export const serverUrl = "https://elitepro-hire.vercel.app/api/v1";
// export const serverRoot = "https://elitepro-hire.vercel.app/";

// chat application server url
// This is localhost server url
// export const chatApplicationUrl = "http://localhost:5080/api/v1";
// export const chatApplicationSocketUrl = "http://localhost:5080";
// this is hire chat server url
export const chatApplicationUrl = "https://chat.elite-professionals.in/api/v1";
export const chatApplicationSocketUrl = "https://chat.elite-professionals.in/";
// this is render server url
// export const chatApplicationUrl = "https://test-socekt.onrender.com/api/v1";
// export const chatApplicationSocketUrl = "https://test-socekt.onrender.com";

// live site url all
export const liveSiteUrl = "http://www.hire.elite-professionals.in";
// export const liveSiteUrl = "http://localhost:3000";

// Server image load url
export const serverImageUrl = "https://hire.server.elite-professionals.in";
// export const serverImageUrl = "http://localhost:8080";
