import React, { useState } from "react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { TimePeriodContext } from "../TimePeriodContext";

const TimSheetDashboardNavbar = ({ children }) => {
  const [activeTimePeriod, setActiveItem] = useState("day");

  const handleClick = (item) => {
    setActiveItem(item);
  };

  return (
    <TimePeriodContext.Provider value={{ activeTimePeriod, handleClick }}>
      <div>
        {" "}
        <div>
          <div>
            <navbar className="">
              <div className=" border-b border-gray-400 flex justify-between items-center gap-4 px-4  pt-4">
                <div>
                  <ul className="flex gap-4">
                    <li
                      className={`text-lg cursor-pointer font-semibold ${
                        activeTimePeriod === "day"
                          ? "border-b-4 border-secondary"
                          : "text-gray-400"
                      }`}
                      onClick={() => handleClick("day")}>
                      Day
                    </li>
                    <li
                      className={`text-lg cursor-pointer font-semibold ${
                        activeTimePeriod === "week"
                          ? "border-b-4 border-secondary"
                          : "text-gray-400"
                      }`}
                      onClick={() => handleClick("week")}>
                      Week
                    </li>
                    <li
                      className={`text-lg cursor-pointer font-semibold ${
                        activeTimePeriod === "month"
                          ? "border-b-4 border-secondary"
                          : "text-gray-400"
                      }`}
                      onClick={() => handleClick("month")}>
                      Month
                    </li>
                  </ul>
                </div>
                <div>
                  <div className="flex gap-2 items-center">
                    <ul className="flex gap-2">
                      <li className="flex gap-1 items-end">
                        <h3>All location</h3>
                        <IoMdArrowDropdown />
                      </li>
                      <li className="flex gap-1 items-end">
                        <h3>All groups</h3>
                        <IoMdArrowDropdown />
                      </li>
                      <li className="flex gap-1 items-end">
                        <h3>All schedules</h3>
                        <IoMdArrowDropdown />
                      </li>
                    </ul>
                    <div className="btn-group">
                      <button
                        style={{
                          fontSize: "11px",
                          padding: "3px",
                        }}
                        className=" btn btn-sm btn-outline btn-active">
                        Organization
                      </button>
                      <button
                        style={{
                          fontSize: "11px",
                          padding: "3px",
                        }}
                        className="btn btn-sm btn-outline">
                        Personal
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </navbar>
          </div>
          <div className="my-5">{children}</div>
        </div>
      </div>
    </TimePeriodContext.Provider>
  );
};

export default TimSheetDashboardNavbar;
