import React, { useContext, useEffect, useState } from "react";
import style from "./NotificationDrawer.module.css";
import {
  serverImageUrl,
  serverUrl,
} from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const NotificationDrawer = () => {
  const {
    currentUser,
    windowLoading,
    currentUserAllNotification,
    userNotificationLoading,
    userNotificationRefetch,
    handelNotificationCount,
    setHandelNotificationCount,
  } = useContext(globalFunctionOneContext);

  //new notification count reset function
  useEffect(() => {
    if (currentUserAllNotification) {
      // Filter notifications where heHasSeen is "No" and return their IDs
      const newNotificationFilter = currentUserAllNotification.result
        .filter((data) => data.heHasSeen === "No")
        .map((data) => data.id);
      setHandelNotificationCount(0);
      if (newNotificationFilter.length > 0) {
        const result = handelNotificationHeHaSeenStatus(newNotificationFilter);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserAllNotification, setHandelNotificationCount]);

  const handelNotificationHeHaSeenStatus = async (postData) => {
    if (currentUser) {
      const res = await fetch(`${serverUrl}/userProfile/all/notification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          uid: currentUser?.PersonID,
          // authorization: `bearer ${localStorage.getItem("access_Token")}`,
        },
        body: JSON.stringify({ userData: postData, changeData: "Yes" }),
      });
      const data = await res.json();
      // console.log(data);
      return data;
    }
  };

  if (!currentUserAllNotification) {
    return <div>Loading ...</div>;
  }

  return (
    <section className="fixed top-18 right-0" style={{ zIndex: "9999999" }}>
      <div className="drawer drawer-end">
        <input
          id="NotificationDrawer"
          type="checkbox"
          className="drawer-toggle"
        />

        <div className="drawer-side">
          <label
            htmlFor="NotificationDrawer"
            className="drawer-overlay"
            style={{ background: "transparent" }}
          ></label>
          <ul className="p-4 w-80 h-full bg-base-200 text-base-content mb-10">
            <h2 className="font-bold text-xl my-2">All Notification</h2>
            {currentUserAllNotification?.result &&
              currentUserAllNotification?.result.map((notification) => {
                const providedDate = new Date(notification?.notificationStore);
                const currentLocalDate = new Date();
                const timeDifference = currentLocalDate - providedDate;

                let timeMessage = "";
                if (timeDifference < 60000) {
                  timeMessage = "Just now";
                } else if (timeDifference < 3600000) {
                  const minutesAgo = Math.floor(timeDifference / 60000);
                  timeMessage = `${minutesAgo} minute${
                    minutesAgo > 1 ? "s" : ""
                  } ago`;
                } else if (timeDifference < 86400000) {
                  const hoursAgo = Math.floor(timeDifference / 3600000);
                  timeMessage = `${hoursAgo} hour${
                    hoursAgo > 1 ? "s" : ""
                  } ago`;
                } else if (timeDifference < 604800000) {
                  const daysAgo = Math.floor(timeDifference / 86400000);
                  timeMessage = `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`;
                } else if (timeDifference < 2419200000) {
                  const weeksAgo = Math.floor(timeDifference / 604800000);
                  timeMessage = `${weeksAgo} week${
                    weeksAgo > 1 ? "s" : ""
                  } ago`;
                } else {
                  const monthsAgo = Math.floor(timeDifference / 2419200000);
                  timeMessage = `${monthsAgo} month${
                    monthsAgo > 1 ? "s" : ""
                  } ago`;
                }

                return (
                  <li
                    key={notification?.id}
                    className={`${style.list_container_wrapper} flex justify-start items-start gap-2 my-2 relative`}
                  >
                    {notification?.senderProfilePicture && (
                      <div className="avatar mt-1">
                        <div className="w-10 h-10 rounded-full">
                          <img
                            src={`${serverImageUrl}/${notification?.senderProfilePicture}`}
                            alt="This is profile pic"
                            className="rounded-full"
                          />
                        </div>
                      </div>
                    )}
                    {!notification?.senderProfilePicture && (
                      <div className="w-10 h-10 rounded-full border border-gray-400 font-medium text-xl flex justify-center items-center text-secondary">
                        <p>{notification?.senderName.substring(0, 2)}</p>
                      </div>
                    )}
                    <div>
                      <h1 className="font-medium text-base">
                        {notification?.senderName}
                      </h1>
                      <p className="text-sm">
                        {notification?.NotificationContent}
                      </p>
                      <p className="text-xs font-bold text-secondary">
                        {timeMessage}
                      </p>

                      {/* <p className="text-xs font-bold text-secondary">
                      10 hour ago
                    </p> */}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default NotificationDrawer;
