import React from "react";
import { serverImageUrl } from "../../../../../hooks/AllServerUrlControl/useAllServerUrlControl";

const InviteModalUp = ({ freelancer }) => {
  return (
    <div>
      <div className="flex gap-4 ">
        <div>
          <div className="avatar online">
            <div className="w-24 rounded-full">
              <img
                src={`${serverImageUrl}/${freelancer?.profilePhoto}`}
                alt="User Profile"
              />
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-[1rem] text-primary">
            Fn-{freelancer?.PersonID}.
          </h2>
          <h2 className="font-bold">
            Internal communicate associate <br /> [Fn-{freelancer?.PersonID}.]
          </h2>
        </div>
      </div>
    </div>
  );
};

export default InviteModalUp;
