import React from "react";
import style from "./AllCommonButton.module.css";
import { useNavigate } from "react-router-dom";

export const CommonFormSubmitBtn = ({ type, BtnText, className }) => {
  return (
    <button type={type} className={`${style.form_submit_btn} ${className}`}>
      {BtnText}
    </button>
  );
};
export const CommonFormBackBtn = ({ type, BtnText, className }) => {
  const navigate = useNavigate();
  return (
    <button
      type={type}
      className={`${style.form_submit_btn} ${className}`}
      onClick={() => {
        navigate(-1);
      }}
    >
      {BtnText}
    </button>
  );
};
