import React from "react";

const BankInfo = ({ extraElement, searchIfsc }) => {
  return (
    <>
      <div
        className={`${
          !extraElement && "bg-secondary bg-opacity-10 rounded"
        } py-2  px-2 w-full md:w-[50%]`}>
        {extraElement && (
          <h2 className="text-xl font-semibold">Bank Information</h2>
        )}
        <h2 className={`${extraElement ? "font-semibold" : "card-title"}`}>
          Bank
        </h2>
        <h3>{(searchIfsc?.BANK, searchIfsc?.CITY)}</h3>
        <h3>{searchIfsc?.BRANCH} </h3>
        <h3>{(searchIfsc?.ADDRESS, searchIfsc?.STATE)} </h3>
        <p className="link text-accent">Not your bank or branch</p>
      </div>
      {/* currency and withdraw fee details */}
      <div>
        <div className="text-semibold mt-5">
          <h2 className="font-semibold text-lg">Account Currency</h2>
          <h3>Indian Rupee (INR)</h3>
        </div>
        {!extraElement && (
          <div>
            <h2 className="font-semibold text-lg">Account Currency (INR)</h2>
            <h3>$0.99 USD Per withdrawal</h3>
          </div>
        )}
      </div>
    </>
  );
};

export default BankInfo;
