import React from "react";
import { MdOutlineAttachEmail } from "react-icons/md";

const Users = ({ data }) => {
  let skillKey = [];

  try {
    if (typeof data.top_skill === "string" && data.top_skill.trim() !== "") {
      const skill = JSON.parse(data.top_skill);

      if (typeof skill === "object" && skill !== null) {
        for (const [key, value] of Object.entries(skill)) {
          skillKey.push(key);
        }
      }
    }
  } catch (error) {
    console.log("Error parsing JSON:", error);
  }

  return (
    <>
      <div>
        <h2 className="card-title">
          <MdOutlineAttachEmail className="text-accent" />{" "}
          {data?.firstName + " " + data.lastName}
        </h2>
        <h2 className="text-lg font-bold">Forest Pathology Officer</h2>
        <p>
          mamaun khan Lorem ipsum dolor sit, amet consectetur adipisicing elit.
          Fugit, ut excepturi similique vitae enim earum?
        </p>
        <div className="mt-3">
          {skillKey.map((skill, i) => (
            <button
              key={i}
              className="btn btn-secondary btn-sm ml-2 btn-outline"
            >
              {skill}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default Users;
