import React, { useContext } from "react";
import { SiNintendonetwork } from "react-icons/si";
import JobCard from "../../../Shared/JobCard/JobCard";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import {
  HandelCurrentFreelancerSavedAllJobsGetApplyData,
  HandelFreelancerSavedJobAllDataCount,
} from "../../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";

const RightSide = ({ setJobs, jobs, data, refetch }) => {
  const { currentUser, Loading } = useContext(globalFunctionOneContext);

  // sort data
  const sortData = [
    { label: "Newest", value: "newest" },
    { label: "Oldest", value: "oldest" },
    { label: "Lowest Price", value: "lowestPrice" },
    { label: "Highest Price", value: "highestPrice" },
    { label: "Most Experienced", value: "mostExperienced" },
    { label: "Least Experienced", value: "leastExperienced" },
  ];
  // handel freelancer All job bookMark function
  const {
    data: CurrentFreelancerAllSavedJobsData,
    isLoading: FreelancerAllSavedJobsDataGetLoading,
    refetch: freelancerAllSavedJobDataGetRefetch,
  } = HandelCurrentFreelancerSavedAllJobsGetApplyData(currentUser?.PersonID, 1);
  // Freelancer all saved job count function
  const {
    data: FreelancerJobSavedJobDataCount,
    isLoading: FreelancerJobSavedJobDataCountLoading,
    refetch: freelancerJobSavedJobDataCountRefetch,
  } = HandelFreelancerSavedJobAllDataCount(currentUser?.PersonID);

  return (
    <div>
      <div className="card px-2 w-full bg-base-100 shadow-xl">
        <div className="card-body">
          {/* sort wrapper  */}
          <div className="flex justify-between gap-4 items-center">
            <div className="flex gap-2 items-center">
              <span>
                <SiNintendonetwork className="text-primary" />
              </span>
              <span>{jobs?.length ? jobs?.length : 0}, Jobs Found</span>
            </div>
            <div className="flex gap-2 items-center">
              <span>sort</span>
              <select className="select select-xs select-bordered w-full max-w-xs">
                <option selected>sort</option>
                {sortData.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* showing result */}
          <div className="divider">
            <h1>All Jobs</h1>
          </div>
          {/* start */}

          <JobCard
            jobs={jobs}
            data={data}
            refetch={refetch}
            freelancerAllSavedJobDataGetRefetch={
              freelancerAllSavedJobDataGetRefetch
            }
            freelancerJobSavedJobDataCountRefetch={
              freelancerJobSavedJobDataCountRefetch
            }
          />
        </div>
      </div>
    </div>
  );
};

export default RightSide;
