import React, { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useJobPost } from "../../../../../context/JobPostProvider";
import Loading from "../../../../Loading/Loading";
import InviteModalDown from "./InviteModalDown";
import InviteModalUp from "./InviteModalUp";
import { auth } from "../../../../../config/firebaseConfig/firebaseConfig";
import { serverUrl } from "../../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { useAllMessage } from "../../../../../hooks/useAllMessage";
import { toast } from "react-toastify";
import {
  AllJobInvitedData,
  ClientInviteAllFreelancerDataCount,
  CurrentUserAllFriendListGet,
} from "../../../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import { globalFunctionOneContext } from "../../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const InviteJobModal = ({ modalTwo, setModalTwo, freelancer }) => {
  const {
    currentUser,
    allMessageFriendList,
    allMessageFriendListRefetch,
    allMessageFriendListLoading,
    singleUserRoomIdFiendFromCurrentFriendList,
  } = useContext(globalFunctionOneContext);
  const {
    state: { postJobs },
  } = useJobPost();

  const [inviteFreelancerRoomId, setInviteFreelancerRoomId] = useState(null);
  // console.log(freelancer, "freelencher");

  /*  console.log(postJobs); */
  const [jobId, setJobId] = useState("");
  const [user] = useAuthState(auth);
  const inviterId = user.uid;
  const { isLoading, refetch } = useAllMessage();
  const [applyData, setApplyData] = useState(null);
  // const[currentInviteFreelancerId]

  const {
    data: jobApplyData,
    refetch: jobApplyDataRefetch,
    isLoading: jobApplyDataLoading,
  } = AllJobInvitedData(
    `${serverUrl}/client/all/invited/Jobs?jobId=${jobId}&freelancerPersonId=${freelancer?.PersonID}
    `
  );
  // get ClientInviteAllFreelancerDataCount data function
  const {
    data: clientInviteAllFreelancerDataCount,
    refetch: ClientInviteAllFreelancerDataCountRefetch,
    isLoading: clientInviteAllFreelancerDataCountLoading,
  } = ClientInviteAllFreelancerDataCount();

  // submitInvite
  const submitInvite = async (e) => {
    e.preventDefault();
    const time = new Date().toLocaleTimeString();
    const date = new Date().toLocaleDateString();
    const PersonID = freelancer.PersonID;
    // const status = "unread";
    const jobRole = e.target.job_role.value;
    const message = e.target.message.value;
    console.log(message);
    // // const data = { inviterId, job_role, message, PersonID, job_id, status };

    const applyData = {
      invited: currentUser?.PersonID,
      invitedPersonType: currentUser?.accountType,
      jobId,
      shortlisted: "yes",
      viewOffer: "",
      heHasSeen: "client",
      freelancerPersonId: freelancer?.PersonID,
    };

    const inviteFreelancerData = {
      inviteFreelancerData: applyData,
      message,
      roomId: inviteFreelancerRoomId,
    };
    // const res = await fetch(`${serverUrl}/client/add/invite/message`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(userFormData),
    // });
    // const data = await res.json();

    const res = await fetch(`${serverUrl}/client/invited/Job`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        uid: currentUser?.PersonID,
      },
      body: JSON.stringify(inviteFreelancerData),
    });
    const data = await res.json();
    console.log(data, "data");

    if (data?.status === "success") {
      ClientInviteAllFreelancerDataCountRefetch();
      jobApplyDataRefetch();
      allMessageFriendListRefetch();
      e.target.reset();
      setModalTwo(!modalTwo);
      toast.success("Your message send successful !!!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Your job apply failed!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // job apply data looping function
  useEffect(() => {
    if (jobApplyData?.result) {
      const foundData = jobApplyData?.result.find(
        (job) =>
          job?.jobId === jobId &&
          job?.freelancerPersonId === freelancer?.PersonID
      );
      setApplyData(foundData);
      // console.log(foundData);
    }
  }, [freelancer?.PersonID, jobApplyData?.result, jobId]);

  useEffect(() => {
    if (freelancer?.PersonID && allMessageFriendList?.data) {
      const getInviteFreelancerRoomId =
        singleUserRoomIdFiendFromCurrentFriendList(
          freelancer?.PersonID,
          allMessageFriendList?.data
        );
      setInviteFreelancerRoomId(getInviteFreelancerRoomId);
    }
  }, [freelancer?.PersonID, allMessageFriendList?.data]);

  // console.log(jobId, freelancer?.PersonID);
  // console.log(applyData?.freelancerPersonId === freelancer?.PersonID);

  // this is loading
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      {/* The button to open modal */}

      {/* Put this part before </body> tag */}
      <input type="checkbox" id="my-modal-5" className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box ">
          <div className="flex justify-end">
            <button
              onClick={() => {
                setModalTwo(!modalTwo);
                setJobId("");
              }}
              className="btn btn-sm inline btn-primary"
            >
              x
            </button>
          </div>
          <h3 className="font-bold text-lg">Invite Job</h3>

          <form onSubmit={submitInvite}>
            <div className="">
              <InviteModalUp freelancer={freelancer} />
              <InviteModalDown setJobId={setJobId} postJobs={postJobs} />
            </div>

            <div className="flex flex-end justify-end gap-4 items-center">
              <h3>
                You have
                <span className="text-secondary font-bold">
                  30 invites left
                </span>
              </h3>

              <button
                type="submit"
                className="btn capitalize btn-sm btn-primary rounded-full"
                disabled={
                  applyData?.freelancerPersonId === freelancer?.PersonID ||
                  applyData?.jobId === jobId
                }
              >
                send invitation
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InviteJobModal;
