import React, { useContext } from "react";
import Loading from "../../../../../Loading/Loading.js";
import Left from "./Left";
import Right from "./Right";
import { useAllFreelancer } from "../../../../../../hooks/useAllFreelancer.js";

const SearchResult = ({ searchInput }) => {
  const { isLoading, error, data } = useAllFreelancer();
  if (isLoading || data?.results?.length < 0)
    return (
      <div className="h-[455px]">
        <Loading />
      </div>
    );
  if (error) {
    console.log(error.message);
    return <p>Error</p>;
  }

  let content;

  if (isLoading) {
    content = (
      <div className="h-[455px]">
        <Loading />
      </div>
    );
  }

  if (!isLoading && !error && data?.results?.length === 0) {
    content = <p>Nothing to show, the list is empty</p>;
  }

  if (!isLoading && !error && data.results?.length) {
    content = data?.results
      ?.filter((u) => {
        if (searchInput === "") {
          return u;
        } else if (
          u?.profile_title.toLowerCase().includes(searchInput.toLowerCase())
        ) {
          return u;
        }
        return null; // You can remove this if condition if you don't need it.
      })
      .map((freelancer, i) => (
        <div
          key={i}
          className="grid grid-cols-1 mt-10 pb-10 border-b-2 border-gray-300 md:grid-cols-3"
        >
          <Left freelancer={freelancer} />
          <Right freelancer={freelancer}>
            <div className="mt-4 flex items-center gap-2 wrap">
              <button className="btn btn-primary btn-sm">...</button>
              <button className="btn btn-outline capitalize btn-primary btn-sm">
                Shortlist
              </button>
              <label
                // onClick={() => setModalTwo(!modalTwo)}
                htmlFor="my-modal-5"
                className="capitalize btn btn-primary btn-sm"
              >
                View Offer
              </label>
            </div>
          </Right>
        </div>
      ));
  }

  return <>{content}</>;
};

export default SearchResult;
