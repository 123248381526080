import React, { useContext, useState } from "react";
import { BsFillPersonPlusFill } from "react-icons/bs";
import PostJob from "../../PostJob/PostJob";
import HomeOne from "./HomeOne";
import HomeTwo from "./HomeTwo";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import Loading from "../../../Loading/Loading";

const Home = () => {
  const { windowLoading, currentUser } = useContext(globalFunctionOneContext);
  const [job, setJob] = useState(false);
  const arr = [1];
  if (arr.length <= 0) {
    // console.log("done");
  } else {
    // console.log("gun");
  }
  // console.log(arr);
  if (windowLoading) {
    return (
      <div className="h-[455px]">
        <Loading />
      </div>
    );
  }
  return (
    <>
      <div className="flex justify-between gap-2 my-4">
        <div className="ml-4">
          <h2 className="card-title">Your Dashboard</h2>
          <div className="flex gap-3 items-center">
            <h2>{currentUser?.firstName + currentUser?.lastName}</h2>
            <button className="btn btn-primary btn-outline btn-sm no-animation btn-circle">
              <BsFillPersonPlusFill className="text-xl" />
            </button>
          </div>
        </div>
        <div className=" flex gap-2">
          <button className="btn btn-outline btn-primary">
            Browse project catalog
          </button>
          <label
            onClick={() => {
              if (currentUser?.userProfileStats === 1) {
                setJob(!job);
              }
            }}
            htmlFor="post_job"
            className="btn btn-primary"
          >
            Post a job
          </label>
        </div>
      </div>

      {/* Job post Modal component */}
      {arr.length <= 0 ? <HomeOne job={job} setJob={setJob} /> : <HomeTwo />}
      {/* modal */}
      {job && <PostJob post={job} setPost={setJob} />}
    </>
  );
};

export default Home;
