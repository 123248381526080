export const weeklyDummyData = [
  {
    day: "Mon",
    leave: 0.5,
    workHours: 5,
  },
  {
    day: "Tue",
    leave: 0.7,
    workHours: 8,
  },
  {
    day: "Wed",
    leave: 0.2,
    workHours: 6,
  },
  {
    day: "Thu",
    leave: 0.3,
    workHours: 3.2,
  },
  {
    day: "Fri",
    leave: 0.5,
    workHours: 5,
  },
  {
    day: "Sat",
    leave: 0.33,
    workHours: 7,
  },
  {
    day: "Sun",
    leave: 1,
    workHours: 10,
  },
];

export const monthDummyData = Array.from({ length: 30 }, (_, i) => {
  const dayOfWeek = ["S", "M", "T", "W", "T", "F", "S"];
  const day = dayOfWeek[i % 7];
  return {
    day,
    leave: Math.random() * 5,
    workHours: Math.random() * 15,
  };
});
export const dayDummyData = [
  {
    day: "Morning",
    leave: Math.random() * 3,
    workHours: Math.random() * 2,
  },
  {
    day: "Afternoon",
    leave: Math.random(),
    workHours: Math.random() * 2,
  },
  {
    day: "Evening",
    leave: Math.random() * 2,
    workHours: Math.random() * 4,
  },
];
