import React from "react";

const ShowLink = () => {
  return (
    <div>
      <h2 className="uppercase font-semibold mt-10">
        🔗 Showcase your links
        <span className="text-[10px] text-slate-300">(Optional)</span>
      </h2>
      <div className="divider mb-2 mt-0"></div>
      <div className="grid grid-cols-2 gap-4">
        <div className="form-control w-full max-w-xs">
          <label className="label">
            <span className="label-text">Linkedin</span>
          </label>
          <input
            required
            name="linkedin"
            type="text"
            className="input input-bordered w-full max-w-xs input-sm"
          />

          <label className="label">
            <span className="label-text">Github</span>
          </label>
          <input
            required
            name="github"
            type="text"
            className="input input-bordered w-full max-w-xs input-sm"
          />

          <label className="label">
            <span className="label-text">Personal/portfolio</span>
          </label>
          <input
            required
            name="portfolio"
            type="text"
            className="input input-bordered w-full max-w-xs input-sm"
          />

          <label className="label">
            <span className="label-text">Stack Overflow</span>
          </label>
          <input
            required
            name="stackOverflow"
            type="text"
            className="input input-bordered w-full max-w-xs input-sm"
          />
          <label></label>
        </div>
      </div>
    </div>
  );
};

export default ShowLink;
