import React from "react";

const JobCategory = ({ register }) => {
  const jobList = [
    "Digital Marketing",
    "Fun & Lifestyle",
    "Mobiles,",
    "Music & Audio",
    "Programming & Tech",
    "Video & Animation",
    "Writing & Translation",
    "Software development",
    "Web development",
    "Data analysis",
    "Data science",
    "Database administration",
    "Network engineering",
    "Information security",
    "DevOps",
    "Technical support",
    "Information technology (IT) management",
  ];

  return (
    <div>
      <select
        {...register("job_categories", {
          require: {
            value: true,
            massage: "select job catagories required",
          },
        })}
        className="select select-primary w-full"
      >
        <option selected> Select job </option>
        {jobList.map((list, i) => (
          <option key={i} value={list}>
            {list}
          </option>
        ))}
      </select>
    </div>
  );
};

export default JobCategory;
