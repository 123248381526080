import React, { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import {
  serverImageUrl,
  serverUrl,
} from "../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { useEffect } from "react";
import ModalBody from "../../../Shared/ModalBody/ModalBody";
import { ClientAllMessageUser } from "../../../../hooks/useAllMessage";
import { toast } from "react-toastify";
import ScheduleInterview from "../../../Shared/ScheduleInterview/ScheduleInterview";
import { SingleUserProfileGet } from "../../../../hooks/useUser";
import { CurrentClientHireFreelancerData } from "../../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";

const ViewOffer = () => {
  const {
    firebaseUser,
    currentUser,
    Loading,
    windowLoading,
    setWindowLoading,
    setUserCurrentLocationPathParameter,
  } = useContext(globalFunctionOneContext);
  const parameter = useParams();
  const {
    data: HireConfirmDAta,
    refetch,
    isLoading: freelancerHireCheck,
  } = CurrentClientHireFreelancerData(
    parameter?.jobId,
    parameter?.freelancerId,
    1
  );

  const [viewOfferData, setViewOfferData] = useState({});
  const [totalVatAmount, setTotalVatAmount] = useState(0);
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [subMenuOpenAndClose, setSubMenuOpenAndClose] = useState(false);
  const [inviteMessageM, setInviteMessageM] = useState(false);
  const [scheduleCandidate, setScheduleCandidate] = useState({});
  const [currentPersonDAta, setCurrentPersonDAta] = useState({});
  const [handelScheduleInterviewModal, setHandelScheduleInterviewModal] =
    useState(false);
  const [currentHireJobId, setCurrentHireJobId] = useState("");
  // get single freelancer user profile
  const {
    data: currentLoginUserProfileData,
    isLoading,
    error,
  } = SingleUserProfileGet(currentUser?.PersonID);

  // All message data get function
  const { refetch: ClientAllMessageDataRefetch } = ClientAllMessageUser();

  // mony Amount calculate function
  const calculateTotalAmount = (initialAmount) => {
    const mainAmount = Number(initialAmount);
    const vatRate = 0.2; // 20% VAT
    const vatAmount = Math.round(mainAmount * vatRate);
    const totalAmount = mainAmount + vatAmount;
    setTotalVatAmount(vatAmount);
    setTotalPaymentAmount(totalAmount);
  };

  // console.log(freelancerId, "freelancerId, freelancerId");
  // console.log(HireConfirmDAta, "HireConfirmDAta");
  // console.log(
  //   currentHireJobId === jobId,
  //   "currentHireJobId === jobId ",
  //   currentHireJobId
  // );

  // handelSendMessage
  const handelSendMessage = async (e) => {
    e.preventDefault();
    const message = e.target.sendMessage.value;
    const time = new Date().toLocaleTimeString();
    const date = new Date().toLocaleDateString();

    if (message) {
      const userFormData = {
        inviterId: firebaseUser?.uid,
        jobRole: null,
        PersonID: currentPersonDAta?.freelancerId,
        jobId: null,
        message,
        time,
        date,
        heHasSeen: "freelancer",
      };
      console.log(userFormData, "userFormData userFormData");

      const res = await fetch(`${serverUrl}/client/add/invite/message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userFormData),
      });
      const data = await res.json();
      if (data?.status === "success") {
        e.target.reset();
        ClientAllMessageDataRefetch();
        setInviteMessageM(false);
        toast.success("🦄 Your message send successful !!!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("🦄 Your message successful no't send !!!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  // handel Freelancer Hire
  const handelFreelancerHire = async (data) => {
    setCurrentHireJobId(data?.job_id);
    const freelancerHireData = {
      hireIngPersonId: data?.PersonID,
      freelancerId: data?.freelancerId ? data?.freelancerId : data?.PersonID,
      jobId: data?.job_id,
    };

    const userData = {
      senderNotificationPersonId: data?.PersonID,
      receiverNotificationPersonId: data?.freelancerId
        ? data?.freelancerId
        : data?.PersonID,
      senderProfilePicture: data?.result?.profilePhoto,
      senderName: currentUser?.firstName + currentUser?.lastName,
      NotificationContent: "your selected and join my company",
      NotificationType: "Hire",
      heHasSeen: "No",
    };

    const res = await fetch(`${serverUrl}/client/freelancer/hire`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ freelancerHireData, userData }),
    });
    const result = await res.json();
    console.log(result);
    if (result?.status === "success") {
      refetch();
      toast.success("This freelancer hire complete!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error("This freelancer hire failed!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // set Global state query id  function
  useEffect(() => {
    setUserCurrentLocationPathParameter(parameter);
    fetch(
      `${serverUrl}/client/jobs/view/offer/${parameter?.jobId}/${parameter?.freelancerId}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === "success") {
          setViewOfferData(data?.data);
          calculateTotalAmount(data?.data?.minimum_price);
          setCurrentPersonDAta(data?.data);
          setScheduleCandidate(data?.data);
        }
      });
  }, [
    parameter,
    parameter?.freelancerId,
    parameter?.jobId,
    setUserCurrentLocationPathParameter,
  ]);

  if (
    !viewOfferData?.job_categories ||
    !totalVatAmount ||
    !totalPaymentAmount ||
    isLoading
  ) {
    return <div>loading ........</div>;
  }

  return (
    <div>
      <div className="bg-gray-100 py-10">
        <h2 className="text-center text-xl mb-10">
          You're protected by{" "}
          <span className="text-[#15a800] font-medium">
            Hire Payment Protection.
          </span>{" "}
          Only pay for the work you authorize
        </h2>
        <div className="sm:px-10">
          <div className="card bg-white border border-gray-300 rounded-md max-w-full mx-auto ">
            <div className="grid grid-cols-1 md:grid-cold-2 lg:grid-cols-6 ">
              <div className="border-r border-gray-300 lg:col-span-4 py-5">
                <div className="grid grid-cols-1 mg:grid-cols-2  mg:px-10 px-5">
                  <ul className="col-span-2">
                    {/* <li className="my-5 font-medium text-sm s:text-lg grid gap-2 grid-cols-2">
                      <span>status</span>
                      <span>Open (expires on June 30 2021)</span>
                    </li>
                    <li className="my-5 font-medium text-sm s:text-lg grid gap-2 grid-cols-2">
                      <span>Contract Title</span>
                      <span className="text-[#15a800]">
                        Social Media Manager
                      </span>
                    </li>
                    <li className="my-5 font-medium text-sm s:text-lg grid gap-2 grid-cols-2">
                      <span>Related Job Opening</span>
                      <span>Social Media Manager</span>
                    </li> */}
                    <li className="my-5 font-medium text-sm s:text-lg grid gap-2 grid-cols-2">
                      <span>Job Title</span>
                      <span>{viewOfferData?.profile_title}</span>
                    </li>
                    <li className="my-5 font-medium text-sm s:text-lg grid gap-2 grid-cols-2">
                      <span>Job Category</span>
                      <span>{viewOfferData?.job_categories}</span>
                    </li>
                    <li className="my-5 font-medium text-sm s:text-lg grid gap-2 grid-cols-2">
                      <span>Project Start Date</span>
                      <span>{viewOfferData?.project_start_date}</span>
                    </li>
                    <li className="my-5 font-medium text-sm s:text-lg grid gap-2 grid-cols-2">
                      <span>Project Expire Date</span>
                      <span>{viewOfferData?.project_expire_date}</span>
                    </li>
                    <li className="my-5 font-medium text-sm s:text-lg grid gap-2 grid-cols-2">
                      <span>Project Deadline</span>
                      <span>{viewOfferData?.project_deadline_date}</span>
                    </li>
                    <li className="my-5 font-medium text-sm s:text-lg grid gap-2 grid-cols-2">
                      <span>Project Fixted Price</span>
                      <span>₹ {viewOfferData?.minimum_price}</span>
                    </li>
                  </ul>
                </div>
                <hr className="border-b border-gray-300 mt-20" />

                <ul className="px-5 mg:px-10">
                  <li className="my-5 text-sm mg:text-lg grid gap-2 grid-cols-3">
                    <div className="font-medium">
                      <p>Fixed Rate</p>
                      <p className="text-base">
                        The total amount the client will see
                      </p>
                    </div>
                    <div className="flex justify-center items-center">
                      <p className="text-center">₹</p>
                    </div>
                    <div className="flex justify-center items-center">
                      <p className="text-center">
                        {viewOfferData?.minimum_price}
                      </p>
                    </div>
                  </li>
                  <hr className="border-b border-gray-300" />

                  <li className="my-5 text-sm mg:text-lg grid gap-2 grid-cols-3">
                    <div>
                      <p>
                        20% Hire Service Fee{" "}
                        <span className="text-[#15a800] font-medium">
                          Explain This
                        </span>
                      </p>
                    </div>
                    <div className="flex justify-center items-center">
                      <p className="text-center">₹</p>
                    </div>
                    <div className="flex justify-center items-center">
                      <p className="text-center">{totalVatAmount}</p>
                    </div>
                  </li>
                  <hr className="border-b border-gray-300" />

                  <li className="my-5 text-sm mg:text-lg grid gap-2 grid-cols-3">
                    <div>
                      <p className="font-medium">You'll payment</p>
                      <p className="text-base">
                        The estimated amount you'll pay after{" "}
                      </p>
                      <p className="text-base">Service fees</p>
                    </div>
                    <div className="flex justify-center items-center">
                      <p className="text-center">₹</p>
                    </div>
                    <div className="flex justify-center items-center">
                      <p className="text-center">{totalPaymentAmount}</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-span-2">
                <div className="w-full px-2 md:px-10 lg:px-10 py-5">
                  {HireConfirmDAta?.result?.jobId === parameter?.jobId ||
                  currentHireJobId === parameter?.jobId ? (
                    <Link to="/dashboard/client/jobs/inviteFreelancer">
                      <button className="rounded-full py-3 lg:py-2 w-full my-2 duration-300 ease-in-out bg-primary text-white hover:bg-[#f72ad1]">
                        Re Hire
                      </button>
                    </Link>
                  ) : (
                    <button
                      className="rounded-full py-3 lg:py-2 w-full my-2 duration-300 ease-in-out bg-primary text-white hover:bg-[#f72ad1]"
                      onClick={() => handelFreelancerHire(viewOfferData)}
                    >
                      Hire
                    </button>
                  )}
                  <button
                    className="rounded-full py-3 lg:py-2 w-full my-2 duration-300 ease-in-out hover:bg-primary text-primary hover:text-white border border-primary "
                    onClick={() => {
                      setSubMenuOpenAndClose(false);
                      setInviteMessageM(true);
                    }}
                  >
                    Message
                  </button>
                  <button className="rounded-full py-3 lg:py-2 w-full my-2 duration-300 ease-in-out hover:bg-primary text-primary hover:text-white border border-primary ">
                    Decline Request
                  </button>
                  <button
                    className="rounded-full py-3 lg:py-2 w-full my-2 duration-300 ease-in-out hover:bg-primary text-primary font-bold hover:text-white border border-primary "
                    onClick={() => {
                      setSubMenuOpenAndClose(false);
                      setHandelScheduleInterviewModal(true);
                    }}
                  >
                    Schedule Interview
                  </button>
                </div>
                <hr className="border-b border-gray-300 mb-10" />
                <div className="px-5 mg:px-10">
                  <div className="flex flex-col sm:flex-row justify-center sm:justify-start items-start gap-5">
                    <div className="avatar online">
                      <div className="w-16 rounded-full">
                        <img
                          src={`${serverImageUrl}/${viewOfferData?.profilePhoto}`}
                          alt="My-Logo"
                          className="rounded-full"
                        />
                      </div>
                    </div>
                    <div>
                      <h1 className="font-medium text-lg">
                        {viewOfferData?.firstName} {viewOfferData?.lastName}
                      </h1>
                      <p className="text-sm">{viewOfferData?.profile_title}</p>
                    </div>
                  </div>
                  {/* <h1 className="font-medium text-lg my-5">About the client</h1>

                <div className="my-5 flex justify-start items-center gap-5">
                  <button className="bg-[#15bbf0] hover:bg-[#0fa3d4] px-2 uppercase font-medium rounded text-white duration-300 ease-in-out">
                    Plus
                  </button>
                  <p className="text-[#15bbf0]">Hire puls client</p>
                </div> */}

                  {/* <div className="my-5 flex justify-start items-center gap-3">
                  <p className="w-5 h-5 p-2 rounded-full bg-[#15a800] text-white text-center flex justify-center items-center text-sm">
                    &#10003;
                  </p>
                  <p>Payment method verify</p>
                </div>

                <div className="my-5">
                  <p className="font-bold">Nauru</p>
                  <p>Yaren District :21 pm</p>
                </div> */}
                  {/* <div className="my-5">
                  <p className="font-bold">1 job posted</p>
                  <p>0% hire rate. 1 open job</p>
                </div> */}
                  {/* <div className="my-5">
                  <p>Member jun 23,2021</p>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalBody
        onClose={() => setInviteMessageM(false)}
        isVisible={inviteMessageM}
        customOpacityLayer="bg-opacity-0"
        customClass="card w-full mx-10 md:max-w-md md:mx-auto bg-[#fff] p-4"
        modalCloseCustomButtonAdd={true}
      >
        <div className="flex justify-center sm:justify-between items-start sm:flex-row flex-col-reverse  gap-5 md:gap-40 ">
          <div>
            <div className="flex flex-col sm:flex-row justify-center md:justify-start items-start gap-5">
              <div className="avatar online">
                <div className="w-16 rounded-full">
                  <img
                    src={`${serverImageUrl}/${currentPersonDAta?.profilePhoto}`}
                    alt="This is profile pic"
                    className="rounded-full"
                  />
                </div>
              </div>
              <div>
                <h1 className="font-medium text-lg">
                  {currentPersonDAta?.firstName} {currentPersonDAta?.lastName}
                </h1>
                <p className="text-sm">{currentPersonDAta?.profile_title}</p>
              </div>
            </div>
          </div>
          <button
            className="btn-secondary btn btn-sm btn-circle place-self-end sm:place-self-start"
            onClick={() => {
              setInviteMessageM(false);
              setCurrentPersonDAta({});
            }}
          >
            ✕
          </button>
        </div>
        <form className="px-3 mt-10 mb-5" onSubmit={handelSendMessage}>
          <input
            className="w-full py-2 px-5 rounded-lg focus:border-secondary outline-none border"
            placeholder="Type Your Message"
            name="sendMessage"
          />

          <div className="flex justify-end items-center">
            <button className="btn btn-sm bg-secondary mt-5 px-5" type="submit">
              Send
            </button>
          </div>
        </form>
      </ModalBody>

      <ModalBody
        onClose={() => setHandelScheduleInterviewModal(false)}
        isVisible={handelScheduleInterviewModal}
        customOpacityLayer="bg-opacity-0"
        customClass="card w-full md:max-w-xl md:mx-auto bg-[#fff] p-5"
        modalCloseCustomButtonAdd={false}
      >
        <div className="h-[550px] overflow-y-auto">
          <ScheduleInterview
            scheduleCandidate={scheduleCandidate}
            setHandelScheduleInterviewModal={setHandelScheduleInterviewModal}
          />
        </div>
      </ModalBody>
    </div>
  );
};

export default ViewOffer;
