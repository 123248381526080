import React, { useContext } from "react";
import InviteNavbar from "./InviteNavbar";

import WindowsLoadingSpinner from "../../../Shared/WindowsLoadingSpinner/WindowsLoadingSpinner";
import {
  AllSaveFreelancerDataCount,
  ClientInviteAllFreelancerDataCount,
} from "../../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const InviteJob = () => {
  const { currentUser } = useContext(globalFunctionOneContext);

  // get ClientInviteAllFreelancerDataCount data function
  const {
    data: clientInviteAllFreelancerDataCount,
    refetch: ClientInviteAllFreelancerDataCountRefetch,
    isLoading: clientInviteAllFreelancerDataCountLoading,
  } = ClientInviteAllFreelancerDataCount();

  // get AllSaveFreelancerData count data get function
  const {
    data: allSaveFreelancerDataCount,
    refetch: saveFreelancerDataRefetch,
    isLoading: allSaveFreelancerDataCountLoading,
  } = AllSaveFreelancerDataCount(currentUser?.PersonID);

  if (
    !currentUser ||
    clientInviteAllFreelancerDataCountLoading ||
    allSaveFreelancerDataCountLoading
  ) {
    return <WindowsLoadingSpinner />;
  }
  return (
    <div>
      <div className="card w-full bg-base-100 ">
        <div className="card-body p-2">
          <div className="border-2 rounded-lg border-gray-200 p-2">
            <InviteNavbar
              clientInviteAllFreelancerDataCount={
                clientInviteAllFreelancerDataCount
              }
              allSaveFreelancerDataCount={allSaveFreelancerDataCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteJob;
