import React, { useContext, useEffect, useState } from "react";
import ModalBody from "../ModalBody/ModalBody";
import {
  serverImageUrl,
  serverUrl,
} from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { GrSchedule } from "react-icons/gr";
import {
  BsCameraVideo,
  BsPlusSquareDotted,
  BsTelephoneInbound,
} from "react-icons/bs";
import { ImOffice } from "react-icons/im";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { SingleUserProfileGet } from "../../../hooks/useUser";
import { toast } from "react-toastify";

const ScheduleInterview = ({
  scheduleCandidate,
  setHandelScheduleInterviewModal,
}) => {
  const { currentUser, firebaseUserAccessToken } = useContext(
    globalFunctionOneContext
  );
  const [handelFreelancerAddOption, setHandelFreelancerAddOption] =
    useState(false);
  const [HandelDurationBtn, setHandelDurationBtn] = useState(false);
  const [handelDurationBtnValue, setHandelDurationBtnValue] =
    useState("30 minute");
  const [scheduleInterviewCandidate, setScheduleInterviewCandidate] = useState(
    []
  );
  const [scheduleInterviewAutoTsm, setScheduleInterviewAutoTsm] = useState({});
  const [LocalLoading, setLocalLoading] = useState(false);

  // get single freelancer user profile
  const { data, isLoading, error } = SingleUserProfileGet(
    currentUser?.PersonID
  );
  useEffect(() => {
    setLocalLoading(true);
    fetch(`${serverUrl}/client/schedule/interview/auto/assign/tsm`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === "success") {
          setScheduleInterviewAutoTsm(data?.data);
          setLocalLoading(false);
        }
      });
  }, [scheduleCandidate]);

  // handel Interview Date function
  const handelInterviewDate = (e) => {
    const interviewDate = e.target.value;
    const currentDate = new Date().toISOString().substr(0, 10);

    if (interviewDate < currentDate) {
      e.target.value = "";
    }
  };

  // handel Schedule Interview from
  const handelScheduleInterview = async (e) => {
    e.preventDefault();
    const from = e.target;
    const jobTitle = from.jobTitle.value;
    const interviewDate = from.interviewDate.value;
    const interviewTime = from.interviewTime.value;
    const interviewLink = from.interviewLink.value;
    const currentDate = new Date().toISOString().substr(0, 10);
    const reversedDate = interviewDate.split("-").reverse().join("-");

    if (interviewDate < currentDate) {
      from.interviewDate.value = "";
    }

    const userData = {
      jobTitle,
      interviewDuration: handelDurationBtnValue,
      interviewDate: reversedDate,
      interviewTime,
      interviewLink,
      candidate: [
        {
          candidateEmail: scheduleCandidate?.email,
          candidatePersonId: scheduleCandidate?.freelancerId
            ? scheduleCandidate?.freelancerId
            : scheduleCandidate?.PersonID,
        },
      ],
      createInterviewer: {
        createInterviewerEmail: currentUser.email,
        createInterviewerPersonId: currentUser?.PersonID,
        createInterviewErType: currentUser?.accountType,
      },
      interviewer: [
        {
          interviewerEmail: scheduleInterviewAutoTsm.email,
          interviewerPersonId: scheduleInterviewAutoTsm?.PersonID,
          interviewErType: scheduleInterviewAutoTsm?.accountType,
        },
      ],
    };
    const res = await fetch(`${serverUrl}/client/schedule/interview`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        uid: currentUser?.PersonID,
        token: firebaseUserAccessToken,
      },
      body: JSON.stringify(userData),
    });
    const data = await res.json();
    if (data?.status === "success") {
      setHandelScheduleInterviewModal(false);
      from.reset();
      toast.success("🦄 Schedule interview upload is successful!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error("🦄 Schedule interview upload is field", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // handel freelancer add input function
  const handelFreelancerAdd = async (e) => {
    e.preventDefault();
    const freelancerEmail = e.target.freelancerEmail.value;
    if (freelancerEmail) {
      setHandelFreelancerAddOption(false);
      console.log(freelancerEmail);
      const req = await fetch(
        `${serverUrl}/client/schedule/interview/candidateAdd?email=${freelancerEmail}`
      );
      const data = await req.json();
      if (data?.status === "success") {
        const fiendOldCandidate = scheduleInterviewCandidate.filter(
          (data) => data?.PersonID !== data?.data?.PersonID
        );
        const newData = [...fiendOldCandidate, data?.data];
        setScheduleInterviewCandidate(newData);
      }
    }
  };

  if (isLoading || !scheduleCandidate || LocalLoading) {
    return <div>loading ......</div>;
  }

  return (
    <div className="px-3">
      <div>
        <h3 className="font-bold text-xl flex gap-2 items-center">
          <GrSchedule /> Schedule interview
        </h3>
        <h3>
          <span className="font-bold text-xl mt-4">To: </span>
          <span className="uppercase bg-gray-100 rounded px-2 py-1">
            Mohit kumar
          </span>
        </h3>
        <h5 className="font-medium text-lg my-2">Candidate</h5>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          <div className="flex justify-start items-start gap-2 my-2">
            <div className="avatar">
              <div className="w-10 rounded">
                <img
                  src={`${serverImageUrl}/${scheduleCandidate?.profilePhoto}`}
                  alt="This is profile pic"
                  className="rounded"
                />
              </div>
            </div>

            <div>
              <h1 className="font-medium text-sm">
                {scheduleCandidate?.firstName} {scheduleCandidate?.lastName}
              </h1>
              <p className="text-sm">Candidate</p>
            </div>
          </div>
          {/* <div className="flex justify-center items-center gap-2 my-2">
            <BsPlusSquareDotted
              className="font-bold text-4xl cursor-pointer"
              onClick={() =>
                setHandelFreelancerAddOption(!handelFreelancerAddOption)
              }
            />
          </div> */}
          <div className="flex justify-center items-center gap-2 my-2">
            <BsPlusSquareDotted className="font-bold text-4xl cursor-pointer" />
          </div>
        </div>

        {handelFreelancerAddOption && (
          <form
            className="flex justify-start items-center gap-3"
            onSubmit={handelFreelancerAdd}
          >
            <input
              type="email"
              name="freelancerEmail"
              placeholder="Add your Candidate email"
              className="input input-bordered w-full outline-none border focus:outline-none focus:border-secondary"
            />
            <button type="submit" className="btn btn-sm bg-secondary px-5">
              Add
            </button>
          </form>
        )}

        <h5 className="font-medium text-lg my-2">Room</h5>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          <div className="flex justify-start items-start gap-2 my-2">
            <div className="avatar">
              <div className="w-10 rounded">
                <img
                  src={`${serverImageUrl}/${data?.result?.profilePhoto}`}
                  alt="This is profile pic"
                  className="rounded"
                />
              </div>
            </div>

            <div>
              <h1 className="font-medium text-sm">
                {currentUser?.firstName} {currentUser?.lastName}
              </h1>
              <p className="text-sm">Interviewer</p>
            </div>
          </div>

          <div className="flex justify-start items-start gap-2 my-2">
            <div className="avatar">
              <div className="w-10 rounded">
                <img
                  src={`${serverImageUrl}/${scheduleInterviewAutoTsm?.profilePhoto}`}
                  alt="This is profile pic"
                  className="rounded"
                />
              </div>
            </div>

            <div>
              <h1 className="font-medium text-sm">
                {scheduleInterviewAutoTsm?.firstName}{" "}
                {scheduleInterviewAutoTsm?.lastName}
              </h1>
              <p className="text-sm">Interviewer</p>
            </div>
          </div>

          <div className="flex justify-center items-center gap-2 my-2">
            <BsPlusSquareDotted className="font-bold text-4xl cursor-pointer" />
          </div>
        </div>

        <form onSubmit={handelScheduleInterview}>
          <div className="form-control w-full  ">
            <label className="label">
              <span className="label-text">Add Title</span>
            </label>
            <input
              type="text"
              name="jobTitle"
              placeholder="e.g First round interview with tech lead"
              className="input input-bordered w-full outline-none border focus:outline-none focus:border-secondary"
              required
            />

            <div>
              <h5 className="label-text-alt text-xl my-2">Duration</h5>
              <div className="btn-group btn-group-vertical lg:btn-group-horizontal">
                <button
                  className={`btn btn-secondary btn-outline ${
                    HandelDurationBtn ? "" : "btn-active"
                  }`}
                  onClick={() => {
                    setHandelDurationBtnValue("30 minute");
                    setHandelDurationBtn(false);
                  }}
                >
                  30 minute
                </button>
                <button
                  className={`btn btn-secondary btn-outline ${
                    HandelDurationBtn ? "btn-active" : ""
                  }`}
                  onClick={() => {
                    setHandelDurationBtnValue("60 minute");
                    setHandelDurationBtn(true);
                  }}
                >
                  60 minute
                </button>
              </div>
            </div>
            <div>
              <label className="label">
                <span className="label-text">Interview date & time</span>
              </label>
              <input
                type="date"
                name="interviewDate"
                placeholder="Select date"
                className="input input-bordered w-full outline-none border focus:outline-none focus:border-secondary"
                required
                onBlur={(e) => handelInterviewDate(e)}
              />

              <select
                name="interviewTime"
                className="select select-bordered w-full mt-4 outline-none border focus:outline-none focus:border-secondary"
                required
              >
                <option disabled value="" selected>
                  Pick a slot
                </option>
                <option value="9:00am">9:00am</option>
                <option value="9:30am">9:30am</option>
                <option value="10:00am">10:00am</option>
              </select>
            </div>
            <div>
              <h2 className="card-title my-3">Interview Type video call</h2>
              {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <button className="btn btn-secondary btn-outline btn-active  rounded-full flex flex-wrap items-center gap-2">
                  <span>
                    <BsCameraVideo className="text-xl" />
                  </span>
                  <span>Video call</span>
                </button>
                <button className="btn btn-outline rounded-full flex flex-wrap items-center gap-2 btn-secondary">
                  <span>
                    <BsTelephoneInbound className="text-xl" />
                  </span>
                  <span>Phone</span>
                </button>
                <button className="btn btn-outline rounded-full flex flex-wrap items-center gap-2 btn-secondary">
                  <span>
                    <ImOffice className="text-xl" />
                  </span>
                  <span>In-Office</span>
                </button>
              </div> */}
            </div>
            <div>
              <h2 className="card-title my-4">share video call link</h2>

              <input
                type="url"
                name="interviewLink"
                defaultValue="https://meet.google.com/ddf-mfko-upv"
                placeholder="e.g First round interview with tech lead"
                className="input input-bordered w-full outline-none border focus:outline-none focus:border-secondary"
              />
            </div>
          </div>
          <p className="mt-4">
            Unable to create & share video call link ?{" "}
            <span className="link text-accent">Need help</span>
          </p>
          <div className="mt-10 mb-5 flex justify-end items-center">
            <button
              type="submit"
              className="btn btn-secondary btn-sm px-4 btn-outline rounded-md"
            >
              <span>Send</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ScheduleInterview;
