import React, { useContext, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { MdOutlineDone } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import InterestedLocation from "./InterestedLocation";
import InterestedRole from "./InterestedRole";
import NoticePeriode from "./NoticePeriode";
import ProfileTitle from "./ProfileTitle";
import SalaryExpectation from "./SalaryExpectation";
import { auth } from "../../../../config/firebaseConfig/firebaseConfig";
import { serverUrl } from "../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const Intro = () => {
  const { confirmAlertValue, firebaseUser } = useContext(
    globalFunctionOneContext
  );
  const navigate = useNavigate();
  const [interestedRole, setInterestedRole] = useState([]);
  const [user] = useAuthState(auth);
  const PersonID = firebaseUser?.uid;

  // Freelancer Intro Form submit function
  const freelancerIntro = (e) => {
    e.preventDefault();
    const currentCtc = e.target.currentCtc.value;
    const fixedCtc = e.target.fixedCtc.value;
    const expectedCtc = e.target.expectedCtc.value;
    const muchExpectedCtc = e.target.muchExpectedCtc.value;
    const startedPeriod = e.target.startedPeriod.value;
    const longPeriod = e.target.longPeriod.value;
    const negotiablePeriod = e.target.negotiablePeriod.value;
    const currentLocation = e.target.currentLocation.value;
    const okaytoRemoteJob = e.target.okaytoRemoteJob.value;
    const desireCitiesWorking = e.target.desireCitiesWorking.value;
    const citiesNcr = e.target.citiesNcr.value;
    const profile_title = e.target.profile_title.value;
    const datas = {
      currentCtc,
      fixedCtc,
      expectedCtc,
      muchExpectedCtc,
      startedPeriod,
      longPeriod,
      negotiablePeriod,
      currentLocation,
      okaytoRemoteJob,
      desireCitiesWorking,
      citiesNcr,
      role: interestedRole,
      profile_title,
    };
    // console.log(datas, PersonID);
    if (
      !currentCtc ||
      !fixedCtc ||
      !expectedCtc ||
      !muchExpectedCtc ||
      !startedPeriod ||
      !longPeriod ||
      !negotiablePeriod ||
      !currentLocation ||
      !okaytoRemoteJob ||
      !desireCitiesWorking ||
      !citiesNcr ||
      !interestedRole ||
      !profile_title ||
      !PersonID
    ) {
      return;
    }
    console.log(datas, PersonID);

    fetch(`${serverUrl}/userProfile/freelancer-user-introduction`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        uid: PersonID,
      },
      body: JSON.stringify(datas),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          navigate("/user/profile/from/experience/freelancer/form");
        }
        console.log(data);
      });
  };
  return (
    <div className="my-10">
      <form onSubmit={freelancerIntro}>
        <InterestedRole setInterestedRole={setInterestedRole} />
        <ProfileTitle />
        <SalaryExpectation />
        <NoticePeriode />
        <InterestedLocation />
        <div className="flex justify-end mt-2">
          <button
            type="submit"
            className="btn btn-sm flex gap-2 items-center btn-primary"
          >
            <MdOutlineDone />
            <span>save</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Intro;
