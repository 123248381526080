import React, { useContext, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { RiFacebookCircleFill } from "react-icons/ri";
import { globalFunctionOneContext } from "../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const Social = ({ signInWithFacebook, setR }) => {
  const { signInWithGooglePopup } = useContext(globalFunctionOneContext);

  return (
    <div className="md:max-w-full sm:max-w-sm">
      <div className="flex gap-4 flex-wrap">
        <div className="flex-1">
          <button
            onClick={signInWithGooglePopup}
            className="btn btn-outline w-full btn-secondary gap-2 "
          >
            <span className="">
              <FcGoogle className="text-xl" />
            </span>
            <span>Google</span>
          </button>
        </div>
        <div className="flex-1">
          <button
            onClick={() => signInWithFacebook()}
            className="btn btn-outline btn-xl btn-primary w-full gap-2"
          >
            <span>
              <RiFacebookCircleFill className="text-xl" />
            </span>
            <span>Facebook</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Social;
