/* eslint-disable default-case */
import { signOut } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Swal from "sweetalert2";
import style from "./Navbar.module.css";
import { auth } from "../../config/firebaseConfig/firebaseConfig";
import LoginModal from "../Authontection/LoginModal/LoginModal";
import RegisterModal from "../Authontection/SingUp/RegisterModal";
import { globalFunctionOneContext } from "../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { BiMessageDetail } from "react-icons/bi";
import { MdNotificationsActive } from "react-icons/md";
import { serverImageUrl } from "../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { SingleUserProfileGet } from "../../hooks/useUser";
import { NavbarLogoShowAndHidden } from "../../hooks/NavbarAllFunction/useNavbarAllFunction";
import NotificationDrawer from "../Shared/NotificationDrawer/NotificationDrawer";
import Counter from "../TimeCounter/Counter/Counter";
import { Buffer } from "buffer";

const Navbar = ({ children }) => {
  const {
    currentUserAccountTypeFreelancer,
    currentUserAccountTypeClient,
    currentUser,
    navbarLogoShow,
    setNavbarLogoShow,
    userCurrentLocationPath,
    setUserCurrentLocationPath,
    windowLoading,
    clientMessageNotification,
    setClientMessageNotification,
    freelancerMessageNotification,
    setFreelancerMessageNotification,
    currentUserAllNotification,
    userNotificationLoading,
    userNotificationRefetch,
    handelNotificationCount,
    setHandelNotificationCount,
    setHandelCustomDrawerOpenCls,
    l,
    setL,
    r,
    setR,
    userCurrentLocationPathParameter,
  } = useContext(globalFunctionOneContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user] = useAuthState(auth);
  const [scroll, setScroll] = useState(0);
  const [navbarBackground, setNavbarBackground] = useState("");
  const [checkFreelancerRoute, setCheckFreelancerRoute] = useState(false);
  const [dashboard, setDashboardPath] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const parameter = useParams();
  NavbarLogoShowAndHidden(
    userCurrentLocationPath,
    userCurrentLocationPathParameter
  );
  const [handelNotificationDrawer, setHandelNotificationDrawer] =
    useState(false);

  const logout = () => {
    Swal.fire({
      title: "Are you sure logout your Account?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        signOut(auth);
        setL(!l);

        Swal.fire("Logout!", "Your Account has been Logout.", "success");
      }
    });
  };

  // get single freelancer user profile
  const { data, isLoading, error } = SingleUserProfileGet(
    currentUser?.PersonID
  );

  // if (data) {
  //   console.log(data.result, "single current user Data");
  // }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY);
    });

    if (location.pathname.includes("dashboard")) {
      setDashboardPath(true);
    } else {
      setDashboardPath(false);
    }

    if (location.pathname === "/") {
      setNavbarBackground(" bg-[#B242D9]");
    } else {
      setNavbarBackground("");
    }
    return () => {
      window.removeEventListener("scroll", () => {
        setScroll(window.scrollY);
      });
    };
  }, [location.pathname, dashboard, checkFreelancerRoute]);

  // handel new enw Notification
  useEffect(() => {
    if (currentUserAllNotification?.result) {
      const newNotification = currentUserAllNotification?.result.filter(
        (notification) => notification?.heHasSeen === "No"
      );
      setHandelNotificationCount(newNotification.length);
    }
  }, [currentUserAllNotification]);

  // common Navbar logo change function and check function
  useEffect(() => {
    setUserCurrentLocationPath(location?.pathname);
  }, [location?.pathname, setUserCurrentLocationPath]);

  // dropDown menu Icon
  const dropDownMenuIcon = (
    <>
      <svg
        className="fill-current mt-1"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
      >
        <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
      </svg>
    </>
  );

  // Navbar menu root menu list
  const navbarMenu = (
    <>
      {location.pathname === "/" && (
        <>
          <li className={style.drop_down_link_wrapper}>
            <span className="flex lg:justify-center justify-start">
              Services {dropDownMenuIcon}
            </span>
            <div className={style.drop_down_menu_container_wrapper}>
              <ul className={style.drop_down_menu_wrapper}>
                <li>
                  <a href="https://elite-professionals.in/shop/">
                    All Products
                  </a>
                </li>
                <li>
                  <a
                    href="https://elite-professionals.in/product/consulting-services/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Consulting Services
                  </a>
                </li>
                <li>
                  <a
                    href="https://elite-professionals.in/product/annual-maintenance-support-services/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Annual Maintenance & Support Services
                  </a>
                </li>
                <li>
                  <a
                    href="https://elite-professionals.in/product/it-infrastructure-consulting/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    IT Infrastructure Consulting
                  </a>
                </li>
                <li>
                  <a
                    href="https://elite-professionals.in/product/automation-training-support/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Automation Training & Support
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li className={style.drop_down_link_wrapper}>
            <span className="flex lg:justify-center justify-start">
              Browse Jobs
              {dropDownMenuIcon}
            </span>
            <div className={style.drop_down_menu_container_wrapper}>
              <ul className={style.drop_down_menu_wrapper}>
                <li>
                  <NavLink to="/freelancer/jobs">Job listing</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/">Job listing</NavLink>
                </li> */}
                {/* <li>
                  <NavLink to="/test">Job proposal</NavLink>
                </li> */}
              </ul>
            </div>
          </li>
          <li className={style.drop_down_link_wrapper}>
            <span className="flex lg:justify-center justify-start">
              Resources
              {dropDownMenuIcon}
            </span>
            <div className={style.drop_down_menu_container_wrapper}>
              <ul className={style.drop_down_menu_wrapper}>
                <li>
                  <NavLink to="/blog">Blog</NavLink>
                </li>
                {/*   <li>
                  <NavLink to="/career">Careers</NavLink>
                </li> */}
                {/*   <li>
                  <NavLink to="/">Tools</NavLink>
                </li> */}
              </ul>
            </div>
          </li>
          <li className={style.drop_down_link_wrapper}>
            <span>
              <Link
                to="/guest/mode/freelancer"
                className="flex lg:justify-center justify-start"
              >
                View Freelancers
              </Link>
            </span>
          </li>

          {/* <li className={style.drop_down_link_wrapper}>
            <a
              href="https://work.elite-professionals.in/how-it-works/"
              className="flex lg:justify-center justify-start">
              How it Work
            </a>
          </li> */}

          <li className={style.drop_down_link_wrapper}>
            <span>
              <a
                href="https://elite-professionals.in/contact-us/"
                className="flex lg:justify-center justify-start"
              >
                Help and Support
              </a>
            </span>
          </li>
          <li className={style.drop_down_link_wrapper}>
            <span className="flex lg:justify-center justify-start">
              About
              {dropDownMenuIcon}
            </span>
            <div className={style.drop_down_menu_container_wrapper}>
              <ul className={style.drop_down_menu_wrapper}>
                <li>
                  <a
                    href="https://work.elite-professionals.in/how-it-works"
                    target="_blank"
                    rel="noreferrer"
                  >
                    How it work{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="https://elite-professionals.in/about-us"
                    target="_blank"
                    rel="noreferrer"
                  >
                    The Company
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </>
      )}

      {!user ? (
        <>
          <li className={style.nav_bar_link_btn}>
            <label
              onClick={() => setL(!l)}
              htmlFor="my-modal-6"
              className="btn text-sm btn-sm  modal-button btn-secondary text-base-100"
            >
              Login
            </label>
          </li>
          <li className={style.nav_bar_link_btn}>
            <label
              onClick={() => setR(!r)}
              htmlFor="my-modal-7"
              className="btn btn-sm modal-button btn-primary text-base-100"
            >
              Register
            </label>
          </li>
        </>
      ) : (
        <>
          {" "}
        {location.pathname.includes("/dashboard/time-sheet-dashboad") && (
            <Counter />
          )}
          <li className={style.nav_bar_link_btn}>
            <label
              onClick={() => navigate("/dashboard")}
              className="btn btn-primary text-sm btn-sm  modal-button text-base-100"
            >
              Dashboard
            </label>
          </li>
          <li className={`${style.nav_bar_link_btn} relative`}>
            <BiMessageDetail
              className={`text-2xl ${
                scroll > 0
                  ? "text-gray-400"
                  : userCurrentLocationPath === "/"
                  ? "text-gray-200"
                  : "text-gray-800"
              } `}
            />
            {currentUserAccountTypeClient &&
              clientMessageNotification !== 0 && (
                <span className="absolute top-3 -right-1 bg-yellow-400 text-xs w-3 h-3 rounded-full text-center text-black flex justify-center items-center font-semibold">
                  {currentUserAccountTypeClient && (
                    <span>{clientMessageNotification}</span>
                  )}
                </span>
              )}
            {currentUserAccountTypeFreelancer &&
              freelancerMessageNotification !== 0 && (
                <span className="absolute top-3 -right-1 bg-yellow-400 text-xs w-3 h-3 rounded-full text-center text-black flex justify-center items-center font-semibold">
                  {freelancerMessageNotification && (
                    <span>{freelancerMessageNotification}</span>
                  )}
                </span>
              )}
          </li>
          <li className={`${style.nav_bar_link_btn} relative cursor-pointer`}>
            <label
              htmlFor="NotificationDrawer"
              className="cursor-pointer"
              onClick={() =>
                setHandelNotificationDrawer(!handelNotificationDrawer)
              }
            >
              <MdNotificationsActive
                className={`text-2xl ${
                  scroll > 0 ? "text-gray-400" : "text-gray-800"
                } `}
              />

              <span className="absolute top-3 -right-1 bg-yellow-400 text-xs w-3 h-3 rounded-full text-center text-black flex justify-center items-center font-semibold">
                <span>{handelNotificationCount}</span>
              </span>
            </label>
          </li>
        </>
      )}

      {data?.result && (
        <div className="dropdown dropdown-end bg-blue-300 rounded-full">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
            <div className="w-10 h-10 rounded-full mt-1">
              {user?.photoURL || data?.result?.profilePhoto ? (
                <img
                  src={
                    user.photoURL
                      ? `${serverImageUrl}/${user?.photoURL}`
                      : `data:${data.result.fileType};base64,${Buffer.from(
                          data.result.profilePhoto
                        ).toString("base64")}`
                  }
                  alt="user profile picturer"
                />
              ) : (
                <div className="flex justify-center items-center">
                  <span className="rounded-xl text-xl font-bold text-secondary">
                    {currentUser?.firstName?.slice(0, 2).toUpperCase()}
                  </span>
                </div>
              )}
            </div>
          </label>

          <ul
            tabIndex={0}
            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li>
              <Link to="/dashboard/complete_profile">Profile</Link>
            </li>
            <li>
              <Link to="/dashboard/settings">Setting</Link>
            </li>
            <li>
              <Link onClick={logout}>Logout</Link>
            </li>
          </ul>
        </div>
      )}
    </>
  );

  // dashboard NavbarMenu List
  const dashboardNavbarMenu = (
    <>
      <li className={style.drop_down_link_wrapper}>
        <span className="flex lg:justify-center justify-start">
          Find Work {dropDownMenuIcon}
        </span>
        <div className={style.drop_down_menu_container_wrapper}>
          <ul className={style.drop_down_menu_wrapper}>
            <li>
              <NavLink to="/freelancer/jobs">Find Works</NavLink>
            </li>
            <li>
              <Link to="/dashboard/my/save/jobs">Saved jobs</Link>
            </li>
            <li>
              <a>Proposals</a>
            </li>
          </ul>
        </div>
      </li>
      <li className={style.drop_down_link_wrapper}>
        <span className="flex lg:justify-center justify-start">
          My Jobs {dropDownMenuIcon}
        </span>
        <div className={style.drop_down_menu_container_wrapper}>
          <ul className={style.drop_down_menu_wrapper}>
            <li>
              <Link to="/dashboard/my_jobs">My Jobs</Link>
            </li>
            <li>
              <Link to="/dashboard/my_jobs/contract">All Contracts</Link>
            </li>
            <li>
              <Link to="/dashboard/my/Job/proposal">Job proposal</Link>
            </li>
          </ul>
        </div>
      </li>
      <li className={style.drop_down_link_wrapper}>
        <span className="flex lg:justify-center justify-start">
          Reports {dropDownMenuIcon}
        </span>
        <div className={style.drop_down_menu_container_wrapper}>
          <ul className={style.drop_down_menu_wrapper}>
            <li>
              <a>My reviews</a>
            </li>
            <li>
              <a>Billing and Earnings</a>
            </li>
            <li>
              <a>Transaction History</a>
            </li>
          </ul>
        </div>
      </li>
    </>
  );

  return (
    <>
      <div className="relative">
        <header
          // className={`sticky top-0 z-30  flex h-16 w-full justify-center text-base-content  transition-all duration-100 ${
          //   scroll > 0
          //     ? "bg-opacity-90"
          //     : `${navbarBackground} bg-base-100 backdrop-blur`
          // }`}
          className={`sticky top-0 z-30  flex h-16 w-full justify-center text-base-content  transition-all duration-100 ${
            scroll > 0 ? "bg-base-100 backdrop-blur" : `${navbarBackground} `
          }`}
        >
          <div className="relative  w-full">
            <div className="px-4 py-3 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xxl md:px-24 lg:px-8 ">
              <div className=" flex items-center justify-between">
                <div className="lg:hidden">
                  <button
                    aria-label="Open Menu"
                    title="Open Menu"
                    className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
                    onClick={() => setIsMenuOpen(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h7"
                      />
                    </svg>
                  </button>
                </div>
                {navbarLogoShow && !(location.pathname === "/") && (
                  <Link to="/" className="lg:inline-flex items-center hidden">
                    <svg
                      className="w-8 text-teal-accent-400"
                      viewBox="0 0 24 24"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      stroke="currentColor"
                      fill="none"
                    >
                      <rect x="3" y="1" width="7" height="12" />
                      <rect x="3" y="17" width="7" height="6" />
                      <rect x="14" y="1" width="7" height="6" />
                      <rect x="14" y="11" width="7" height="12" />
                    </svg>
                    <span className="ml-2 text-xl font-bold tracking-wide uppercase">
                      Hire
                    </span>
                  </Link>
                )}
                {location.pathname === "/" && (
                  <Link to="/" className="lg:inline-flex items-center hidden">
                    <svg
                      className="w-8 text-teal-accent-400"
                      viewBox="0 0 24 24"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      stroke="currentColor"
                      fill="none"
                    >
                      <rect x="3" y="1" width="7" height="12" />
                      <rect x="3" y="17" width="7" height="6" />
                      <rect x="14" y="1" width="7" height="6" />
                      <rect x="14" y="11" width="7" height="12" />
                    </svg>
                    <span className="ml-2 text-xl font-bold tracking-wide uppercase">
                      Hire
                    </span>
                  </Link>
                )}
                {dashboard && (
                  <>
                    <div className="lg:inline-flex items-center hidden">
                      <label
                        // tabIndex={0}
                        htmlFor="my-drawer-2"
                        className="btn btn-ghost btn-circle"
                        // onClick={() => setOpenAndCloseDrawer(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h7"
                          />
                        </svg>
                      </label>
                    </div>
                    <>
                      {currentUserAccountTypeFreelancer && (
                        <ul
                          className={`flex items-center hidden space-x-8 lg:flex ${style.menu_container_wrapper} mr-auto`}
                        >
                          {dashboardNavbarMenu}
                        </ul>
                      )}
                    </>
                  </>
                )}
                <ul
                  className={`flex items-center hidden space-x-8 lg:flex ${style.menu_container_wrapper}`}
                >
                  {navbarMenu}
                </ul>
                <div className="lg:hidden">
                  <Link to="/" className="inline-flex items-center">
                    <svg
                      className="w-8 text-teal-accent-400"
                      viewBox="0 0 24 24"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      stroke="currentColor"
                      fill="none"
                    >
                      <rect x="3" y="1" width="7" height="12" />
                      <rect x="3" y="17" width="7" height="6" />
                      <rect x="14" y="1" width="7" height="6" />
                      <rect x="14" y="11" width="7" height="12" />
                    </svg>
                    <span className="ml-2 text-xl font-bold tracking-wide uppercase">
                      Hire
                    </span>
                  </Link>
                  {isMenuOpen && (
                    <div
                      className={`${style.sm_navbar_menu_wraper} p-5 rounded-r shadow-s`}
                    >
                      <div className={style.sm_navbar_menu_container}>
                        <button
                          className="transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline text-center absolute top-2 right-2 flex justify-center items-center p-1 z-10"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          <svg
                            className="w-5 text-gray-300"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                            />
                          </svg>
                        </button>
                        <div className={style.sm_navbar_menu_container}>
                          <nav className="mt-5">
                            <ul
                              className={`space-y-4 ${style.mobile_menu_content_wraper}`}
                            >
                              {navbarMenu}
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>

        {/* This is Notification Drawer component start  */}
        {handelNotificationDrawer && <NotificationDrawer />}

        {/* This is web body all component includes this div  */}
        <div
          id="mainBodyWrapperContainerId"
          onClick={(e) => {
            setHandelNotificationDrawer(false);
            if (e.target.id === "mainBodyWrapperContainerId") {
              setHandelCustomDrawerOpenCls(false);
            }
          }}
        >
          {children}
        </div>
      </div>
      {/* This is Register and login Modal component */}
      {l && <LoginModal setL={setL} l={l} />}
      {r && <RegisterModal setR={setR} r={r} />}
    </>
  );
};

export default Navbar;
