import React from "react";

const PostJobModalHeading = ({ headingText }) => {
  return (
    <h2 className="card-title mt-5 mb-4">
      <div className="divider divider-horizontal mr-0 ml-1"></div>
      {headingText}
    </h2>
  );
};

export default PostJobModalHeading;
