import React, { useContext, useEffect, useState } from "react";
import style from "../clientProfile.module.css";
import { serverUrl } from "../../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { globalFunctionOneContext } from "../../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { useNavigate } from "react-router-dom";
import { SingleUserProfileGet } from "../../../../../hooks/useUser";
import {
  CommonFormBackBtn,
  CommonFormSubmitBtn,
} from "../../../../Shared/AllCommonButton/AllCommonButton";

const Details = () => {
  const { firebaseUser, currentUser, databaseSingleUserRefetch } = useContext(
    globalFunctionOneContext
  );
  const [registerType, setRegisterType] = useState(false);
  const [companyDetailsRadioValue, setCompanyDetailsRadioValue] = useState("");
  const [numberOfEmployeesValue, setNumberOfEmployeesValue] = useState("");
  const navigate = useNavigate();

  // get single freelancer user profile
  const { data, isLoading, error, refetch } = SingleUserProfileGet(
    currentUser?.PersonID
  );

  // default data set
  useEffect(() => {
    setCompanyDetailsRadioValue(data?.result?.companyDetailsRadioValue);
    setNumberOfEmployeesValue(data?.result?.numberOfEmployeesValue);
    setRegisterType(data?.result?.registerType);
  }, [data]);

  const typeHandler = (e) => {
    const value = e.target.value;
    console.log(value);
    if (value === "On Behalf of Company/Business") {
      console.log(value);
      setRegisterType(true);
    } else {
      setRegisterType(false);
    }
  };

  // handelClientPersonalDitails
  const handelClientPersonalDetails = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData();
    const firstName = form.firstName.value;
    const lastName = form.lastName.value;
    const description = form.description.value;
    const email = form.email.value;
    const contact = form.description.value;
    const profilePhoto = form.profilePhoto.files[0];
    const UploadIdentity = form.UploadIdentity.files[0];
    if (email === currentUser?.email) {
      if (registerType) {
        const displayName = form.displayName.value;
        const companyName = form.companyName.value;
        const registrationNumberCIN = form.registrationNumberCIN.value;
        const GSTIN = form.GSTIN.value;
        const companyUrl = form.companyUrl.value;
        const tagLine = form.tagLine.value;
        const addressRegisteredOffice = form.addressRegisteredOffice.value;
        const companyEmail = form.companyEmail.value;
        const registerType = form.registerType.value;
        const companyLogo = form.companyLogo.files[0];

        formData.append("description", description);
        formData.append("contact", contact);
        formData.append("displayName", displayName);
        formData.append("companyName", companyName);
        formData.append("registrationNumberCIN", registrationNumberCIN);
        formData.append("GSTIN", GSTIN);
        formData.append("companyUrl", companyUrl);
        formData.append("tagLine", tagLine);
        formData.append("addressRegisteredOffice", addressRegisteredOffice);
        formData.append("companyEmail", companyEmail);
        formData.append("registerType", registerType);
        formData.append("companyDetailsRadioValue", companyDetailsRadioValue);
        formData.append("numberOfEmployeesValue", numberOfEmployeesValue);
        formData.append("profilePhoto", profilePhoto);
        formData.append("companyLogo", companyLogo);
        formData.append("UploadIdentity", UploadIdentity);

        fetch(`${serverUrl}/userProfile`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            uid: firebaseUser?.uid,
          },
          body: JSON.stringify({
            PersonID: currentUser?.PersonID,
            accountType: currentUser?.accountType,
            firstName,
            lastName,
            country: currentUser?.country,
            email: currentUser?.email,
            password: currentUser?.password,
            rules: currentUser?.rules,
            username: currentUser?.username,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data?.status === "success") {
              databaseSingleUserRefetch();
            }
          });
        // Make the server request using formData
        const res = await fetch(
          `${serverUrl}/client-user-profile/personal-details`,
          {
            method: "POST",
            headers: { Accept: "multipart/form-data", uid: firebaseUser?.uid },
            Credential: "include",
            body: formData,
          }
        );
        const data = await res.json();

        if (data?.status === "success") {
          form.reset();
          refetch();
          navigate("/user/profile/from/social/client/form");
        }
      } else {
        const address = form.address.value;
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("address", address);
        formData.append("description", description);
        formData.append("contact", contact);
        formData.append("registerType", registerType);
        formData.append("companyDetailsRadioValue", companyDetailsRadioValue);
        formData.append("numberOfEmployeesValue", numberOfEmployeesValue);
        formData.append("profilePhoto", profilePhoto);
        formData.append("UploadIdentity", UploadIdentity);

        // Make the server request using formData
        const res = await fetch(
          `${serverUrl}/client-user-profile/personal-details`,
          {
            method: "POST",
            headers: { Accept: "multipart/form-data", uid: firebaseUser?.uid },
            Credential: "include",
            body: formData,
          }
        );
        const data = await res.json();

        if (data?.status === "success") {
          form.reset();
          refetch();
          navigate("/user/profile/from/social/client/form");
        }
      }
    }
  };

  const registerInputFiltArray = [
    {
      id: 1,
      inputName: "displayName",
      inputType: "text",
      placeholder: "Display Name",
    },
    {
      id: 2,
      inputName: "companyName",
      inputType: "text",
      placeholder: "Company Name",
    },
    {
      id: 3,
      inputName: "registrationNumberCIN",
      inputType: "text",
      placeholder: "CIN/ Registration Number",
    },
    {
      id: 4,
      inputName: "companyUrl",
      inputType: "url",
      placeholder: "Company Url",
    },
    {
      id: 5,
      inputName: "GSTIN",
      inputType: "text",
      placeholder: "GSTIN",
    },

    {
      id: 6,
      inputName: "tagLine",
      inputType: "text",
      placeholder: "Tag Line",
    },
    {
      id: 7,
      inputName: "addressRegisteredOffice",
      inputType: "text",
      placeholder: "Address Of Registered Office",
    },
    {
      id: 8,
      inputName: "companyEmail",
      inputType: "email",
      placeholder: "Company Email",
    },
  ];

  /// companyDetailsRadio array
  const companyDetailsArray = [
    { id: 1, radioLabelText: "Accounting and Finance" },
    { id: 2, radioLabelText: "Customer Service or Operations" },
    { id: 3, radioLabelText: "Engineering Or Product Management" },
    { id: 4, radioLabelText: "Human Resource Management" },
    { id: 5, radioLabelText: "Marketing" },
    { id: 6, radioLabelText: "Production" },
    { id: 7, radioLabelText: "Purchasing" },
    { id: 8, radioLabelText: "Research and Development" },
    { id: 9, radioLabelText: "Sales" },
  ];

  // Number of employees
  const NumberOfEmployeesArray = [
    {
      id: 1,
      radioLabelText: "It's Just Me",
    },
    {
      id: 2,
      radioLabelText: "10 - 99",
    },
    {
      id: 3,
      radioLabelText: "100 - 499 Employees",
    },
    {
      id: 4,
      radioLabelText: "500 - 1000 Employees",
    },
    {
      id: 5,
      radioLabelText: "More Than 1000",
    },
  ];

  if (isLoading) {
    return <h1>Thsi is Loading ..........</h1>;
  }

  return (
    <div className="">
      <h2>Your Details</h2>
      <form onSubmit={handelClientPersonalDetails}>
        <div className="grid grid-cols-2 mt-10 gap-3">
          <select
            onChange={typeHandler}
            name="registerType"
            defaultValue={data?.result?.registerType}
            className="col-span-2 select select-bordered w-full"
          >
            <option disabled value="" defaultChecked>
              Register type ?
            </option>
            <option value="On Behalf of Company/Business">
              On Behalf of Company/Business
            </option>
            <option value="As an individual/Proprietor">
              As an individual/Proprietor
            </option>
          </select>
          <div className="form-control w-full">
            <input
              type="text"
              name="firstName"
              placeholder={`First Name ${
                registerType ? "of Owner" : "(As per AADHAR Card)"
              } `}
              defaultValue={currentUser?.firstName}
              className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc]"
            />
          </div>
          <div className="form-control w-full">
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              defaultValue={currentUser.lastName}
              className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc]"
            />
          </div>
          <div className="form-control w-full">
            <input
              type="email"
              name="email"
              value={currentUser?.email}
              placeholder="email"
              className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc]"
            />
          </div>
          {!registerType && (
            <div className="form-control w-full">
              <input
                type="text"
                name="address"
                placeholder="address"
                defaultValue={data?.result?.address}
                className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc]"
              />
            </div>
          )}

          {registerType && (
            <>
              {registerInputFiltArray.map((inputData) => (
                <div
                  key={inputData?.id}
                  className={`form-control w-full  ${
                    inputData?.id === 4 ? "col-span-2" : ""
                  }`}
                >
                  <input
                    type={inputData?.inputType}
                    name={inputData?.inputName}
                    placeholder={inputData?.placeholder}
                    defaultValue={data?.result[inputData?.inputName]}
                    className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc]"
                    required
                  />
                </div>
              ))}
            </>
          )}

          <div className="form-control w-full">
            <input
              type="text"
              name="contact"
              defaultValue={data?.result?.contact}
              placeholder={`${registerType ? "Company" : ""} Contact`}
              className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc]"
            />
          </div>

          <div className="form-control w-full">
            <input
              type="text"
              name="description"
              defaultValue={data?.result?.description}
              placeholder="Description"
              className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc]"
            />
          </div>
          <div className="form-control w-full col-span-2">
            <label className="label">
              <span className="label-text">Profile Photo</span>
            </label>
            <input
              type="file"
              name="profilePhoto"
              className="file-input file-input-bordered w-full"
            />
          </div>
          {registerType ? (
            <div className="form-control w-full col-span-2">
              <label className="label">
                <span className="label-text">Company Logo</span>
              </label>
              <input
                type="file"
                name="companyLogo"
                className="file-input file-input-bordered w-full"
              />
            </div>
          ) : (
            ""
          )}

          <div className="form-control w-full col-span-2">
            <label className="label">
              <span className="label-text">Upload Identity</span>
            </label>
            <input
              type="file"
              name="UploadIdentity"
              className="file-input file-input-bordered w-full"
            />
          </div>

          <div className="col-span-2">
            <h2> Company details </h2>
          </div>
          <div>
            <h2>Your Department?</h2>
            <div className="flex flex-col gap-2">
              {companyDetailsArray &&
                companyDetailsArray.map((radio) => (
                  <div className="form-control" key={radio?.id}>
                    <label
                      className="flex gap-2 cursor-pointer"
                      onClick={() =>
                        setCompanyDetailsRadioValue(radio?.radioLabelText)
                      }
                    >
                      <input
                        type="radio"
                        name="companyDetails"
                        className="radio radio-sm checked:bg-red-500"
                        checked={
                          radio?.radioLabelText === companyDetailsRadioValue
                        }
                      />
                      <span className="label-text">
                        {radio?.radioLabelText}
                      </span>
                    </label>
                  </div>
                ))}

              {/*
              <div className="form-control">
                <label className="flex gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="companyDetails"
                    className="radio radio-sm checked:bg-red-500"
                  />
                  <span className="label-text">Accounting and Finance</span>
                </label>
              </div>
              <div className="form-control">
                <label className="flex gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="companyDetails"
                    className="radio radio-sm checked:bg-red-500"
                  />
                  <span className="label-text">
                    Customer Service or Operations
                  </span>
                </label>
              </div>
              <div className="form-control">
                <label className="flex gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="companyDetails"
                    className="radio radio-sm checked:bg-red-500"
                  />
                  <span className="label-text">
                    Engineering Or Product Management
                  </span>
                </label>
              </div>
              <div className="form-control">
                <label className="flex gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="companyDetails"
                    className="radio radio-sm checked:bg-red-500"
                  />
                  <span className="label-text">Human Resource Management</span>
                </label>
              </div>
              <div className="form-control">
                <label className="flex gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="companyDetails"
                    className="radio radio-sm checked:bg-red-500"
                  />
                  <span className="label-text">Marketing</span>
                </label>
              </div>
              <div className="form-control">
                <label className="flex gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="companyDetails"
                    className="radio radio-sm checked:bg-red-500"
                  />
                  <span className="label-text">Production</span>
                </label>
              </div>
              <div className="form-control">
                <label className="flex gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="companyDetails"
                    className="radio radio-sm checked:bg-red-500"
                  />
                  <span className="label-text">Purchasing</span>
                </label>
              </div>
              <div className="form-control">
                <label className="flex gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="companyDetails"
                    className="radio radio-sm checked:bg-red-500"
                  />
                  <span className="label-text">Research and Development</span>
                </label>
              </div>
              <div className="form-control">
                <label className="flex gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="companyDetails"
                    className="radio radio-sm checked:bg-red-500"
                  />
                  <span className="label-text">Sales</span>
                </label>
              </div> */}
            </div>
          </div>

          <div>
            <h2>No. of employees you have</h2>
            <div className="flex flex-col gap-2">
              {NumberOfEmployeesArray &&
                NumberOfEmployeesArray.map((radio) => (
                  <div className="form-control" key={radio?.id}>
                    <label
                      className="flex gap-2 cursor-pointer"
                      onClick={() =>
                        setNumberOfEmployeesValue(radio?.radioLabelText)
                      }
                    >
                      <input
                        type="radio"
                        name="radio-9"
                        checked={
                          radio?.radioLabelText === numberOfEmployeesValue
                        }
                        className="radio radio-sm checked:bg-red-500"
                      />
                      <span className="label-text">
                        {radio?.radioLabelText}
                      </span>
                    </label>
                  </div>
                ))}

              {/* <div className="form-control">
                <label className="flex gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="radio-9"
                    className="radio radio-sm checked:bg-red-500"
                  />
                  <span className="label-text">It's Just Me</span>
                </label>
              </div>
              <div className="form-control">
                <label className="flex gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="radio-9"
                    className="radio radio-sm checked:bg-red-500"
                  />
                  <span className="label-text">It's Just Me</span>
                </label>
              </div>
              <div className="form-control">
                <label className="flex gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="radio-9"
                    className="radio radio-sm checked:bg-red-500"
                  />
                  <span className="label-text">10 - 99</span>
                </label>
              </div>
              <div className="form-control">
                <label className="flex gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="radio-9"
                    className="radio radio-sm checked:bg-red-500"
                  />
                  <span className="label-text">100 - 499 Employees</span>
                </label>
              </div>
              <div className="form-control">
                <label className="flex gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="radio-9"
                    className="radio radio-sm checked:bg-red-500"
                  />
                  <span className="label-text">500 - 1000 Employees</span>
                </label>
              </div>
              <div className="form-control">
                <label className="flex gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="radio-9"
                    className="radio radio-sm checked:bg-red-500"
                  />
                  <span className="label-text">More Than 1000</span>
                </label>
              </div> */}
            </div>
          </div>
        </div>
        {/* submit & Back button wraper  */}
        <div className="flex justify-end items-center w-full mt-10">
          <div>
            <CommonFormBackBtn
              type={"button"}
              BtnText={"Back"}
              className={"mr-5 px-10 py-3 text-sm"}
            />
            <CommonFormSubmitBtn
              type={"submit"}
              BtnText={"Save & Update"}
              className={"px-3 py-3 text-sm"}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Details;
