/* eslint-disable default-case */
import React, { useEffect, useState, createContext } from "react";
import {
  chatApplicationSocketUrl,
  serverUrl,
} from "../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../config/firebaseConfig/firebaseConfig";
import { useQuery } from "react-query";
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  CurrentUserAllFriendListGet,
  SendCredentialProfileSendDatabase,
} from "../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import { io } from "socket.io-client";

export const globalFunctionOneContext = createContext();
const GlobalFunctionProviderOne = ({ children }) => {
  // ===================================
  //  All Usestate handel start
  // ===================================

  const [firebaseUser, setFirebaseUser] = useState(null);
  const [firebaseUserAccessToken, setFirebaseUserAccessToken] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserAccountTypeClient, setCurrentUserAccountTypeClient] =
    useState(false);
  const [
    currentUserAccountTypeFreelancer,
    setCurrentUserAccountTypeFreelancer,
  ] = useState(false);
  const [
    currentUserAccountTypeTalentManager,
    setCurrentUserAccountTypeTalentManager,
  ] = useState(false);
  const [navbarLogoShow, setNavbarLogoShow] = useState(true);
  const [userCurrentLocationPath, setUserCurrentLocationPath] = useState("");
  const [
    userCurrentLocationPathParameter,
    setUserCurrentLocationPathParameter,
  ] = useState({});
  const [fileDropLimitNumber, setFileDropLimitNumber] = useState(2);
  const [multiFileList, setMultiFileList] = useState([]);
  const [multiFileListCount, setMultiFileListCount] = useState(0);
  const [oneFile, setOneFile] = useState(null);
  const [imagesUrlLocalHost, setImagesUrlLocalHost] = useState([]);
  const [imageUrlLocalHost, setImageUrlLocalHost] = useState("");
  const [windowLoading, setWindowLoading] = useState(true);
  const [confirmAlertValue, setConfirmAlertValue] = useState(true);
  const [clientMessageNotification, setClientMessageNotification] = useState(0);
  const [freelancerMessageNotification, setFreelancerMessageNotification] =
    useState(0);
  const [project_start_date, setProject_start_date] = useState("");
  const [project_expire_date, setProject_expire_date] = useState("");
  const [project_deadline_date, setProject_deadline_date] = useState("");
  const [isValidDate, setIsValidDate] = useState({
    error: false,
    errorMessages: "",
    errorNumber: 0,
  });
  const [handelFreelancerDetailsInfo, setHandelFreelancerDetailsInfo] =
    useState({});
  const [
    GuestModeFreelancerDetailsPersonId,
    setGuestModeFreelancerDetailsPersonId,
  ] = useState(0);
  const [handelNotificationCount, setHandelNotificationCount] = useState(0);
  const [handelCustomDrawerOpenCls, setHandelCustomDrawerOpenCls] =
    useState(false);
  // console.log(currentUser?.PersonID);

  // server data fetch & Update & Delete & post & Put state
  const [serverDataPutParamsAllData, setServerDataPutParamsAllData] =
    useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [l, setL] = useState(false);
  const [r, setR] = useState(false);
  const [credentialUser, setCredentialUser] = useState(null);
  const [socialAccountChecker, setSocialAccountChecker] = useState(false);
  // socket implemest usestate
  const [socketIo, setSocketIo] = useState(null);
  // user online or offline tracking function
  const [onlineUsers, setOnlineUsers] = useState([]);

  // ===================================
  //   All user authentacion start
  // ===================================
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/calendar");

  const {
    data: databaseCredentialUser,
    refetch: databaseCredentialUserRefetch,
    isLoading: databaseCredentialUserLoading,
    error: databaseCredentialUserError,
  } = SendCredentialProfileSendDatabase(credentialUser, firebaseUser?.uid);

  useEffect(() => {
    if (databaseCredentialUserLoading) {
      setWindowLoading(true);
    } else {
      setWindowLoading(false);
    }
  }, [databaseCredentialUserLoading]);

  const socialAccountProfileSendingDatabase = (user) => {
    fetch(`${serverUrl}/userProfile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // uid: PersonID,
      },
      body: JSON.stringify({
        PersonID: user?.uid,
        accountType: "freelancer",
        firstName: user?.displayName,
        lastName: "",
        country: "",
        email: user?.email,
        password: "",
        rules: "agree",
        username: user?.displayName,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          databaseCredentialUserRefetch();
        } else {
          signOut(auth);
        }
      });
  };

  // user login with googel function

  const signInWithGooglePopup = async () => {
    setWindowLoading(true);
    setError(null);
    try {
      const currentUser = await signInWithPopup(auth, provider);
      const credentialUser = await GoogleAuthProvider.credentialFromResult(
        currentUser
      );
      if (currentUser && credentialUser) {
        socialAccountProfileSendingDatabase(currentUser?.user);

        setCredentialUser(credentialUser);
        setR(false);
        navigate("/");
        setWindowLoading(false);
      }

      setWindowLoading(false);
    } catch (error) {
      setError(error);
      setWindowLoading(false);
    }
  };

  // ===================================
  //   All user authentacion End
  // ===================================

  // ===================================
  //   All Usestate handel End
  // ===================================

  // ==================================================
  //   All Function and useEffect handel start
  // ==================================================

  // firebase user tracking function
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseCurrentUser) => {
      setFirebaseUser(firebaseCurrentUser);
      if (firebaseCurrentUser) {
        firebaseCurrentUser
          .getIdToken()
          .then((accessToken) => {
            setFirebaseUserAccessToken(accessToken);
          })
          .catch((error) => {
            console.error("Error getting access token:", error);
          });
      }
      setWindowLoading(false);
    });
    return () => unsubscribe();
  }, []);

  // Get Database user fetch function
  const {
    data: databaseUser,
    isLoading,
    refetch: databaseSingleUserRefetch,
  } = useQuery({
    queryKey: ["user", firebaseUser],
    queryFn: async () => {
      if (firebaseUser) {
        const res = await fetch(
          `${serverUrl}/userProfile/singleUser/${firebaseUser?.uid}`,
          {
            headers: {
              "Content-Type": "application/json",
              // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            },
          }
        );
        const data = await res.json();
        return data;
      }
    },
  });

  // send Notification from server
  const sendNotification = async (payload) => {
    // const userData = {
    //   receiverNotificationPersonId: data?.PersonID,
    //   senderNotificationPersonId: currentUser?.PersonID,
    //   senderProfilePicture: data?.result?.profilePhoto,
    //   senderName: currentUser?.firstName + currentUser?.lastName,
    //   NotificationContent: "your selected and join my company",
    //   NotificationType: "Hire",
    //   heHasSeen: "No",
    // };

    const res = await fetch(`${serverUrl}/userProfile/send/notification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const data = await res.json();
    console.log(data);
    return data;
  };

  // All user Notification get function
  const {
    data: currentUserAllNotification = {},
    isLoading: userNotificationLoading,
    refetch: userNotificationRefetch,
  } = useQuery({
    queryKey: ["user", currentUser],
    queryFn: async () => {
      if (currentUser) {
        const res = await fetch(`${serverUrl}/userProfile/all/notification`, {
          headers: {
            "Content-Type": "application/json",
            uid: currentUser?.PersonID,
            // authorization: `bearer ${localStorage.getItem("access_Token")}`,
          },
        });
        const data = await res.json();
        return data;
      }
    },
  });

  // get the user type function
  useEffect(() => {
    if (databaseUser) {
      setWindowLoading(false);
      setCurrentUser(databaseUser[0]);

      // console.log(databaseUser[0]);
      switch (databaseUser[0]?.accountType) {
        case "client":
          setCurrentUserAccountTypeClient(true);
          setCurrentUserAccountTypeFreelancer(false);
          setCurrentUserAccountTypeTalentManager(false);
          break;
        case "Freelancer":
          setCurrentUserAccountTypeClient(false);
          setCurrentUserAccountTypeFreelancer(true);
          setCurrentUserAccountTypeTalentManager(false);
          break;
        case "talent_manager":
          setCurrentUserAccountTypeClient(false);
          setCurrentUserAccountTypeFreelancer(false);
          setCurrentUserAccountTypeTalentManager(true);
          break;
      }
    }
  }, [firebaseUser, databaseUser, currentUser]);

  // onFileDroponFileDrop function
  const handelOnFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      setOneFile(newFile);
    }
  };

  // handelMultiFileDrop
  const handelMultiFileDrop = (e, limit) => {
    const newFile = e.target.files[0];
    if (newFile && !(multiFileListCount === limit)) {
      setMultiFileListCount(multiFileListCount + 1);
      const updatedList = [...multiFileList, newFile];
      console.log(updatedList.length);
      setMultiFileList(updatedList);
    }
  };

  // handelMultiFileOneRemoveItem function
  const handelMultiFileOneRemoveItem = (id) => {
    const findFile = multiFileList[id];
    const newMultiFileList = multiFileList.filter((file) => file !== findFile);
    setMultiFileListCount(multiFileListCount - 1);
    setMultiFileList(newMultiFileList);
  };

  // handel buffer file download function
  const downloadAnyBufferFileForFrontEnd = async (
    bufferArray,
    bufferFileType
  ) => {
    if (bufferArray && bufferFileType) {
      const arrayBufferView = new Uint8Array(bufferArray.data);
      const blob = new Blob([arrayBufferView], {
        type: bufferFileType,
      });

      // Create a download link
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");

      // Generate a dynamic filename, e.g., using the current timestamp
      const timestamp = new Date().toISOString().replace(/[-:]/g, "");
      const filename = `downloadedFile_${timestamp}.${
        bufferFileType.split("/")[1]
      }`;

      a.href = url;
      a.download = filename;

      // Trigger the download
      document.body.appendChild(a);
      a.click();

      // Cleanup
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  // handel buffer file open img tag and link generate function
  const bufferAnyFileOpenImgTagLinkGenerate = (bufferArray, bufferFileType) => {
    if (bufferArray && bufferFileType) {
      const arrayBufferView = new Uint8Array(bufferArray.data);
      const blob = new Blob([arrayBufferView], {
        type: bufferFileType,
      });
      const imageUrl = URL.createObjectURL(blob);
      return imageUrl;
    }
  };

  // drop file link generate function
  useEffect(() => {
    if (multiFileList) {
      const images = multiFileList.map((file) => URL.createObjectURL(file));
      setImagesUrlLocalHost(images);
    }
    if (oneFile) {
      const image = URL.createObjectURL(oneFile);
      setImageUrlLocalHost(image);
    }
  }, [multiFileList, oneFile]);

  // This is freelancer job interview count get database function
  const handelFreelancerAllJobsInterviewDataCount = async (uid) => {
    const res = await fetch(
      `${serverUrl}/freelancer/all_jobs/interview/count`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          uid,
        },
      }
    );
    const data = await res.json();
    if (data) {
      // console.log(data);
    }
  };

  // this is current user all message friend list get function
  const {
    data: allMessageFriendList,
    refetch: allMessageFriendListRefetch,
    isLoading: allMessageFriendListLoading,
  } = CurrentUserAllFriendListGet(currentUser?.PersonID);

  // this function is single user roomId return
  const singleUserRoomIdFiendFromCurrentFriendList = (PersonID, roomData) => {
    if (roomData) {
      let foundRoom = null;
      roomData.forEach((room) => {
        if (
          room?.participants.length === 1 &&
          room?.participants[0]?.PersonID === PersonID
        ) {
          foundRoom = room?.roomId;
        }
      });

      return foundRoom;
    }

    return null;
  };

  // ==================================================
  //   All Function and useEffect handel End
  // ==================================================
  // this is socket connection
  useEffect(() => {
    if (chatApplicationSocketUrl && currentUser?.PersonID) {
      const newSocketIo = io(chatApplicationSocketUrl, {
        transports: ["websocket", "polling"],
        query: { personId: currentUser?.PersonID },
      });

      if (newSocketIo) {
        setSocketIo(newSocketIo);
        console.log("client side socket io server connect successful");
      } else {
        console.log("client side socket io server connect successful");
      }
      return () => {
        newSocketIo.disconnect();
      };
    }
  }, [currentUser?.PersonID]);

  useEffect(() => {
    if (socketIo) {
      socketIo.on("hireOnlineUsers", (users) => {
        setOnlineUsers(users);
      });
      return () => {
        socketIo.off("hireOnlineUsers");
      };
    }
  }, [socketIo]);
  useEffect(() => {
    console.log(onlineUsers, "onlineUsers");
  }, [onlineUsers]);

  // shear globalFunInfo object
  const globalFunInfo = {
    firebaseUserAccessToken,
    setFirebaseUserAccessToken,
    currentUser,
    databaseSingleUserRefetch,
    setCurrentUser,
    firebaseUser,
    signInWithGooglePopup,
    l,
    setL,
    r,
    setR,
    credentialUser,
    setCredentialUser,
    currentUserAccountTypeClient,
    setCurrentUserAccountTypeClient,
    currentUserAccountTypeFreelancer,
    setCurrentUserAccountTypeFreelancer,
    currentUserAccountTypeTalentManager,
    setCurrentUserAccountTypeTalentManager,
    navbarLogoShow,
    setNavbarLogoShow,
    userCurrentLocationPath,
    setUserCurrentLocationPath,
    userCurrentLocationPathParameter,
    setUserCurrentLocationPathParameter,
    windowLoading,
    setWindowLoading,
    handelOnFileDrop,
    handelMultiFileDrop,
    fileDropLimitNumber,
    setFileDropLimitNumber,
    multiFileList,
    setMultiFileList,
    setMultiFileListCount,
    oneFile,
    setOneFile,
    imagesUrlLocalHost,
    imageUrlLocalHost,
    setImageUrlLocalHost,
    handelMultiFileOneRemoveItem,
    serverDataPutParamsAllData,
    setConfirmAlertValue,
    confirmAlertValue,
    clientMessageNotification,
    setClientMessageNotification,
    freelancerMessageNotification,
    setFreelancerMessageNotification,
    isValidDate,
    setIsValidDate,
    project_start_date,
    setProject_start_date,
    project_expire_date,
    setProject_expire_date,
    project_deadline_date,
    setProject_deadline_date,
    handelFreelancerDetailsInfo,
    setHandelFreelancerDetailsInfo,
    GuestModeFreelancerDetailsPersonId,
    setGuestModeFreelancerDetailsPersonId,
    sendNotification,
    currentUserAllNotification,
    userNotificationLoading,
    userNotificationRefetch,
    handelNotificationCount,
    setHandelNotificationCount,
    handelCustomDrawerOpenCls,
    setHandelCustomDrawerOpenCls,
    downloadAnyBufferFileForFrontEnd,
    bufferAnyFileOpenImgTagLinkGenerate,
    allMessageFriendList,
    allMessageFriendListRefetch,
    allMessageFriendListLoading,
    singleUserRoomIdFiendFromCurrentFriendList,
    socketIo,
    setSocketIo,
    onlineUsers,
    setOnlineUsers,
  };

  return (
    <globalFunctionOneContext.Provider value={globalFunInfo}>
      {children}
    </globalFunctionOneContext.Provider>
  );
};

export default GlobalFunctionProviderOne;
