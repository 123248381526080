import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ModalBody from "../../Shared/ModalBody/ModalBody";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { NavbarLogoShowAndHidden } from "../../../hooks/NavbarAllFunction/useNavbarAllFunction";
import { JobAllMilestoneGet } from "../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import { BsCurrencyRupee } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { ImBin } from "react-icons/im";
import { serverUrl } from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { toast } from "react-toastify";

const ContractDetails = () => {
  const {
    currentUser,
    userCurrentLocationPathParameter,
    setUserCurrentLocationPathParameter,
  } = useContext(globalFunctionOneContext);
  const parameter = useParams();

  const [myJobMenuActiveTracker, setMyJobMenuActiveTracker] = useState(1);
  const [handelEditMilestoneModal, setHandelEditMilestoneModal] =
    useState(false);
  const [remainingMilestonePayment, setRemainingMilestonePayment] =
    useState("00.00");
  const [LastPaymentCompleteMilestone, setLastPaymentCompleteMilestone] =
    useState({});
  const [lastEscrowMilestoneActive, setLastEscrowMilestoneActive] = useState(
    {}
  );
  const [TotalPayments, setTotalPayments] = useState("00.0");
  const [handelMilestoneModal, setHandelMilestoneModal] = useState(false);
  const [handelSingleMilestoneData, setHandelSingleMilestoneData] = useState(
    {}
  );
  const [handelMilestoneFromError, setHandelMilestoneFromError] = useState(0);
  const [handelAmountInputFocus, setHandelAmountInputFocus] = useState(false);

  // This is all milestone get function
  const {
    data: allMIlestoneLIst,
    refetch: allMIlestoneLIstRefetch,
    isLoading: allMIlestoneLIstLoading,
  } = JobAllMilestoneGet(parameter?.id);
  console.log(allMIlestoneLIst);

  // This is single milestone delete function
  const deleteSingleMilestone = async (id) => {
    const res = await fetch(`${serverUrl}/milestone/delete/${id}`, {
      method: "DELETE",
    });
    const data = await res.json();
    if (data?.status === "success") {
      allMIlestoneLIstRefetch();
      toast.success("Milestone delete success", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error("Milestone delete fail", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  // This is single milestone get function
  const singleMilestoneGet = async (id) => {
    const res = await fetch(`${serverUrl}/milestone/get/single/${id}`);
    const data = await res.json();
    if (data?.status === "success") {
      setHandelSingleMilestoneData(data?.result);
      setHandelMilestoneModal(false);
      setHandelEditMilestoneModal(true);
    }
  };

  // this is create milestone data post server function
  const handelMilestoneUpdate = async (e) => {
    e.preventDefault();
    const from = e.target;
    const milestoneDescription = from?.milestoneDescription?.value;
    const dueDateUTC = from?.dueDateUTC?.value;
    const depositAmount = from?.depositAmount?.value;

    const milestoneUserData = {
      jobId: handelSingleMilestoneData?.job_id,
      milestoneDescription: milestoneDescription,
      dateLine: dueDateUTC,
      depositAmount: depositAmount,
      milestonePaymentState: handelSingleMilestoneData?.milestonePaymentState,
      milestoneState: handelSingleMilestoneData?.milestoneState,
    };

    if (
      milestoneUserData?.milestoneDescription &&
      milestoneUserData?.dateLine &&
      milestoneUserData?.depositAmount &&
      milestoneUserData?.jobId &&
      Number(handelSingleMilestoneData?.minimum_price) >= depositAmount
    ) {
      const res = await fetch(
        `${serverUrl}/milestone/update/${handelSingleMilestoneData?.id}`,
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            // authorization: `bearer ${localStorage.getItem("access_Token")}`,
          },
          body: JSON.stringify(milestoneUserData),
        }
      );
      const data = await res.json();
      if (data?.status === "success") {
        allMIlestoneLIstRefetch();
        setHandelEditMilestoneModal(false);
        toast.success("Milestone update success", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Milestone update fail", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  // this is handel usParameter
  useEffect(() => {
    setUserCurrentLocationPathParameter(parameter);
  }, [parameter, setUserCurrentLocationPathParameter]);

  // this is all payment and remaining and total calculation function
  useEffect(() => {
    if (allMIlestoneLIst?.result && allMIlestoneLIst?.resultStatus === "Yes") {
      const paymentCompleteMilestone = allMIlestoneLIst.result.filter(
        (data) => data?.milestonePaymentState !== "No"
      );
      const paymentNotCompleteMilestone = allMIlestoneLIst.result.filter(
        (data) => data?.milestonePaymentState !== "yes"
      );
      const allEscrowMilestone = allMIlestoneLIst.result.filter(
        (data) => data?.milestoneState === "active"
      );

      // remaining payment
      let totalRemainingPayment = 0;
      let totalMilestonePaymentSum = 0;

      // Check if there are incomplete milestones
      allMIlestoneLIst?.result.forEach((remaining) => {
        totalMilestonePaymentSum += Number(remaining.depositAmount);
      });
      const newTotalRemainingPayment =
        Number(allMIlestoneLIst?.result[0]?.minimum_price) -
        totalMilestonePaymentSum;
      totalRemainingPayment = newTotalRemainingPayment;
      // console.log(
      //   totalMilestonePaymentSum,
      //   Number(allMIlestoneLIst?.result[0]?.minimum_price),
      //   "newTotalRemainingPayment"
      // );
      setRemainingMilestonePayment(totalRemainingPayment);

      // last milestone payment
      if (paymentCompleteMilestone.length > 0) {
        const lastPaymentCompleteMilestone =
          paymentCompleteMilestone[paymentCompleteMilestone.length - 1];
        setLastPaymentCompleteMilestone(lastPaymentCompleteMilestone);
      }
      // all Escrow milestone
      if (allEscrowMilestone.length > 0) {
        const lastMilestoneActiveData =
          allEscrowMilestone[allEscrowMilestone.length - 1];
        setLastEscrowMilestoneActive(lastMilestoneActiveData);
      }

      // total payment
      if (paymentNotCompleteMilestone.length > 0) {
        // Check if there are complete milestones
        let totalPayment = 0;
        paymentCompleteMilestone.forEach((remaining) => {
          totalPayment += Number(remaining.depositAmount);
        });
        setTotalPayments(totalPayment);
      }
    } else {
      setRemainingMilestonePayment("00.00");
      setTotalPayments("00.00");
    }
  }, [allMIlestoneLIst.result, allMIlestoneLIst?.resultStatus]);

  if (allMIlestoneLIstLoading || !currentUser) {
    return <div>contract details Loading ......</div>;
  }

  return (
    <div>
      <div className="grid gap-5 grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
        <div>
          <p className="font-bold">Budget</p>
          <h1 className="font-bold text-2xl flex justify-start items-center">
            <span>
              <BsCurrencyRupee />
            </span>
            <span>
              {allMIlestoneLIst?.result &&
                allMIlestoneLIst?.result[0]?.minimum_price}
            </span>
          </h1>
        </div>
        <div>
          <p className="font-bold">In Escrow</p>
          <h1 className="font-bold text-2xl flex justify-start items-center">
            <span>
              <BsCurrencyRupee />
            </span>
            <span>
              {lastEscrowMilestoneActive &&
              lastEscrowMilestoneActive?.depositAmount
                ? lastEscrowMilestoneActive?.depositAmount
                : "00.0"}
            </span>
          </h1>
        </div>
        <div>
          <p className="font-bold">Milestones Paid</p>
          <h1 className="font-bold text-2xl flex justify-start items-center">
            <span>
              <BsCurrencyRupee />
            </span>{" "}
            <span>
              {LastPaymentCompleteMilestone &&
              LastPaymentCompleteMilestone?.depositAmount
                ? LastPaymentCompleteMilestone?.depositAmount
                : "00.00"}
            </span>
          </h1>
        </div>
        <div>
          <p className="font-bold">Remaining</p>
          <h1 className="font-bold text-2xl flex justify-start items-center">
            <span>
              <BsCurrencyRupee />
            </span>{" "}
            <span>{remainingMilestonePayment}</span>
          </h1>
        </div>
        <div>
          <p className="font-bold">Total Payments</p>
          <h1 className="font-bold text-2xl flex justify-start items-center">
            <span>
              <BsCurrencyRupee />
            </span>{" "}
            <span>{TotalPayments}</span>
          </h1>
        </div>
      </div>
      <hr className="my-3 border-t border-gray-300" />
      <div className="flex justify-between items-center">
        <div>
          <p className=" text-lg font-medium">Remaining milestones </p>
        </div>
        <div>
          <button
            className="btn btn-outline btn-primary btn-sm rounded-full"
            onClick={() => {
              setHandelMilestoneModal(true);
            }}
          >
            Edit Milestones
          </button>
        </div>
      </div>
      <hr className="my-3 border-t border-gray-300" />
      <div>
        <ol type="1" style={{ listStyleType: "decimal" }}>
          {allMIlestoneLIst?.resultStatus === "Yes" &&
            allMIlestoneLIst?.result &&
            allMIlestoneLIst?.result.map((milestone, i) => (
              <li key={i}>
                <div className="flex justify-between items-center">
                  <div className="w-[30%]">
                    <p>
                      <span>
                        {milestone?.milestoneDescription &&
                          (milestone.milestoneDescription.length > 50
                            ? milestone.milestoneDescription.slice(0, 50) +
                              " more..."
                            : milestone.milestoneDescription)}
                      </span>

                      {milestone?.milestoneState &&
                        milestone?.milestoneState === "active" && (
                          <span className="btn btn-outline btn-primary btn-xs ml-3">
                            Active
                          </span>
                        )}
                      {milestone?.milestoneState &&
                        milestone?.milestoneState === "complete" && (
                          <span className="btn btn-outline btn-primary btn-xs ml-3">
                            complete
                          </span>
                        )}
                      {milestone?.milestoneState &&
                        milestone?.milestoneState === "workSubmitted" && (
                          <Link
                            to={`/dashboard/client/contract/review/work/${milestone?.id}`}
                            className="btn btn-outline btn-primary btn-xs ml-3"
                          >
                            Check work
                          </Link>
                        )}
                    </p>
                    <p className="flex justify-start items-center">
                      <BsCurrencyRupee />
                      <span>{milestone?.depositAmount}</span>
                      <span className="ml-1">(founded)</span>
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-[70%]">
                    <div>
                      <p className="ml-5">
                        {(() => {
                          const date = new Date(milestone?.dateLine);
                          const monthNames = [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec",
                          ];
                          const year = date.getFullYear() % 100;
                          const month = monthNames[date.getMonth()];
                          const day = date.getDate();

                          return `${month} ${day} ${year}`;
                        })()}
                      </p>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-circle btn-outline btn-sm btn-primary mx-2"
                        onClick={() => singleMilestoneGet(milestone?.id)}
                      >
                        <FiEdit />
                      </button>
                      <button
                        type="button"
                        className="btn btn-circle btn-sm btn-primary mx-2"
                        onClick={() => deleteSingleMilestone(milestone?.id)}
                      >
                        <ImBin />
                      </button>
                      {milestone?.milestonePaymentState &&
                        milestone?.milestonePaymentState === "No" && (
                          <button
                            className="btn btn-outline btn-primary btn-sm rounded-full"
                            onClick={() => {
                              setHandelMilestoneModal(true);
                            }}
                          >
                            Pay Now
                          </button>
                        )}
                      {milestone?.milestonePaymentState &&
                        milestone?.milestonePaymentState === "yes" && (
                          <button className="btn btn-outline btn-primary btn-sm rounded-full px-7">
                            Paid
                          </button>
                        )}
                    </div>
                  </div>
                </div>
                <hr className="my-3 border-t border-gray-300" />
              </li>
            ))}
        </ol>
      </div>

      <ModalBody
        onClose={() => setHandelMilestoneModal(false)}
        isVisible={handelMilestoneModal}
        customOpacityLayer="bg-opacity-0"
        customClass="card w-full mx-10 md:max-w-[600px] md:mx-auto bg-[#fff] p-5 rounded-md  overflow-y-auto"
        modalCloseCustomButtonAdd={false}
      >
        <div className="my-5 p-5 max-h-[500px] overflow-y-auto">
          <ol type="1" style={{ listStyleType: "decimal" }}>
            {allMIlestoneLIst?.result &&
              allMIlestoneLIst?.result.map((milestone, i) => (
                <li key={i}>
                  <div className="grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                    <div className="col-span-2">
                      <p>
                        <span>{milestone?.milestoneDescription}</span>

                        {milestone?.milestoneState &&
                          milestone?.milestoneState === "noActive" && (
                            <span className="btn btn-outline btn-primary btn-xs ml-3">
                              No Active
                            </span>
                          )}
                        {milestone?.milestoneState &&
                          milestone?.milestoneState === "complete" && (
                            <span className="btn btn-outline btn-primary btn-xs ml-3">
                              Complete
                            </span>
                          )}
                        {milestone?.milestoneState &&
                          milestone?.milestoneState === "active" && (
                            <span className="btn btn-outline btn-primary btn-xs ml-3">
                              Active
                            </span>
                          )}
                        {milestone?.milestoneState &&
                          milestone?.milestoneState === "workSubmitted" && (
                            <span className="btn btn-outline btn-primary btn-xs ml-3">
                              Check work
                            </span>
                          )}
                      </p>
                      <p className="flex justify-start items-center">
                        <BsCurrencyRupee />
                        <span>{milestone?.depositAmount}</span>
                        <span className="ml-1">(founded)</span>
                      </p>
                    </div>
                    <div>
                      <p className="ml-5">
                        {(() => {
                          const date = new Date(milestone?.dateLine);
                          const monthNames = [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec",
                          ];
                          const year = date.getFullYear() % 100;
                          const month = monthNames[date.getMonth()];
                          const day = date.getDate();

                          return `${month} ${day} ${year}`;
                        })()}
                      </p>
                    </div>

                    <div className="flex justify-start items-center">
                      <button
                        type="button"
                        className="btn btn-circle btn-outline btn-sm btn-primary mx-2"
                        onClick={() => singleMilestoneGet(milestone?.id)}
                      >
                        <FiEdit />
                      </button>
                      <button
                        type="button"
                        className="btn btn-circle btn-sm btn-primary mx-2"
                        onClick={() => deleteSingleMilestone(milestone?.id)}
                      >
                        <ImBin />
                      </button>
                    </div>
                  </div>
                  <hr className="my-3 border-t border-gray-300" />
                </li>
              ))}
          </ol>
        </div>

        {/* <hr className="border-t border-gray-400" /> */}
        <div className="mt-5 px-5 flex justify-end items-start">
          <div>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => setHandelMilestoneModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalBody>

      <ModalBody
        onClose={() => setHandelEditMilestoneModal(false)}
        isVisible={handelEditMilestoneModal}
        customOpacityLayer="bg-opacity-0"
        customClass="card w-full mx-10 md:max-w-[900px] md:mx-auto bg-[#fff] p-5 rounded-md h-[580px] overflow-y-auto"
        modalCloseCustomButtonAdd={false}
      >
        {handelSingleMilestoneData && (
          <form onSubmit={handelMilestoneUpdate}>
            <div className="my-5 p-5">
              <p className="font-bold mb-4">
                You're protected by
                <span className="text-primary">
                  Hire Payment Protection.
                </span>{" "}
                Only pay for the work you authorize
              </p>
              <p className="font-bold">Payment Option</p>
              <p className="font-medium">Fixed price budget</p>
              <p className="label-text font-medium flex justify-start items-center">
                <BsCurrencyRupee /> {handelSingleMilestoneData?.minimum_price}
              </p>

              <p className="mb-4 text-sm font-medium text-gray-400">
                Pay as project milestones are completed
              </p>
              <p className="font-bold">Remaining budget of your project</p>
              <p className="font-medium flex justify-start items-center">
                <BsCurrencyRupee /> {remainingMilestonePayment}
              </p>
              <p className="mb-4 font-medium text-gray-400 text-sm">
                This is the price you and Eimer have agreed upon
              </p>
              <p className="font-bold">Deposit funds onto Escrow</p>
              <p className="mb-3 font-medium text-gray-400 text-sm">
                Escrow is a neutral holding place that protects your deposit
                until work is approved
              </p>

              <div className="form-control">
                <label className="label cursor-pointer justify-start py-1">
                  <input
                    type="radio"
                    name="radio-10"
                    value="customAmount"
                    className="radio radio-primary"
                    defaultChecked
                  />
                  <span className="label-text ml-2 font-medium">
                    Deposit a lesser amount to cover the first milestone
                  </span>
                </label>
              </div>

              <div>
                <hr className="border-t border-gray-400 mt-5" />
                <div>
                  <h3 className="text-2xl font-medium my-2">
                    Project Milestones
                  </h3>
                  <p className="font-medium text-gray-400 text-sm">
                    Add project milestones and pay in installments as oath
                    milestone is completed to your satisfaction
                  </p>

                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 my-5">
                    <div>
                      <label
                        htmlFor="milestoneDescription"
                        className="font-bold mb-2 cursor-pointer"
                      >
                        Milestone Description
                      </label>
                      <input
                        id="milestoneDescription"
                        type="text"
                        name="milestoneDescription"
                        className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc] rounded-md"
                        placeholder="Enter your milestone description"
                        required
                        defaultValue={
                          handelSingleMilestoneData?.milestoneDescription
                        }
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="dueDateUTC"
                        className="font-bold mb-2 cursor-pointer"
                      >
                        Due Date UTC
                      </label>
                      <input
                        id="dueDateUTC"
                        type="date"
                        name="dueDateUTC"
                        className={`input input-bordered ${
                          handelMilestoneFromError === "dueDateUTC" &&
                          "border border-red-600"
                        } w-full focus:outline-none focus:border-[#fd03dc] rounded-md`}
                        required
                        defaultValue={handelSingleMilestoneData?.dateLine}
                        onChange={(e) => {
                          const enteredDate = new Date(e.target.value);
                          const currentDate = new Date();
                          if (enteredDate < currentDate) {
                            e.target.value = "";
                            setHandelMilestoneFromError("dueDateUTC");
                          } else {
                            setHandelMilestoneFromError("");
                          }
                        }}
                      />
                      {handelMilestoneFromError === "dueDateUTC" && (
                        <p className="text-xs text-red-600">
                          You are entering the wrong date.
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="depositAmount"
                        className="font-bold mb-2 cursor-pointer"
                      >
                        Deposit Amount
                      </label>
                      <label className="input-group input-group-md">
                        <span
                          className={`border-y border-r-0 border-l ${
                            handelAmountInputFocus &&
                            "border-y-[#fd03dc] border-l-[#fd03dc]"
                          } ${
                            handelMilestoneFromError === "depositAmount" &&
                            "border-y-red-600 border-l-red-600"
                          }`}
                        >
                          <BsCurrencyRupee />
                        </span>
                        <input
                          id="depositAmount"
                          type="number"
                          name="depositAmount"
                          className={`input input-bordered numberInputArrowHidden ${
                            handelMilestoneFromError === "depositAmount" &&
                            "border-l-[#FFFFFF]  border-y-red-600 border-r-red-600"
                          } w-full focus:outline-none focus:border-l-[#FFFFFF]  focus:border-y-[#fd03dc] focus:border-r-[#fd03dc]  rounded-md`}
                          placeholder="Enter your deposit amount"
                          required
                          defaultValue={
                            handelSingleMilestoneData?.depositAmount
                          }
                          onFocus={() => {
                            setHandelMilestoneFromError("");
                            setHandelAmountInputFocus(true);
                          }}
                          onBlur={(e) => {
                            setHandelAmountInputFocus(false);
                            if (
                              Number(
                                handelSingleMilestoneData?.minimum_price
                              ) >= e.target.value
                            ) {
                              setHandelMilestoneFromError("");
                              console.log(e.target.value);
                            } else {
                              console.log(e.target.value, "else");
                              e.target.value = "";
                              setHandelMilestoneFromError("depositAmount");
                            }
                            if (remainingMilestonePayment >= e.target.value) {
                              setHandelMilestoneFromError("");
                              console.log(e.target.value);
                            } else {
                              console.log(e.target.value, "else");
                              e.target.value = "";
                              setHandelMilestoneFromError("depositAmount");
                            }
                          }}
                        />
                      </label>
                      {handelMilestoneFromError === "depositAmount" && (
                        <p className="text-xs text-red-600">
                          You are paying more than your project amount.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <p className={`font-medium text-sm text-primary cursor-pointer `}>
                + Add Milestone
              </p>
            </div>

            <hr className="border-t border-gray-400" />
            <div className="my-5 px-5 flex justify-end items-start">
              <div>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => setHandelEditMilestoneModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm btn-outline ml-5"
                >
                  Update Milestone
                </button>
              </div>
            </div>
          </form>
        )}
      </ModalBody>
    </div>
  );
};

export default ContractDetails;
