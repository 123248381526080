import React from "react";
// import { DayPicker } from "react-day-picker";
// import "react-day-picker/dist/style.css";
import { BsCameraVideo, BsTelephoneInbound } from "react-icons/bs";
import { GrSchedule } from "react-icons/gr";
import { ImOffice } from "react-icons/im";
const ScheduleModule = ({ setS, s }) => {
  // const [date, setDate] = useState(new Date());

  return (
    <>
      {/* <!-- The button to open modal --> */}

      {/* <!-- Put this part before </body> tag --> */}
      {/* The button to open modal */}

      {/* Put this part before </body> tag */}
      <input type="checkbox" id="my-modal-6" className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <label
            onClick={() => setS(!s)}
            htmlFor="my-modal-6"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="font-bold text-xl flex gap-2 items-center">
            <GrSchedule /> Schedule interview
          </h3>
          <h3>
            <span className="font-bold text-xl mt-4">To: </span>
            <span className="uppercase bg-gray-100 rounded px-2 py-1">
              Mohit kumar
            </span>
          </h3>
          <div className="form-control w-full  ">
            <label className="label">
              <span className="label-text">Add Title</span>
            </label>
            <input
              type="text"
              placeholder="e.g First round interview with tech lead"
              className="input input-bordered w-full  "
            />
            {/* <label className="label">
              <span className="label-text-alt">Alt label</span>
            </label> */}
            <div>
              <label className="label">
                <span className="label-text-alt text-xl">duration</span>
              </label>
              <div className="btn-group btn-group-vertical lg:btn-group-horizontal">
                <button className="btn btn-secondary btn-outline btn-active">
                  30 minute
                </button>
                <button className="btn btn-secondary btn-outline">
                  60 minute
                </button>
              </div>

              {/* <div>
                <DayPicker mode="single" selected={date} onSelect={setDate} />
              </div> */}
            </div>
            <div>
              <label className="label">
                <span className="label-text">Interview date & time</span>
              </label>
              <input
                type="date"
                placeholder="Select date"
                className="input input-bordered w-full  "
              />
              {/* <label className="label">
              <span className="label-text-alt">Alt label</span>
            </label> */}
              <select className="select select-bordered w-full mt-4 ">
                <option disabled value="" selected>
                  Pick a slot
                </option>
                <option value="9:00am">9:00am</option>
                <option value="9:30am">9:30am</option>
                <option value="10:00am">10:00am</option>
              </select>
            </div>
            <div>
              <h2 className="card-title">Interview Type</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <button className="btn btn-secondary btn-outline btn-active  rounded-full flex flex-wrap items-center gap-2">
                  <span>
                    <BsCameraVideo className="text-xl" />
                  </span>
                  <span>Video call</span>
                </button>
                <button className="btn btn-outline rounded-full flex flex-wrap items-center gap-2 btn-secondary">
                  <span>
                    <BsTelephoneInbound className="text-xl" />
                  </span>
                  <span>Phone</span>
                </button>
                <button className="btn btn-outline rounded-full flex flex-wrap items-center gap-2 btn-secondary">
                  <span>
                    <ImOffice className="text-xl" />
                  </span>
                  <span>In-Office</span>
                </button>
              </div>
            </div>
            <div>
              <h2 className="card-title my-4">share video call link</h2>

              <input
                type="link"
                value="http://localhost:3000"
                placeholder="e.g First round interview with tech lead"
                className="input input-bordered w-full  "
              />
              {/* <label className="label">
              <span className="label-text-alt">Alt label</span>
            </label> */}
            </div>
          </div>
          <p className="mt-4">
            Unable to create & share video call link ?{" "}
            <span className="link text-accent">Need help</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default ScheduleModule;
