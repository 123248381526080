import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Forget from "./component/Authontection/Forget";
import Login from "./component/Authontection/Login";
import Analytics from "./component/Client/Analytics/Analytics";
import Campaigns from "./component/Client/Campaigns/Campaigns";
import Candidates from "./component/Client/Candidates/Candidates";
import Interview from "./component/Client/Interview/Interview";
import FullJobDetails from "./component/Client/Jobs/FullJobDetils/FullJobDetails";
import Hire from "./component/Client/Jobs/Hire.js/Hire";
import Hired from "./component/Client/Jobs/Hire.js/Hired/Hired";
import Offer from "./component/Client/Jobs/Hire.js/Offer/Offer";
import InviteJob from "./component/Client/Jobs/inviteFreelancer/InviteJob";
import Search from "./component/Client/Jobs/inviteFreelancer/Search/Search";
import FreelancerHire from "./component/Client/Jobs/inviteFreelancer/Search/SearchComponent/FreelancerHire/FreelancerHire";
import FreelancerSaved from "./component/Client/Jobs/inviteFreelancer/Search/SearchComponent/FreelancerSaved/FreelancerSaved";
import InviteFreelancerList from "./component/Client/Jobs/inviteFreelancer/Search/SearchComponent/InviteFreelancerList/InviteFreelancerList";
import Job from "./component/Client/Jobs/Job/Job";
import Jobs from "./component/Client/Jobs/Jobs";
import AllProposals from "./component/Client/Jobs/ReviewProposals/AllProposals/AllProposals";
import ReviewProposals from "./component/Client/Jobs/ReviewProposals/ReviewProposals";
import Shortlisted from "./component/Client/Jobs/ReviewProposals/Shortlisted/Shortlisted";
import SearchResume from "./component/Client/SearchResume/SearchResume";
import Tools from "./component/Client/Tools/Tools";
import Brochures from "./component/Client/ClientProfile/ProfileForm/Brochures/Brochures";
import SocialProfile from "./component/Client/ClientProfile/ProfileForm/SocialProfile/SocialProfile";
import JobsF from "./component/Freelancer/Dashboard/Jobs/JobsF.js";
import ProfileFormDashboard from "./component/ProfileForm/ProfileFormDashboard";
import "firebase/auth";
import "firebase/firestore";
import "./component/Shared/GlobalCss/GlobalOne.css";
import CompleteProfileDetails from "./component/Freelancer/FreelancerProfile/CompleteProfileDetails/CompleateProfileDetails";
import Complete from "./component/Freelancer/FreelancerProfile/Complete/Complete";
import Experience from "./component/Freelancer/FreelancerProfile/Experience/Experience";
import Extra from "./component/Freelancer/FreelancerProfile/Extras/Extra";
import MyProfile from "./component/Freelancer/FreelancerProfile/ProfileHome/MyProfile";
import GlobalDashboard from "./component/GlobalDashboard/GlobalDashboard";
import GlobalHome from "./component/GlobalDashboard/GlobalHome";
import MessageDashboard from "./component/GlobalDashboard/Message/MessageDashboard";
import HomePage from "./component/HomePage/HomePage";
import FormHome from "./component/ProfileForm/FormHome";
import RequireAuth from "./component/Require/RequirAuth";
import TalentManager from "./component/TalentManager/TalentManagerHome";
import Navbar from "./component/Navbar/Navbar";
import { ErrorBoundary } from "./component/ErrorBoundary/ErrorBoundary";
import EmailVerification from "./component/Authontection/SingUp/multStepForm/Steps/EmailVerification";

import TalentManageSocialProfileForm from "./component/TalentManager/TalentManagerProfiel/TalentManageSocialProfileForm";
import TalentManagerProfileUploadId from "./component/TalentManager/TalentManagerProfiel/TalentManagerProfileUploadId";
import PaymentSucess from "./component/Client/payment/PaymentSucess";
import PlaceOrder from "./component/Client/payment/PlaceOrder";
import EmailVerifyPage from "./component/EmailVerify/EmailVerifyPage";
import JobDetails from "./component/Freelancer/Dashboard/Jobs/JobsNavbar/JobDetails";
import InviteFreelancerDetails from "./component/Client/Jobs/inviteFreelancer/Search/SearchComponent/InviteFreelancerList/InviteFreelancerDetails";
import ViewOffer from "./component/Client/Jobs/ViewOffer/ViewOffer";
import Footer from "./component/HomePage/Footer";
import BlogHome from "./component/Blogs/BlogHome";
import CareerHome from "./component/career/CareerHome";
import PrivacyPolicy from "./component/privacyPolicy/PrivacyPolicy";
import CookieSetting from "./component/cookieSetting/CookieSetting";
import Faq from "./component/FAQ/Faq";
import Terms from "./component/terms/Terms";
import GuestModeFreelancer from "./component/GuestModeFreelancer/GuestModeFreelancer";
import GuestModeFreelancerDetails from "./component/GuestModeFreelancer/GuestModeFreelancerDetails";
import GuestModeFreelancerPrivateRoute from "./component/Routes/Privetroutes/GuestModeFreelancerPrivateRoute";
import GuestModeClientPrivateRoute from "./component/Routes/Privetroutes/GuestModeClientPrivateRoute";
import HireViewContract from "./component/Client/Jobs/ViewOffer/HireViewContract";
import DeclineFreelancer from "./component/Client/Jobs/ReviewProposals/DeclineFreelancer/DeclineFreelancer";
import InvoiceViewer from "./component/Client/payment/Invoices/InvoiceViewer";

import MyJobs from "./component/Freelancer/MyJobs/MyJobs";
import MyJobsSaved from "./component/Freelancer/MyJobs/MyJobsSaved";
import MyJobsApply from "./component/Freelancer/MyJobs/MyJobsApply";
import MyJobsInterview from "./component/Freelancer/MyJobs/MyJobsInterview";
import MyJobsContract from "./component/Freelancer/MyJobs/MyJobsContract";
import Contract from "./component/Client/Contract/Contract";
import ContractDetails from "./component/Client/Contract/ContractDetails";
import MyJobsContractDetails from "./component/Freelancer/MyJobs/MyJobsContractDetails";

import Setting from "./component/Setting/Setting";
import IdentityVerification from "./component/Setting/IdentityVerification";
import JobProposal from "./component/Freelancer/MyJobs/JobProposal";
// import MyJobs from "./component/Freelancer/Dashboard/Jobs/";
import BillingPayment from "./component/Setting/billingPayment/BillingPayment";
import ManageFreelancer from "./component/Client/Contract/ManageFreelancer";
import ContractDetailsIndex from "./component/Client/Contract/ContractDetailsIndex";
import ReviewWork from "./component/Client/Contract/ReviewWork.jsx";
import TimeCounter from "./component/TimeCounter/TimeCounter.js";

import TimeCounterDashboard from "./component/TimeCounter/Dashboard/TimeCounterDashboard.js";
import TimeSheetDashboard from "./component/TimeCounter/TimeSheet/TimeSheetDashboard.js";

function App() {
  const location = useLocation();
  return (
    <div>
      <ErrorBoundary>
        <Navbar>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/guest/mode/freelancer"
              element={
                <GuestModeClientPrivateRoute>
                  <GuestModeFreelancer />
                </GuestModeClientPrivateRoute>
              }
            />
            <Route
              path="/guest/mode/freelancer/details"
              element={
                <GuestModeClientPrivateRoute>
                  <GuestModeFreelancerDetails />
                </GuestModeClientPrivateRoute>
              }
            />
            <Route path="/career" element={<CareerHome />} />
            <Route path="/blog" element={<BlogHome />} />
            <Route path="/invoice" element={<InvoiceViewer />} />
            <Route path="/privacy&policy" element={<PrivacyPolicy />} />
            <Route path="/cookieSetting" element={<CookieSetting />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/terms&condition" element={<Terms />} />

            {/* start global dashboard */}
            <Route path="/success" element={<PaymentSucess />} />
            <Route path="/payment" element={<PlaceOrder />} />
            <Route
              path="/verifyEmail/:mode/:code/:key/:lang"
              element={<EmailVerifyPage />}
            />
            {/* <Route path="/verifyEmail" element={<EmailVerifyPage />} /> */}
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <GlobalDashboard />
                </RequireAuth>
              }
            >
              <Route index element={<GlobalHome />} />
              {/* Time sheet start */}
              <Route
                path="/dashboard/time-sheet-dashboard"
                element={<TimeCounter />}
              >
                <Route index element={<TimeCounterDashboard />} />
                <Route
                  path="/dashboard/time-sheet-dashboard/time-sheet"
                  element={<TimeSheetDashboard />}
                />
              </Route>
              {/* Time sheet end */}
              {/* client Route */}
              <Route path="/dashboard/client/jobs" element={<Jobs />}>
                <Route index element={<Job />} />

                <Route
                  path="/dashboard/client/jobs/inviteFreelancer"
                  element={<InviteJob />}
                >
                  <Route index element={<Search />} />
                  <Route
                    path="/dashboard/client/jobs/inviteFreelancer/search/details"
                    element={<InviteFreelancerDetails />}
                  />
                  <Route
                    path="/dashboard/client/jobs/inviteFreelancer/freelancerInviteList"
                    element={<InviteFreelancerList />}
                  />
                  <Route
                    path="/dashboard/client/jobs/inviteFreelancer/details"
                    element={<InviteFreelancerDetails />}
                  />

                  <Route
                    path="/dashboard/client/jobs/inviteFreelancer/freelancerHire"
                    element={<FreelancerHire />}
                  />
                  <Route
                    path="/dashboard/client/jobs/inviteFreelancer/saved"
                    element={<FreelancerSaved />}
                  />
                  <Route
                    path="/dashboard/client/jobs/inviteFreelancer/saved/details"
                    element={<InviteFreelancerDetails />}
                  />
                </Route>

                <Route path="/dashboard/client/jobs/hire" element={<Hire />}>
                  <Route index element={<Hired />} />
                  {/* <Route
                    path="/dashboard/client/jobs/hire/offer"
                    element={<Offer />}
                  /> */}
                </Route>
                <Route
                  path="/dashboard/client/jobs/hire/freelancer/view/contract/:jobId/:freelancerId"
                  element={<HireViewContract />}
                />

                <Route
                  path="/dashboard/client/jobs/reviewProposal"
                  element={<ReviewProposals />}
                >
                  <Route index element={<AllProposals />} />
                  <Route
                    path="/dashboard/client/jobs/reviewProposal/freelancer/details"
                    element={<InviteFreelancerDetails />}
                  />
                  <Route
                    path="/dashboard/client/jobs/reviewProposal/shortlisted"
                    element={<Shortlisted />}
                  />
                  <Route
                    path="/dashboard/client/jobs/reviewProposal/decline/freelancer"
                    element={<DeclineFreelancer />}
                  />
                  <Route
                    path="/dashboard/client/jobs/reviewProposal/shortlisted/freelancer/details"
                    element={<InviteFreelancerDetails />}
                  />
                </Route>
              </Route>
              <Route
                path="/dashboard/client/jobs/view/offer/:jobId/:freelancerId"
                element={<ViewOffer />}
              />
              <Route
                path="/dashboard/client/campaigns"
                element={<Campaigns />}
              />

              {/* Client contract start   */}
              <Route path="/dashboard/client/contract" element={<Contract />} />
              <Route
                path="/dashboard/client/contract/details/:id"
                element={<ContractDetailsIndex />}
              >
                <Route index element={<ContractDetails />} />
                <Route
                  path="/dashboard/client/contract/details/:id/manage/freelancer"
                  element={<ManageFreelancer />}
                />
                <Route
                  path="/dashboard/client/contract/details/:id/feedback"
                  element={<ManageFreelancer />}
                />
              </Route>
              <Route
                path="/dashboard/client/contract/review/work/:id"
                element={<ReviewWork />}
              ></Route>

              {/* Client contract End   */}

              {/* <Route
                path="/dashboard/client/candidates"
                element={<Candidates />}
              /> */}
              <Route
                path="/dashboard/client/search_resume"
                element={<SearchResume />}
              />
              <Route
                path="/dashboard/client/Interviews"
                element={<Interview />}
              />
              <Route
                path="/dashboard/client/analytics"
                element={<Analytics />}
              />
              <Route path="/dashboard/client/Tools" element={<Tools />} />
              {/* end client */}

              {/* freelancer start */}

              {/*  <Route
            path="/dashboard/freelancer"
            element={
                <ManueItem/>
            }
          >
            <Route index element={<Match />} />
            <Route path="/freelancer/home/recent" element={<Recent />} />
            <Route path="/freelancer/home/saved_job" element={<Saved />} />
          </Route>   */}

              <Route path="/dashboard/jobs" element={<JobsF />} />
              <Route
                path="/dashboard/complete_profile"
                element={<MyProfile />}
              />
              <Route path="/dashboard/message" element={<MessageDashboard />} />

              {/* user setting all route start hear */}
              <Route path="/dashboard/settings" element={<Setting />}>
                <Route index element={<MyProfile />} />
                <Route
                  path="/dashboard/settings/billing_payment"
                  element={<BillingPayment />}
                />
                <Route
                  path="/dashboard/settings/identity_verification"
                  element={<IdentityVerification />}
                />
              </Route>

              {/* freelancer dashboard my jos all route start hear */}
              <Route path="/dashboard/my/save/jobs" element={<MyJobsSaved />} />
              <Route
                path="/dashboard/my/Job/proposal"
                element={<JobProposal />}
              />

              <Route path="/dashboard/my_jobs" element={<MyJobs />}>
                <Route index element={<MyJobsSaved />} />
                <Route
                  path="/dashboard/my_jobs/apply"
                  element={<MyJobsApply />}
                />
                <Route
                  path="/dashboard/my_jobs/interview"
                  element={<MyJobsInterview />}
                />
                <Route
                  path="/dashboard/my_jobs/contract"
                  element={<MyJobsContract />}
                />
              </Route>
              <Route
                path="/dashboard/my_jobs/contract/details/:id"
                element={<MyJobsContractDetails />}
              />

              {/* freelancer end */}

              {/* End  */}
            </Route>
            {/* end dashboard */}

            {/* start freelancer */}
            <Route
              path="/freelancer/jobs"
              element={
                <GuestModeFreelancerPrivateRoute>
                  <JobsF />
                </GuestModeFreelancerPrivateRoute>
              }
            />
            <Route
              path="/freelancer/job/:id"
              element={
                <GuestModeFreelancerPrivateRoute>
                  <JobDetails />
                </GuestModeFreelancerPrivateRoute>
              }
            />
            <Route
              path="/user/profile/from"
              element={
                <RequireAuth>
                  <ProfileFormDashboard />
                </RequireAuth>
              }
            >
              <Route index element={<FormHome />} />
              {/* client */}
              <Route
                path="/user/profile/from/social/client/form"
                element={<SocialProfile />}
              />
              <Route
                path="/user/profile/from/brochures/client/form"
                element={<Brochures />}
              />
              {/* freelancer */}
              <Route
                path="/user/profile/from/experience/freelancer/form"
                element={<Experience />}
              />
              <Route
                path="/user/profile/from/extra/freelancer/form"
                element={<Extra />}
              />
              <Route
                path="/user/profile/from/complete/freelancer/form"
                element={<Complete />}
              />
              {/* Tsm Profile Talent Manager */}

              <Route
                path="/user/profile/from/social/talent/manager/form"
                element={<TalentManageSocialProfileForm />}
              />
              <Route
                path="/user/profile/from/upload/id/talent/manager/form"
                element={<TalentManagerProfileUploadId />}
              />
            </Route>
            {/* start tsm */}
            <Route
              path="/talentManager"
              element={
                <RequireAuth>
                  <TalentManager />
                </RequireAuth>
              }
            />
            {/* end tsm */}
            <Route
              path="/jobs/job/:id"
              element={
                <RequireAuth>
                  <FullJobDetails />
                </RequireAuth>
              }
            />
            <Route
              path="/freelancer/full/details/:id"
              element={
                <RequireAuth>
                  <CompleteProfileDetails />
                </RequireAuth>
              }
            />

            <Route path="/login" element={<Login />} />
            <Route path="/emailVerified" element={<EmailVerification />} />
            <Route path="/recover_password" element={<Forget />} />
          </Routes>
          <ToastContainer />
        </Navbar>
        {!location.pathname.includes("/dashboard") && <Footer />}
      </ErrorBoundary>
    </div>
  );
}

export default App;
