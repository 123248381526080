import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { serverUrl } from "../../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import LeftSlide from "../../../../Client/Jobs/Job/LeftSlide";

const JobDetails = () => {
  const { id } = useParams();
  const [singleJob, setSingleJob] = useState(null);

  // single job data f fetch function
  const fetchJobDetails = async () => {
    try {
      const response = await fetch(`${serverUrl}/freelancer/job/${id}`);
      const data = await response.json();
      if (data?.status === "success") {
        setSingleJob(data?.result);
      }
    } catch (error) {
      console.error("Error fetching job details:", error);
    }
  };

  useEffect(() => {
    fetchJobDetails();
  }, [id]);

  if (!singleJob) {
    return <div>loading .....</div>;
  }

  return (
    <div>
      <div className="card w-full scrollbar-hide bg-base-100">
        <div className="card-body mt-10 lg:px-20">
          <div className="border-2 rounded-lg border-gray-200">
            <LeftSlide job={singleJob} button={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
