import React from "react";
import { Link } from "react-router-dom";

const Privacy = ({ p, i }) => {
  return (
    <div>
      <ol>
        <li>
          <h2>
            {" "}
            <span className="font-semibold pr-3">{i + 1}</span>
            <span>{p.title}</span>
          </h2>
          <p className="mt-3 px-4">{p?.content}</p>
          <ul className="list-disc ml-8 mr-8 mt-3">
            {p?.subContent?.map((c, i) => (
              <li>{c}</li>
            ))}
            <span className="text-underline link text-blue-500 hover:text-blue-800">
              {p?.cookie}
            </span>
            {p?.subContentArr?.map((c, i) => (
              <li>
                <h2>{c?.title}</h2>
                <p>{c.description}</p>
              </li>
            ))}
          </ul>
        </li>
      </ol>
    </div>
  );
};

export default Privacy;
