import React, { useContext, useEffect, useRef, useState } from "react";
import { chatApplicationUrl } from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import Loading from "../../Loading/Loading";
import NoMessageRoomImage from "../../media/Messaging-pana.png";
import DynamicRowsTextarea from "./DynamicRowsTextarea";
import SyncLoader from "react-spinners/SyncLoader";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const TYPING_TIMEOUT = 1000; // 1 seconds
let typingTimer;

const SelectRoomAllMessageList = ({
  handelUserSelectMessageRoom,
  allMessageFriendListRefetch,
}) => {
  const { currentUser, socketIo, setSocketIo, onlineUsers, setOnlineUsers } =
    useContext(globalFunctionOneContext);
  const messageContainerRef = useRef(null);
  const [
    userSelectRoomAllConversionMessage,
    setUserSelectRoomAllConversionMessage,
  ] = useState([]);
  const [
    userSelectRoomAllConversionMessageLoading,
    setUserSelectRoomAllConversionMessageLoading,
  ] = useState(true);
  const [showValueFromTextArea, setShowValueFromTextArea] = useState("");
  const [userTypingMessage, setUserTypingMessage] = useState("");
  const [userTypingCurrentMessage, setUserTypingCurrentMessage] = useState({});
  const [userTypingTrack, setUserTypingTrack] = useState(false);
  const [chatFriendOnlineTrack, setChatFriendOnlineTrack] = useState(false);

  // this is user select room all conversion message fetch function
  const selectRoomAllMessageFetchFnc = async (roomId) => {
    setUserSelectRoomAllConversionMessageLoading(true);
    const res = await fetch(`${chatApplicationUrl}/all/conversion/message`, {
      method: "GET",
      headers: {
        roomId,
        "Content-Type": "application/json",
        // authorization: `bearer ${localStorage.getItem("access_Token")}`,
      },
    });
    const data = await res.json();
    if (data?.data) {
      setUserSelectRoomAllConversionMessage(data?.data);
      setUserSelectRoomAllConversionMessageLoading(false);
    }
  };

  // this is user message send from DB
  const handelUserMessageSend = async (e) => {
    e.preventDefault();
    const from = e.target;
    handelUserTypingMessageSendFromServer();
  };

  // this is userTyping message send for server
  const handelUserTypingMessageSendFromServer = async () => {
    if (
      handelUserSelectMessageRoom?.roomId &&
      currentUser?.PersonID &&
      userTypingMessage
    ) {
      const userMessage = {
        messageUserId: currentUser?.PersonID,
        messageRoomId: handelUserSelectMessageRoom?.roomId,
        messageContent: userTypingMessage,
      };
      typingTimer = "";
      stopTyping(handelUserSelectMessageRoom?.roomId, currentUser?.PersonID);
      socketIo?.emit("sendMessage", userMessage);
      setShowValueFromTextArea("");
      const res = await fetch(`${chatApplicationUrl}/send/message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // authorization: `bearer ${localStorage.getItem("access_Token")}`,
        },
        body: JSON.stringify(userMessage),
      });
      const data = await res.json();
      setUserTypingCurrentMessage(userMessage);
      setUserTypingMessage("");
      allMessageFriendListRefetch();
    }
  };

  // When a user starts typing
  const startTyping = (roomId, PersonID) => {
    if (socketIo) {
      socketIo.emit("startTyping", roomId, PersonID);
    }
  };

  // When a user stops typing
  const stopTyping = (roomId, PersonID) => {
    if (socketIo) {
      socketIo.emit("stopTyping", roomId, PersonID);
    }
  };

  // user message typing handelar
  const typingHandler = (e) => {
    // setNewMessage(e.target.value);

    if (!socketIo) return;
    if (!userTypingTrack) {
      setUserTypingTrack(true);
      startTyping(handelUserSelectMessageRoom?.roomId, currentUser?.PersonID);
    }
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      stopTyping(handelUserSelectMessageRoom?.roomId, currentUser?.PersonID);
      setUserTypingTrack(false);
    }, TYPING_TIMEOUT);
  };

  // user select room change then new room conversion message fetch function call
  useEffect(() => {
    if (handelUserSelectMessageRoom?.roomId) {
      selectRoomAllMessageFetchFnc(handelUserSelectMessageRoom?.roomId);
    }
  }, [handelUserSelectMessageRoom?.roomId]);

  // Auto-scroll to the bottom when new messages are added
  useEffect(() => {
    // Auto-scroll to the bottom when new messages are added
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [userSelectRoomAllConversionMessage]);

  // user typing new message set
  useEffect(() => {
    setUserSelectRoomAllConversionMessage([
      ...userSelectRoomAllConversionMessage,
      { message: userTypingCurrentMessage, viewMessageResult: [] },
    ]);
  }, [userTypingCurrentMessage]);

  // this is socket setup function
  useEffect(() => {
    if (socketIo && currentUser) {
      socketIo.emit("setup", currentUser);
      socketIo.emit(
        "joinRoom",
        handelUserSelectMessageRoom?.roomId,
        currentUser?.PersonID
      );
    }
  }, [currentUser, handelUserSelectMessageRoom?.roomId, socketIo]);

  // Listen for typing events
  useEffect(() => {
    if (socketIo) {
      const handleUserTyping = (roomDAta) => {
        setUserSelectRoomAllConversionMessage([
          ...userSelectRoomAllConversionMessage,
          { id: 1, messageTyping: true },
        ]);
      };
      const handleUserStoppedTyping = (roomDAta) => {
        const typingDataRemove = userSelectRoomAllConversionMessage.filter(
          (message) => message?.id !== 1
        );
        setUserSelectRoomAllConversionMessage(typingDataRemove);
      };
      if (currentUser) {
        socketIo.on("userTyping", handleUserTyping);
        socketIo.on("userStoppedTyping", handleUserStoppedTyping);
        return () => {
          socketIo.off("userTyping", handleUserTyping);
          socketIo.off("userStoppedTyping", handleUserStoppedTyping);
        };
      }
    }
  }, [currentUser, userSelectRoomAllConversionMessage, socketIo]);

  // this function new message getting
  useEffect(() => {
    if (socketIo) {
      const handleNewMessageReceived = (receivedNewMessage) => {
        const typingDataRemove = userSelectRoomAllConversionMessage.filter(
          (message) => message?.id !== 1
        );
        setUserSelectRoomAllConversionMessage([
          ...typingDataRemove,
          { message: receivedNewMessage, viewMessageResult: [] },
        ]);
      };

      socketIo.on("newMessageReceived", handleNewMessageReceived);

      return () => {
        socketIo.off("newMessageReceived", handleNewMessageReceived);
      };
    }
  }, [socketIo, userSelectRoomAllConversionMessage]);

  // user online offline track
  useEffect(() => {
    if (handelUserSelectMessageRoom?.participateUsers) {
      const isAnyUserOnline = handelUserSelectMessageRoom.participateUsers.some(
        (user) => onlineUsers.includes(user?.PersonID)
      );

      setChatFriendOnlineTrack(isAnyUserOnline);
    }
  }, [handelUserSelectMessageRoom?.participateUsers, onlineUsers]);

  //   user No message room select then default image set
  if (!handelUserSelectMessageRoom?.roomId) {
    return (
      <>
        <div className="h-[88vh] flex flex-col justify-center items-center">
          <div>
            <img className="w-1/3 mx-auto" src={NoMessageRoomImage} alt="" />
          </div>
          <div>
            <h2 className="font-bold text-center text-lg">You have message</h2>
            <p className="text-center">Select a conversation to read </p>
          </div>
        </div>
      </>
    );
  }

  // this is this component loading set
  if (userSelectRoomAllConversionMessageLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="ml-5 pt-5 flex gap-3 mb-5">
        <div
          className={`avatar placeholder ${
            chatFriendOnlineTrack ? "online" : "offline"
          }
          `}
        >
          <div className="bg-neutral text-neutral-content rounded-full w-14">
            {handelUserSelectMessageRoom?.isGroup === false && (
              <span className="text-xl font-bold">
                {handelUserSelectMessageRoom?.firstName.charAt(0).toUpperCase()}
              </span>
            )}
            {handelUserSelectMessageRoom?.isGroup === true && (
              <span className="text-xl font-bold">
                {handelUserSelectMessageRoom?.groupName.charAt(0).toUpperCase()}
              </span>
            )}
          </div>
        </div>
        <div>
          {handelUserSelectMessageRoom?.isGroup === false && (
            <h1 className="text-lg font-medium">
              {`${handelUserSelectMessageRoom?.firstName} ${handelUserSelectMessageRoom?.lastName}`}
            </h1>
          )}
          {handelUserSelectMessageRoom?.isGroup === true && (
            <h1 className="text-lg font-medium">
              {handelUserSelectMessageRoom?.groupName}
            </h1>
          )}
          <p className="text-md font-medium">online</p>
        </div>
      </div>

      <div
        className="h-[70vh] overflow-hidden overflow-y-auto px-20"
        ref={messageContainerRef}
      >
        <ul>
          {userSelectRoomAllConversionMessage &&
            userSelectRoomAllConversionMessage.map((msg, i) => (
              <li key={i}>
                <div
                  className={`chat ${
                    currentUser?.PersonID === msg?.message?.messageUserId
                      ? "chat-end"
                      : "chat-start"
                  }`}
                >
                  {!handelUserSelectMessageRoom?.groupName &&
                    !handelUserSelectMessageRoom?.profilePic && (
                      <div className="chat-image avatar">
                        <div className="w-10 rounded-full bg-gray-300">
                          <div className="w-full h-full flex justify-center items-center">
                            <span className="font-medium text-xl">
                              {currentUser?.PersonID ===
                                msg?.message?.messageUserId &&
                                currentUser.firstName.charAt(0).toUpperCase()}
                              {!(
                                currentUser?.PersonID ===
                                msg?.message?.messageUserId
                              ) &&
                                handelUserSelectMessageRoom.firstName
                                  .charAt(0)
                                  .toUpperCase()}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                  {!handelUserSelectMessageRoom?.isGroup === true &&
                    handelUserSelectMessageRoom?.profilePic && (
                      <div className="chat-image avatar">
                        <div className="w-10 rounded-full">
                          <img
                            src={
                              "https://hire.server.elite-professionals.in/" +
                              handelUserSelectMessageRoom?.profilePic
                            }
                            alt={handelUserSelectMessageRoom?.groupName}
                          />
                        </div>
                      </div>
                    )}
                  {!handelUserSelectMessageRoom?.isGroup === false &&
                    handelUserSelectMessageRoom?.profilePic && (
                      <div className="chat-image avatar">
                        <div className="w-10 rounded-full">
                          <img
                            src={
                              "https://hire.server.elite-professionals.in/" +
                              handelUserSelectMessageRoom?.profilePic
                            }
                            alt={handelUserSelectMessageRoom?.firstName}
                          />
                        </div>
                      </div>
                    )}

                  <div
                    className={`chat-bubble ${
                      currentUser?.PersonID === msg?.message?.messageUserId
                        ? "chat-bubble-primary "
                        : "chat-bubble-info "
                    }}`}
                  >
                    {msg?.message?.messageContent && (
                      <span>{msg?.message?.messageContent}</span>
                    )}
                    {msg?.messageTyping && (
                      <span>
                        <SyncLoader color={"black"} loading={true} size={8} />
                      </span>
                    )}
                  </div>
                  <div className="chat-footer">
                    <time className="text-xs opacity-60">12:46</time>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>

      <div className="h-[5rem]">
        <form
          className="flex justify-end items-center w-full px-20 mt-5"
          onSubmit={handelUserMessageSend}
          onChange={(e) => typingHandler(e)}
          onKeyDown={(event) => {
            if (event.key === "Enter" && !event.shiftKey) {
              event.preventDefault();
              handelUserTypingMessageSendFromServer();
            }
          }}
        >
          <div className="w-[60%]">
            <DynamicRowsTextarea
              value={userTypingMessage}
              setValue={setUserTypingMessage}
              showValueFromTextArea={showValueFromTextArea}
              SetShowValueFromTextArea={setShowValueFromTextArea}
              placeholder={"Enter your message"}
              customClassName={
                "textarea textarea-bordered w-full resize-none focus:outline-none focus:border-[#fd03dc] rounded-md"
              }
            />
          </div>
          <button type="submit" className="btn btn-primary btn-sm ml-4">
            send
          </button>
        </form>
      </div>
    </div>
  );
};

export default SelectRoomAllMessageList;
