import React, { useRef, useState } from "react";
import JoditEditor, { Jodit } from "jodit-react";

// const TextAreaEditor = () => {
const TextAreaEditor = ({ content, setContent, config, setConfig }) => {
  const editor = useRef(null);

  // handleTextEditor text function
  const handleTextEditor = () => {
    setConfig({ ...config, textIcons: true });
  };

  // handleTextEditorIcon function
  const handleTextEditorIcon = () => {
    setConfig({ ...config, textIcons: false });
  };

  return (
    <div>
      <div className="flex justify-end items-center bg-gray-50 border-t border-x rounded-t">
        <button
          type="button"
          className="border-l-2 py-1 px-5 hover:bg-[#DCDCDC]"
          onClick={handleTextEditorIcon}
        >
          Icon
        </button>
        <button
          type="button"
          className="border-l-2 py-1 px-5 hover:bg-[#DCDCDC]"
          onClick={handleTextEditor}
        >
          Text
        </button>
      </div>
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        onChange={(newContent) => setContent(newContent)}
      />
    </div>
  );
};

export default TextAreaEditor;
