import React from "react";

const Campaigns = () => {
  return (
    <div>
      <h2>Campaigns</h2>
    </div>
  );
};

export default Campaigns;
