import React from "react";

const ProfileTitle = () => {
  return (
    <div>
      <h2 className=" capitalize font-semibold mt-8">🌁 Your title</h2>
      <div className="divider mb-2 mt-0"></div>

      <div className="form-control   ">
        {/* name="currentLocation" */}
        <label className="label">
          <p>
            Enter a single sentence description of your professional
            skills/experience (e.g. Expert Web Designer with Ajax experience)
          </p>
        </label>
        <input
          name="profile_title"
          type="text"
          placeholder="Type here"
          className="input input-bordered input-sm w-full max-w-xs"
        />
      </div>
    </div>
  );
};

export default ProfileTitle;
