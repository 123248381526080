import React from "react";
import { AiOutlineEdit } from "react-icons/ai";
import BankInfo from "./BankInfo";
import FormPreviewTitle from "./FormPreviewTitle";

const Step3 = ({ formData, formLoading, submitForm, setStep, searchIfsc }) => {
  return (
    <div>
      <div className="px-2">
        <div>
          <div className="flex gap-2  items-end justify-between">
            <div>
              <h2 className="text-3xl font-semibold">Review</h2>
            </div>

            <div>
              <button
                onClick={() => setStep(2)}
                className="btn-sm flex gap-2 btn btn-primary btn-outline">
                <AiOutlineEdit />
                Edit
              </button>
            </div>
          </div>
          <p className="w-[80%]">
            Double check your account info for accuracy, incorrect or mismatch
            account name and number can result in displays and fees{" "}
          </p>
          <BankInfo searchIfsc={searchIfsc} extraElement={true} />
          <h2 className="my-5 card-title">Account holder Information</h2>
          {Object.keys(formData).map((key, index) => (
            <FormPreviewTitle title={key} value={formData[key]} />
          ))}
        </div>
        <div className="flex gap-8 flex-wrap mt-8">
          <button className="btn btn-sm btn-primary" onClick={() => setStep(2)}>
            back
          </button>
          <button
            onClick={submitForm}
            type="submit"
            className="btn btn-sm btn-primary">
            {formLoading ? "loading..." : "next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step3;
