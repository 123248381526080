import React, { useEffect, useRef, useState } from "react";

const DynamicRowsTextarea = ({
  value,
  setValue,
  placeholder,
  customClassName,
  showValueFromTextArea,
  SetShowValueFromTextArea,
}) => {
  const textAreaRef = useRef(null);

  const resizeTextArea = () => {
    if (!textAreaRef.current) {
      return;
    }

    textAreaRef.current.style.height = "auto";
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
  };

  useEffect(() => {
    resizeTextArea();
    window.addEventListener("resize", resizeTextArea);

    return () => {
      window.removeEventListener("resize", resizeTextArea);
    };
  }, [value]);

  return (
    <div className="App">
      <textarea
        className={customClassName}
        placeholder={placeholder}
        style={{
          resize: "none",
          overflow: "hidden",
          minHeight: "10px",
          backgroundColor: "#eee",
        }}
        value={showValueFromTextArea}
        ref={textAreaRef}
        rows={1}
        onChange={(e) => {
          setValue(e.target.value);
          SetShowValueFromTextArea(e.target.value);
          resizeTextArea();
        }}
      />
    </div>
  );
};

export default DynamicRowsTextarea;
