import React from "react";
import HireNavbar from "./HireNavbar";

const Hire = () => {
  return (
    <div>
      <div>
        <div className="card w-full bg-base-100 shadow-xl">
          <div className="card-body">
            <HireNavbar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hire;
