import React, { useContext, useEffect, useState } from "react";
import { globalFunctionOneContext } from "../../../../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import {
  AllSaveFreelancer,
  AllSaveFreelancerDataCount,
} from "../../../../../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import Left from "../../../../FreelancerTemplate/Left";
import Right from "../../../../FreelancerTemplate/Right";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  serverImageUrl,
  serverUrl,
} from "../../../../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { toast } from "react-toastify";
import ModalBody from "../../../../../../Shared/ModalBody/ModalBody";
import WindowsLoadingSpinner from "../../../../../../Shared/WindowsLoadingSpinner/WindowsLoadingSpinner";

const FreelancerSaved = () => {
  const {
    currentUser,
    Loading,
    firebaseUser,
    allMessageFriendList,
    allMessageFriendListRefetch,
    allMessageFriendListLoading,
    singleUserRoomIdFiendFromCurrentFriendList,
  } = useContext(globalFunctionOneContext);
  const [subMenuOpenAndClose, setSubMenuOpenAndClose] = useState(false);
  const [inviteMessageM, setInviteMessageM] = useState(false);
  const [currentPersonDAta, setCurrentPersonDAta] = useState({});

  // get AllSaveFreelancer data get function
  const {
    data,
    refetch: saveFreelancerDataRefetch,
    isLoading,
  } = AllSaveFreelancer(currentUser?.PersonID, 10);

  // get AllSaveFreelancerData count data get function
  const {
    data: allSaveFreelancerDataCount,
    refetch: saveFreelancerDataCountRefetch,
    isLoading: allSaveFreelancerDataCountLoading,
  } = AllSaveFreelancerDataCount(currentUser?.PersonID);

  // handelSendMessage
  const handelSendMessage = async (e) => {
    e.preventDefault();
    const message = e.target.sendMessage.value;
    const roomId = singleUserRoomIdFiendFromCurrentFriendList(
      currentPersonDAta?.PersonID,
      allMessageFriendList?.data
    );
    const messageUserCreateDAta = {
      clientPersonId: firebaseUser?.uid,
      freelancerPersonId: currentPersonDAta?.PersonID,
      message: message,
      messageRequestId: firebaseUser?.uid,
      roomId,
    };

    const res = await fetch(`${serverUrl}/client/message/user/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // authorization: `bearer ${localStorage.getItem("access_Token")}`,
      },
      body: JSON.stringify(messageUserCreateDAta),
    });
    const data = await res.json();

    if (data?.status === "success") {
      e.target.reset();
      allMessageFriendListRefetch();
      setInviteMessageM(false);
      toast.success("Your message send successful !!!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Your message successful no't send !!!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    // const time = new Date().toLocaleTimeString();
    // const date = new Date().toLocaleDateString();

    // if (message) {
    //   const userFormData = {
    //     inviterId: firebaseUser?.uid,
    //     jobRole: null,
    //     PersonID: currentPersonDAta?.PersonID,
    //     jobId: null,
    //     message,
    //     time,
    //     date,
    //     heHasSeen: "freelancer",
    //   };

    //   const res = await fetch(`${serverUrl}/client/add/invite/message`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(userFormData),
    //   });
    //   const data = await res.json();
    //   if (data?.status === "success") {
    //     e.target.reset();
    //     ClientAllMessageDataRefetch();
    //     setInviteMessageM(false);
    //     toast.success("Your message send successful !!!", {
    //       position: "top-center",
    //       autoClose: 2000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   } else {
    //     toast.error("Your message successful no't send !!!", {
    //       position: "top-center",
    //       autoClose: 2000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   }
    // }
  };

  // Save Freelancer delete function
  const handelSaveFreelancerDelete = async (proposal) => {
    if (proposal) {
      const res = await fetch(
        `${serverUrl}/client/save/freelancer/delete/${proposal}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            uid: currentUser?.PersonID,
          },
        }
      );

      const data = await res.json();
      if (data?.status === "success") {
        saveFreelancerDataRefetch();
        saveFreelancerDataCountRefetch();
        toast.success("🦄 This freelancer save successful !!!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("🦄 This freelancer save not successful !!!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  if (!currentUser || isLoading) {
    return (
      <div className="h-[455px]">
        <WindowsLoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      {data?.result &&
        data?.result.map((proposal, i) => (
          <div
            key={i}
            className="grid grid-cols-1 mt-10 pb-10 border-b-2 border-gray-300 md:grid-cols-3"
          >
            <Left proposal={proposal} />
            <Right proposal={proposal}>
              <div className="mt-4 flex items-center gap-2 wrap relative">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    setSubMenuOpenAndClose(i);
                    console.log(i);
                  }}
                >
                  <BsThreeDots />
                </button>
                {subMenuOpenAndClose === i && (
                  <div className="absolute top-12 left-0 border  w-40 min-h-40 rounded bg-gray-50">
                    <ul className="submenu_top_right_arrow">
                      <li className="flex justify-between items-start">
                        <h1 className="ml-2">Select</h1>
                        <button
                          className="btn btn-outline capitalize btn-primary btn-xs rounded"
                          onClick={() => setSubMenuOpenAndClose(false)}
                        >
                          <AiOutlineClose className="text-black text-md" />
                        </button>
                      </li>
                      <li
                        className="border-y border-gray-200  hover:bg-primary hover:border-primary cursor-pointer py-1 px-3 my-2 duration-300 ease-out"
                        onClick={() => {
                          setSubMenuOpenAndClose(false);
                          setCurrentPersonDAta(proposal);
                          setInviteMessageM(true);
                        }}
                      >
                        Message
                      </li>

                      <li
                        className="border-y border-gray-200  hover:bg-primary hover:border-primary cursor-pointer py-1 px-3 my-2 duration-300 ease-out"
                        onClick={() => {
                          setSubMenuOpenAndClose(false);
                          handelSaveFreelancerDelete(proposal?.PersonID);
                        }}
                      >
                        Delete
                      </li>
                    </ul>
                  </div>
                )}

                <button className="btn btn-outline capitalize btn-primary btn-sm">
                  Invite to job
                </button>
                <Link
                  to={`/dashboard/client/jobs/view/offer/${proposal?.jobId}/${proposal?.PersonID}`}
                  className="capitalize btn btn-primary btn-sm"
                >
                  View Offer
                </Link>
              </div>
            </Right>
          </div>
        ))}
      {!data?.result?.length && !isLoading && (
        <div className="h-[500px] flex justify-center items-center">
          <h1 className="font-bold text-2xl">
            Nothing to show save freelancer list is empty
          </h1>
        </div>
      )}

      <ModalBody
        onClose={() => setInviteMessageM(false)}
        isVisible={inviteMessageM}
        customOpacityLayer="bg-opacity-0"
        customClass="card w-full mx-10 md:max-w-md md:mx-auto bg-[#fff] p-5"
        modalCloseCustomButtonAdd={true}
      >
        <div className="flex justify-center sm:justify-between items-start sm:flex-row flex-col-reverse  gap-5 md:gap-40 ">
          <div>
            <div className="flex flex-col sm:flex-row justify-center md:justify-start items-start gap-5">
              <div className="avatar online">
                <div className="w-16 rounded-full">
                  <img
                    src={`${serverImageUrl}/${currentPersonDAta?.profilePhoto}`}
                    alt="This is profile pic"
                    className="rounded-full"
                  />
                </div>
              </div>
              <div>
                <h1 className="font-medium text-lg">
                  {currentPersonDAta?.firstName} {currentPersonDAta?.lastName}
                </h1>
                <p className="text-sm">{currentPersonDAta?.profile_title}</p>
              </div>
            </div>
          </div>
          <button
            className="btn-secondary btn btn-sm btn-circle place-self-end sm:place-self-start"
            onClick={() => {
              setInviteMessageM(false);
              setCurrentPersonDAta({});
            }}
          >
            ✕
          </button>
        </div>

        <form className="px-3 mt-10 mb-5" onSubmit={handelSendMessage}>
          <input
            className="w-full py-2 px-5 rounded-lg focus:border-secondary outline-none border"
            placeholder="Type Your Message"
            name="sendMessage"
          />

          <div className="flex justify-end items-center">
            <button className="btn btn-sm bg-secondary mt-5 px-5" type="submit">
              Send
            </button>
          </div>
        </form>
      </ModalBody>
    </div>
  );
};

export default FreelancerSaved;
