import React from "react";
import { AiOutlineMobile } from "react-icons/ai";
import { GiCosmicEgg } from "react-icons/gi";
import { TbMessages } from "react-icons/tb";
import { FiVideoOff } from "react-icons/fi";
import { SiGoogleclassroom } from "react-icons/si";
import { FaPeopleArrows } from "react-icons/fa";
const CategoriesHome = () => {
  return (
    <div className="bg-[#EBF1F7] category-bg">
      <div className="pt-52 pb-16 container mx-auto">
        <h2 className="text-center text-4xl font-bold mb-2">
          Trending Top <span className="text-primary">Categories</span>
        </h2>
        <p className="text-center">
          Looking for Top Categories?{" "}
          <span className="text-primary">Browse top categories</span>{" "}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 my-10">
          {/* item 1*/}
          <div className="text-center hover:rounded hover:shadow-xl py-16">
            <div>
              <h2 className="bg-white inline-block rounded-full">
                <AiOutlineMobile className=" mx-auto text-6xl p-2" />
              </h2>
            </div>
            <div className="mt-2">
              <h3 className="font-bold text-base text-gray-700">Mobiles</h3>
              <p className="text-sm mt-1">
                Develop great app for your business
              </p>
            </div>
          </div>
          {/* item 2 */}
          <div className="text-center hover:rounded hover:shadow-xl py-16">
            <div>
              <h2 className="bg-white inline-block rounded-full">
                <GiCosmicEgg className=" mx-auto text-6xl p-2" />
              </h2>
            </div>
            <div className="mt-2">
              <h3 className="font-bold text-base text-gray-700">
                Digital Marketing
              </h3>
              <p className="text-sm mt-1">
                Build your brand. Grow your business.
              </p>
            </div>
          </div>
          {/* item 3*/}
          <div className="text-center hover:rounded hover:shadow-xl py-16">
            <div>
              <h2 className="bg-white inline-block rounded-full">
                <TbMessages className=" mx-auto text-6xl p-2" />
              </h2>
            </div>
            <div className="mt-2">
              <h3 className="font-bold text-base text-gray-700">
                Writing & Translation
              </h3>
              <p className="text-sm mt-1">
                Get your words across—in any language.
              </p>
            </div>
          </div>
          {/* item 4*/}
          <div className="text-center hover:rounded hover:shadow-xl py-16">
            <div>
              <h2 className="bg-white inline-block rounded-full">
                <FiVideoOff className=" mx-auto text-6xl p-2" />
              </h2>
            </div>
            <div className="mt-2">
              <h3 className="font-bold text-base text-gray-700">
                Video & Animation
              </h3>
              <p className="text-sm mt-1">
                Bring your story to life with creative videos.
              </p>
            </div>
          </div>
          {/* item 5 */}
          <div className="text-center hover:rounded hover:shadow-xl py-16">
            <div>
              <h2 className="bg-white inline-block rounded-full">
                <SiGoogleclassroom className=" mx-auto text-6xl p-2" />
              </h2>
            </div>
            <div className="mt-2">
              <h3 className="font-bold text-base text-gray-700">
                Programming & Tech
              </h3>
              <p className="text-sm mt-1">
                You think it. A programmer develops it.
              </p>
            </div>
          </div>
          {/* item 6*/}
          <div className="text-center hover:rounded hover:shadow-xl py-16">
            <div>
              <h2 className="bg-white inline-block rounded-full">
                <GiCosmicEgg className=" mx-auto text-6xl p-2" />
              </h2>
            </div>
            <div className="mt-2">
              <h3 className="font-bold text-base text-gray-700">Business</h3>
              <p className="text-sm mt-1">
                Take your business to the next level.
              </p>
            </div>
          </div>

          {/* item */}
          <div className="text-center hover:rounded hover:shadow-xl py-16">
            <div>
              <h2 className="bg-white inline-block rounded-full">
                <FaPeopleArrows className=" mx-auto text-6xl p-2" />
              </h2>
            </div>
            <div className="mt-2">
              <h3 className="font-bold text-base text-gray-700">
                Fun & Lifestyle
              </h3>
              <p className="text-sm mt-1">
                Your life. Your style. Find the balance.
              </p>
            </div>
          </div>
          {/* item */}
          <div className="text-center bg-primary ml-0 md:ml-6 rounded text-white shadow-xl py-16 px-5">
            <div className="mt-2">
              <h3 className="font-bold text-xl">Explore Categories</h3>
              <p className="text-sm mt-1">
                Consectetur adipisicing elit deius temor incididunt utnenbo
              </p>
            </div>
            <div className="mt-6">
              <button className="btn btn-sm bg-white hover:bg-white border-none text-gray-800">
                Show All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesHome;
