import React from "react";

// import Swal from "sweetalert2";

const InvoiceViewer = () => {
  return (
    <>
      {/* The button to open modal */}
      <a href="#my_modal_8" className="btn">
        open modal
      </a>
      {/* Put this part before </body> tag */}
      <div className="modal" id="my_modal_8">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Hello!</h3>
          <p className="py-4">This modal works with anchor links</p>
          <div className="modal-action">
            <a href="#" className="btn">
              Yay!
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceViewer;

/*
 const data = {
    company: "ABC Company",
    email: "company@example.com",
    method: "Credit Card",
    serviceFee: 100,
    cost: 500,
    gst: 50,
    money: 650,
    currency: "USD",
  };const invoiceHTML = `
      <!DOCTYPE html>
      <html>
      <head>
          <title>Invoice Design</title>
          <style>
          body {
              font-family: Arial, sans-serif;
          }
          .invoice {
              max-width: 800px;
              margin: 0 auto;
              padding: 20px;
              border: 1px solid #ccc;
          }
          .invoice-header {
              text-align: center;
              margin-bottom: 20px;
          }
          .company-details {
              text-align: right;
              margin-bottom: 20px;
          }
          .invoice-details {
              margin-bottom: 20px;
          }
          .invoice-table {
              width: 100%;
              border-collapse: collapse;
          }
          .invoice-table th, .invoice-table td {
              padding: 10px;
              border: 1px solid #ccc;
          }
          .invoice-total {
              text-align: right;
          }
      </style>
      </head>
      <body>
      <div className="invoice">
              <div className="invoice-details">
                  <p>Bill To:</p>
                  <p>${data.company}</p>
                  <p>Email: ${data.email} </p>
                  <p>Payment method: ${data.method} </p>
              </div>
              <table className="invoice-table">
                  <tr>
                      <th>Description/MEMO</th>
                      <th>Total</th>
                  </tr>
                  <tr>
                      <td>Service fee</td>
                      <td>${data.serviceFee} ${data.currency}</td>
                  </tr>
                  <tr>
                      <td>labour fee</td>
                      <td>${data.cost} ${data.currency}</td>
                  </tr>
                  <tr>
                      <td>GST</td>
                      <td>${data.gst} ${data.currency}</td>
                  </tr>
              </table>
              <div className="invoice-total">
                  <p>Total: ${data.money} ${data.currency} </p>
              </div>
          </div>
      </body>
      </html>
    `; */
