import React from "react";

const Right = ({ children }) => {
  //   const [modalTwo, setModalTwo] = useState(false);
  return <div>{children}</div>;
};

export default Right;

//   <div>
//     <div className="mt-4 flex items-center gap-2 wrap">
//       <button className="btn btn-primary btn-sm">...</button>
//       <button className="btn btn-outline capitalize btn-primary btn-sm">
//         Shortlist
//       </button>
//       <label
//         // onClick={() => setModalTwo(!modalTwo)}
//         htmlFor="my-modal-5"
//         className="capitalize btn btn-primary btn-sm"
//       >
//         View Offer
//       </label>
//     </div>
//   </div>
// );
