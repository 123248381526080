import { BsFillPlayFill } from "react-icons/bs";
import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

const CounterModal = ({ handleClose, handleSave, startTime }) => {
  const [selectedTime, setSelectedTime] = useState(""); // Assuming you have a way to capture the selected time

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const displayHours = hours > 0 ? `${hours}:` : "";
    const displayMinutes = minutes >= 10 ? minutes : `0${minutes}`;
    const displaySeconds =
      remainingSeconds >= 10 ? remainingSeconds : `0${remainingSeconds}`;

    return `${displayHours}${displayMinutes}:${displaySeconds}`;
  };

  return (
    <div>
      <AnimatePresence>
        <motion.div
          className="fixed z-10 right-0 top-0 h-screen w-full md:w-1/3 bg-white p-4 shadow-xl"
          initial={{ x: "100vw" }}
          animate={{ x: 0 }}
          exit={{ x: "100vw" }}
          transition={{ type: "spring", stiffness: 50 }}>
          {/* Modal content */}
          <div>
            <div className="flex justify-between gap-2 mx-2 items-center">
              <h2 className="card-title">Confirm Clock In</h2>
              <button onClick={handleClose}>X</button>
            </div>
            <div className="divider"></div>
            {/* content box */}
            <div
              style={{
                height: "calc(100vh - 250px)",
              }}
              className="overflow-y-auto">
              {/* user info */}
              <div className="flex items-center gap-4">
                <div>
                  <div className="avatar online">
                    <div className="w-24 rounded-full">
                      <img src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=1528&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                    </div>
                  </div>
                </div>
                <div className="ml-2">
                  <h4 className="text-lg font-semibold">User Name</h4>
                  <p className="text-sm">Clocking from GMT+6</p>
                  <p className="text-sm">Last out 2 hours ago</p>
                  <p className="text-sm">Split time: 12:00 am</p>
                </div>
              </div>
              <div className="divider"></div>

              {/* form */}
              <form className="flex flex-col gap-2">
                {/* 1. Time Input */}
                <label className="flex flex-col">
                  <span className="text-sm">Select time</span>
                  <input
                    type="time"
                    className="border border-gray-300 rounded-md p-2"
                  />
                </label>

                {/* 2. Calendar Input */}
                <label className="flex flex-col">
                  <span className="text-sm">Select Date</span>
                  <input
                    type="date"
                    defaultValue={new Date().toISOString().split("T")[0]}
                    className="border border-gray-300 rounded-md p-2"
                  />
                </label>

                {/* 3. Select Activity Input */}
                <label className="flex flex-col">
                  <span className="text-sm">Select activity:</span>
                  <select className="border border-gray-300 rounded-md p-2">
                    <option>Select activity</option>
                    {/* Add options for activities */}
                  </select>
                </label>

                {/* 4. Select Project Input */}
                <label className="flex flex-col">
                  <span className="text-sm">Select project:</span>
                  <select className="border border-gray-300 rounded-md p-2">
                    <option>Select project</option>
                    {/* Add options for projects */}
                  </select>
                </label>

                {/* 5. Add Note Textarea */}
                <label className="flex flex-col">
                  <span className="text-sm">Add note:</span>
                  <textarea
                    placeholder="Add note"
                    className="border border-gray-300 rounded-md p-2"></textarea>
                </label>
              </form>
            </div>
            {/* footer  */}
            <div className="divider"></div>

            <div className="flex justify-end gap-3">
              <button
                onClick={handleClose}
                className="text-primary font-semibold">
                cancel
              </button>
              <button
                onClick={() => handleSave(selectedTime)}
                className="btn btn-sm btn-primary">
                save
              </button>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
      ;
    </div>
  );
};
export default CounterModal;
