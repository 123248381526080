import React from "react";
import { AiFillFolderOpen } from "react-icons/ai";
import { BsFillPersonPlusFill } from "react-icons/bs";

const HomeOne = ({ job, setJob }) => {
  return (
    <div>
      <div className="w-full my-4 ">
        <div className="card max-w-3xl mx-auto my-4 bg-base-100 shadow-xl">
          <div className="card-body">
            <div className="flex justify-between gap-2">
              <h2 className="card-title">Your Postings</h2>
              <h2 className="text-primary underline">See all postings</h2>
            </div>
            <div className="text-center">
              <h2>
                <AiFillFolderOpen className=" font-bold text-primary block mx-auto text-[250px] text-center" />
              </h2>
              <h2 className="text-xl font-bold text-black">
                No Active Job Posts
              </h2>
              <p className="text-black">
                Post a job to the marketplace and let talent come to you
              </p>
            </div>
            <div className="card-actions justify-center">
              <label
                onClick={() => setJob(!job)}
                htmlFor="post_job"
                className="btn btn-primary"
              >
                Post a job
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeOne;
