export const arr = [
  {
    name: "Sarah Johnson",
    position: "Marketing Manager",
    content:
      "Finding reliable freelancers used to be a challenge until I discovered work-elite. The platform has an extensive pool of talented professionals, and the hiring process is straightforward. I've successfully completed multiple projects with exceptional results. work-elite is now my go-to platform for all my outsourcing needs.",
    img: "https://elite-professionals.in/wp-content/uploads/2019/06/client2-1.jpg",
  },
  {
    name: "Emily Parker",
    position: "Project Coordinator",
    content:
      "work-elite has revolutionized the way I find and work with freelancers. The platform offers a wide range of skilled professionals, and the project management tools make it easy to track progress. I have found talented freelancers who have delivered top-notch work within my budget and timeline. Highly recommended!",
    img: "https://elite-professionals.in/wp-content/uploads/2019/06/Testimonial1-1.jpg",
  },
  {
    name: "Michael Brown",
    position: "Creative Director",
    content:
      "As both a freelancer and client on work-elite, I can confidently say it's an incredible platform. As a freelancer, I've connected with clients globally, expanded my portfolio, and grown my business. As a client, I've found skilled freelancers who have exceeded my expectations. work-elite has become an indispensable part of my professional journey.",
    img: "https://elite-professionals.in/wp-content/uploads/2019/06/client2-1.jpg",
  },
  {
    name: "jaiba Anam",
    position: "CEO",
    content:
      "All in all, work-elite has been instrumental in shaping my professional journey, both as a freelancer and a client. I highly recommend this platform to anyone looking to boost their career or find the right talent for their projects. Kudos to the team behind work-elite for creating such a fantastic marketplace for freelancers and clients alike!",
    img: "https://elite-professionals.in/wp-content/uploads/2019/06/Testimonial1-1.jpg",
  },
];
