import React, { useContext, useEffect, useState } from "react";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { useParams } from "react-router-dom";
import {
  FreelancerRequestSingleJobMilestoneGet,
  JobAllMilestoneGet,
  SingleJobHireAllFreelancerGet,
} from "../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import {
  serverImageUrl,
  serverUrl,
} from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { ImLocation2 } from "react-icons/im";
import { BsCurrencyRupee, BsThreeDots } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import ModalBody from "../../Shared/ModalBody/ModalBody";
import { toast } from "react-toastify";
import DrawerBodyWLg from "../../Shared/DrawerBody/DrawerBodyWLg";

const ManageFreelancer = () => {
  const {
    currentUser,
    userCurrentLocationPathParameter,
    setUserCurrentLocationPathParameter,
    handelCustomDrawerOpenCls,
    setHandelCustomDrawerOpenCls,
    allMessageFriendList,
    allMessageFriendListRefetch,
    singleUserRoomIdFiendFromCurrentFriendList,
  } = useContext(globalFunctionOneContext);
  const parameter = useParams();
  const [subMenuOpenAndClose, setSubMenuOpenAndClose] = useState(false);
  const [handelSendMilestoneModal, setHandelSendMilestoneModal] =
    useState(false);
  const [handelSendMilestoneUserData, setHandelSendMilestoneUserData] =
    useState({});
  const [freelancerRequestMilestone, setFreelancerRequestMilestone] = useState(
    {}
  );
  const [handelManageFreelancerModal, setHandelManageFreelancerModal] =
    useState(false);
  const [sendMessageFreelancer, setSendMessageFreelancer] = useState({});

  // This is Single job hire all freelancer get
  const {
    data: singleJobHireAllFreelancer,
    refetch: singleJobHireAllFreelancerRefetch,
    isLoading: singleJobHireAllFreelancerLoading,
  } = SingleJobHireAllFreelancerGet(currentUser?.PersonID, parameter?.id);
  // this is single job all milestone get function
  const {
    data: allMIlestoneLIst,
    refetch: allMIlestoneLIstRefetch,
    isLoading: allMIlestoneLIstLoading,
  } = JobAllMilestoneGet(parameter?.id);
  // this is single job all milestone get function
  const {
    data: FreelancerRequestSingleJobMilestone,
    refetch: FreelancerRequestSingleJobMilestoneRefetch,
    isLoading: FreelancerRequestSingleJobMilestoneLoading,
  } = FreelancerRequestSingleJobMilestoneGet(
    freelancerRequestMilestone?.personId,
    freelancerRequestMilestone?.jobId
  );

  // handel send milestone send freelancer and milestone states change
  const handelMilestoneSendFreelancer = async (milestone, freelancer) => {
    if (milestone?.milestonePaymentState === "No") {
      const userData = {
        milestoneId: milestone?.id,
        freelancerPersonId: freelancer?.PersonID,
      };

      const res = await fetch(`${serverUrl}/milestone/send/freelancer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // authorization: `bearer ${localStorage.getItem("access_Token")}`,
        },
        body: JSON.stringify(userData),
      });
      const data = await res.json();
      if (data?.status === "success") {
        allMIlestoneLIstRefetch();
        toast.success("Milestone send freelancer successful.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Milestone send freelancer not successful.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const handelFreelancerRequestMilestoneGet = (personId) => {
    if (personId && parameter?.id) {
      setFreelancerRequestMilestone({ personId, jobId: parameter?.id });
    }
  };

  // handelSendMessage
  const handelSendMessage = async (e) => {
    e.preventDefault();
    const message = e.target.sendMessage.value;

    const roomId = singleUserRoomIdFiendFromCurrentFriendList(
      sendMessageFreelancer?.PersonID,
      allMessageFriendList?.data
    );
    const messageUserCreateDAta = {
      clientPersonId: currentUser?.PersonID,
      freelancerPersonId: sendMessageFreelancer?.PersonID,
      message: message,
      messageRequestId: currentUser?.PersonID,
      roomId,
    };

    const res = await fetch(`${serverUrl}/client/message/user/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // authorization: `bearer ${localStorage.getItem("access_Token")}`,
      },
      body: JSON.stringify(messageUserCreateDAta),
    });
    const data = await res.json();
    console.log(messageUserCreateDAta, data);

    if (data?.status === "success") {
      e.target.reset();
      allMessageFriendListRefetch();
      setHandelManageFreelancerModal(false);
      toast.success("Your message send successful !!!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Your message successful no't send !!!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // this is handel usParameter
  useEffect(() => {
    setUserCurrentLocationPathParameter(parameter);
  }, [parameter, setUserCurrentLocationPathParameter]);

  if (
    !currentUser ||
    singleJobHireAllFreelancerLoading ||
    allMIlestoneLIstLoading
  ) {
    return <div>manage freelancer loading ......</div>;
  }

  return (
    <div>
      <div className="card px-2 w-full bg-base-100 shadow-xl my-10">
        <div>
          <ul>
            {singleJobHireAllFreelancer?.freelancerProfileData &&
              singleJobHireAllFreelancer?.freelancerProfileData.map((data) => (
                <li>
                  <div className="flex justify-between items-center px-3 py-2 rounded">
                    <div className="flex justify-start items-end">
                      <div className="flex justify-start items-start gap-4">
                        <div className="avatar">
                          <div className="w-14 rounded-full">
                            <img
                              src={`${serverImageUrl}/${data?.profilePhoto}`}
                              alt="My-Logo"
                            />
                          </div>
                        </div>
                        <div>
                          <h1 className="font-bold">
                            {data?.firstName + " " + data?.lastName}
                          </h1>
                          <p className="flex justify-start items-center">
                            <ImLocation2 /> <span>{data?.country}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <p className="ml-5 flex justify-start items-center">
                      <ImLocation2 /> <span>{"Location"}</span>
                    </p> */}
                    <div>
                      <p className="font-medium">{"freelancer data"}</p>
                      <p className="font-medium">
                        adipisicing elit. Accusantium, alias quod!
                      </p>
                      <p className="font-medium">
                        adipisicing elit. Accusantium, alias quod!
                      </p>
                    </div>
                    <div className="relative">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          setHandelSendMilestoneModal(true);
                          setHandelSendMilestoneUserData(data);
                        }}
                      >
                        Send Milestone
                      </button>
                      <button
                        className="btn btn-circle btn-outline btn-primary btn-sm ml-5"
                        onClick={() =>
                          setSubMenuOpenAndClose(!subMenuOpenAndClose)
                        }
                      >
                        <BsThreeDots className="text-lg" />
                      </button>

                      {subMenuOpenAndClose && (
                        <div className="absolute top-12 right-0 border w-70 min-h-40 rounded bg-gray-50">
                          <ul className="submenu_top_left_arrow">
                            <li className="flex justify-between items-start">
                              <button
                                className="btn btn-outline capitalize btn-primary btn-xs rounded"
                                onClick={() => setSubMenuOpenAndClose(false)}
                              >
                                <AiOutlineClose className="text-black text-md" />
                              </button>
                              <h1 className="mr-2">Select</h1>
                            </li>

                            <li
                              className="border-y border-gray-200 hover:bg-primary hover:border-primary cursor-pointer py-1 px-3 my-2 duration-300 ease-out"
                              onClick={() => {
                                setSubMenuOpenAndClose(false);
                                setHandelManageFreelancerModal(true);
                                setSendMessageFreelancer(data);
                              }}
                            >
                              Message 2
                            </li>

                            <label htmlFor="DrawerBodyWLg">
                              <li
                                className="border-y border-gray-200 hover:bg-primary hover:border-primary cursor-pointer py-1 px-3 my-2 duration-300 ease-out"
                                // onClick={() => setSubMenuOpenAndClose(false)}
                                onClick={() => {
                                  setHandelCustomDrawerOpenCls(
                                    !handelCustomDrawerOpenCls
                                  );
                                  handelFreelancerRequestMilestoneGet(
                                    data?.PersonID
                                  );
                                }}
                              >
                                Proposal new milestones
                              </li>
                            </label>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  <hr className="my-3 border-t border-gray-300" />
                </li>
              ))}
          </ul>
        </div>
      </div>

      <ModalBody
        onClose={() => setHandelSendMilestoneModal(false)}
        isVisible={handelSendMilestoneModal}
        customOpacityLayer="bg-opacity-0"
        customClass="card w-full mx-10 md:max-w-[600px] md:mx-auto bg-[#fff] p-5 rounded-md  overflow-y-auto"
        modalCloseCustomButtonAdd={false}
      >
        <div className="my-5 p-5 max-h-[500px] overflow-y-auto">
          <ol type="1" style={{ listStyleType: "decimal" }}>
            {allMIlestoneLIst?.result &&
              allMIlestoneLIst?.result.map((milestone, i) => (
                <li key={i}>
                  <div className="grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    <div>
                      <p>
                        <span>{milestone?.milestoneDescription}</span>

                        {milestone?.milestoneState &&
                          milestone?.milestoneState === "noActive" && (
                            <span className="btn btn-outline btn-primary btn-xs ml-3">
                              No Active
                            </span>
                          )}
                        {milestone?.milestoneState &&
                          milestone?.milestoneState === "complete" && (
                            <span className="btn btn-outline btn-primary btn-xs ml-3">
                              Complete
                            </span>
                          )}
                        {milestone?.milestoneState &&
                          milestone?.milestoneState === "active" && (
                            <span className="btn btn-outline btn-primary btn-xs ml-3">
                              Active
                            </span>
                          )}
                        {milestone?.milestoneState &&
                          milestone?.milestoneState === "workSubmitted" && (
                            <span className="btn btn-outline btn-primary btn-xs ml-3">
                              Review work
                            </span>
                          )}
                      </p>
                      <p className="flex justify-start items-center">
                        <BsCurrencyRupee />
                        <span>{milestone?.depositAmount}</span>
                        <span className="ml-1">(founded)</span>
                      </p>
                    </div>
                    <div>
                      <p className="ml-5">
                        {(() => {
                          const date = new Date(milestone?.dateLine);
                          const monthNames = [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec",
                          ];
                          const year = date.getFullYear() % 100;
                          const month = monthNames[date.getMonth()];
                          const day = date.getDate();

                          return `${month} ${day} ${year}`;
                        })()}
                      </p>
                    </div>

                    <div>
                      {milestone?.milestonePaymentState &&
                        milestone?.milestoneState &&
                        milestone?.milestonePaymentState === "No" &&
                        milestone?.milestoneState === "active" && (
                          <button className="btn btn-outline btn-primary btn-sm rounded-full px-5">
                            Already Send
                          </button>
                        )}
                      {milestone?.milestonePaymentState &&
                        milestone?.milestoneState &&
                        milestone?.milestonePaymentState === "No" &&
                        milestone?.milestoneState === "noActive" && (
                          <button
                            className="btn btn-outline btn-primary btn-sm rounded-full"
                            onClick={() =>
                              handelMilestoneSendFreelancer(
                                milestone,
                                handelSendMilestoneUserData
                              )
                            }
                          >
                            Send Milestone
                          </button>
                        )}
                      {milestone?.milestonePaymentState &&
                        milestone?.milestoneState &&
                        milestone?.milestonePaymentState === "yes" &&
                        milestone?.milestoneState === "complete" && (
                          <button className="btn btn-outline btn-primary btn-sm rounded-full px-9">
                            Complete
                          </button>
                        )}
                    </div>
                  </div>
                  <hr className="my-3 border-t border-gray-300" />
                </li>
              ))}
          </ol>
        </div>

        {/* <hr className="border-t border-gray-400" /> */}
        <div className="mt-5 px-5 flex justify-end items-start">
          <div>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => setHandelSendMilestoneModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalBody>

      {/* this is Message modal */}
      <ModalBody
        onClose={() => setHandelManageFreelancerModal(false)}
        isVisible={handelManageFreelancerModal}
        customOpacityLayer="bg-opacity-0"
        customClass="card w-full mx-10 md:max-w-md md:mx-auto bg-[#fff] p-5"
        modalCloseCustomButtonAdd={true}
      >
        <div className="relative">
          <div>
            <div className="flex flex-col sm:flex-row justify-center md:justify-start items-start gap-5">
              <div className="avatar online">
                <div className="w-16 rounded-full">
                  <img
                    src={`${serverImageUrl}/${sendMessageFreelancer?.profilePhoto}`}
                    alt="This is profile pic"
                    className="rounded-full"
                  />
                </div>
              </div>
              <div>
                <h1 className="font-medium text-lg">
                  {sendMessageFreelancer?.firstName}{" "}
                  {sendMessageFreelancer?.lastName}
                </h1>
                <p className="text-sm">{sendMessageFreelancer?.country}</p>
              </div>
            </div>
          </div>
          <button
            className="btn-secondary btn btn-sm btn-circle place-self-end sm:place-self-start absolute top-0 right-0"
            onClick={() => {
              setHandelManageFreelancerModal(false);
              setSendMessageFreelancer({});
            }}
          >
            ✕
          </button>
        </div>

        <form className="px-3 mt-10 mb-5" onSubmit={handelSendMessage}>
          <input
            className="w-full py-2 px-5 rounded-lg focus:border-secondary outline-none border"
            placeholder="Type Your Message"
            name="sendMessage"
          />

          <div className="flex justify-end items-center">
            <button className="btn btn-sm bg-secondary mt-5 px-5" type="submit">
              Send
            </button>
          </div>
        </form>
      </ModalBody>

      {handelCustomDrawerOpenCls && (
        <DrawerBodyWLg
          identityId={"DrawerBodyWLg"}
          zIndex={"99"}
          onClose={() => setHandelCustomDrawerOpenCls(false)}
          isVisible={handelCustomDrawerOpenCls}
          customButton={true}
        >
          <div>
            <button
              className="btn-primary btn btn-sm btn-outline btn-circle place-self-end"
              onClick={() => {
                setHandelCustomDrawerOpenCls(!handelCustomDrawerOpenCls);
                setSubMenuOpenAndClose(false);
              }}
            >
              ✕
            </button>
            <h2 className="font-bold text-xl my-2 ml-5 mt-10">
              Request milestone changes
            </h2>

            <div className="mt-10 ml-10">
              <ol type="1" style={{ listStyleType: "decimal" }}>
                {FreelancerRequestSingleJobMilestone?.result &&
                  FreelancerRequestSingleJobMilestone?.result.length > 0 &&
                  FreelancerRequestSingleJobMilestone?.result.map(
                    (milestone, i) => (
                      <li key={i}>
                        <div className="flex justify-between items-center">
                          <div className="flex justify-between items-end w-[30%]">
                            <div>
                              <p>
                                <span>{milestone?.milestoneDescription}</span>
                                {milestone?.milestoneState &&
                                  milestone?.milestoneState === "active" && (
                                    <span className="btn btn-outline btn-primary btn-xs ml-3">
                                      Active
                                    </span>
                                  )}
                                {milestone?.milestoneState &&
                                  milestone?.milestoneState === "complete" && (
                                    <span className="btn btn-outline btn-primary btn-xs ml-3">
                                      complete
                                    </span>
                                  )}
                                {milestone?.milestoneState &&
                                  milestone?.milestoneState ===
                                    "workSubmitted" && (
                                    <span className="btn btn-outline btn-primary btn-xs ml-3">
                                      Check work
                                    </span>
                                  )}
                              </p>
                              <p className="flex justify-start items-center">
                                <BsCurrencyRupee />
                                <span>{milestone?.milestoneAmount}</span>
                                <span className="ml-1">(founded)</span>
                              </p>
                            </div>
                            <div>
                              <p className="ml-5">
                                {(() => {
                                  const date = new Date(
                                    milestone?.milestoneDeadline
                                  );
                                  const monthNames = [
                                    "Jan",
                                    "Feb",
                                    "Mar",
                                    "Apr",
                                    "May",
                                    "Jun",
                                    "Jul",
                                    "Aug",
                                    "Sep",
                                    "Oct",
                                    "Nov",
                                    "Dec",
                                  ];
                                  const year = date.getFullYear() % 100;
                                  const month = monthNames[date.getMonth()];
                                  const day = date.getDate();

                                  return `${month} ${day} ${year}`;
                                })()}
                              </p>
                            </div>
                          </div>
                          <div>
                            <button
                              type="button"
                              className="btn btn-outline btn-sm btn-primary mx-2"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline btn-sm btn-primary mx-2"
                            >
                              Decline
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-primary mx-2"
                            >
                              Accept
                            </button>
                          </div>
                        </div>
                        <hr className="my-3 border-t border-gray-300" />
                      </li>
                    )
                  )}
                {FreelancerRequestSingleJobMilestone?.result &&
                  FreelancerRequestSingleJobMilestone?.result.length === 0 && (
                    <div className="flex justify-center items-center font-bold text-2xl h-[500px]">
                      <h1>No data found!</h1>
                    </div>
                  )}
              </ol>
            </div>
          </div>
        </DrawerBodyWLg>
      )}
    </div>
  );
};

export default ManageFreelancer;
