import React from "react";

const StepperControl = ({ handleClick, currentStep, steps, err }) => {
  console.log(err);
  return (
    <div className="container mt-4 mb-8">
      {/* back button */}
      <button
        onClick={() => handleClick()}
        className={`btn btn-secondary btn-sm ${
          currentStep === 1 ? "opacity-50 hidden" : ""
        }`}
      >
        Back
      </button>
      {/* next button */}

      <button
        type="submit"
        disabled={err}
        onClick={() => handleClick("next")}
        style={{ padding: currentStep === 1 && "0 50px" }}
        className={`btn btn-secondary ${currentStep !== 1 && "btn-sm"} ml-2`}
      >
        {currentStep === 1
          ? "Start Now"
          : currentStep === steps.length - 1
          ? "Confirm"
          : "continue"}
      </button>
    </div>
  );
};

export default StepperControl;
