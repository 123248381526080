import React from "react";
import JobsHeader from "./JobsHeader";
import JobsNavbar from "./JobsNavbar";

const Jobs = () => {
  return (
    <div>
      <JobsHeader />
      <JobsNavbar />
    </div>
  );
};

export default Jobs;
