import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import "swiper/styles.css";
// import required modules
import { Pagination } from "swiper/modules";
import { dummyReview } from "./dummyReview";
import CountryFlag from "./CountryFlag";
import FreelancerRating from "./FreelancerRating";
import { GiSelfLove } from "react-icons/gi";

const ReviewSlider = () => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          "@0.00": {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          "@0.75": {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          "@1.00": {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          "@1.50": {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
        modules={[Pagination]}
        className="mySwiper">
        {dummyReview.map((r, i) => (
          <SwiperSlide key={i}>
            <div className="bg-white rounded-lg">
              <div className="my-3 wt-freelancer">
                <div className="shadow-xl  text-gray-400  text-xl py-6 relative my-10">
                  <div className=" px-8 my-10">
                    <h2 className=" text-blue-400 hover:text-primary cursor-pointer text-sm">
                      {r.name}
                    </h2>
                    <FreelancerRating rating={r.rating} />
                  </div>

                  <div className="divider"></div>
                  <div className=" px-8 flex gap-2 justify-between">
                    <div className="flex gap-2">
                      <CountryFlag countryCode={r.countryCode} />
                      <h2>{r.country}</h2>
                    </div>
                    <div className="flex gap-2 items-center">
                      <GiSelfLove />
                      <h2>Save</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default ReviewSlider;
