import React from "react";
import PostJobModalHeading from "../../Shared/PostJobModalHeading";
import AddLocation from "./AddLocation";
import AddSkill from "./AddSkill";
import JobCategory from "./JobCategory";
import JobDescription from "./JobDescription";
import JobDetails from "./JobDetils";
import JobLanguage from "./JobLanguage";
import Prices from "./Prices";

const Form = ({ register, dynamicInputData, setAmount }) => {
  const { userSkill, setUserSkill } = dynamicInputData;
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.size > 5 * 1024 * 1024) {
      alert("File size exceeds 5MB limit!");
      event.target.value = null; // Reset the file input
    }
  };

  return (
    <div className="mx-4">
      <PostJobModalHeading headingText="Job description" />
      <JobDescription register={register} />
      <PostJobModalHeading headingText="Prices" />
      <Prices setAmount={setAmount} register={register} />
      <div className="mt-5 mb-4">
        <div className="form-control w-full">
          <label className="cursor-pointer label">
            <span className="card-title">
              <div className="divider divider-horizontal mr-0 ml-1"></div>
              Will this project require milestone payments?
            </span>
            <input
              {...register("projectMileStonePayment", { required: true })}
              type="checkbox"
              className="toggle toggle-primary toggle-sm"
            />
          </label>
        </div>
      </div>

      <PostJobModalHeading headingText=" Job Categories" />
      <JobCategory register={register} />

      <PostJobModalHeading headingText="Language" />
      <JobLanguage register={register} />

      <PostJobModalHeading headingText=" Job Details" />
      <JobDetails register={register} />

      <PostJobModalHeading headingText="Skills Required" />
      <AddSkill
        register={register}
        userSkill={userSkill}
        setUserSkill={setUserSkill}
        name="userSkill"
        placeholder="Type your Skill"
      />
      <PostJobModalHeading headingText="Upload Relevant project file" />

      <input
        {...register("projectFile")}
        type="file"
        onChange={handleFileChange}
        // accept="pdf/*"
        multiple={false}
        className="file-input file-input-bordered file-input-primary w-full"
      />
      <div className="mt-5 mb-4">
        <div className="form-control w-full">
          <label className="cursor-pointer label">
            <span className="card-title">
              <div className="divider divider-horizontal mr-0 ml-1"></div>
              Attachments
            </span>
            <span className="flex items-center gap-2">
              <span className="text-sm">
                Show "Attachments" on Job detail page
              </span>
              <input
                {...register("attachMent", { required: true })}
                type="checkbox"
                className="toggle toggle-primary toggle-sm"
              />
            </span>
          </label>
        </div>
      </div>
      <PostJobModalHeading headingText="Location" />
      <AddLocation register={register} />
      <h2 className="text-sm mt-4">
        Update all the latest changes made by you, by just clicking on "save &
        Update button
      </h2>
    </div>
  );
};

export default Form;
