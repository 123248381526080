import React, { useState } from "react";

const InviteModalDown = ({ postJobs, setJobId }) => {
  // console.log(postJobs);
  const [jobTitle, setJobTitle] = useState("");
  /* console.log(jobTitle); */
  const findId = postJobs.find((job) => job?.job_title === jobTitle);

  setJobId(findId?.job_id);
  return (
    <div>
      <h3 className="font-bold py-2">Message</h3>
      <select
        required
        onChange={(e) => setJobTitle(e.target.value)}
        name="job_role"
        className="select select-bordered w-full"
      >
        <option disabled value="" selected>
          Select Job?
        </option>
        {postJobs.map((job, i) => (
          <option key={i} value={job?.job_title}>
            {job.job_title}
          </option>
        ))}
      </select>

      <textarea
        required
        name="message"
        className="textarea mt-2 w-full h-52 textarea-bordered"
        placeholder="I have reviewed your Resume &amp; found it suitable for QA profile. If you are interested then share your updated resume. Thanks and Regards Vaishali HR."
      ></textarea>
    </div>
  );
};

export default InviteModalDown;
