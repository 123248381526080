import React, { useContext, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { BiArrowBack } from "react-icons/bi";
import { MdOutlineDone } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BiggestAchievement from "./BiggestAchievement";
import DescribeJob from "./DescribeJob";
import ShowLink from "./ShowLink";
import { auth } from "../../../../config/firebaseConfig/firebaseConfig";
import { serverUrl } from "../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { SingleUserProfileGet } from "../../../../hooks/useUser";

const Extra = () => {
  const { firebaseUser, currentUser } = useContext(globalFunctionOneContext);
  const [describeJobIda, setDescribeJobIda] = useState({});
  const navigate = useNavigate();
  const PersonID = firebaseUser?.uid;
  const { data, isLoading, error, refetch } = SingleUserProfileGet(
    currentUser?.PersonID
  );
  // console.log(data?.result?.work_at_company_size  );

  // all from data get function
  const formSubmit = (e) => {
    e.preventDefault();
    // console.log(e.target);
    const achievement = e.target.achievement.value;
    const linkedin = e.target.linkedin.value;
    const github = e.target.github.value;
    const stackOverflow = e.target.stackOverflow.value;
    const portfolio = e.target.portfolio.value;
    const data = {
      achievement,
      linkedin,
      github,
      stackOverflow,
      portfolio,
      describeJobIda,
    };
    fetch(`${serverUrl}/userProfile/freelancer-user-extra-exp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        uid: PersonID,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          navigate("/user/profile/from/complete/freelancer/form");
          // console.log(data);
        }
      });
  };
  return (
    <div className="my-10">
      <form onSubmit={formSubmit}>
        <BiggestAchievement />
        <DescribeJob
          describeJobIda={describeJobIda}
          setDescribeJobIda={setDescribeJobIda}
        />
        <ShowLink />
        <div className="flex justify-end gap-4 my-4">
          <button
            onClick={() => navigate(-1)}
            className="btn btn-sm flex gap-2 items-center btn-primary"
          >
            <BiArrowBack />
            <span>Back</span>
          </button>
          <button
            type="submit"
            // onClick={() => navigate("/compleat")}
            className="btn btn-sm flex gap-2 items-center btn-primary"
          >
            <MdOutlineDone />
            <span>save</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Extra;
