import React from "react";

const Faq = () => {
  return (
    <>
      <div className="container mx-auto">
        <style
          dangerouslySetInnerHTML={{
            __html:
              '\n      <!--\n       /* Font Definitions */\n       @font-face\n      \t{font-family:"Cambria Math";\n      \tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n       /* Style Definitions */\n       p.MsoNormal, li.MsoNormal, div.MsoNormal\n      \t{margin:0in;\n      \tline-height:115%;\n      \tfont-size:11.0pt;\n      \tfont-family:"Arial",sans-serif;}\n      h1\n      \t{margin-top:20.0pt;\n      \tmargin-right:0in;\n      \tmargin-bottom:6.0pt;\n      \tmargin-left:0in;\n      \tline-height:115%;\n      \tpage-break-after:avoid;\n      \tfont-size:20.0pt;\n      \tfont-family:"Arial",sans-serif;\n      \tfont-weight:normal;}\n      h2\n      \t{margin-top:.25in;\n      \tmargin-right:0in;\n      \tmargin-bottom:6.0pt;\n      \tmargin-left:0in;\n      \tline-height:115%;\n      \tpage-break-after:avoid;\n      \tfont-size:16.0pt;\n      \tfont-family:"Arial",sans-serif;\n      \tfont-weight:normal;}\n      h3\n      \t{margin-top:16.0pt;\n      \tmargin-right:0in;\n      \tmargin-bottom:4.0pt;\n      \tmargin-left:0in;\n      \tline-height:115%;\n      \tpage-break-after:avoid;\n      \tfont-size:14.0pt;\n      \tfont-family:"Arial",sans-serif;\n      \tcolor:#434343;\n      \tfont-weight:normal;}\n      h4\n      \t{margin-top:14.0pt;\n      \tmargin-right:0in;\n      \tmargin-bottom:4.0pt;\n      \tmargin-left:0in;\n      \tline-height:115%;\n      \tpage-break-after:avoid;\n      \tfont-size:12.0pt;\n      \tfont-family:"Arial",sans-serif;\n      \tcolor:#666666;\n      \tfont-weight:normal;}\n      .MsoChpDefault\n      \t{font-family:"Arial",sans-serif;}\n      .MsoPapDefault\n      \t{line-height:115%;}\n      @page WordSection1\n      \t{size:8.5in 11.0in;\n      \tmargin:1.0in 1.0in 1.0in 1.0in;}\n      div.WordSection1\n      \t{page:WordSection1;}\n       /* List Definitions */\n       ol\n      \t{margin-bottom:0in;}\n      ul\n      \t{margin-bottom:0in;}\n      -->\n    ',
          }}
        />
        <div className="WordSection1">
          <h1 style={{ marginTop: "24pt", pageBreakAfter: "auto" }}>
            <a name="_iop6a9lt3t5" />
            <b>
              <span lang="EN" style={{ fontSize: "23pt", lineHeight: "115%" }}>
                For Client
              </span>
            </b>
          </h1>
          <ol style={{ marginTop: "0in" }} start={1} type={1}>
            <li
              className="MsoNormal"
              style={{ marginTop: "12pt", marginBottom: "12pt" }}>
              <b>
                <span lang="EN">How to Hire</span>
              </b>
            </li>
          </ol>
          <h1 style={{ marginTop: "24pt", pageBreakAfter: "auto" }}>
            <a name="_s8j63md1w1ir" />
            <b>
              <span lang="EN" style={{ fontSize: "23pt", lineHeight: "115%" }}>
                Choose your way to get work done
              </span>
            </b>
          </h1>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">
              Find freelancers and run projects your way at the world’s work
              marketplace.
            </span>
          </p>
          <h3 style={{ marginTop: "14pt", pageBreakAfter: "auto" }}>
            <a name="_vqert4549kg1" />
            <b>
              <span
                lang="EN"
                style={{
                  fontSize: "13pt",
                  lineHeight: "115%",
                  color: "black",
                }}>
                Post a job and hire a pro
              </span>
            </b>
          </h3>
          <h4
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "2pt",
              marginLeft: "0in",
              pageBreakAfter: "auto",
            }}>
            <a name="_kbmjcbteg189" />
            <b>
              <span
                lang="EN"
                style={{
                  fontSize: "11pt",
                  lineHeight: "115%",
                  color: "black",
                }}>
                Connect with talent that gets you with Talent Marketplace
              </span>
            </b>
          </h4>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">
              Post your job on the world’s work marketplace and wait for the
              proposals to flood in from talented people around the world.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">
              Our advanced algorithms help you shortlist candidates who are the
              best fit. And you can check profiles, portfolios, and reviews
              before you give someone the green light.
            </span>
          </p>
          <h3 style={{ marginTop: "14pt", pageBreakAfter: "auto" }}>
            <a name="_hrw83cmqctrh" />
            <b>
              <span
                lang="EN"
                style={{
                  fontSize: "13pt",
                  lineHeight: "115%",
                  color: "black",
                }}>
                Browse and buy projects
              </span>
            </b>
          </h3>
          <h4
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "2pt",
              marginLeft: "0in",
              pageBreakAfter: "auto",
            }}>
            <a name="_h03skgyaqrub" />
            <b>
              <span
                lang="EN"
                style={{
                  fontSize: "11pt",
                  lineHeight: "115%",
                  color: "black",
                }}>
                Start critical work right away with Project Catalog
              </span>
            </b>
          </h4>
          <h2 style={{ marginBottom: "4pt", pageBreakAfter: "auto" }}>
            <a name="_4jc7tghxvj0r" />
            <b>
              <span lang="EN" style={{ fontSize: "17pt", lineHeight: "115%" }}>
                The seal of approval
              </span>
            </b>
          </h2>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">
              You’ll have plenty of help choosing the right person for the job.
              And no matter who you’re hiring you can:
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "0.0001pt",
              marginLeft: "0.5in",
              textIndent: "-0.25in",
            }}>
            <span lang="EN">
              ●
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN">Browse similar projects they’ve worked on</span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginLeft: "0.5in", textIndent: "-0.25in" }}>
            <span lang="EN">
              ●
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN">
              Direct-access to proven talent without unnecessary layers
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginLeft: "0.5in", textIndent: "-0.25in" }}>
            <span lang="EN">
              ●
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN">Check their portfolio</span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginLeft: "0.5in", textIndent: "-0.25in" }}>
            <span lang="EN">
              ●
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN">Check qualifications</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0.5in",
              textIndent: "-0.25in",
            }}>
            <span lang="EN">
              ●
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN">Run a chat or video interview</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <b>
              <span lang="EN">2. Talent Marketplace</span>
            </b>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">Post a job today and hire tomorrow</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <b>
              <span lang="EN">
                Connect with talent that gets you, and hire them to take your
                business to the next level.
              </span>
            </b>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <b>
              <span lang="EN">
                We've got you covered from idea to delivery. Post your job&nbsp;
                and you'll start getting proposals. Once you've found your
                expert, you can talk timings, availability, and prices before
                going ahead.
              </span>
            </b>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <b>
              <span lang="EN">3. Hire an agency</span>
            </b>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">
              <a href="/faq">
                <span style={{ color: "#1155cc" }}>hire</span>
              </a>
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <b>
              <span lang="EN">4.Payroll service</span>
            </b>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">
              <a href="/faq">
                <span style={{ color: "#1155cc" }}>hire</span>
              </a>
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">5. Direct contracts</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">
              <a href="/faq">
                <span style={{ color: "#1155cc" }}>hire</span>
              </a>
            </span>
          </p>
          <h1 style={{ marginTop: "24pt", pageBreakAfter: "auto" }}>
            <a name="_7ecxesz8y2z6" />
            <b>
              <span lang="EN" style={{ fontSize: "23pt", lineHeight: "115%" }}>
                Freelancer
              </span>
            </b>
          </h1>
          <ol style={{ marginTop: "0in" }} start={1} type={1}>
            <li
              className="MsoNormal"
              style={{ marginTop: "12pt", marginBottom: "12pt" }}>
              <b>
                <span lang="EN">How to find work</span>
              </b>
            </li>
          </ol>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">
              <a href="/faq">
                <span style={{ color: "#1155cc" }}>faq</span>
              </a>
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">2. Direct contracts</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">
              <a href="/faq">
                <span style={{ color: "#1155cc" }}>hire</span>
              </a>
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">3. Freelancer jobs</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">
              <a href="/faq">
                <span style={{ color: "#1155cc" }}>hire</span>
              </a>
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">Guidelines for safe job search</span>
          </p>
          <h1 style={{ marginTop: "24pt", pageBreakAfter: "auto" }}>
            <a name="_qal5w9xz3ckd" />
            <b>
              <span lang="EN" style={{ fontSize: "23pt", lineHeight: "115%" }}>
                Guidelines for a Safe Job Search
              </span>
            </b>
          </h1>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">
              If you believe you are a victim of a scam, consider following
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">
              At work-elite, our mission of helping people get jobs includes
              working proactively to help keep predatory content out of search
              results and providing tools to help jobseekers protect their
              personal information and financial well-being. We have several
              teams across the globe dedicated to the safety and authenticity of
              the jobs posted on our platform. Our goal is to maintain the
              highest level of security and privacy of our users and not show
              preference for any job or role while keeping airepro accessible to
              all .
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">
              The following guidelines are designed to help jobseekers recognise
              and avoid fraudulent tactics.
            </span>
          </p>
          <h2 style={{ marginBottom: "4pt", pageBreakAfter: "auto" }}>
            <a name="_sprnb2jynrwf" />
            <b>
              <span lang="EN" style={{ fontSize: "17pt", lineHeight: "115%" }}>
                &nbsp;
              </span>
            </b>
          </h2>
          <h2 style={{ marginBottom: "4pt", pageBreakAfter: "auto" }}>
            <a name="_ma6ac8ohq8yd" />
            <b>
              <span lang="EN" style={{ fontSize: "17pt", lineHeight: "115%" }}>
                Job Search Dos
              </span>
            </b>
          </h2>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <b>
              <span lang="EN">
                Look for verifiable company email addresses.
              </span>
            </b>
            <span lang="EN">
              Larger, more established companies usually have email addresses
              with top level domains that match their websites. Generally,
              communications from such large established companies do not come
              from publicly available addresses like yahoo or gmail. If you are
              contacted by someone with a generic address, ask if they are able
              to conduct communications via a company domain email address. If
              they cannot or will not, proceed with caution.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <b>
              <span lang="EN">
                Watch closely for email addresses with misspelled or “spoofed”
                company names.
              </span>
            </b>
            <span lang="EN">
              These are often similar to, but not exactly matching the actual
              company name. Examples of suspicious addresses include
              “Info@company.<b>net</b>” instead of “Info@company.com,” and
              misspelled company names like “Info@compan<b>ie</b>.com” or
              “Info@comp<b>aa</b>
              <a href="http://ny.com">
                <span style={{ color: "#1155cc" }}>http://ny.com</span>
              </a>
              .”
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <b>
              <span lang="EN">Match the job offer to your application.</span>
            </b>
            <span lang="EN">
              Avoid bait-and-switch scams by confirming that employers that
              respond to your applications are offering the job for which you
              applied.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <b>
              <span lang="EN">
                Be cautious when pursuing positions with salaries, perks and
                flexibility that seem too good to be true.
              </span>
            </b>
            <span lang="EN">
              Ask questions to confirm that the position is salaried (not
              commission only) and that there is a physical base of operations
              when a “work from home” opportunity is advertised.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <b>
              <span lang="EN">Insist on an in-person or video interview. </span>
            </b>
            <span lang="EN">
              Be wary of job offers that come with little or no interview
              process. Scammers often avoid face-to-face interviews by relying
              on chat services, such as Google Hangouts or WhatsApp while
              offering “work from home” positions.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <b>
              <span lang="EN">
                Always report suspicious communications to airepro.
              </span>
            </b>
            <span lang="EN">
              If you are contacted by someone you suspect of fraudulent
              intentions or suspicious business practices, please Contact
              <u>
                <span style={{ color: "#1155cc" }}> </span>
              </u>
              airepro immediately.
            </span>
          </p>
          <h2 style={{ marginBottom: "4pt", pageBreakAfter: "auto" }}>
            <a name="_fetzye4s7oaj" />
            <b>
              <span lang="EN" style={{ fontSize: "17pt", lineHeight: "115%" }}>
                &nbsp;
              </span>
            </b>
          </h2>
          <h2 style={{ marginBottom: "4pt", pageBreakAfter: "auto" }}>
            <a name="_of522sdybf75" />
            <b>
              <span lang="EN" style={{ fontSize: "17pt", lineHeight: "115%" }}>
                Job Search Don'ts
              </span>
            </b>
          </h2>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <b>
              <span lang="EN">
                Never send any form of payment to a potential employer you apply
                to on airepro.
              </span>
            </b>
            <span lang="EN">
              Not only is charging fees a violation of airepro rules for
              companies, these are often a type of scam (see “Types of scams.”)
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <b>
              <span lang="EN">
                Never agree to perform any financial transaction on behalf of a
                potential employer.
              </span>
            </b>
            <span lang="EN">
              This includes, but is not limited to, accepting or making wire
              transfers or cashing checks sent to you in order to make purchases
              on someone’s behalf.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <b>
              <span lang="EN">
                Never agree to a job that involves opening multiple accounts
                and/or posting ads on airepro or on other sites.&nbsp;
              </span>
            </b>
            <span lang="EN">
              While there are certain roles where this activity may be accepted,
              such as recruiting or staffing services, exercise caution if asked
              upfront to open accounts for an employer you have not met. These
              are likely scammers and you could become involved in activities
              that may put you in financial and legal jeopardy.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <b>
              <span lang="EN">
                Never accept money upfront for work you have not performed.
              </span>
            </b>
            <span lang="EN">
              This is a tactic commonly used in financial scams and can put you
              at considerable legal risk.
            </span>
          </p>
          <h2 style={{ marginBottom: "4pt", pageBreakAfter: "auto" }}>
            <a name="_8jop8wytddrb" />
            <b>
              <span lang="EN" style={{ fontSize: "17pt", lineHeight: "115%" }}>
                &nbsp;
              </span>
            </b>
          </h2>
          <h2 style={{ marginBottom: "4pt", pageBreakAfter: "auto" }}>
            <a name="_gou6pyxpxnw6" />
            <b>
              <span lang="EN" style={{ fontSize: "17pt", lineHeight: "115%" }}>
                Types of Scams to Avoid
              </span>
            </b>
          </h2>
          <h3 style={{ marginTop: "14pt", pageBreakAfter: "auto" }}>
            <a name="_4eolnxix9dep" />
            <b>
              <span
                lang="EN"
                style={{
                  fontSize: "13pt",
                  lineHeight: "115%",
                  color: "black",
                }}>
                Money Laundering Scams
              </span>
            </b>
          </h3>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">
              Reputable, legitimate companies should never ask you to perform
              financial transactions or money transfers for them, especially via
              Western Union, MoneyGram, BitCoin or UPI(PayTM, GooglePay,
              PhonePe). Even if you are not asked to transfer any of your own
              money, scammers are often looking for other people to perform scam
              related financial transactions so they do not have to.
            </span>
          </p>
          <h3 style={{ marginTop: "14pt", pageBreakAfter: "auto" }}>
            <a name="_ohdw9bj6m17" />
            <b>
              <span
                lang="EN"
                style={{
                  fontSize: "13pt",
                  lineHeight: "115%",
                  color: "black",
                }}>
                Fee Scams
              </span>
            </b>
          </h3>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">
              It should not cost you money to apply for a job on airepro. Not
              only do fee scams fleece jobseekers, they are also signs that a
              company may be involved in other shady activities. Certain
              legitimate expenditures like uniforms and job tools may be
              required after you accept a position, but are generally deducted
              from your salary after you begin working. Likewise, background
              checks are most often paid for by the employer or offered directly
              to you by a local government agency and should not be paid by you
              in the form of a fee to the employer. Types of fees often levied
              unscrupulously include:
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "0.0001pt",
              marginLeft: "0.5in",
              textIndent: "-0.25in",
            }}>
            <span lang="EN">
              ●
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN">Application fees</span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginLeft: "0.5in", textIndent: "-0.25in" }}>
            <span lang="EN">
              ●
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN">Registration fees</span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginLeft: "0.5in", textIndent: "-0.25in" }}>
            <span lang="EN">
              ●
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN">Recruiter placement fees</span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginLeft: "0.5in", textIndent: "-0.25in" }}>
            <span lang="EN">
              ●
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN">Interview reservations</span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginLeft: "0.5in", textIndent: "-0.25in" }}>
            <span lang="EN">
              ●
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN">Training fees</span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginLeft: "0.5in", textIndent: "-0.25in" }}>
            <span lang="EN">
              ●
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN">Resume formatting/re-writing fees</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0.5in",
              textIndent: "-0.25in",
            }}>
            <span lang="EN">
              ●
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN">Background checks</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <span lang="EN">
              If you have any concerns about a job you find on airepro, or
              receive any communication of a job offer that concerns you, please
              <a href="https://in.indeed.com/support/contact?zlang=en&zlang=ar&articleId=216354123">
                <span style={{ color: "windowtext", textDecoration: "none" }}>
                  {" "}
                </span>
              </a>
              <a href="https://in.indeed.com/support/contact?zlang=en&zlang=ar&articleId=216354123">
                <span style={{ color: "#1155cc" }}>contact</span>
              </a>
              <u>
                <span style={{ color: "#1155cc" }}> </span>airepro
              </u>
              immediately.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "12pt",
              marginRight: "0in",
              marginBottom: "12pt",
              marginLeft: "0in",
            }}>
            <b>
              <span lang="EN">Please note:</span>
            </b>
            <span lang="EN">
              The information provided in this article is for general
              informational purposes only and may not be comprehensive.
            </span>
          </p>
          <p className="MsoNormal">
            <span lang="EN">&nbsp;</span>
          </p>
          <p className="MsoNormal">
            <span lang="EN">&nbsp;</span>
          </p>
          <p className="MsoNormal">
            <span lang="EN">&nbsp;</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default Faq;
