import React from "react";
import { GiCutDiamond } from "react-icons/gi";
const JobsHeader = () => {
  return (
    <div className="my-3 px-3">
      <div className="flex justify-between gap-2 wrap">
        <div className="flex-1">
          <h2 className="card-title">Internal Factors Designer</h2>
        </div>
        <div className="flex-1 flex justify-end items-center">
          <h2 className="text-right">
            <h2 className="text-secondary card-title">
              Reach 6x more top rated talent
            </h2>
            <p>Upgrade this job</p>
          </h2>

          <div className="flex">
            <div className="divider divider-horizontal m-0"></div>
            <GiCutDiamond className="text-primary text-[30px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobsHeader;
