import React, { useContext, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  monthDummyData,
  weeklyDummyData,
  dayDummyData,
} from "./chartDummyData";
import CustomTooltip from "./CustomTooltip";
import { TimePeriodContext } from "../TimePeriodContext";

const TrackedHourChart = () => {
  const [timePeriod, setTimePeriod] = useState([]); // default to 'day'

  const { activeTimePeriod } = useContext(TimePeriodContext);
  console.log(activeTimePeriod);
  useEffect(() => {
    switch (activeTimePeriod) {
      case "day":
        setTimePeriod(dayDummyData);
        break;
      case "week":
        setTimePeriod(weeklyDummyData);
        break;
      case "month":
        setTimePeriod(monthDummyData);
        break;
      default:
        setTimePeriod(dayDummyData);
    }
  }, [activeTimePeriod]);

  let totalLeaveTime = 0;
  let totalWorkHours = 0;

  timePeriod.forEach((item) => {
    totalLeaveTime += item.leave;

    totalWorkHours += item.workHours;
  });
  const convertToHoursAndMinutes = (time) => {
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 60);
    return `${hours}h ${minutes}m`;
  };
  return (
    <div>
      <div className="card w-full bg-base-100 shadow-xl">
        <div className="p-4 bg-white shadow rounded-md relative group">
          {/* card header */}

          <h2 className="text-sm font-semibold">TRACKED HOURS</h2>
          {/* card body start */}
          <div className="grid grid-cols-6 items-center my-3">
            <div className="col-span-1 flex gap-5 flex-wrap flex-col">
              <div className="flex items-center">
                <div
                  className="mr-2 rounded-sm bg-blue-500"
                  style={{ height: "12px", width: "12px" }}></div>
                <div>
                  <div className="text-sm text-gray-400">WORKED HOURS</div>
                  <div className="font-bold text-black">
                    {convertToHoursAndMinutes(totalWorkHours)}
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <div
                  className="mr-2 rounded-sm bg-green-400"
                  style={{ height: "12px", width: "12px" }}></div>
                <div>
                  <div className="text-sm text-gray-400">leave</div>
                  <div className="font-bold text-black">
                    {convertToHoursAndMinutes(totalLeaveTime)}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-5">
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={timePeriod}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip payload={timePeriod} />} />
                  <Legend />
                  <Bar dataKey="workHours" stackId="a" fill="#8884d8" />
                  <Bar dataKey="leave" stackId="a" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* card body end */}
          <button
            className="absolute top-4 right-4 bg-inherit  
                      hover:bg-gray-200 text-inherit hover:text px-2 py-1 rounded-md transform scale-0 group-hover:scale-100 text-sm transition-transform duration-200">
            Go to timeSheets
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrackedHourChart;
