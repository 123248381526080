import React, { useContext, useEffect, useState } from "react";
import { globalFunctionOneContext } from "../../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import {
  AllProposalsData,
  CurrentClientHireAllData,
} from "../../../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import Left from "../../FreelancerTemplate/Left";
import Right from "../../FreelancerTemplate/Right";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import {
  serverImageUrl,
  serverUrl,
} from "../../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { toast } from "react-toastify";
import ModalBody from "../../../../Shared/ModalBody/ModalBody";
import ScheduleInterview from "../../../../Shared/ScheduleInterview/ScheduleInterview";
import { Link } from "react-router-dom";
import { SingleUserProfileGet } from "../../../../../hooks/useUser";
import WindowsLoadingSpinner from "../../../../Shared/WindowsLoadingSpinner/WindowsLoadingSpinner";

const Shortlisted = () => {
  const {
    currentUser,
    Loading,
    windowLoading,
    setWindowLoading,
    firebaseUser,
    allMessageFriendList,
    allMessageFriendListRefetch,
    allMessageFriendListLoading,
    singleUserRoomIdFiendFromCurrentFriendList,
  } = useContext(globalFunctionOneContext);
  const [subMenuOpenAndClose, setSubMenuOpenAndClose] = useState(false);
  const [inviteMessageM, setInviteMessageM] = useState(false);
  const [currentPersonDAta, setCurrentPersonDAta] = useState({});
  const [handelScheduleInterviewModal, setHandelScheduleInterviewModal] =
    useState(false);
  const [scheduleCandidate, setScheduleCandidate] = useState({});
  const [currentHireJobId, setCurrentHireJobId] = useState({});
  const [currentHireJobIdFreelancerId, setCurrentHireJobIdFreelancerId] =
    useState({});

  // AllProposalsData data function
  const { data, refetch, isLoading } = AllProposalsData(
    currentUser?.PersonID,
    "",
    "yes",
    "",
    "No",
    10
  );

  // get single freelancer user profile
  const {
    data: CurrentUserProfileAllDataGet,
    isLoading: CurrentUserAllDataGetLoading,
    error,
  } = SingleUserProfileGet(currentUser?.PersonID);

  // Hire freelancer data fetch
  const {
    data: HireConfirmDAta,
    refetch: HireFreelancerRefetch,
    isLoading: freelancerHireCheck,
  } = CurrentClientHireAllData(currentUser?.PersonID);

  // handelSendMessage
  const handelSendMessage = async (e) => {
    e.preventDefault();
    const message = e.target.sendMessage.value;
    const roomId = singleUserRoomIdFiendFromCurrentFriendList(
      currentPersonDAta?.PersonID,
      allMessageFriendList?.data
    );
    const messageUserCreateDAta = {
      clientPersonId: firebaseUser?.uid,
      freelancerPersonId: currentPersonDAta?.PersonID,
      message: message,
      messageRequestId: firebaseUser?.uid,
      roomId,
    };

    const res = await fetch(`${serverUrl}/client/message/user/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // authorization: `bearer ${localStorage.getItem("access_Token")}`,
      },
      body: JSON.stringify(messageUserCreateDAta),
    });
    const data = await res.json();
    if (data?.status === "success") {
      e.target.reset();
      allMessageFriendListRefetch();
      setInviteMessageM(false);
      toast.success("Your message send successful !!!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Your message successful no't send !!!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    // const time = new Date().toLocaleTimeString();
    // const date = new Date().toLocaleDateString();

    // if (message) {
    //   const userFormData = {
    //     inviterId: firebaseUser?.uid,
    //     jobRole: null,
    //     PersonID: currentPersonDAta?.PersonID,
    //     jobId: null,
    //     message,
    //     time,
    //     date,
    //     heHasSeen: "freelancer",
    //   };

    //   const res = await fetch(`${serverUrl}/client/add/invite/message`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(userFormData),
    //   });
    //   const data = await res.json();
    //   if (data?.status === "success") {
    //     e.target.reset();
    //     ClientAllMessageDataRefetch();
    //     setInviteMessageM(false);
    //     toast.success("🦄 Your message send successful !!!", {
    //       position: "top-center",
    //       autoClose: 2000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   } else {
    //     toast.error("🦄 Your message successful no't send !!!", {
    //       position: "top-center",
    //       autoClose: 2000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   }
    // }
  };

  // handel Freelancer Hire
  const handelFreelancerHire = async (data) => {
    setCurrentHireJobId({ jobId: data?.jobId, freelancerId: data?.PersonID });
    const freelancerHireData = {
      hireIngPersonId: currentUser?.PersonID,
      freelancerId: data?.PersonID,
      jobId: data?.jobId,
    };

    const userData = {
      receiverNotificationPersonId: data?.PersonID,
      senderNotificationPersonId: currentUser?.PersonID,
      senderProfilePicture: CurrentUserProfileAllDataGet?.result?.profilePhoto,
      senderName: currentUser?.firstName + currentUser?.lastName,
      NotificationContent: "your selected and join my company",
      NotificationType: "Hire",
      heHasSeen: "No",
    };

    const res = await fetch(`${serverUrl}/client/freelancer/hire`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ freelancerHireData, userData }),
    });
    const result = await res.json();
    console.log(result);
    if (result?.status === "success") {
      HireFreelancerRefetch();
      HireFreelancerRefetch();
      toast.success("This freelancer hire complete!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error("This freelancer hire failed!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // handel Freelancer Decline function
  const handelFreelancerDecline = async (proposal) => {
    const { jobId, PersonID } = proposal;
    const userData = {
      jobId,
      freelancerId: PersonID,
      freelancerDecline: "yes",
    };
    console.log(userData);
    const res = await fetch(`${serverUrl}/client/freelancer/decline`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });
    const result = await res.json();
    if (result?.status === "success") {
      refetch();
      toast.success("This freelancer Decline complete!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error("This freelancer Decline failed!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (HireConfirmDAta?.result) {
      HireConfirmDAta?.result.map((data) =>
        setCurrentHireJobIdFreelancerId(data)
      );
    }
  }, [HireConfirmDAta?.result]);

  // This is loading
  if (
    !currentUser ||
    isLoading ||
    CurrentUserAllDataGetLoading ||
    freelancerHireCheck
  ) {
    return (
      <div className="h-[455px]">
        <WindowsLoadingSpinner />
      </div>
    );
  }
  return (
    <div>
      {data?.result &&
        data?.result.map((proposal, i) => (
          <div
            key={i}
            className="grid grid-cols-1 mt-10 pb-10 border-b-2 border-gray-300 md:grid-cols-3"
          >
            <Left proposal={proposal} />
            <Right proposal={proposal}>
              <div className="mt-4 flex items-center gap-2 wrap relative">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    setSubMenuOpenAndClose(i);
                    console.log(i);
                  }}
                >
                  <BsThreeDots />
                </button>
                {subMenuOpenAndClose === i && (
                  <div className="absolute top-12 left-0 border  w-40 min-h-40 rounded bg-gray-50">
                    <ul className="submenu_top_right_arrow">
                      <li className="flex justify-between items-start">
                        <h1 className="ml-2">Select</h1>
                        <button
                          className="btn btn-outline capitalize btn-primary btn-xs rounded"
                          onClick={() => setSubMenuOpenAndClose(false)}
                        >
                          <AiOutlineClose className="text-black text-md" />
                        </button>
                      </li>
                      {(currentHireJobIdFreelancerId?.jobId &&
                        currentHireJobIdFreelancerId?.jobId ===
                          proposal?.jobId &&
                        currentHireJobIdFreelancerId?.freelancerId ===
                          proposal?.PersonID) ||
                      currentHireJobId?.freelancerId === proposal?.PersonID ? (
                        <Link to="/dashboard/client/jobs/inviteFreelancer">
                          <li className="border-y border-gray-200  hover:bg-primary hover:border-primary cursor-pointer py-1 px-3 my-2 duration-300 ease-out">
                            Re Hire
                          </li>
                        </Link>
                      ) : (
                        <li
                          className="border-y border-gray-200  hover:bg-primary hover:border-primary cursor-pointer py-1 px-3 my-2 duration-300 ease-out"
                          onClick={() => handelFreelancerHire(proposal)}
                        >
                          Hire
                        </li>
                      )}

                      <li
                        className="border-y border-gray-200  hover:bg-primary hover:border-primary cursor-pointer py-1 px-3 my-2 duration-300 ease-out"
                        onClick={() => {
                          setSubMenuOpenAndClose(false);
                          setInviteMessageM(true);
                          setCurrentPersonDAta(proposal);
                        }}
                      >
                        Message
                      </li>
                      <li
                        className="border-y border-gray-200  hover:bg-primary hover:border-primary cursor-pointer py-1 px-3 my-2 duration-300 ease-out"
                        onClick={() => {
                          setSubMenuOpenAndClose(false);
                          setHandelScheduleInterviewModal(true);
                          setScheduleCandidate(proposal);
                        }}
                      >
                        Schedule Interview
                      </li>
                    </ul>
                  </div>
                )}

                <button
                  className="btn btn-outline capitalize btn-primary btn-sm"
                  onClick={() => {
                    handelFreelancerDecline(proposal);
                  }}
                >
                  Decline
                </button>
                <Link
                  to={`/dashboard/client/jobs/view/offer/${proposal?.jobId}/${proposal?.PersonID}`}
                  className="capitalize btn btn-primary btn-sm"
                >
                  View Offer
                </Link>
              </div>
            </Right>
          </div>
        ))}
      {!data?.result?.length && !isLoading && (
        <div className="h-[500px] flex justify-center items-center">
          <h1 className="font-bold text-2xl">
            There is nothing to show on the freelancer shortlist list
          </h1>
        </div>
      )}

      <ModalBody
        onClose={() => setInviteMessageM(false)}
        isVisible={inviteMessageM}
        customOpacityLayer="bg-opacity-0"
        customClass="card w-full mx-10 md:max-w-md md:mx-auto bg-[#fff] p-4"
        modalCloseCustomButtonAdd={true}
      >
        <div className="flex justify-center sm:justify-between items-start sm:flex-row flex-col-reverse  gap-5 md:gap-40 ">
          <div>
            <div className="flex flex-col sm:flex-row justify-center md:justify-start items-start gap-5">
              <div className="avatar online">
                <div className="w-16 rounded-full">
                  <img
                    src={`${serverImageUrl}/${currentPersonDAta?.profilePhoto}`}
                    alt="This is profile pic"
                    className="rounded-full"
                  />
                </div>
              </div>
              <div>
                <h1 className="font-medium text-lg">
                  {currentPersonDAta?.firstName} {currentPersonDAta?.lastName}
                </h1>
                <p className="text-sm">{currentPersonDAta?.profile_title}</p>
              </div>
            </div>
          </div>
          <button
            className="btn-secondary btn btn-sm btn-circle place-self-end sm:place-self-start"
            onClick={() => {
              setInviteMessageM(false);
              setCurrentPersonDAta({});
            }}
          >
            ✕
          </button>
        </div>

        <form className="px-3 mt-10 mb-5" onSubmit={handelSendMessage}>
          <input
            className="w-full py-2 px-5 rounded-lg focus:border-secondary outline-none border"
            placeholder="Type Your Message"
            name="sendMessage"
          />

          <div className="flex justify-end items-center">
            <button className="btn btn-sm bg-secondary mt-5 px-5" type="submit">
              Send
            </button>
          </div>
        </form>
      </ModalBody>

      <ModalBody
        onClose={() => setHandelScheduleInterviewModal(false)}
        isVisible={handelScheduleInterviewModal}
        customOpacityLayer="bg-opacity-0"
        customClass="card w-full md:max-w-xl md:mx-auto bg-[#fff] p-5"
        modalCloseCustomButtonAdd={false}
      >
        <div className="h-[550px] overflow-y-auto">
          <ScheduleInterview
            scheduleCandidate={scheduleCandidate}
            setHandelScheduleInterviewModal={setHandelScheduleInterviewModal}
          />
        </div>
      </ModalBody>
    </div>
  );
};

export default Shortlisted;
