import React, { useEffect, useState } from "react";
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import { BsStackOverflow } from "react-icons/bs";
import { SiTransportforlondon } from "react-icons/si";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../Loading/Loading";
import "./styleInbt.module.css";
import { serverUrl } from "../../../../hooks/AllServerUrlControl/useAllServerUrlControl";

const CompleteProfileDetails = () => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    data: users,
    isLoading,
    refetch,
  } = useQuery(["SingleUserCompleteProfileDetails", id], () => {
    if (id) {
      fetch(`${serverUrl}/userProfile/singleUser/${id}`).then((res) => {
        return res.json();
      });
    }
  });
  useEffect(() => {
    if (users) {
      setUser(users[0]);
    }
  }, [users]);
  if (isLoading || Object.keys(user).length === 0) {
    return <Loading />;
  }

  console.log(users);
  /*  const {
    firstName,
    lastName,
    country,
    phonNumber,
    dateOfBirth,
    username,
    accountType,
    email,
  } = user;

  const {
    interestedRole,
    currentCtc,
    muchExpectedCtc,
    expectedCtc,
    fixedCtc,
    startedPeriod,
    negotiablePeriod,
    longPeriod,
    currentLocation,
    okaytoRemoteJob,
    desireCitiesWorking,
    citiesNcr,
  } = user;
 */
  const {
    firstName,
    lastName,
    country,
    phonNumber,
    dateOfBirth,
    username,
    accountType,
    email,
    interestedRole,
    currentCtc,
    muchExpectedCtc,
    expectedCtc,
    fixedCtc,
    startedPeriod,
    negotiablePeriod,
    longPeriod,
    currentLocation,
    okaytoRemoteJob,
    desireCitiesWorking,
    citiesNcr,
    address,
    password,
    rules,
    addEducation,
    addUniversity,
    courStartingYear,
    courseEndingYear,
    course,
    courseSpecialization,
    gradingSystem,
    marks,
    otherCourse,
    otherEducation,
    otherUniversity,
    boardName,
    schoolPassingYear,
    schoolMedium,
    englishMarks,
    MathMarks,
    schoolMarks,
    currentCompanyName,
    currentDesignation,
    currentJob,
    currentSalary,
    employmentType,
    jobJoiningDate,
    jobEndingYear,
    skillUsed,
    jobProfile,
    full_time_job_experience,
    report_people,
    top_skill,
    resume,
    bigest_achivement,
    work_at_company_size,
    linkedIn,
    github,
    portfolio,
    stackoverflow,

    hear_aboutUs,
    job_search,
    ready_to_interview,
    hidden_form,
  } = user;

  const userData = {
    phonNumber,
    email,
    country,
    currentCtc,
    muchExpectedCtc,
    expectedCtc,
    fixedCtc,
    startedPeriod,
    negotiablePeriod,
    longPeriod,
    currentLocation,
    okaytoRemoteJob,
    desireCitiesWorking,
    citiesNcr,
    address,
    rules,
    addEducation,
    addUniversity,
    course,
    courStartingYear,
    courseEndingYear,
    courseSpecialization,
    gradingSystem,
    marks,
    otherCourse,
    otherEducation,
    otherUniversity,
    boardName,
    schoolPassingYear,
    schoolMedium,
    englishMarks,
    MathMarks,
    schoolMarks,
    currentCompanyName,
    currentDesignation,
    currentJob,
    currentSalary,
    employmentType,
    jobJoiningDate,
    jobEndingYear,
    skillUsed,
    jobProfile,
    full_time_job_experience,
    report_people,
    bigest_achivement,
    hear_aboutUs,
    job_search,
    ready_to_interview,
    hidden_form,
  };
  // console.log(userData);
  const userDataKey = [
    "phonNumber",
    "email",
    "Country",
    "Current Ctc",
    "Much Expected Ctc",
    "Expected Ctc",
    "Fixed Ctc",
    "Started Period",
    "negotiable Period",
    "Period",
    "Current Location",
    "okay to RemoteJob",
    "Desire Cities Working",
    "Cities Ncr",
    "Address",
    "rules",
    "Education",
    "University",
    "Course",
    "Course Starting Year",
    "Course Ending Year",
    "Course Specialization",
    "Grading System",
    "marks",
    "Other Course",
    "Other Education",
    "Other University",
    "Board Name",
    "School Passing Year",
    "School Medium",
    "English Marks",
    "Math Marks",
    "School Marks",
    "Current Company Name",
    "Current Designation",
    "current Job",
    "Current Salary",
    "Employment Type",
    "Job Joining Date",
    "Job Ending Year",
    "Skill Used",
    "Job Profile",
    "Full Time Job Experience",
    "Report People",
    "Bigest achivement",
    "Hear aboutUs",
    "Job search",
    "Ready To Interview",
    "Hidden Form",
  ];
  // company work json
  const userWorkCompany = JSON.parse(work_at_company_size);
  // userInRole json
  const userIntRoleValue = JSON.parse(interestedRole);
  // user top skill json
  const userTopSkill = JSON.parse(top_skill);

  // userGeneral Data array
  const userDataValue = [];
  // user Companay size array
  const userCompanySizeKey = [];
  const userCompanySizeValue = [];
  // user Int role arrayish
  const userIntRolesValues = [];
  // user userTopSkillArray
  const userTopSkillsKey = [];
  // calling in array
  objKeyValueGenarator(userData, false, userDataValue);
  objKeyValueGenarator(
    userWorkCompany,
    userCompanySizeKey,
    userCompanySizeValue
  );
  objKeyValueGenarator(userIntRoleValue, false, userIntRolesValues);
  objKeyValueGenarator(userTopSkill, userTopSkillsKey, false);

  console.log();
  function objKeyValueGenarator(obj, objKey, objValue) {
    for (const [key, value] of Object.entries(obj)) {
      if (objKey) {
        objKey.push(key);
      }
      if (objValue) {
        if (!value) {
          objValue.push("x");
        }
        objValue.push(value);
      }
    }
  }

  return (
    <div className="container mx-auto">
      <div className="card mx-auto max-w-xl">
        <div className="card-body justify-center flex-wrap">
          <div className="mx-auto">
            <div className="avatar">
              <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                <img
                  src={
                    "https://hire.server.elite-professionals.in/" +
                    user.profile_pic
                  }
                />
              </div>
            </div>
          </div>
          <h2 className=" text-center text-[20px] text-semibold">
            <p>{firstName + " " + lastName}</p>
            <p className="text-[13px]">
              <span className="text-semibold">Account Type: </span>{" "}
              {accountType}
            </p>
          </h2>

          <div className="btn-group btn-group-vertical lg:btn-group-horizontal justify-center">
            <button className="btn btn-sm btn-secondary btn-active">
              Approved
            </button>
            <button className="btn btn-sm btn-secondary">declined</button>
            <button className="btn btn-sm btn-secondary">block</button>
          </div>
          <div>
            <h2 className="card-title justify-center">Job Profile</h2>

            <p className="text-center">
              {user.jobProfile.length === 2
                ? user.jobProfile
                : "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cumque totam accusantium, et iusto quia aut quibusdam soluta expedita harum adipisci."}
            </p>
          </div>

          <div className="">
            <div>
              <div className="mb-2 text-slate-500 flex gap-2 items-center border-b-2  border-dashed">
                <span className="card-title justify-center">
                  User Interest Role
                </span>
              </div>
              <div className="flex gap-2 flex-wrap">
                {userIntRolesValues.map((role, i) => (
                  <span
                    key={i}
                    className="bg-secondary px-3 py-1 text-base-100 opacity-60 rounded-lg"
                  >
                    {role}
                  </span>
                ))}
              </div>
            </div>
            <div>
              <div className="mb-2 text-slate-500 flex gap-2 items-center border-b-2  border-dashed">
                <span className="card-title justify-center">
                  User Top Skill
                </span>
              </div>
              <div className="flex gap-2 flex-wrap">
                {userTopSkillsKey.map((skill, i) => (
                  <span
                    key={i}
                    className="bg-secondary px-3 py-1 text-base-100 opacity-60  rounded-lg"
                  >
                    {skill}
                  </span>
                ))}
              </div>
            </div>

            <div className="text-slate-500 flex gap-2 items-center border-b-2  border-dashed">
              <span className="card-title justify-center">
                User Full Information
              </span>
            </div>
            <div className="flex justify-between  font-semibold text-slate-700 items-center cursor-pointer">
              <div>
                {userDataKey.map((data, i) => (
                  <p key={i}>{data}</p>
                ))}
              </div>

              <div className="mt-2 ml-4">
                {userDataValue.map((data, i) => (
                  <p key={i} className="text-right">
                    {data}
                  </p>
                ))}
              </div>
            </div>
            <div>
              <div className="mb-2 text-slate-500 flex gap-2 items-center border-b-2  border-dashed">
                <span className="card-title justify-center">
                  User Social LInk
                </span>
              </div>
              <div className="flex gap-2 flex-wrap">
                <button className="btn btn-secondary">
                  <span>Linkedin</span>
                  <span>
                    <AiFillLinkedin className=" text-2xl " />
                  </span>
                </button>

                <button className="btn btn-secondary">
                  <span>StackOverflow </span>
                  <span>
                    <BsStackOverflow className=" text-2xl " />
                  </span>
                </button>

                <button className="btn btn-secondary">
                  <span>Github</span>
                  <span>
                    <AiFillGithub className=" text-2xl " />
                  </span>
                </button>
                <button className="btn btn-secondary">
                  <span>Portfolio </span>
                  <span>
                    <SiTransportforlondon className=" text-2xl  " />
                  </span>
                </button>
              </div>
            </div>
            <div className="divider"></div>
          </div>
        </div>
        <div className="flex justify-center my-4">
          <button
            className="btn btn-warning btn-sm"
            onClick={() => {
              navigate(-1);
            }}
          >
            back
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompleteProfileDetails;
