import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { MdOutlineDone } from "react-icons/md";
import { auth } from "../../../../config/firebaseConfig/firebaseConfig";
import { serverUrl } from "../../../../hooks/AllServerUrlControl/useAllServerUrlControl";

const EmploymentHistory = ({ register, showJobPage, setShowJobPage }) => {
  const [user] = useAuthState(auth);
  const PersonID = user?.uid;

  const employment = [
    { name: "currentJob", value: "Yes" },
    { name: "currentJob", value: "No" },
  ];
  const employmentType = [
    { name: "employmentType", value: "Full Time" },
    { name: "employmentType", value: "Internship" },
  ];
  const inputBox = [
    {
      title: "Current Company Name",
      placeHolder: "Type Your Organization",
      name: "currentCompanyName",
    },
    {
      title: "Current Designation",
      placeHolder: "Type Your Designation",
      name: "currentDesignation",
    },
    { title: "Current Salary", placeHolder: "2000", name: "currentSalary" },
    {
      title: "Skill Used",
      placeHolder: "Enter your area of expertise/Specialization",
      name: "skillUsed",
    },
  ];
  const [employerInfo, setEmployerInfo] = useState({});
  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setEmployerInfo({ ...employerInfo, [name]: value });
  };
  let joiningYear = [];
  let currentYear = new Date().getFullYear();
  for (let i = 1950; i <= currentYear; i++) {
    joiningYear.push(i);
  }
  const fullemployerData = () => {
    console.log(employerInfo);
    fetch(`${serverUrl}/userProfile/userjob`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        uid: PersonID,
      },
      body: JSON.stringify(employerInfo),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
  };
  return (
    <div>
      <label htmlFor="drop2">
        <h2 className="uppercase font-semibold mt-10">👝 Employment History</h2>
        <div className="divider mb-2 mt-0"></div>
        <div className="cursor-pointer flex gap-2">
          <p> Add Job</p>
          <label className="swap swap-rotate">
            {/* <!-- this hidden checkbox controls the state --> */}
            <input
              onClick={() => setShowJobPage(!showJobPage)}
              id="drop2"
              type="checkbox"
            />

            {/* <!-- hamburger icon --> */}
            <div className="swap-off fill-current">
              <AiOutlinePlusSquare />
            </div>

            {/* <!-- close icon --> */}
            <div className="swap-on fill-current">
              <AiOutlineMinusSquare />
            </div>
          </label>
        </div>
      </label>

      {/* showing page  */}
      {showJobPage && (
        <div>
          <div className="form-control w-full ">
            <div>
              <h2 className="menu-title mt-4">
                Is this your current employment
              </h2>
              <div className="flex items-center gap-2">
                {employment.map((type, i) => (
                  <label
                    key={i}
                    className="label cursor-pointer flex gap-2 items-center"
                  >
                    <input
                      onChange={handleInputValue}
                      value={type.value}
                      type="radio"
                      name={type.name}
                      className="radio checked:bg-primary"
                    />
                    <span className="label-text">{type.value}</span>
                  </label>
                ))}
              </div>
            </div>
            <div>
              <h2 className="menu-title mt-5">Employment Type</h2>
              <div className="flex items-center gap-2">
                {employmentType.map((type, i) => (
                  <label
                    key={i}
                    className="label cursor-pointer flex gap-2 items-center"
                  >
                    <input
                      onChange={handleInputValue}
                      value={type.value}
                      type="radio"
                      name={type.name}
                      className="radio checked:bg-primary"
                    />
                    <span className="label-text">{type.value}</span>
                  </label>
                ))}
              </div>
            </div>
            {inputBox.map((input, i) => (
              <div key={i} className="form-control w-[50%]  ">
                <label className="label">
                  <span className="label-text">{input.title}</span>
                </label>
                <input
                  type="text"
                  onChange={handleInputValue}
                  name={input.name}
                  placeholder={input.placeholder}
                  className="input input-bordered w-full  "
                />
                {/* <label className="label">

                <span className="label-text-alt">Alt label</span>
              </label> */}
              </div>
            ))}
            <div>
              <h2 className="mt-4 menu-title">Joining Date</h2>

              <div className="flex gap-3 items-center">
                <div>
                  <select
                    onChange={handleInputValue}
                    name="jobJoiningDate"
                    className="select w-full select-bordered"
                  >
                    <option disabled value="" selected>
                      Joining Date
                    </option>

                    {joiningYear?.map((option, i) => (
                      <option key={i} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {/*  <label className="label">
                    <span className="label-text-alt">Alt label</span>
                  </label> */}
                </div>
                <h2 className="menu-title">To</h2>
                <div>
                  <select
                    onChange={handleInputValue}
                    name="jobEndingYear"
                    className="select w-full select-bordered"
                  >
                    <option disabled value="" selected>
                      Ending Year
                    </option>

                    {joiningYear?.map((option, i) => (
                      <option key={i} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {/*  <label className="label">
                    <span className="label-text-alt">Alt label</span>
                  </label> */}
                </div>
              </div>
            </div>
            <textarea
              name="jobProfile"
              onChange={handleInputValue}
              className="textarea mt-4 textarea-primary w-[50%]"
              placeholder="Job Profile"
            ></textarea>
            <div className="flex justify-end gap-4 my-4">
              <button
                onClick={fullemployerData}
                className="btn btn-sm flex gap-2 items-center btn-primary"
              >
                <MdOutlineDone />
                <span>save</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmploymentHistory;
