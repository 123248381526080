import React, { useContext } from "react";
import { serverUrl } from "../../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { globalFunctionOneContext } from "../../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { useNavigate } from "react-router-dom";
import {
  CommonFormBackBtn,
  CommonFormSubmitBtn,
} from "../../../../Shared/AllCommonButton/AllCommonButton";

const Brochures = () => {
  const { firebaseUser, databaseSingleUserRefetch, setConfirmAlertValue } =
    useContext(globalFunctionOneContext);
  const navigate = useNavigate();

  // Handel client Brochures function
  const handelClientBrochures = async (e) => {
    e.preventDefault();
    const clientBrochures = e.target.brochures.files[0];
    const formData = new FormData();
    formData.append("clientBrochures", clientBrochures);
    // console.log(clientBrochures);

    const res = await fetch(`${serverUrl}/client-user-profile/brochures`, {
      method: "POST",
      headers: { Accept: "multipart/form-data", uid: firebaseUser?.uid },
      Credential: "include",
      body: formData,
    });
    const data = await res.json();
    if (data?.status === "success") {
      fetch(
        `${serverUrl}/userProfile/single-user-update/${firebaseUser?.uid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            // authorization: `bearer ${localStorage.getItem("access_Token")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data?.status === "success") {
            console.log(data);
            databaseSingleUserRefetch();
            e.target.reset();
            setConfirmAlertValue(true);
            navigate("/dashboard/complete_profile");
          }
        });
    }
  };

  return (
    <div>
      <h2>Brochures</h2>
      <form onSubmit={handelClientBrochures}>
        <div className="">
          <input
            type="file"
            name="brochures"
            className="shadow-xl bordered file-input w-full max-w-xs"
          />
          {/* submit & Back button wraper  */}
          <div className="flex justify-start items-center w-full mt-10 ">
            <div>
              <CommonFormBackBtn
                type={"button"}
                BtnText={"Back"}
                className={"mr-5 px-10 py-3 text-sm"}
              />
              <CommonFormSubmitBtn
                type={"submit"}
                BtnText={"Save & Update"}
                className={"px-3 py-3 text-sm"}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Brochures;
