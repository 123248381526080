import React, { useContext, useEffect, useState } from "react";
import ReviewNavbar from "./ReviewNavbar";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { AllProposalsData } from "../../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import WindowsLoadingSpinner from "../../../Shared/WindowsLoadingSpinner/WindowsLoadingSpinner";

const ReviewProposals = () => {
  const { currentUser, Loading } = useContext(globalFunctionOneContext);

  // AllProposalsData Data get function
  const { data, refetch, isLoading } = AllProposalsData(
    currentUser?.PersonID,
    "Freelancer",
    "no",
    "",
    "No",
    10
  );

  if (!currentUser || isLoading) {
    return <WindowsLoadingSpinner />;
  }
  return (
    <div>
      <div className="card w-full bg-base-100 shadow-xl">
        <div className="card-body">
          <ReviewNavbar data={data} />
        </div>
      </div>
    </div>
  );
};

export default ReviewProposals;
