import React, { useContext, useEffect, useState } from "react";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import { serverUrl } from "../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { AllJobInvitedData } from "../../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const JobsF = () => {
  const { currentUser, Loading, windowLoading, setWindowLoading } = useContext(
    globalFunctionOneContext
  );

  const [jobs, setJobs] = useState([]);
  const [notUserLogInGetLimitJobs, setNotUserLogInGetLimitJobs] = useState(10);

  const { data, refetch, isLoading } = AllJobInvitedData(
    `${serverUrl}/client/all/invited/Jobs?freelancerPersonId=${currentUser?.PersonID}`
  );

  useEffect(() => {
    if (currentUser) {
      setNotUserLogInGetLimitJobs(100);
    }
  }, [currentUser]);

  useEffect(() => {
    fetch(`${serverUrl}/client/getTotalJob?limit=${notUserLogInGetLimitJobs}`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.result) {
          setJobs(data.result);
          setWindowLoading(false);
        }
      });
  }, [notUserLogInGetLimitJobs, currentUser]);

  if (isLoading || windowLoading) {
    return <div>hello JobsF ...............</div>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-9 gap-10 container mx-auto">
      <div className="col-span-2">
        <LeftSide jobs={jobs} setJobs={setJobs} />
      </div>

      <div className="col-span-7  h-[89vh]  overflow-auto mr-20">
        <RightSide
          jobs={jobs}
          data={data}
          refetch={refetch}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default JobsF;
