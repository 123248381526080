import { useContext, useEffect, useState } from "react";
import { globalFunctionOneContext } from "../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { useParams } from "react-router-dom";

// Home Navbar logo show and hidden function
export const NavbarLogoShowAndHidden = (path, parameter) => {
  const { setNavbarLogoShow, viewOfferJobIdOrFreelancerId } = useContext(
    globalFunctionOneContext
  );

  useEffect(() => {
    // This switch case function determines whether to show or hide the navbar logo based on the 'path'.
    switch (path) {
      case "/dashboard":
      case "/dashboard/":
      case "/dashboard/settings":
      case "/dashboard/settings/":
      case "/dashboard/complete_profile":
      case "/dashboard/complete_profile/":
      case "/dashboard/message":
      case "/dashboard/message/":
      case "/dashboard/client/jobs":
      case "/dashboard/client/jobs/":
      case "/dashboard/client/jobs/inviteFreelancer":
      case "/dashboard/client/campaigns":
      case "/dashboard/client/candidates":
      case "/dashboard/client/search_resume":
      case "/dashboard/client/Interviews":
      case "/dashboard/client/Analytics":
      case "/dashboard/client/Tools":
      case "/dashboard/client/jobs/reviewProposal":
      case "/dashboard/client/jobs/hire":
      case "/dashboard/client/jobs/inviteFreelancer/freelancerInviteList":
      case "/dashboard/client/jobs/inviteFreelancer/saved":
      case "/dashboard/client/jobs/inviteFreelancer/freelancerHire":
      case "/dashboard/client/jobs/reviewProposal/shortlisted":
      case "/dashboard/client/jobs/hire/offer":
      case "/dashboard/client/jobs/inviteFreelancer/details":
      case "/dashboard/client/jobs/inviteFreelancer/saved/details":
      case "/dashboard/client/jobs/reviewProposal/freelancer/details":
      case "/dashboard/client/jobs/reviewProposal/shortlisted/freelancer/details":
      case "/dashboard/client/jobs/inviteFreelancer/search/details":
      case `/dashboard/client/jobs/view/offer/${parameter?.jobId}/${parameter?.freelancerId}`:
      case `/dashboard/client/jobs/hire/freelancer/view/contract/${parameter?.jobId}/${parameter?.freelancerId}`:
      case "/dashboard/client/jobs/reviewProposal/decline/freelancer":
      case "/dashboard/settings/identity_verification":
      case "/dashboard/my_jobs":
      case "/dashboard/my_jobs/apply":
      case "/dashboard/my_jobs/interview":
      case "/dashboard/my_jobs/contract":
      case "/dashboard/client/contract":
      case `/dashboard/client/contract/details/${parameter?.id}`:
      case `/dashboard/client/contract/details/${parameter?.id}/manage/freelancer`:
      case `/dashboard/client/contract/details/${parameter?.id}/feedback`:
      case `/dashboard/my_jobs/contract/details/${parameter?.id}`:
      case "/dashboard/my/save/jobs":
      case "/dashboard/my/Job/proposal":
      case "/dashboard/settings/billing_payment":
      case `/dashboard/client/contract/review/work/${parameter?.id}`:
        setNavbarLogoShow(false);
        break;
      default:
        setNavbarLogoShow(true);
        break;
    }
  }, [parameter, path, setNavbarLogoShow]);
};

// dashboard client jobs Navbar active and active none function
export const DashboardClientJobsNavbar = (path, parameter) => {
  const [active, setActive] = useState(1);
  const [activeInvite, setActiveInvite] = useState(1);
  const [proposalActiveMenu, setProposalActiveMenu] = useState(1);
  const [hireMenuActive, setHireMenuActive] = useState(1);
  const { jobId, freelancerId } = useParams();

  useEffect(() => {
    switch (path) {
      case "/dashboard/client/jobs":
        setActive(1);
        break;
      case "/dashboard/client/jobs/inviteFreelancer/search/details":
        setActive(2);
        setActiveInvite(1);
        break;
      case "/dashboard/client/jobs/inviteFreelancer":
        setActive(2);
        setActiveInvite(1);
        break;
      case "/dashboard/client/jobs/inviteFreelancer/freelancerInviteList":
        setActive(2);
        setActiveInvite(2);
        break;
      case "/dashboard/client/jobs/inviteFreelancer/details":
        setActive(2);
        setActiveInvite(2);
        break;
      case "/dashboard/client/jobs/inviteFreelancer/freelancerHire":
        setActive(2);
        setActiveInvite(3);
        break;
      case "/dashboard/client/jobs/inviteFreelancer/saved":
        setActive(2);
        setActiveInvite(4);
        break;
      case "/dashboard/client/jobs/inviteFreelancer/saved/details":
        setActive(2);
        setActiveInvite(4);
        break;
      case "/dashboard/client/jobs/reviewProposal":
        setActive(3);
        setProposalActiveMenu(1);
        break;
      case "/dashboard/client/jobs/reviewProposal/freelancer/details":
        setActive(3);
        setProposalActiveMenu(1);
        break;
      case "/dashboard/client/jobs/reviewProposal/shortlisted":
        setActive(3);
        setProposalActiveMenu(2);
        break;
      case "/dashboard/client/jobs/reviewProposal/decline/freelancer":
        setActive(3);
        setProposalActiveMenu(3);
        break;
      case "/dashboard/client/jobs/reviewProposal/shortlisted/freelancer/details":
        setActive(3);
        setProposalActiveMenu(2);
        break;
      case "/dashboard/client/jobs/hire":
        setActive(4);
        setHireMenuActive(1);
        break;
      case "/dashboard/client/jobs/hire/offer":
        setActive(4);
        setHireMenuActive(2);
        break;
      case `/dashboard/client/jobs/hire/freelancer/view/contract/${jobId}/${freelancerId}`:
        setActive(4);
        break;
      default:
        setActive(1);
        setActiveInvite(1);
        break;
    }
  }, [freelancerId, jobId, path]);

  return {
    active,
    activeInvite,
    proposalActiveMenu,
    hireMenuActive,
  };
};
