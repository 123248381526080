import React, { useContext } from "react";
import { SingleUserProfileGet } from "../../../../hooks/useUser";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const NoticePeriode = () => {
  const { firebaseUser, currentUser } = useContext(globalFunctionOneContext);

  const { data, isLoading, error, refetch } = SingleUserProfileGet(
    currentUser?.PersonID
  );

  return (
    <div className="mt-10">
      <h2 className="uppercase font-semibold "> ⌛Notice Period</h2>
      <div className="divider mb-2 mt-0"></div>

      <div className="form-control w-full max-w-xs">
        <label className="label">
          <span className="label-text">
            Have you started serving your notice period?
          </span>
        </label>
        <select
          name="startedPeriod"
          className="select select-bordered select-sm w-full max-w-xs"
        >
          <option disabled value="" selected>
            Select Option
          </option>
          <option
            value="NO, not resigned yet"
            selected={data?.result?.startedPeriod === "NO, not resigned yet"}
          >
            NO, not resigned yet
          </option>
          <option value="Yes" selected={data?.result?.startedPeriod === "Yes"}>
            Yes
          </option>
          <option value="NO" selected={data?.result?.startedPeriod === "No"}>
            No
          </option>
        </select>

        <label className="label">
          <span className="label-text"> How long is your notice period?</span>
        </label>
        <select
          name="longPeriod"
          className="select select-bordered select-sm w-full max-w-xs"
        >
          <option disabled value="" selected>
            Select Option
          </option>
          <option
            value="2 month"
            selected={data?.result?.longPeriod === "2 month"}
          >
            2 months
          </option>
          <option
            value="1 week"
            selected={data?.result?.longPeriod === "1 week"}
          >
            1 week
          </option>
          <option
            value="15 days"
            selected={data?.result?.longPeriod === "15 days"}
          >
            15 days
          </option>
        </select>

        <label className="label">
          <span className="label-text"> Is your notice period negotiable</span>
        </label>
        <select
          name="negotiablePeriod"
          className="select select-bordered select-sm w-full max-w-xs"
        >
          <option disabled value="" selected>
            Select Option
          </option>
          <option
            value="NO, not resigned yet"
            selected={data?.result?.negotiablePeriod === "NO, not resigned yet"}
          >
            NO, not resigned yet
          </option>
          <option
            value="Yes"
            selected={data?.result?.negotiablePeriod === "Yes"}
          >
            Yes
          </option>
          <option value="No" selected={data?.result?.negotiablePeriod === "No"}>
            No
          </option>
        </select>
      </div>
    </div>
  );
};

export default NoticePeriode;
