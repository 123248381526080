import React, { useState } from "react";

const JobDetails = ({ register }) => {
  const [jobDetails, setJobDetails] = useState('');
  const handleJobDetailsChange = (event) => {
    let value = event.target.value;

    const wordLimit = 500;

    if (value.trim().split(/\s+/).length > wordLimit) {
      value = value
        .trim()
        .split(/\s+/)
        .slice(0, wordLimit)
        .join(' ');
      alert('500 words limit exceeded!');
    }

    setJobDetails(value);
  };
  return (
    <div>
      <div className="form-control">
        <textarea
          {...register("job_details", { required: true })}
          className="textarea textarea-primary textarea-bordered h-24 sm:w-full md:w-full"
          placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore quibusdam deleniti saepe aut veniam natus ipsam possimus laboriosam error obcaecati."
          onChange={handleJobDetailsChange}
        ></textarea>
      </div>
    </div>
  );
};

export default JobDetails;
