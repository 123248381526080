import React from "react";
import { AiTwotoneCreditCard } from "react-icons/ai";
import { BsBagCheck } from "react-icons/bs";
import { FaProjectDiagram } from "react-icons/fa";
import { FcCalendar, FcExpired } from "react-icons/fc";
import { GiDirectorChair, GiEternalLove } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

const Links = ({ s, setS, data }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <label className="cursor-pointer label justify-start gap-2">
          <span className="label-text">
            <BsBagCheck />
          </span>
          <span className="label-text">Medium Level</span>
        </label>
        <label className="cursor-pointer label justify-start gap-2">
          <span className="label-text">
            <FcCalendar />
          </span>
          <span className="label-text">0.3 to 06 months</span>
        </label>
        <label className="cursor-pointer label justify-start gap-2">
          <span className="label-text">
            <GiDirectorChair />
          </span>
          <span className="label-text">job type: {data.okaytoRemoteJob}</span>
        </label>
        <label className="cursor-pointer label justify-start gap-2">
          <span className="label-text">
            <FaProjectDiagram />
          </span>
          <span className="label-text">Project type: Hourly</span>
        </label>
        <label className="cursor-pointer label justify-start gap-2">
          <span className="label-text">
            <FcExpired />
          </span>
          <span className="label-text">Expired</span>
        </label>
        <label className="cursor-pointer label justify-start gap-2">
          <span className="label-text">
            <AiTwotoneCreditCard />
          </span>
          <span className="label-text">{data.country}</span>
        </label>
        <label className="cursor-pointer label justify-start gap-2">
          <span className="label-text">
            <GiEternalLove />
          </span>
          <span className="label-text">Save</span>
        </label>
        <div className="flex flex-auto gap-2 justify-between">
          <button
            onClick={() =>
              navigate("/freelancer/full/details/" + data.PersonID)
            }
            className="btn btn-secondary btn-sm"
          >
            view Profile
          </button>
          <label
            onClick={() => setS(!s)}
            className="btn btn-secondary btn-sm"
            htmlFor="my-modal-6"
          >
            schedule
          </label>
        </div>
      </div>
    </div>
  );
};

export default Links;
