import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useQuery } from "react-query";
import { SiVerizon } from "react-icons/si";
import { RiEarthFill } from "react-icons/ri";
import { AiFillLike } from "react-icons/ai";
import Step2 from "./Step2";
import Step1 from "./Step1";
import Step3 from "./Step3";
import { serverUrl } from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { auth } from "../../../config/firebaseConfig/firebaseConfig";
import Loading from "../../Loading/Loading";
import { SingleUserProfileGet } from "../../../hooks/useUser";
const BillingPayment = () => {
  const [paymentModal, setPaymentModal] = useState(false);
  const [searchIfsc, setSearchIfsc] = useState({});
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [methods, setMethods] = useState([]); // Withdrawal methods
  const [user, loading, userError] = useAuthState(auth);
  const [formLoading, setFormLoading] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState("");
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [accountBalance, setAccountBalance] = useState(null);
  const [paymentModalStep, setPaymentModalStep] = useState(1);
  // payment withdrawal state
  const [withdrawLading, setWithdrawLoading] = useState(false);
  const [customAmount, setCustomAmount] = useState(false);
  const [totalWithdrawAmount, setTotalWithdrawAmount] = useState(0);
  const [selectedMethod, setSelectedMethod] = useState("");

  // Define error state variables
  const [amountError, setAmountError] = useState("");
  const [methodError, setMethodError] = useState("");
  // payment withdrawal state end
  // console.log(customAmount);

  const {
    data: userData,
    isLoading: userLoading,
    error: SingleUserError,
    refetch: refetchAmount,
  } = SingleUserProfileGet(user?.uid);

  const { isLoading, isError, data, error, refetch } = useQuery(
    "getPMD Data",
    async () => {
      const response = await fetch(
        `${serverUrl}/paymentInfo/person/${user.uid}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    {
      onSuccess: (data) => {
        // Update the 'methods' state with the fetched data
        // console.log(data.result, "this is data ");
        setMethods(data.result);
      },
    }
  );

  useEffect(() => {
    if (userData) {
      if (Object.keys(userData?.result)?.length > 0) {
        setAccountBalance(userData?.result?.currentBalance);
      }
    }
  }, [userData]);

  if (isError || userError || SingleUserError)
    return (
      <p>
        {SingleUserError && console.log(SingleUserError.message)}
        {error
          ? error.message
          : userError
          ? userError
          : `fetching error ${error.message}. user Error Firebase ${userError}`}
      </p>
    );
  console.log(user, "single current user Data");
  // console.log(userData?.result, "single current user Data");

  // console.log(methods, "building and payments getting data");
  // console.log(error, userError, "building and payments getting data");

  /* if (userData) {
    if (Object.keys(userData?.result)?.length > 0) {
      setAccountBalance(userData?.result?.currentBalance);
    }
  } */

  // console.log(methods);
  const addMethod = () => {
    // Open the setup modal
    setPaymentModal(!paymentModal);
  };
  const withdrawModalEnable = () => {
    // Open the setup modal
    setWithdrawModal(!withdrawModal);
  };

  // console.log(user);
  const submitForm = () => {
    // console.log(searchIfsc);
    setFormLoading(true);
    const { BANK, BRANCH, CITY, IFSC } = searchIfsc;
    // console.log({
    //   ...formData,
    //   bankName: BANK,
    //   branch: BRANCH,
    //   city: CITY,
    //   ifsc: IFSC,
    //   PersonID: user.uid,
    //   currency: "INR",
    // });
    fetch(`${serverUrl}/paymentInfo`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        ...formData,
        bankName: BANK,
        branch: BRANCH,
        city: CITY,
        ifsc: IFSC,
        PersonID: user.uid,
        currency: "INR",
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          setFormLoading(false);
          setSearchIfsc({});
          setStep(1);
          refetch();
        } else if (result.status === "failed") {
          setFormLoading(false);
          setFormSubmitError(result.message);
          setSearchIfsc({});
        }
      })
      .catch((error) => console.log("error", error));
    //
    // console.log(methods);
  };
  //payment withdrawal function
  const handleAmountChange = (e) => {
    const amount = Math.floor(e.target.value);
    setTotalWithdrawAmount(amount);
    const withdrawalFee = 200; // Fixed withdrawal fee

    // Add validation logic for amount input
    if (amount < withdrawalFee || amount > accountBalance) {
      setAmountError(
        `Amount must be between ₹${withdrawalFee} and ₹${accountBalance}`
      );
    } else {
      setAmountError("");
    }
  };

  const handleCustomAmountChange = () => {
    setCustomAmount(true);
    setTotalWithdrawAmount(0.0);
    setAmountError(""); // Clear the error when custom amount is selected
  };

  const handleMethodChange = (e) => {
    const method = e.target.value;
    setSelectedMethod(method);

    // Add validation logic for method selection
    if (method === "") {
      setMethodError("Please select a withdrawal method");
    } else {
      setMethodError("");
    }
  };

  // Other input change handlers and validation logic can be added as needed
  // console.log(user);
  const submitWithdrawRequest = () => {
    // Check for errors before submitting
    if (!selectedMethod) {
      setMethodError("Please select a withdrawal method");
    }

    if (customAmount) {
      if (totalWithdrawAmount <= 0 || totalWithdrawAmount > accountBalance) {
        setAmountError("Amount must be between 0 and the available balance");
      }
    }

    // If no errors, proceed with the withdrawal request
    if ((!amountError && !methodError) || totalWithdrawAmount !== 0) {
      setWithdrawLoading(true);
      const currentNewBalance = accountBalance - totalWithdrawAmount;
      const BankIfsc = methods.find((m) => m.accountNumber === selectedMethod);
      const { PersonID, ifsc, branch, bankName } = BankIfsc;
      // console.log("accountNumber", BankIfsc);
      const bankExtraInfo = `${branch} ${bankName}`;
      const withdrawalInfo = {
        PersonID,
        BankIFSC: ifsc,
        AccountNumber: selectedMethod,
        Amount: totalWithdrawAmount,
      };

      // console.log(BankIfsc);
      // console.log(withdrawalInfo);
      const extraInfo = {
        currentBalance: currentNewBalance,
        bankAddress: bankExtraInfo,
        name: user?.displayName,
        email: user.email,
      };
      console.log(currentNewBalance);

      fetch(`${serverUrl}/withdrawalInfo`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify([withdrawalInfo, extraInfo]),
      })
        .then((res) => res.json())
        .then((data) => {
          setWithdrawLoading(false);
          setAccountBalance(currentNewBalance);
          setPaymentModalStep(2);
        });
      // Implement your submission logic here
    }
  };

  // withdraw payment modal
  const withdrawMOdalClose = () => {
    setPaymentModalStep(1);
    setCustomAmount(false);
    setWithdrawModal(!withdrawModal);
    setTotalWithdrawAmount(0.0);
  };

  // payment withdrawal function end

  return (
    <div>
      <div>
        {step === 1 ? (
          <Step1
            accountBalance={accountBalance}
            withdrawModalEnable={withdrawModalEnable}
            refetch={refetch}
            isLoading={isLoading}
            methods={methods}
            addMethod={addMethod}
          />
        ) : step === 2 ? (
          <Step2
            formData={formData}
            setFormData={setFormData}
            setStep={setStep}
            searchIfsc={searchIfsc}
            setSearchIfsc={setSearchIfsc}
          />
        ) : (
          <Step3
            formLoading={formLoading}
            formData={formData}
            submitForm={submitForm}
            setStep={setStep}
            searchIfsc={searchIfsc}
          />
        )}
      </div>

      {/* Put this part before </body> tag */}
      {paymentModal && (
        <>
          <input
            type="checkbox"
            id="paymentAndBillingModal"
            className="modal-toggle"
          />
          <div className="modal">
            <div className="modal-box w-6/12 max-w-5xl">
              <label
                htmlFor="paymentAndBillingModal"
                onClick={() => setPaymentModal(!paymentModal)}
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="font-semibold text-2xl">
                Add a withdrawal method
              </h3>
              <p className="py-4">
                Tell us how you want to get your found, for all account types it
                may able up to 3 days to activate
              </p>
              <div>
                <h2 className="text-xl my-4 font-semibold">
                  Recommended For India
                </h2>
                <div className="flex gap-2 items-center justify-between">
                  <div className="flex items-center gap-6">
                    <div>
                      <RiEarthFill className="text-4xl text-secondary" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold mb-3">
                        Direct to local Bank (INR)
                      </h3>
                      <ul>
                        <li className="flex gap-2 items-center">
                          <SiVerizon className="  text-secondary" />
                          <span>$0.99 per withdrawal</span>
                        </li>
                        <li className="flex gap-2 mt-3 items-center">
                          <SiVerizon className=" text-secondary" />
                          <span>Diopside to your local bank account (INR)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <label
                      onClick={() => {
                        setPaymentModal(!paymentModal);
                        setStep(2);
                        // setPaymentMethodPreview(false);
                      }}
                      className="btn btn-sm btn-primary rounded-full"
                    >
                      set Up
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {withdrawModal && (
        <>
          <input type="checkbox" id="withdrawModal" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box w-6/12 max-w-5xl">
              <label
                htmlFor="withdrawModal"
                onClick={withdrawMOdalClose}
                className="btn btn-sm btn-circle btn-primary absolute right-2 top-2"
              >
                ✕
              </label>
              {paymentModalStep === 1 && (
                <div>
                  <h3 className="font-semibold text-2xl">Get Paid Now</h3>
                  <div className="my-5">
                    <h2 className="card-title">Available balance</h2>
                    <h2 className="card-title text-primary">
                      ₹{accountBalance}
                    </h2>
                  </div>

                  <div className="form-control w-full max-w-xs">
                    <label className="label">
                      <span className="label-text">Withdrawal method</span>
                    </label>
                    <select
                      className="select select-ghost select-bordered"
                      value={selectedMethod}
                      onChange={handleMethodChange}
                    >
                      <option disabled value="">
                        Pick Your Account
                      </option>
                      {methods.map(
                        (m) =>
                          m.PaymentAccountStatus && (
                            <option
                              key={m.accountNumber}
                              value={m.accountNumber}
                            >
                              {m.accountNumber}
                            </option>
                          )
                      )}
                    </select>
                    {methodError && (
                      <div className="text-red-500">{methodError}</div>
                    )}
                  </div>

                  <div>
                    <h2 className="text-xl my-4 font-semibold">Amount</h2>
                    <div className="form-control">
                      <label
                        onChange={() => {
                          setCustomAmount(false);
                          setTotalWithdrawAmount(accountBalance);
                        }}
                        className="gap-2 justify-start label cursor-pointer"
                      >
                        <input
                          type="radio"
                          name="radio-10"
                          className="radio checked:bg-red-500"
                        />
                        <span className="label-text">₹{accountBalance}</span>
                      </label>
                      <label
                        /* onChange={() => {
                          setCustomAmount(true);
                          setTotalWithdrawAmount(0.0);
                        }} */
                        onChange={handleCustomAmountChange}
                        className="label gap-2 justify-start cursor-pointer"
                      >
                        <input
                          // onChange={() => setCustomAmount(true)}
                          type="radio"
                          name="radio-10"
                          className="radio checked:bg-red-500"
                        />
                        <span className="label-text">Other amount</span>
                      </label>
                      {customAmount && (
                        <>
                          <div>
                            <label className="label">
                              <span className="label-text">Enter amount</span>
                            </label>
                            <label className="input-group">
                              <input
                                type="number"
                                onChange={handleAmountChange}
                                // defaultValue={totalWithdrawAmount}
                                max={accountBalance}
                                placeholder="amount"
                                className="input w-full md:w-[50%] input-bordered"
                              />
                              <span>₹INR</span>
                            </label>
                            {amountError && (
                              <div className="text-red-500">{amountError}</div>
                            )}
                          </div>
                        </>
                      )}
                      <div className=" mt-5 border w-full h-[1px]"></div>

                      <div>
                        <div className="mt-3 text-xl flex items-center justify-between gap-2 ">
                          <h2> Withdrawal fee (per payment) </h2>

                          <h2>₹200</h2>
                        </div>
                        <div className="my-5 card-title flex items-center justify-between gap-2 ">
                          <h2> Total Amount </h2>

                          <h2>
                            ₹
                            {totalWithdrawAmount
                              ? totalWithdrawAmount - 200
                              : 0}{" "}
                            {/* Display the withdrawal fee as the minimum */}
                          </h2>
                        </div>
                        {amountError && (
                          <div className="text-red-500">{amountError}</div>
                        )}
                      </div>
                      <div>
                        <div className="border w-full h-[1px]"></div>

                        <div className="flex mt-2 gap-2 flex-wrap mt-8 justify-end">
                          <button
                            onClick={withdrawMOdalClose}
                            className="btn btn-link no-underline btn-sm btn-primary"
                          >
                            cancel
                          </button>
                          <label
                            onClick={submitWithdrawRequest}
                            // type="submit"
                            className="btn btn-sm btn-primary rounded-full"
                          >
                            {withdrawLading ? "Loading" : "Get Paid"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {paymentModalStep === 2 && (
                <div>
                  <div>
                    <h2 className="font-semibold text-lg mb-5">
                      Hello {user?.displayName}
                    </h2>
                    <p>
                      Thank you for registering for your account at our website.{" "}
                      <b>
                        Your specified will be credited with in 48 hours after
                        successfully verification. Meanwhile All the Very best!
                      </b>{" "}
                      <br />
                      Please contact us more information.
                      <br />
                      Best Regards
                    </p>
                  </div>

                  <div className="flex justify-center gap-2 my-5">
                    <button
                      onClick={withdrawMOdalClose}
                      className="btn rounded-full  btn-sm btn-primary"
                    >
                      close
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BillingPayment;
