import React, { useContext } from "react";
import Loading from "../Loading/Loading";
import LeftTalentManager from "./LeftTalentManager/LeftTalentManager";
import RightTalentManager from "./RightTalentManager/RightTalentManager";
import { globalFunctionOneContext } from "../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { useAllFreelancer } from "../../hooks/useAllFreelancer";

const TalentManagerHome = () => {
  const { windowLoading } = useContext(globalFunctionOneContext);
  const { data, isLoading, error } = useAllFreelancer();
  // console.log(data);

  if (isLoading || data.results.length <= 0 || windowLoading)
    return <Loading />;
  if (error) return console.log("error..." + error.message);
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-10 container mx-auto ">
      <div className="h-[90vh] overflow-y-scroll">
        <LeftTalentManager />
      </div>

      <div className="col-span-2 h-[90vh] overflow-y-scroll">
        <RightTalentManager data={data.results} />
      </div>
    </div>
  );
};

export default TalentManagerHome;
