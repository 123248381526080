import React from "react";

const AccountBalanceCard = ({
  accountBalance,
  withdrawModalEnable,
  method,
}) => {
  return (
    <div>
      <div className="card  w-full border bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">Available Balance</h2>
          <p className="text-primary">₹{accountBalance}</p>
          <div className="card-actions justify-start">
            <label
              htmlFor="withdrawModal"
              onClick={withdrawModalEnable}
              className="btn btn-primary btn-sm rounded-full">
              Get Paid
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountBalanceCard;
