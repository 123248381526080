import React, { useContext } from "react";
import { globalFunctionOneContext } from "../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import {
  serverImageUrl,
  serverUrl,
} from "../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { GrMapLocation } from "react-icons/gr";
import { BsClock } from "react-icons/bs";
import currentEmployment from "../Assect/img/currentEmployment.png";
import Company from "../Assect/img/Company.png";
import moneyIcon from "../Assect/img/Project_price.jpg";
import Designation from "../Assect/img/Designation.png";
import skillIcon from "../Assect/img/Skill.png";
import JobStartDate from "../Assect/img/JobStartDate.png";
import JobEndDate from "../Assect/img/JobEndDate.png";
import { useQuery } from "react-query";

const GuestModeFreelancerDetails = () => {
  const {
    GuestModeFreelancerDetailsPersonId,
    setGuestModeFreelancerDetailsPersonId,
  } = useContext(globalFunctionOneContext);

  const {
    isLoading,
    error,
    data = {},
  } = useQuery({
    queryKey: [GuestModeFreelancerDetailsPersonId],
    queryFn: () =>
      fetch(
        `${serverUrl}/userProfile/guest/mode/user/details?selectType=client&PersonID=${GuestModeFreelancerDetailsPersonId}`
      ).then((res) => res.json()),
  });

  let skillKey = [];

  try {
    if (
      typeof data?.data.top_skill === "string" &&
      data?.data.top_skill.trim() !== ""
    ) {
      const skill = JSON.parse(data?.data.top_skill);

      if (typeof skill === "object" && skill !== null) {
        for (const [key, value] of Object.entries(skill)) {
          skillKey.push(key);
        }
      }
    }
  } catch (error) {
    console.log("Error parsing JSON:", error);
  }

  if (isLoading) {
    return <div>Loading ............</div>;
  }
  return (
    <div className="bg-gray-100 pb-10">
      {/* <FcLeft className="text-3xl cursor-pointer text-black" /> */}
      {/* <button onClick={() => navigate(-1)}>
      <BsArrowLeft className="text-3xl cursor-pointer text-black mb-5 hover:text-gray-600" />
    </button> */}
      <div className="w-[1200px] mx-auto">
        <div className=" py-10 px-5 bg-white rounded-lg shadow-lg">
          <div className="flex">
            <div>
              {data?.data?.profilePhoto ? (
                <div className="avatar online">
                  <div className="w-24 rounded-full">
                    <img
                      src={`${serverImageUrl}/${data?.data?.profilePhoto}`}
                      alt="user profile pic"
                    />
                  </div>
                </div>
              ) : (
                <div className="w-24 h-24 rounded-full  flex justify-center items-center bg-blue-300 avatar online mt-5">
                  <h1 className="text-secondary text-3xl font-bold ">
                    {data?.data?.firstName && (
                      <span>{data?.data?.firstName.slice(0, 2)}</span>
                    )}
                  </h1>
                </div>
              )}
            </div>
            <div className="pl-10">
              {data?.data?.firstName && data?.data?.lastName && (
                <h2 className="text-xl font-semibold mt-3 ">
                  {data?.data?.firstName + data?.data?.lastName}
                </h2>
              )}
              <h3 className="text-lg">{data?.data?.profile_title}</h3>
              <div className="flex gap-2 items-center text-secondary">
                <GrMapLocation className="text-secondary" />
                <p>{data?.data?.country}</p>
              </div>
              <div>
                {skillKey.map((skill, i) => (
                  <button
                    key={i}
                    className="btn btn-secondary btn-sm ml-2 mt-4 btn-outline"
                  >
                    {skill}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <hr className="border-b-2 border-gray-300 my-5" />
          <div>
            <h1 className="text-xl font-semibold">Overview</h1>
          </div>
          {data?.data?.bigest_achivement && (
            <p className="mt-3">{data?.data?.bigest_achivement}</p>
          )}
        </div>
        <div className=" py-10 px-5 bg-white rounded-lg shadow-lg my-5">
          <div>
            <h1 className="text-xl font-semibold">EMPLOYMENT HISTORY</h1>
          </div>

          <hr className="border-b-2 border-gray-300 my-5" />
          {data?.data?.jobProfile && (
            <p className="mt-3">{data?.data?.jobProfile}</p>
          )}

          <div className="flex gap-5 flex-wrap pt-4 px-5 my-5">
            <div className=" flex gap-2 ">
              <div>
                <img
                  src={currentEmployment}
                  alt="FreelancerExperienceIcon"
                  className="w-5 h-5 mt-1"
                />
              </div>
              <div>
                <h2 className="font-bold">Current Employment</h2>
                <p>{data?.data?.currentJob}</p>
              </div>
            </div>
            <div className=" flex gap-2 ">
              <div>
                <BsClock className="mt-1 text-xl" />
              </div>
              <div>
                <h2 className="font-bold">Employment Type</h2>
                <p>{data?.data?.employmentType}</p>
              </div>
            </div>
            <div className=" flex gap-2 ">
              <div>
                <img
                  src={Company}
                  alt="FreelancerExperienceIcon"
                  className="w-5 h-5 mt-1"
                />
              </div>
              <div>
                <h2 className="font-bold">Current CompanyName</h2>
                <p>{data?.data?.currentCompanyName}</p>
              </div>
            </div>
            <div className=" flex gap-2 ">
              <div>
                <img
                  src={Designation}
                  alt="FreelancerExperienceIcon"
                  className="w-6 h-85 mt-2"
                />
              </div>
              <div>
                <h2 className="font-bold">Current Designation</h2>
                <p>{data?.data?.currentDesignation}</p>
              </div>
            </div>
          </div>

          <div className="flex gap-5 flex-wrap pt-4 px-5">
            <div className=" flex gap-2 ">
              <div>
                <img
                  src={moneyIcon}
                  alt="FreelancerExperienceIcon"
                  className="w-5 h-5 mt-1"
                />
              </div>
              <div>
                <h2 className="font-bold">Current Salary</h2>
                <p>₹ {data?.data?.currentSalary} Lacs PA</p>
              </div>
            </div>
            <div className=" flex gap-2 ">
              <div>
                <img
                  src={skillIcon}
                  alt="FreelancerExperienceIcon"
                  className="w-5 h-5 mt-1"
                />
              </div>
              <div>
                <h2 className="font-bold">Skill Used</h2>
                <p>{data?.data?.skillUsed}</p>
              </div>
            </div>
            <div className=" flex gap-2 ">
              <div>
                <img
                  src={JobStartDate}
                  alt="FreelancerExperienceIcon"
                  className="w-5 h-5 mt-1"
                />
              </div>
              <div>
                <h2 className="font-bold">Job Joining Date</h2>
                <p>{data?.data?.jobJoiningDate}</p>
              </div>
            </div>
            <div className=" flex gap-2 ">
              <div>
                <img
                  src={JobEndDate}
                  alt="FreelancerExperienceIcon"
                  className="w-5 h-5 mt-1"
                />
              </div>
              <div>
                <h2 className="font-bold">Job Ending Year</h2>
                <p>{data?.data?.jobEndingYear}</p>
              </div>
            </div>
          </div>
        </div>
        <div className=" py-10 px-5 bg-white rounded-lg shadow-lg my-5">
          <div>
            <h1 className="text-xl font-semibold">EDUCATION</h1>
          </div>
          <hr className="border-b-2 border-gray-300 my-5" />
          <div>
            <ul className="list-disc pl-5">
              <li className="text-md font-medium my-1">
                I started the course in {data?.data?.courStartingYear}
              </li>
              <li className="text-md font-medium my-1">
                I ended the course in {data?.data?.courseEndingYear}
              </li>
              <li className="text-md font-medium my-1">
                Grading system {data?.data?.gradingSystem}
              </li>
              <li className="text-md font-medium my-1">
                My marks {data?.data?.marks}
              </li>
              <li className="text-md font-medium my-1">
                Education {data?.data?.addEducation}
              </li>
              <li className="text-md font-medium my-1">
                My university Name is {data?.data?.addUniversity}
              </li>
              <li className="text-md font-medium my-1">
                Course specialization {data?.data?.courseSpecialization}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestModeFreelancerDetails;
