import React, { useContext, useState } from "react";
import { SingleUserProfileGet } from "../../../../hooks/useUser";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const InterestedLocation = () => {
  const { firebaseUser, currentUser } = useContext(globalFunctionOneContext);

  const [userIntro, setUserIntro] = useState({});
  const { data, isLoading, error, refetch } = SingleUserProfileGet(
    currentUser?.PersonID
  );

  // get select option value function
  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setUserIntro({ ...userIntro, [name]: value });
  };
  return (
    <div>
      <h2 className="uppercase font-semibold mt-10">🌁 Interested Locations</h2>
      <div className="divider mb-2 mt-0"></div>

      <div className="form-control w-full max-w-xs">
        {/* name="currentLocation" */}

        <div className="mb-2">
          <label className="label">
            <span className="label-text">Where are you currently located?</span>
          </label>
          <select
            onChange={handleInputValue}
            name="currentLocation"
            className="select select-bordered select-sm w-full max-w-xs"
          >
            <option disabled value="" selected>
              Select Option
            </option>
            <option
              selected={data?.result?.currentLocation === "Delhi/NCR"}
              value="Delhi/NCR"
            >
              Delhi/NCR
            </option>
            <option
              selected={data?.result?.currentLocation === "Out of NCR"}
              value="Out of NCR"
            >
              Out of NCR
            </option>
            <option
              selected={data?.result?.currentLocation === "Other"}
              value="Other"
            >
              Other
            </option>
          </select>
          {/*   <label className="label">
                <span className="label-text-alt">Alt label</span>
              </label> */}
          {userIntro?.currentLocation === "Other" && (
            <>
              <div>
                <h2 className="menu-title mt-5">Enter Your currentLocation</h2>
                <label className="label cursor-pointer flex gap-2 items-center">
                  <input
                    onChange={handleInputValue}
                    name="otherCurrentLocation"
                    type="text"
                    defaultValue={data?.result?.otherCurrentLocation}
                    placeHolder="current location"
                    className="input input-bordered input-sm w-full max-w-xs "
                  />
                </label>
              </div>
            </>
          )}
        </div>

        <label className="label">
          <span className="label-text">Are You okay with remote job?</span>
        </label>
        <select
          name="okaytoRemoteJob"
          className="select select-bordered select-sm w-full max-w-xs"
        >
          <option Disabled value="" selected>
            Select Option
          </option>
          <option
            selected={
              data?.result?.okaytoRemoteJob ===
              "Okay with remote/in-office jobs"
            }
            value="Okay with remote/in-office jobs"
          >
            Okay with remote/in-office jobs
          </option>
          <option selected={data?.result?.okaytoRemoteJob === "No"} value="No">
            No
          </option>
        </select>

        <label className="label">
          <span className="label-text">Desired city to work in?</span>
        </label>
        <input
          name="desireCitiesWorking"
          type="text"
          placeholder="Type here"
          defaultValue={data?.result?.desireCitiesWorking}
          className="input input-bordered input-sm w-full max-w-xs"
        />
        <label className="label">
          <span className="label-text">Which cities in Delhi/NCR?</span>
        </label>
        <input
          name="citiesNcr"
          type="text"
          placeholder="Type here"
          defaultValue={data?.result?.citiesNcr}
          className="input input-bordered input-sm w-full max-w-xs"
        />
      </div>
    </div>
  );
};

export default InterestedLocation;
