import React, { useState, useEffect } from "react";
import { BsFillPlayFill } from "react-icons/bs";
import { FaPlay, FaCoffee, FaStop } from "react-icons/fa";
import CounterModal from "./CounterModal";

const Counter = () => {
  const [showModal, setShowModal] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [timeCount, setTimeCount] = useState(false);

  useEffect(() => {
    if (timeCount) {
      const id = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
      }, 1000);

      setIntervalId(id);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timeCount]);

  const handlePlay = () => {
    setShowModal(true);
    setStartTime(new Date().getTime() / 1000); // Set the starting time when the modal is opened
    setElapsedTime(0); // Reset elapsed time
  };

  const handleSave = () => {
    setShowModal(false);
    setTimeCount(true);
  };

  const handleResume = () => {
    // setShowModal(true);
    setTimeCount(true);
  };

  const handleStop = () => {
    clearInterval(intervalId);
    setIntervalId(null);
    setTimeCount(false);
  };

  const handleClose = () => {
    // handleStop();
    setShowModal(false);
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const displayHours = hours > 0 ? `${hours}:` : "";
    const displayMinutes = minutes >= 10 ? minutes : `0${minutes}`;
    const displaySeconds =
      remainingSeconds >= 10 ? remainingSeconds : `0${remainingSeconds}`;

    return `${displayHours}${displayMinutes}:${displaySeconds}`;
  };

  return (
    <div className="flex items-center">
      {timeCount ? (
        <div className="flex justify-end items-center font-bold ml-1">
          <a
            href="#"
            aria-current="page"
            className="flex items-center justify-end mr-2">
            <span className="text-gray-600">{formatTime(elapsedTime)}</span>
            <div className="d-flex align-center ml-2"></div>
          </a>

          <div role="group" className="flex justify-end">
            <div
              className="tooltip tooltip-bottom tooltip-secondary rounded-full p-2"
              data-tip="start break">
              <button type="button" className="rounded mr-2">
                <FaCoffee className="text-xl" />
              </button>
            </div>

            <div
              className="tooltip tooltip-bottom tooltip-secondary rounded-full p-2"
              data-tip="clock out">
              <button type="button" onClick={handleStop} className="mx-2">
                <FaStop className="text-gray-600 text-xl" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className="tooltip tooltip-bottom tooltip-secondary"
            data-tip="Resume your last clock in activity">
            <button
              type="button"
              className="flex p-2  text-gray-600 items-center text-sm mr-2 px-2 rounded-full font-bold bg-gray-100"
              onClick={handleResume}>
              <FaPlay className="mr-1" style={{ fontSize: "15px" }} />
              <span>Resume</span>
            </button>
          </div>

          <div
            className="tooltip tooltip-bottom tooltip-secondary rounded-full p-2"
            data-tip="Clock In">
            <button onClick={handlePlay}>
              <BsFillPlayFill className="text-primary" size={40} />
            </button>
          </div>
        </>
      )}

      {showModal && (
        <CounterModal
          handleClose={handleClose}
          handleSave={handleSave}
          startTime={startTime}
        />
      )}
    </div>
  );
};

export default Counter;
