import React from "react";
import { Link } from "react-router-dom";

const BlogFilter = ({ filter, setSearchItem }) => {
  const onSubmit = (e) => {
    e.preventDefault();
    setSearchItem(e.target.searchField.value);
  };
  return (
    <div className="px-5 md:px-0">
      <div>
        <form onSubmit={onSubmit} className="w-full">
          <div className=" w-[80%] flex  flex-col md:flex-row  gap-3 md:gap-0">
            <input
              type="search"
              id="location-search"
              className="md:rounded-none w-full focus:outline-none p-2.5 z-20 text-sm text-gray-900 bg-gray-50 rounded-lg md:rounded-l-lg ring-0 focus:border-red-400 border"
              placeholder="Search"
              name="searchField"
            />
            <button
              type="submit"
              className="p-2.5 w-full md:w-28 text-sm font-medium text-white bg-pink-600 bg-opacity-90 gradient-pink-purple-95 rounded-lg md:rounded-r-lg md:rounded-none">
              Search
            </button>
          </div>
        </form>
      </div>
      <div className="my-5">
        <h2 className="text-2xl font-bold">Categories</h2>
        <div className="flex flex-col gap-2">
          <p>
            {/*    */}
            <Link to="/blog" className="link transition">
              {" "}
              Cross-Browser{" "}
            </Link>{" "}
          </p>
          <p>
            {" "}
            <a className="link transition"> Cypress</a>
          </p>
          <p>
            <a className="link transition"> Uncategorized </a>{" "}
          </p>
        </div>
      </div>
      <div className="my-5">
        <h2 className="text-2xl font-bold">Recent Posts</h2>
        <div className="flex flex-col gap-3 mb-3">
          {filter.map((content, i) => {
            if (i > 2) {
              return;
            }
            return (
              <p className="text-xl font-semibold">
                {/*    */}
                <Link to="/blog" className="link transition cursor-text">
                  {" "}
                  {content.title.length > 40
                    ? content.title.slice(0, 40)
                    : content.title}
                  {content.title.length > 40 ? (
                    <span title={content.title}>...</span>
                  ) : (
                    ""
                  )}
                </Link>
              </p>
            );
          })}
        </div>
      </div>
      <div className="my-5">
        <h2 className="text-2xl font-bold">Recent Comments</h2>
        <div className="flex flex-col gap-3 mb-3">
          <p className=" text-2xl font-semibold text-center my-5 text-gray-400">
            No comments
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogFilter;
