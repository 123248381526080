import React from "react";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    console.error(error, "This is error");
    console.error(info, "This is error information");
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return (
        <div className="w-full h-screen flex justify-center items-center font-bold text-2xl">
          <h1>Something went wrong. please check our code !!!!</h1>
        </div>
      );
    }
    // Otherwise, render the child components
    return this.props.children;
  }
}
