import React, { useState } from "react";

import { FiSearch } from "react-icons/fi";
import TimeDisplay from "./TimeDisplay";
import TrackedHourChart from "./TrackedHourChart";
import Activities from "./Activities";

const TimeCounterDashboard = () => {
  const [activeStatus, setActiveStatus] = useState("IN");

  const handleClick = (status) => {
    setActiveStatus(status);
  };

  return (
    <div>
      <div>
        <div className="grid grid-cols-7 ">
          <div className="col-span-5 h-[90vh] overflow-y-auto scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-200">
            <div className="grid grid-cols-2 gap-5 pr-6 pl-2">
              {/* card1 */}
              {/* added background image and position right  */}

              <div className="col-span-1">
                <div className="card w-full bg-base-100 shadow-xl">
                  <div
                    className="flex items-center min-h-[230px] justify-between p-4 bg-white shadow rounded-md bg-right bg-no-repeat"
                    style={{
                      backgroundImage:
                        "url('https://web.jibble.io/img/greeting-personal.b6720152.svg')",
                    }}>
                    <div>
                      <h2 className="text-2xl font-bold my-5">Hello User,</h2>
                      <p className="text-gray-600">
                        Here's what's happening at hire-elit
                      </p>
                      {/* in this name chang dynamic */}
                      <p className="text-gray-600">hire-elit</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* card 2 */}
              <div className="col-span-1">
                <div className="card w-full bg-base-100 shadow-xl">
                  <div className="min-h-[230px] p-4 bg-white shadow rounded-md relative group">
                    <h2 className="text-sm font-semibold">UPCOMING HOLIDAYS</h2>
                    {/* if not any holidays */}
                    <h2 className="text-gray-400 text-lg mt-8">
                      No upcoming holidays
                    </h2>
                    <button
                      className="absolute top-4 right-4 bg-inherit  
                      hover:bg-gray-200 text-inherit hover:text px-2 py-1 rounded-md transform scale-0 group-hover:scale-100 text-sm transition-transform duration-200">
                      Go to Holidays
                    </button>
                  </div>
                </div>
              </div>

              {/* card3 */}
              <div className="col-span-2">
                <TrackedHourChart />
              </div>

              {/* card4 */}
              <div className="col-span-2">
                <Activities />
              </div>
            </div>
          </div>

          <div className="col-span-2">
            <div>
              <div className="card h-full w-full bg-base-100">
                <div className="card-body">
                  <div>
                    <h2 className="text-center font-semibold text-gray-700">
                      Who's in/out
                    </h2>
                  </div>
                  <div>
                    <ul className="grid grid-cols-3 mb-4 justify-center gap-2">
                      <li
                        className={`cursor-pointer text-center ${
                          activeStatus === "IN"
                            ? "text-secondary border-b-2 border-secondary "
                            : "text-gray-400"
                        }`}
                        onClick={() => handleClick("IN")}>
                        <h2>{0}</h2>
                        <h2>IN</h2>
                      </li>
                      <li
                        className={`cursor-pointer text-center ${
                          activeStatus === "Break"
                            ? "text-secondary border-b-2 border-secondary"
                            : "text-gray-400"
                        }`}
                        onClick={() => handleClick("Break")}>
                        <h2>{0}</h2>
                        <h2>Break</h2>
                      </li>
                      <li
                        className={`cursor-pointer text-center ${
                          activeStatus === "OUT"
                            ? "text-secondary border-b-2 border-secondary"
                            : "text-gray-400"
                        }`}
                        onClick={() => handleClick("OUT")}>
                        <h2>{0}</h2>
                        <h2>OUT</h2>
                      </li>
                    </ul>

                    {/* search input*/}
                    <div className="relative my-10 mb-4">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <FiSearch className="h-5 w-5 text-gray-500" />
                      </span>
                      <input
                        placeholder="Search"
                        className="transition-colors duration-200 ease-in-out w-full py-2 pl-10 pr-2 focus:outline-none focus:ring-0 border-0 border-b-2 border-primary focus:border-secondary rounded-none"
                      />
                    </div>
                    {/* when no one added time showing this component*/}
                    <div className=" flex justify-center items-center">
                      <TimeDisplay />
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeCounterDashboard;
