import React, { useContext, useEffect, useState } from "react";
import style from "./TalentManagerForm.module.css";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import DropFilesInput from "../../../state/DropFilesInput/DropFilesInput";
import { AiOutlineClose } from "react-icons/ai";
import {
  serverImageUrl,
  serverUrl,
} from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { useNavigate } from "react-router-dom";
import { SingleUserProfileGet } from "../../../hooks/useUser";

const TalentManagerProfileUploadId = () => {
  const {
    currentUser,
    imagesUrlLocalHost,
    handelMultiFileOneRemoveItem,
    databaseSingleUserRefetch,
    setFileDropLimitNumber,
    multiFileList,
    firebaseUser,
    setMultiFileList,
    setMultiFileListCount,
    setConfirmAlertValue,
  } = useContext(globalFunctionOneContext);
  const navigate = useNavigate();
  const [databaseUserIdentity, setDatabaseUserIdentity] = useState(null);
  const [deleteUserIdentity, setDeleteUserIdentity] = useState(0);
  const { data, isLoading, error, refetch } = SingleUserProfileGet(
    currentUser?.PersonID
  );

  // Drop file upload input file limit number
  useEffect(() => {
    setFileDropLimitNumber(2);
  }, []);

  // Handel userParsonIdentity
  const HandelUserCountryId = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < multiFileList.length; i++) {
      formData.append("userParsonIdentity", multiFileList[i]);
    }
    const res = await fetch(
      `${serverUrl}/talent-manager-profile/upload-user-parson-identity`,
      {
        method: "POST",
        headers: { Accept: "multipart/form-data", uid: firebaseUser?.uid },
        Credential: "include",
        body: formData,
      }
    );
    const data = await res.json();
    if (data?.status === "success") {
      fetch(
        `${serverUrl}/userProfile/single-user-update/${firebaseUser?.uid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            // authorization: `bearer ${localStorage.getItem("access_Token")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === "success") {
            console.log(data);
            databaseSingleUserRefetch();
            setMultiFileListCount(0);
            setMultiFileList([]);
            setConfirmAlertValue(true);
            navigate("/dashboard/complete_profile");
          }
        });
    }
  };

  useEffect(() => {
    if (data?.result?.userIdentity) {
      const userIdentity = JSON.parse(data?.result?.userIdentity);
      setDatabaseUserIdentity(userIdentity);
    }
    if (deleteUserIdentity) {
      // Make the server request using user Identity delete
      fetch(
        `${serverUrl}/talent-manager-profile/upload-user-parson-identity-delete`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            uid: firebaseUser?.uid,
          },
          body: JSON.stringify({ id: deleteUserIdentity }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === "success") {
            refetch();
          }
        });
    }
  }, [data, deleteUserIdentity, firebaseUser?.uid, refetch]);

  return (
    <form className="px-4" onSubmit={HandelUserCountryId}>
      <h1 className={style.form_hading}>Upload your Identity</h1>
      <DropFilesInput />
      <div className="flex justify-start items-center">
        {imagesUrlLocalHost &&
          imagesUrlLocalHost.map((img, id) => (
            <div key={id} id={id}>
              <div className="mt-5 flex justify-start mx-2 relative">
                <img
                  src={img}
                  alt="imageUrlLocalHost"
                  className="rounded w-60 h-60 mt-5"
                />
                <i
                  className="text-xl text-center cursor-pointer border rounded-full bg-slate-200 hover:bg-gray-50 absolute top-1 -right-3 p-1"
                  onClick={() => handelMultiFileOneRemoveItem(id)}
                >
                  <AiOutlineClose />
                </i>
              </div>
            </div>
          ))}
        {databaseUserIdentity &&
          databaseUserIdentity.map((img) => (
            <div key={img?.id} id={img?.id}>
              <div className="mt-5 flex justify-start mx-2 relative">
                <img
                  src={`${serverImageUrl}/${img?.filePath}`}
                  alt="imageUrlLocalHost"
                  className="rounded w-60 h-60 mt-5"
                />
                <i
                  className="text-xl text-center cursor-pointer border rounded-full bg-slate-200 hover:bg-gray-50 absolute top-1 -right-3 p-1"
                  onClick={() => setDeleteUserIdentity(img?.id)}
                >
                  <AiOutlineClose />
                </i>
              </div>
            </div>
          ))}
      </div>
      {/* submit button wraper  */}
      <div className="flex justify-end items-center w-full mt-10">
        <button type="submit" className={style.form_submit_btn}>
          Save & Update dddd
        </button>
      </div>
    </form>
  );
};

export default TalentManagerProfileUploadId;
