import React, { useEffect, useState } from "react";

const InterestedRole = ({ setInterestedRole }) => {
  const [checkerValue, setCheckerValue] = useState({});
  setInterestedRole(checkerValue);
  const [checked, setChecked] = useState(false);

  // INTERESTED ROLE value get function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCheckerValue({ ...checkerValue, [name]: value });
  };

  useEffect(() => {
    if (Object.keys(checkerValue).length === 4) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [checkerValue, checked]);

  // Select the role data array
  const arr1 = [
    { value: "Front End Developer", name: "role1" },
    { value: "Full Stack Developer", name: "role2" },
    { value: "Game Developer", name: "role3" },
    { value: "IOS Developer", name: "role4" },
    { value: "Machine Learning Engineer", name: "role5" },
    { value: "Network Engineer", name: "role6" },
    { value: "Mobile App Developer ", name: "role7" },
    { value: "PHP Developer", name: "role8" },
    { value: "Salesforce Developer", name: "role9" },
    { value: "Selenium Engineer", name: "role10" },
  ];
  const arr2 = [{ value: "Research Analyst", name: "role11" }];
  const arr3 = [
    { value: "Technical Expertise", name: "role12" },
    { value: "Management Skills", name: "role13" },
  ];

  return (
    <div>
      <h2 className="uppercase font-semibold ">🌅 Interested Role</h2>
      <div className="divider mb-0"></div>
      <p className="mb-3">
        Select the role that you are well-qualified for. ( max 4)
      </p>
      <div className="grid grid-cols-3 justify-center gap-4">
        <div>
          <h2 className="uppercase font-semibold ">Software Engineering</h2>
          {arr1.map((a, index) => (
            <div key={index} className="form-control ">
              <label className="cursor-pointer label justify-start gap-2">
                <input
                  disabled={checked}
                  name={a.name}
                  onChange={handleChange}
                  type="checkbox"
                  className="checkbox checkbox-accent checkbox-sm"
                />
                <span className="label-text">{a.value}</span>
              </label>
            </div>
          ))}
        </div>
        <div>
          <h2 className="uppercase font-semibold ">Data science</h2>
          {arr2.map((a, i) => (
            <div key={i} className="form-control">
              <label className="cursor-pointer label justify-start gap-2">
                <input
                  disabled={checked}
                  name={a.name}
                  onChange={handleChange}
                  value={a.value}
                  type="checkbox"
                  className="checkbox checkbox-accent checkbox-sm"
                />
                <span className="label-text"> {a.value}</span>
              </label>
            </div>
          ))}
        </div>
        <div>
          <h2 className="uppercase font-semibold ">Technical Management</h2>
          {arr3.map((a, i) => (
            <div key={i} className="form-control">
              <label className="cursor-pointer label justify-start gap-2">
                <input
                  disabled={checked}
                  name={a.name}
                  onChange={handleChange}
                  value={a.value}
                  type="checkbox"
                  className="checkbox checkbox-accent checkbox-sm"
                />
                <span className="label-text"> {a.value}</span>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InterestedRole;
