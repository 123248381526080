import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import {
  HandelFreelancerAllJobsApplyDataCount,
  HandelFreelancerSavedJobAllDataCount,
} from "../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import Loading from "../../Loading/Loading";

const MyJobs = () => {
  const { currentUser, userCurrentLocationPath } = useContext(
    globalFunctionOneContext
  );
  const [myJobMenuActiveTracker, setMyJobMenuActiveTracker] = useState(1);

  // Freelancer all Apply job count function
  const {
    data: FreelancerJobApplyDataCount,
    isLoading: FreelancerJobApplyDataCountLoading,
    refetch: FreelancerJobApplyDataCountRefetch,
  } = HandelFreelancerAllJobsApplyDataCount(currentUser?.PersonID);
  // Freelancer all saved job count function
  const {
    data: FreelancerJobSavedJobDataCount,
    isLoading: FreelancerJobSavedJobDataCountLoading,
    refetch: FreelancerJobSavedJobDataCountRefetch,
  } = HandelFreelancerSavedJobAllDataCount(currentUser?.PersonID);

  // This is freelancer My jobs route menu list
  const menuItems = [
    {
      id: 1,
      url: "/dashboard/my_jobs",
      text: "Saved",
      subText: FreelancerJobSavedJobDataCount?.result,
    },
    {
      id: 2,
      url: "/dashboard/my_jobs/apply",
      text: "Applied",
      subText: FreelancerJobApplyDataCount?.result,
    },
    {
      id: 3,
      url: "/dashboard/my_jobs/interview",
      text: "Interview",
      subText: 0,
    },
    {
      id: 4,
      url: "/dashboard/my_jobs/contract",
      text: "Contract",
      subText: 0,
      hidden: true,
    },
  ];

  // Handel window reload then menu active static
  useEffect(() => {
    switch (userCurrentLocationPath) {
      case "/dashboard/my_jobs/apply":
        setMyJobMenuActiveTracker(2);
        break;
      case "/dashboard/my_jobs/interview":
        setMyJobMenuActiveTracker(3);
        break;
      case "/dashboard/my_jobs/contract":
        setMyJobMenuActiveTracker(4);
        break;
      case "/dashboard/my_jobs/contract/details":
        setMyJobMenuActiveTracker(4);
        break;

      default:
        setMyJobMenuActiveTracker(1);
        break;
    }
  }, [userCurrentLocationPath]);

  //  loading
  if (
    !currentUser ||
    FreelancerJobApplyDataCountLoading ||
    FreelancerJobSavedJobDataCountLoading
  ) {
    return (
      <div className="h-[455px]">
        <Loading />
      </div>
    );
  }
  return (
    <div className="max-w-6xl mx-auto mt-10">
      <h1 className="text-3xl font-bold">My Jobs</h1>
      <div className="mt-5">
        <ul className="flex justify-start items-center">
          {menuItems &&
            menuItems.map((li) => (
              <li className="hover:bg-[#eef1fe]" key={li?.id}>
                <Link
                  to={li?.url}
                  className={`flex flex-col px-2 mx-3 pb-3 cursor-pointer ${
                    myJobMenuActiveTracker === li?.id
                      ? "front-bold my_job_menu_list"
                      : ""
                  }`}
                  onClick={() => setMyJobMenuActiveTracker(li?.id)}
                >
                  <p className={`${li?.hidden ? "opacity-0" : ""}`}>
                    {li?.subText}
                  </p>
                  <p>{li?.text}</p>
                </Link>
              </li>
            ))}
        </ul>
        <hr className="mb-5 border-t-2 border-gray-300" />
        <Outlet />
      </div>
    </div>
  );
};

export default MyJobs;
