import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { useProfileData } from "../../hooks/UseGetDatabaseSingleUser/UseGetDatabaseSingleUser";
import style from "./ProfileFormDashboard.module.css";
import { globalFunctionOneContext } from "../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const ProfileFormDashboard = () => {
  const {
    currentUser,
    currentUserAccountTypeClient,
    currentUserAccountTypeFreelancer,
    currentUserAccountTypeTalentManager,
    windowLoading,
    setWindowLoading,
  } = useContext(globalFunctionOneContext);
  const [profileMenuLinkActive, setProfileMenuLinkActive] = useState(1);
  const [currentMapIngMenu, setCurrentMapIngMenu] = useState([]);
  const location = useLocation();

  const clientProfileMenu = [
    {
      id: 1,
      linkText: "Personal Details",
      link: "/user/profile/from",
    },
    {
      id: 2,
      linkText: "Social Profile",
      link: "/user/profile/from/social/client/form",
    },
    {
      id: 3,
      linkText: "Brochures",
      link: "/user/profile/from/brochures/client/form",
    },
  ];

  // freelancer profile menu list
  const freelancerProfileMenu = [
    {
      id: 1,
      linkText: "Intro",
      link: "/user/profile/from",
    },
    {
      id: 2,
      linkText: "Experience",
      link: "/user/profile/from/experience/freelancer/form",
    },
    {
      id: 3,
      linkText: "Extras",
      link: "/user/profile/from/extra/freelancer/form",
    },
    {
      id: 4,
      linkText: "Complete",
      link: "/user/profile/from/complete/freelancer/form",
    },
  ];

  // Tsm Profile Talent Manager menu list
  const talentManagerProfileMenu = [
    {
      id: 1,
      linkText: "Personal Details",
      link: "/user/profile/from",
    },
    {
      id: 2,
      linkText: "Social Profile",
      link: "/user/profile/from/social/talent/manager/form",
    },
    {
      id: 3,
      linkText: "Upload Id",
      link: "/user/profile/from/upload/id/talent/manager/form",
    },
  ];

  // switch user menu function
  useEffect(() => {
    if (currentUser) {
      // eslint-disable-next-line default-case
      switch (currentUser?.accountType) {
        case "client":
          setCurrentMapIngMenu(clientProfileMenu);
          break;
        case "Freelancer":
          setCurrentMapIngMenu(freelancerProfileMenu);
          break;
        case "talent_manager":
          setCurrentMapIngMenu(talentManagerProfileMenu);
          break;
      }
    }
  }, [currentUser, location?.pathname]);

  // user active menu function
  useEffect(() => {
    if (currentMapIngMenu?.length) {
      const currentActiveRoute = currentMapIngMenu.find(
        (data) => data?.link === location?.pathname
      );
      if (currentActiveRoute?.id) {
        setProfileMenuLinkActive(currentActiveRoute?.id);
        setWindowLoading(false);
      } else {
        setProfileMenuLinkActive(1);
        setWindowLoading(false);
      }
    }
  }, [currentMapIngMenu]);

  if (windowLoading) {
    return <div>loading ...........</div>;
  }
  return (
    <div className="container mx-auto ">
      <div className="grid grid-cols-5 gap-3">
        {/* <div className="drawer-side bg-slate-100 h-[89vh]"> */}
        <div className="drawer-side  h-[89vh] border-r-2 bg-gray-50">
          <label htmlFor="my-drawer" className="drawer-overlay"></label>

          <ul className="p-4 ml-4 w-56 text-base-content flex flex-col gap-1">
            {/* <li>
              <NavLink to="/user/profile/">{indexRoute}</NavLink>
            </li> */}

            {/* <========= client Link menu list map ===========> */}
            {clientProfileMenu &&
              currentUserAccountTypeClient &&
              clientProfileMenu.map((link) => (
                <li key={link?.id}>
                  <Link
                    to={link?.link}
                    className={`${style.user_profile_link} ${
                      profileMenuLinkActive === link?.id
                        ? style.user_profile_link_active
                        : ""
                    } `}
                    onClick={() => setProfileMenuLinkActive(link?.id)}
                  >
                    {link?.linkText}
                  </Link>
                </li>
              ))}
            {/* <========= Freelancer Link menu list map ===========> */}
            {freelancerProfileMenu &&
              currentUserAccountTypeFreelancer &&
              freelancerProfileMenu.map((link) => (
                <li key={link?.id}>
                  <Link
                    to={link?.link}
                    className={`${style.user_profile_link} ${
                      profileMenuLinkActive === link?.id
                        ? style.user_profile_link_active
                        : ""
                    } `}
                    onClick={() => setProfileMenuLinkActive(link?.id)}
                  >
                    {link?.linkText}
                  </Link>
                </li>
              ))}

            {/* <========= Tsm Profile Talent Manager Link menu list map ===========> */}
            {talentManagerProfileMenu &&
              currentUserAccountTypeTalentManager &&
              talentManagerProfileMenu.map((link) => (
                <li key={link?.id}>
                  <Link
                    to={link?.link}
                    className={`${style.user_profile_link} ${
                      profileMenuLinkActive === link?.id
                        ? style.user_profile_link_active
                        : ""
                    } `}
                    onClick={() => setProfileMenuLinkActive(link?.id)}
                  >
                    {link?.linkText}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
        <div className="col-span-3 h-[89vh] overflow-y-auto px-4">
          <Outlet />
        </div>
        <div>{/* extra jaiga */}</div>
      </div>
    </div>
  );
};

export default ProfileFormDashboard;
