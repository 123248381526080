import React, { useEffect } from "react";
import { BlogContent } from "./BlogContent";
import Blog from "./Blog";
import BlogFilter from "./BlogFilter";
import { useState } from "react";

const BlogHome = () => {
  const [searchItem, setSearchItem] = useState(""); // Fix: Added missing useState
  const [showMore, setShowMore] = useState(true);
  const [showLess, setShowLess] = useState(false);
  const [slice, setSlice] = useState(4);
  const arr = BlogContent.slice().reverse(); // Simplified the array creation

  const more = () => {
    if (!searchItem) {
      if (arr.length >= slice) {
        setSlice(arr.length + 1);
        setShowMore(false);
        setShowLess(true);
      }
    }
  };

  const less = () => {
    if (!searchItem) {
      setSlice(4);
      setShowMore(true);
      setShowLess(false);
    }
  };

  const filteredBlogs = arr.filter((u) => {
    if (searchItem === "") {
      return true;
    } else if (u?.title.toLowerCase().includes(searchItem.toLowerCase())) {
      return true;
    }
    return false;
  });

  return (
    <div>
      <div className="container mx-auto">
        <h2 className="text-xl font-sans font-extrabold text-center mt-10">
          Blog
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 justify-between my-10">
          <div className="col-span-1 md:col-span-2 mr-0 md:mr-5">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              {filteredBlogs.length === 0 && searchItem !== "" ? (
                <div className=" col-span-1 md:col-span-2 text-center text-3xl text-gray-700 font-semibold mt-5">
                  <h2>Blog not found</h2>
                </div>
              ) : (
                filteredBlogs
                  .slice(0, slice)
                  .map((content, i) => (
                    <Blog key={i} content={content} search={searchItem} />
                  ))
              )}
            </div>
            <div className="flex gap-2 justify-center my-5">
              {!searchItem && showMore && (
                <button
                  onClick={more}
                  className="btn bnt-sm btn-outline btn-primary">
                  Show more
                </button>
              )}
              {!searchItem && showLess && (
                <button
                  onClick={less}
                  className="btn bnt-sm btn-outline btn-primary">
                  Show Less
                </button>
              )}
            </div>
          </div>
          <div>
            <BlogFilter filter={arr} setSearchItem={setSearchItem} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogHome;
