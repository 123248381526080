import React from "react";
import { useJobPost } from "../../../../context/JobPostProvider";
import Loading from "../../../Loading/Loading";

import LeftSlide from "./LeftSlide";
import RightSlide from "./RightSlide";

const Job = () => {
  // get All job
  const {
    state: { postJobs, loading, error },
  } = useJobPost();

  let content;

  // loading function
  if (loading) {
    content = <Loading />;
  }

  // error handel function
  if (error) {
    content = <p>Error</p>;
  }

  // Nothing to show product list is empty
  if (!loading && !error && postJobs?.length === 0) {
    content = (
      <div className="h-[500px] flex justify-center items-center">
        <h1 className="font-bold text-2xl">
          Nothing to show product list is empty
        </h1>
      </div>
    );
  }

  // all job post get then map function
  if (!loading && !error && postJobs?.length) {
    content = postJobs?.map((job, i) => (
      <div key={i} className="card w-full scrollbar-hide bg-base-100">
        <div className="card-body py-2 px-5">
          <div className="grid md:grid-cols-4 sm:grid-cols-1 border-2 rounded-lg border-gray-200">
            <LeftSlide job={job} button={true} />
            <RightSlide job={job} />
          </div>
        </div>
      </div>
    ));
  }
  // console.log(postJobs, loading, error);

  return <div>{content}</div>;
};

export default Job;
