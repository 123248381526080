import React, { useContext } from "react";
import "./test.css";
import { SingleUserProfileGet } from "../../hooks/useUser";
import { globalFunctionOneContext } from "../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
const InputText = ({
  userSkill,
  setUserSkill,
  register,
  dropDown,
  placeholder,
  name,
}) => {
  // console.log(userSkill);
  const { firebaseUser, currentUser } = useContext(globalFunctionOneContext);

  const { data, isLoading, error, refetch } = SingleUserProfileGet(
    currentUser?.PersonID
  );
  console.log(data?.result?.top_skill);

  // get input value and all user Skill function
  const handleKeyDown = (e) => {
    // e.preventDefault();
    // If user did not press enter key, return

    if (e.key !== "Enter") {
      // e.preventDefault();
      return;
    }
    // Get the value of the input
    const value = e.target.value;
    // If the value is empty, return
    if (!value.trim()) return;
    // Add the value to the tags array
    setUserSkill([...userSkill, value]);
    // Clear the input
    e.target.value = "";
  };
  function removeTag(index) {
    setUserSkill(userSkill.filter((el, i) => i !== index));
  }
  return (
    <>
      {/*

 */}
      <div>
        {dropDown && (
          <h2>Pick your top frameworks, skills and technologies.(up to 8)</h2>
        )}
        <div
          className={` ${
            dropDown
              ? "w-[50%] border-2 p-1 rounded-lg"
              : "input-bordered input input-primary w-full"
          }  mt-4 flex items-center flex-wrap gap-2 w`}
        >
          {userSkill.map((tag, index) => (
            <div
              className="inline-block bg-gray-400 p-2 rounded-xl"
              key={index}
            >
              <span className="text">{tag}</span>
              <span
                className=" inline-flex h-5 w-5 bg-gray-400 text-white rounded-[50%] justify-center items-center ml-2 cursor-pointer text-xl"
                onClick={() => removeTag(index)}
              >
                &times;
              </span>
            </div>
          ))}
          <input
            onKeyDownCapture={handleKeyDown}
            type="text"
            className="tags-input"
            placeholder={`${placeholder ? placeholder : "Type Your Skill"}`}
          />
        </div>
        {dropDown === true &&
          userSkill.map((tag, i) => (
            <div key={i}>
              <div className="overflow-x-auto mt-4">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th className="bg-base-100"></th>
                      <th className="bg-base-100">Beginner</th>
                      <th className="bg-base-100">Intermediate</th>
                      <th className="bg-base-100">Advanced</th>
                      <th className="bg-base-100">Expert</th>
                    </tr>
                    <span></span>
                  </thead>

                  <tbody>
                    <tr>
                      <th>{tag}</th>
                      <td>
                        <input
                          {...register(tag)}
                          value="Beginner"
                          type="radio"
                          className="radio radio-primary radio-sm"
                        />
                      </td>
                      <td>
                        <input
                          {...register(tag)}
                          value="Intermediate"
                          type="radio"
                          className="radio radio-primary radio-sm"
                        />
                      </td>
                      <td>
                        <input
                          {...register(tag)}
                          value="Advanced"
                          type="radio"
                          className="radio radio-primary radio-sm"
                        />
                      </td>
                      <td>
                        <input
                          value="Expert"
                          type="radio"
                          {...register(tag)}
                          className="radio radio-primary radio-sm"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default InputText;
