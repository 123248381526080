import React, { useContext, useEffect, useState } from "react";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const MessageAllFriendList = ({
  allMessageFriendList,
  setHandelUserSelectMessageRoom,
}) => {
  const { onlineUsers } = useContext(globalFunctionOneContext);
  const [sortingFriendListCreate, setSortingFriendListCreate] = useState([]);

  useEffect(() => {
    if (allMessageFriendList?.data) {
      const sortedData = [...allMessageFriendList.data].sort((a, b) => {
        return new Date(b.messageTimeStamp) - new Date(a.messageTimeStamp);
      });

      console.log(sortedData);
      setSortingFriendListCreate(sortedData);
    }
  }, [allMessageFriendList]);

  return (
    <div>
      <h2 className="py-2 text-lg font-medium ml-2">Message 2</h2>
      <div className="card-body overflow-y-auto">
        {!sortingFriendListCreate.length === 0 && (
          <p className="font-bold text-md">You don't have any friends</p>
        )}
        {sortingFriendListCreate && (
          <ul>
            {sortingFriendListCreate?.map((room) => {
              if (!room?.isGroup) {
                const user = room?.participants[0];

                return (
                  <li
                    className="flex justify-start items-center cursor-pointer hover:bg-gray-400 p-2 font-bold"
                    key={user?.PersonID}
                    onClick={() =>
                      setHandelUserSelectMessageRoom({
                        roomId: room?.roomId,
                        groupName: room?.groupName,
                        profilePic: user?.profilePic,
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                        isGroup: false,
                        participateUsers: room?.participants,
                      })
                    }
                  >
                    <div>
                      <div
                        className={`avatar placeholder mr-4 ${
                          onlineUsers.includes(user?.PersonID)
                            ? "online"
                            : "offline"
                        }`}
                      >
                        <div className="bg-gray-300 font-bold rounded-full w-12">
                          <span className="text-xl">
                            {user?.firstName?.charAt(0).toUpperCase()}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h2>
                        {user?.firstName} {user?.lastName}
                      </h2>
                      {room?.messageContent && (
                        <h4>
                          {room?.messageContent.length > 20
                            ? `${room?.messageContent.slice(0, 20) + "..."}`
                            : room?.messageContent}
                        </h4>
                      )}
                    </div>
                  </li>
                );
              } else if (room?.isGroup) {
                return (
                  <li
                    className="flex justify-start items-center cursor-pointer hover:bg-gray-400 p-2 font-bold"
                    key={room?.roomId}
                    onClick={() =>
                      setHandelUserSelectMessageRoom({
                        roomId: room?.roomId,
                        groupName: room?.groupName,
                        profilePic: room?.participants[0]?.profilePic,
                        isGroup: true,
                        participateUsers: room?.participants,
                      })
                    }
                  >
                    <div>
                      <div
                        className={`avatar placeholder mr-4 ${
                          room?.participants.some((user) =>
                            onlineUsers.includes(user?.PersonID)
                          )
                            ? "online"
                            : "offline"
                        }`}
                      >
                        <div className="bg-gray-300 font-bold rounded-full w-12">
                          <span className="text-xl">
                            {room?.groupName?.charAt(0).toUpperCase()}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h2>{room?.groupName}</h2>
                      {room?.messageContent && (
                        <h4>{room?.messageContent.slice(0, 20)}...</h4>
                      )}
                    </div>
                  </li>
                );
              } else {
                return null;
              }
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default MessageAllFriendList;
