import React, { useContext, useState } from "react";
import { BsFillPatchQuestionFill } from "react-icons/bs";
import { HiOutlineClock, HiOutlineOfficeBuilding } from "react-icons/hi";
import { FaRegMoneyBillAlt, FaFlagCheckered } from "react-icons/fa";
import { MdWorkHistory } from "react-icons/md";
import { serverImageUrl } from "../../../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import flagImg from "../../../../../Assect/img/download.jpeg";
import { Link } from "react-router-dom";
import { globalFunctionOneContext } from "../../../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const Left = ({ freelancer }) => {
  const { setHandelFreelancerDetailsInfo } = useContext(
    globalFunctionOneContext
  );
  let skillKey = [];

  try {
    if (
      typeof freelancer.top_skill === "string" &&
      freelancer.top_skill.trim() !== ""
    ) {
      const skill = JSON.parse(freelancer.top_skill);

      if (typeof skill === "object" && skill !== null) {
        for (const [key, value] of Object.entries(skill)) {
          skillKey.push(key);
        }
      }
    }
  } catch (error) {
    console.log("Error parsing JSON:", error);
  }

  return (
    <div className="md:col-span-2 grid grid-cols-3">
      <div className="flex content-center justify-center flex-col">
        {/* profile */}
        <div className=" mx-auto mt-3">
          <div className="avatar online">
            <div className="w-32 rounded-full flex justify-center items-center">
              <img
                src={`${serverImageUrl}/${freelancer?.profilePhoto}`}
                alt="User Profile"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-2 mt-2">
        {/* <h2 className="card-title text-primary">Fn-{freelancer.PersonID}.</h2> */}
        {/* <h2 className="font-bold">
          {freelancer.profile_title} [{freelancer.PersonID}]
        </h2> */}
        <div className="flex justify-between items-center">
          <div>
            <Link
              to="/dashboard/client/jobs/inviteFreelancer/search/details"
              className="font-medium text-xl text-accent underline"
              onClick={() => setHandelFreelancerDetailsInfo(freelancer)}
            >
              {freelancer?.firstName} {freelancer?.lastName}
            </Link>
            <h2 className="text-gray-600">{freelancer?.profile_title}</h2>
          </div>

          {/* Rating  */}
          <div className="mr-10">
            <div className="rating  rating-xs">
              <input
                type="radio"
                name="rating-2"
                className="mask mask-star-2 bg-orange-400"
              />
              <input
                type="radio"
                name="rating-2"
                className="mask mask-star-2 bg-orange-400"
                checked
              />
              <input
                type="radio"
                name="rating-2"
                className="mask mask-star-2 bg-orange-400"
              />
              <input
                type="radio"
                name="rating-2"
                className="mask mask-star-2 bg-orange-400"
              />
              <input
                type="radio"
                name="rating-2"
                className="mask mask-star-2 bg-orange-400"
              />
            </div>
            <h1 className="pl-3 text-md">
              0.0/<span className="text-xs">5</span>
            </h1>
            <h1 className="text-xs text-secondary">(0 Feedback)</h1>
          </div>
        </div>
        <div className="flex">
          <h2 className="my-4 flex justify-start items-center">
            <FaRegMoneyBillAlt className="text-[#746EBB]" />
            <span className="font-bold ml-3">₹{freelancer?.expectedCtc}</span>
          </h2>
          <h2 className="my-4 flex justify-start items-center ml-5">
            <img src={flagImg} alt="" className="w-5  h-3" />
            {/* <FaFlagCheckered className="text-[#F29130]" /> */}
            <span className="font-bold ml-3">{freelancer?.country}</span>
          </h2>
          <h2 className="my-4 flex justify-start items-center ml-5">
            <MdWorkHistory className="text-gray-400" />
            <span className="font-bold ml-3">
              {freelancer?.full_time_job_experience}
            </span>
          </h2>
        </div>
        {/* <div className="mb-3 flex gap-1 items-center">
          <span>
            <HiOutlineClock className="text-secondary text-xl" />
          </span>
          <h2 className="text-secondary">Has been using hireUi since 2022</h2>
        </div> */}
        <p className="mb-5">
          Excepteur sint occaecat cupidatat non proident, saeunt in culpa qui
          officia deserunt mollit anim id est laborum. Seden utem perspiciatis
          undesieu omnis iste natus error
        </p>
        {/* <div className="flex gap-1 items-center">
          <h2>
            Has <span className="font-bold">1 relevant</span> skills to your job
          </h2>
          <span>
            <BsFillPatchQuestionFill className="text-xl" />
          </span>
        </div> */}
        {/* <div>
          <button className="mt-4 rounded-lg bg-gray-200 px-2 py-1 ">
            Web Development
          </button>
        </div> */}
        <div>
          {skillKey.map((skill, i) => (
            <button
              key={i}
              className="btn btn-secondary btn-sm ml-2 mt-4 btn-outline"
            >
              {skill}
            </button>
          ))}
        </div>
        {/* company name */}
        {/* <div className="border-2  mt-8 border-gray-200 flex items-center gap-1 rounded-lg">
          <div>
            <HiOutlineOfficeBuilding className="text-4xl" />
          </div>
          <div>
            <h2>
              <span className="block">Spencer Agency</span>
              <span className="text-secondary ">Spencer Agency</span>
            </h2>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Left;
