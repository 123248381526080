import React, { useContext } from "react";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { useNavigate } from "react-router-dom";

const GuestModeFreelancerPrivateRoute = ({ children }) => {
  const { currentUser, confirmAlertValue, setConfirmAlertValue } = useContext(
    globalFunctionOneContext
  );
  const navigate = useNavigate();

  if (currentUser && !(currentUser?.accountType === "Freelancer")) {
    navigate("/login");
  }

  return children;
};

export default GuestModeFreelancerPrivateRoute;
