import React, { useContext, useEffect, useState } from "react";
import { globalFunctionOneContext } from "../../../../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { FcLeft } from "react-icons/fc";
import { BsArrowLeft, BsClock } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { serverImageUrl } from "../../../../../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { GrMapLocation } from "react-icons/gr";
import { TiBook } from "react-icons/ti";
import currentEmployment from "../../../../../../Assect/img/currentEmployment.png";
import Company from "../../../../../../Assect/img/Company.png";
import moneyIcon from "../../../../../../Assect/img/Project_price.jpg";
import Designation from "../../../../../../Assect/img/Designation.png";
import skillIcon from "../../../../../../Assect/img/Skill.png";
import JobStartDate from "../../../../../../Assect/img/JobStartDate.png";
import JobEndDate from "../../../../../../Assect/img/JobEndDate.png";

const InviteFreelancerDetails = () => {
  const {
    userCurrentLocationPath,
    handelFreelancerDetailsInfo,
    setHandelFreelancerDetailsInfo,
  } = useContext(globalFunctionOneContext);
  const navigate = useNavigate();

  let skillKey = [];

  try {
    if (
      typeof handelFreelancerDetailsInfo.top_skill === "string" &&
      handelFreelancerDetailsInfo.top_skill.trim() !== ""
    ) {
      const skill = JSON.parse(handelFreelancerDetailsInfo.top_skill);

      if (typeof skill === "object" && skill !== null) {
        for (const [key, value] of Object.entries(skill)) {
          skillKey.push(key);
        }
      }
    }
  } catch (error) {
    console.log("Error parsing JSON:", error);
  }

  useEffect(() => {
    switch (userCurrentLocationPath) {
      case "/dashboard/client/jobs/inviteFreelancer/details":
        if (Object.keys(handelFreelancerDetailsInfo).length === 0) {
          navigate(
            "/dashboard/client/jobs/inviteFreelancer/freelancerInviteList"
          );
        }
        break;

      case "/dashboard/client/jobs/inviteFreelancer/saved/details":
        if (Object.keys(handelFreelancerDetailsInfo).length === 0) {
          navigate("/dashboard/client/jobs/inviteFreelancer/saved");
        }
        break;

      case "/dashboard/client/jobs/reviewProposal/freelancer/details":
        if (Object.keys(handelFreelancerDetailsInfo).length === 0) {
          navigate("/dashboard/client/jobs/reviewProposal");
        }
        break;

      case "/dashboard/client/jobs/reviewProposal/shortlisted/freelancer/details":
        if (Object.keys(handelFreelancerDetailsInfo).length === 0) {
          navigate("/dashboard/client/jobs/reviewProposal/shortlisted");
        }
        break;
      case "/dashboard/client/jobs/inviteFreelancer/search/details":
        if (Object.keys(handelFreelancerDetailsInfo).length === 0) {
          navigate("/dashboard/client/jobs/inviteFreelancer");
        }
        break;
      default:
        //  This code default is empty
        break;
    }
  }, [handelFreelancerDetailsInfo, navigate, userCurrentLocationPath]);

  console.log("hello Freelancer Data", handelFreelancerDetailsInfo);
  return (
    <div className="bg-gray-100 pb-10">
      {/* <FcLeft className="text-3xl cursor-pointer text-black" /> */}
      <button onClick={() => navigate(-1)}>
        <BsArrowLeft className="text-3xl cursor-pointer text-black mb-5 hover:text-gray-600" />
      </button>
      <div className="w-[1200px] mx-auto">
        <div className=" py-10 px-5 bg-white rounded-lg shadow-lg">
          <div className="flex">
            <div>
              {handelFreelancerDetailsInfo?.profilePhoto ? (
                <div className="avatar online">
                  <div className="w-24 rounded-full">
                    <img
                      src={`${serverImageUrl}/${handelFreelancerDetailsInfo?.profilePhoto}`}
                      alt="user profile pic"
                    />
                  </div>
                </div>
              ) : (
                <div className="w-24 h-24 rounded-full  flex justify-center items-center bg-blue-300 avatar online mt-5">
                  <h1 className="text-secondary text-3xl font-bold ">
                    {handelFreelancerDetailsInfo?.firstName && (
                      <span>
                        {handelFreelancerDetailsInfo?.firstName.slice(0, 2)}
                      </span>
                    )}
                  </h1>
                </div>
              )}
            </div>
            <div className="pl-10">
              {handelFreelancerDetailsInfo?.firstName &&
                handelFreelancerDetailsInfo?.lastName && (
                  <h2 className="text-xl font-semibold mt-3 ">
                    {handelFreelancerDetailsInfo?.firstName +
                      handelFreelancerDetailsInfo?.lastName}
                  </h2>
                )}
              <h3 className="text-lg">
                {handelFreelancerDetailsInfo?.profile_title}
              </h3>
              <div className="flex gap-2 items-center text-secondary">
                <GrMapLocation className="text-secondary text-sm" />
                <p>{handelFreelancerDetailsInfo?.country}</p>
              </div>
              <div>
                {skillKey.map((skill, i) => (
                  <button
                    key={i}
                    className="btn btn-secondary btn-sm ml-2 mt-4 btn-outline"
                  >
                    {skill}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <hr className="border-b-2 border-gray-300 my-5" />
          <div>
            <h1 className="text-xl font-semibold">Overview</h1>
          </div>
          {handelFreelancerDetailsInfo?.bigest_achivement && (
            <p className="mt-3">
              {handelFreelancerDetailsInfo?.bigest_achivement}
            </p>
          )}
        </div>
        <div className=" py-10 px-5 bg-white rounded-lg shadow-lg my-5">
          <div>
            <h1 className="text-xl font-semibold">EMPLOYMENT HISTORY</h1>
          </div>

          <hr className="border-b-2 border-gray-300 my-5" />
          {handelFreelancerDetailsInfo?.jobProfile && (
            <p className="mt-3">{handelFreelancerDetailsInfo?.jobProfile}</p>
          )}

          <div className="flex gap-5 flex-wrap pt-4 px-5 my-5">
            <div className=" flex gap-2 ">
              <div>
                <img
                  src={currentEmployment}
                  alt="FreelancerExperienceIcon"
                  className="w-5 h-5 mt-1"
                />
              </div>
              <div>
                <h2 className="font-bold">Current Employment</h2>
                <p>{handelFreelancerDetailsInfo?.currentJob}</p>
              </div>
            </div>
            <div className=" flex gap-2 ">
              <div>
                <BsClock className="mt-1 text-xl" />
              </div>
              <div>
                <h2 className="font-bold">Employment Type</h2>
                <p>{handelFreelancerDetailsInfo?.employmentType}</p>
              </div>
            </div>
            <div className=" flex gap-2 ">
              <div>
                <img
                  src={Company}
                  alt="FreelancerExperienceIcon"
                  className="w-5 h-5 mt-1"
                />
              </div>
              <div>
                <h2 className="font-bold">Current CompanyName</h2>
                <p>{handelFreelancerDetailsInfo?.currentCompanyName}</p>
              </div>
            </div>
            <div className=" flex gap-2 ">
              <div>
                <img
                  src={Designation}
                  alt="FreelancerExperienceIcon"
                  className="w-6 h-85 mt-2"
                />
              </div>
              <div>
                <h2 className="font-bold">Current Designation</h2>
                <p>{handelFreelancerDetailsInfo?.currentDesignation}</p>
              </div>
            </div>
          </div>

          <div className="flex gap-5 flex-wrap pt-4 px-5">
            <div className=" flex gap-2 ">
              <div>
                <img
                  src={moneyIcon}
                  alt="FreelancerExperienceIcon"
                  className="w-5 h-5 mt-1"
                />
              </div>
              <div>
                <h2 className="font-bold">Current Salary</h2>
                <p>₹ {handelFreelancerDetailsInfo?.currentSalary} Lacs PA</p>
              </div>
            </div>
            <div className=" flex gap-2 ">
              <div>
                <img
                  src={skillIcon}
                  alt="FreelancerExperienceIcon"
                  className="w-5 h-5 mt-1"
                />
              </div>
              <div>
                <h2 className="font-bold">Skill Used</h2>
                <p>{handelFreelancerDetailsInfo?.skillUsed}</p>
              </div>
            </div>
            <div className=" flex gap-2 ">
              <div>
                <img
                  src={JobStartDate}
                  alt="FreelancerExperienceIcon"
                  className="w-5 h-5 mt-1"
                />
              </div>
              <div>
                <h2 className="font-bold">Job Joining Date</h2>
                <p>{handelFreelancerDetailsInfo?.jobJoiningDate}</p>
              </div>
            </div>
            <div className=" flex gap-2 ">
              <div>
                <img
                  src={JobEndDate}
                  alt="FreelancerExperienceIcon"
                  className="w-5 h-5 mt-1"
                />
              </div>
              <div>
                <h2 className="font-bold">Job Ending Year</h2>
                <p>{handelFreelancerDetailsInfo?.jobEndingYear}</p>
              </div>
            </div>
          </div>
          <div className="px-5 md:my-5 lg:mt-10">
            <h2>
              <span className="my-1 font-bold">
                Have you served your notice period?:
              </span>{" "}
              <span>{handelFreelancerDetailsInfo?.startedPeriod}</span>
            </h2>
            <h2>
              <span className="my-1 font-bold">
                How long is your notice period?:
              </span>{" "}
              <span>{handelFreelancerDetailsInfo?.longPeriod}</span>
            </h2>
            <h2>
              <span className="my-1 font-bold">
                Is the notice period negotiable?:
              </span>{" "}
              <span>{handelFreelancerDetailsInfo?.negotiablePeriod}</span>
            </h2>
          </div>
          <div className="px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-5">
            <div>
              <h2 className="lg:my-2">
                <span className="font-bold mr-1">Desired CTC : </span>
                <span></span>
              </h2>
              <h2 className="lg:my-2">
                <span className="font-bold mr-1">Desired State :</span>
                <span></span>
              </h2>
            </div>
            <div>
              <h2 className="lg:my-2">
                <span className="font-bold mr-1">Minimum Expected CTC :</span>
                <span>{handelFreelancerDetailsInfo?.expectedCtc}</span>
              </h2>
              <h2 className="lg:my-2">
                <span className="font-bold mr-1">Desired City :</span>
                <span>{handelFreelancerDetailsInfo?.desireCitiesWorking}</span>
              </h2>
            </div>
            <div>
              <h2 className="lg:my-2">
                <span className="font-bold mr-1">
                  Preferred Location Onsite/remote :
                </span>
                <span>{handelFreelancerDetailsInfo?.okaytoRemoteJob}</span>
              </h2>
            </div>
          </div>
        </div>
        <div className=" py-10 px-5 bg-white rounded-lg shadow-lg my-5">
          <div>
            <h1 className="text-xl font-semibold">Education History</h1>
          </div>
          <hr className="border-b-2 border-gray-300 my-5" />
          <div className="px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-5">
            <div>
              <div className="lg:my-2">
                <p className="font-bold">Highest Qualification: </p>
                <p>{handelFreelancerDetailsInfo?.addEducation}</p>
              </div>
              {/* <h2 className="lg:my-2">
                <span className="font-bold">Course Type:</span>
                <span></span>
              </h2> */}
            </div>
            <div>
              <h2 className="lg:my-2">
                <p className="font-bold mr-1">University/Institute: </p>
                <p>{handelFreelancerDetailsInfo?.addUniversity}</p>
              </h2>
              <h2 className="lg:my-2">
                <p className="font-bold mr-1">Course Duration: </p>
                <p>
                  {handelFreelancerDetailsInfo?.courStartingYear}
                  to {handelFreelancerDetailsInfo?.courseEndingYear}
                </p>
              </h2>
            </div>
            <div>
              <h2 className="lg:my-2">
                <p className="font-bold mr-1">Course: </p>
                <p>{handelFreelancerDetailsInfo?.courseSpecialization}</p>
              </h2>
              <h2 className="lg:my-2">
                <p className="font-bold mr-1">Grade: </p>
                <p>{handelFreelancerDetailsInfo?.gradingSystem}</p>
              </h2>
              <h2 className="lg:my-2">
                <p className="font-bold mr-1">Marks: </p>
                <p>{handelFreelancerDetailsInfo?.marks}</p>
              </h2>
            </div>
          </div>

          <div>
            <h1 className="text-xl font-semibold mt-10">Social Links</h1>
          </div>
          <hr className="border-b-2 border-gray-300 my-5" />
          <div className="px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-5">
            <div>
              <div className="lg:my-2">
                <h2 className="font-bold">Linkedin</h2>
                <a
                  href={
                    handelFreelancerDetailsInfo?.linkedIn
                      ? `${handelFreelancerDetailsInfo.linkedIn}`
                      : ""
                  }
                  className="text-secondary text-sm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {handelFreelancerDetailsInfo?.linkedIn}
                </a>
              </div>
              <div className="lg:my-2">
                <h2 className="font-bold">Personal</h2>
                <a
                  href={
                    handelFreelancerDetailsInfo?.portfolio
                      ? `${handelFreelancerDetailsInfo.portfolio}`
                      : ""
                  }
                  className="text-secondary text-sm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {handelFreelancerDetailsInfo?.portfolio}
                </a>
              </div>
            </div>

            <div>
              <div className="lg:my-2">
                <h2 className="font-bold">Github</h2>
                <a
                  href={
                    handelFreelancerDetailsInfo?.github
                      ? `${handelFreelancerDetailsInfo.github}`
                      : ""
                  }
                  className="text-secondary text-sm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {handelFreelancerDetailsInfo?.github}
                </a>
              </div>
            </div>
            <div>
              <div className="lg:my-2">
                <h2 className="font-bold">Stack Overflow</h2>
                <a
                  href={
                    handelFreelancerDetailsInfo?.stackoverflow
                      ? `${handelFreelancerDetailsInfo.stackoverflow}`
                      : ""
                  }
                  className="text-secondary text-sm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {handelFreelancerDetailsInfo?.stackoverflow}
                </a>
              </div>
            </div>
          </div>
          <div className="mt-5 px-4">
            <h2 className="font-bold lg:my-2">Where did you hear about us?</h2>
            <h2 className="font-bold lg:my-2">
              Where are you in a job search?
            </h2>
            <h2 className="font-bold lg:my-2">
              Why do you want to work in our company?
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteFreelancerDetails;

// const dta = {
//   MathMarks: "",
//   PersonID: "NOxLEOfOY1hiSUB7iP01ptcE0L73",
//   accountType: "Freelancer",
//   addEducation: "Doctorate/PHD",
//   addUniversity: "Gujarat Technological University",
//   address: null,
//   bigest_achivement: "dgfdhfhfghsfs",
//   boardName: "",
//   citiesNcr: "56546",
//   country: "Albania",
//   courStartingYear: "1951",
//   course: "courses",
//   courseEndingYear: "1953",
//   courseSpecialization: "Food Technology",
//   currentCompanyName: "test",
//   currentCtc: "4566",
//   currentDesignation: "test",
//   currentJob: "No",
//   currentLocation: "Noida",
//   currentSalary: "test",
//   datOfBirth: null,
//   date_req: "2023-04-13T18:00:00.000Z",
//   desireCitiesWorking: "545",
//   email: "maroufulislam.zishan@gmail.com",
//   employmentType: "Full Time",
//   englishMarks: "",
//   expectedCtc: "12",
//   firstName: "roky",
//   fixedCtc: "26",
//   full_time_job_experience: "2 years",
//   github: "https://github.com/zishan344",
//   gradingSystem: "% marks of 100 maximum",
//   hear_aboutUs: "Word of Mouth",
//   hidden_form: "",
//   id: 10,
//   interestedRole:
//     '{"role1":"Front End Developer","role11":"Research Analyst","role3":"Game Developer","role4":"IOS Developer"}',
//   jobEndingYear: "2021",
//   jobJoiningDate: "1965",
//   jobProfile: "dsafdghs5wwt gtth hhhtryt",
//   job_search: "open to new opportunities",
//   lastName: "hasantesxg",
//   linkedIn: "https://www.linkedin.com/in/marouful-islam-zishan-950610241/",
//   longPeriod: "2 month",
//   marks: "44",
//   muchExpectedCtc: "45",
//   negotiablePeriod: "lorem1",
//   okaytoRemoteJob: "okay with remote/in-office jobs",
//   otherCourse: "",
//   otherEducation: "",
//   otherUniversity: "",
//   password: "123456",
//   phonNumber: "",
//   portfolio: "https://zishan-portfolil-1a18c9.netlify.app/",
//   profilePhoto:
//     "uploads/316966771_193500886556222_4776464386374175007_n.jpg-1671087531355.jpeg",
//   profile_title: "mern developer",
//   ready_to_interview: "",
//   report_people: "lorem1",
//   resume: "",
//   rules: "agree",
//   schoolMarks: "",
//   schoolMedium: "",
//   schoolPassingYear: "",
//   skillUsed: "test",
//   stackoverflow: "vfdsdawe",
//   startedPeriod: "lorem1",
//   status: "Approve",
//   top_skill: '{"java":"Beginner","css":"Intermediate","html":"Beginner"}',
//   userProfileStats: 0,
//   username: "roky43",
//   work_at_company_size:
//     '{"Seed (1-10)":"Yes","early (11-50)":"Ideal","Mid-size (51-200)":"Yes","Large (201-500)":"Ideal","veryLarge (501-1000)":"Ideal","Massive (1000+)":"Yes"}',
// };

// Education
// courStartingYear: '1964',
// courseEndingYear: '1966',
// gradingSystem: 'Scale 10 Grading System',
// marks: '5',
// addEducation: 'Doctorate/PHD',
// addUniversity: 'Gujarat Technological University',
// course: 'courses',
// courseSpecialization: 'Aviation',
