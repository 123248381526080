import React, { useContext, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { DashboardClientJobsNavbar } from "../../../../hooks/NavbarAllFunction/useNavbarAllFunction";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const ReviewNavbar = ({ data }) => {
  const { userCurrentLocationPath } = useContext(globalFunctionOneContext);
  const { proposalActiveMenu } = DashboardClientJobsNavbar(
    userCurrentLocationPath
  );

  // list menu
  const menuList = [
    {
      id: 1,
      listText: `All Proposals (${
        data?.result?.length === 0 ? 0 : data?.result?.length
      })`,
      path: "/dashboard/client/jobs/reviewProposal",
    },

    {
      id: 2,
      listText: "Shortlisted",
      path: "/dashboard/client/jobs/reviewProposal/shortlisted",
    },
    {
      id: 3,
      listText: "Decline Freelancer",
      path: "/dashboard/client/jobs/reviewProposal/decline/freelancer",
    },
  ];

  return (
    <div>
      <div className="border-b-2 py-1 mb-2">
        <ul className="flex gap-4 my-2 cursor-pointer">
          {menuList &&
            menuList.map((list) => (
              <li key={list?.id}>
                <Link
                  to={list?.path}
                  className={`${
                    proposalActiveMenu === list?.id
                      ? "font-bold border-b-2 border-black"
                      : ""
                  }`}
                >
                  {list?.listText}
                </Link>
              </li>
            ))}
        </ul>
      </div>
      <Outlet />
    </div>
  );
};

export default ReviewNavbar;
