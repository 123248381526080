import { useContext, useEffect, useRef, useState } from "react";
import {
  useAuthState,
  useSignInWithEmailAndPassword,
  useSignInWithFacebook,
} from "react-firebase-hooks/auth";
import ReCAPTCHA from "react-google-recaptcha";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Social from "../Social";
import { auth } from "../../../config/firebaseConfig/firebaseConfig";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const LoginModal = ({ setL, l }) => {
  const {
    currentUser,
    currentUserAccountTypeClient,
    currentUserAccountTypeFreelancer,
    currentUserAccountTypeTalentManager,
    Loading,
    setLoading,
  } = useContext(globalFunctionOneContext);
  const [checked, setChecked] = useState(false);
  const [signInWithEmailAndPassword, rUser, loading, error] =
    useSignInWithEmailAndPassword(auth);
  const [signInWithFacebook, fUser, fLoading, fError] =
    useSignInWithFacebook(auth);
  const [showPassword, setShowPassword] = useState(true);
  const [pathname, setPathname] = useState("/");
  const [user] = useAuthState(auth);

  const navigate = useNavigate();
  const location = useLocation();
  const googleCapchaRef = useRef();

  useEffect(() => {
    if (error) {
      toast.error(error?.message.split("/")[1]);
    }
  }, [error]);

  // const { data } = useProfileData();

  const handleLogin = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    await signInWithEmailAndPassword(email, password);
  };

  const [recaptchaValue, setRecaptchaValue] = useState("");

  useEffect(() => {
    // if (data && data.length > 0) {
    //   switch (data[0].accountType) {
    //     case "client":
    //       setPathname("/client");
    //       break;
    //     case "talent_manager":
    //       setPathname("/talentManager");
    //       break;
    //     case "Freelancer":
    //       setPathname("/freelancer/home");
    //       break;
    //     default:
    //       setPathname("/");
    //       break;
    //   }
    // }

    if (currentUser) {
      // console.log(currentUser);
      switch (currentUser?.accountType) {
        case "client":
          setPathname("/client");
          break;
        case "talent_manager":
          setPathname("/talentManager");
          break;
        case "Freelancer":
          setPathname("/freelancer/home");
          break;
        default:
          setPathname("/");
          break;
      }
    }
  }, [currentUser]);

  let { from } = location.state || { from: { pathname: pathname } };

  useEffect(() => {
    if (currentUser) {
      navigate(from, { replace: true });
    }
  }, [currentUser, from, navigate]);

  useEffect(() => {
    if (user) {
      setL(false);
    }
  }, [user, setL]);

  const onChange = (value) => {
    setRecaptchaValue(value);
  };

  // captcha  key
  /*
  site key==> 6LfL2u0kAAAAAKmLq_8F2ns2CxsYa9TM4jNn2vN8
  secret key ==>6LfL2u0kAAAAAOpXMEBYZ7GVotclxoJxAjc4t6m_
  */
  return (
    <>
      {/* <!-- The button to open modal --> */}

      {/* <!-- Put this part before </body> tag --> */}
      <div className="card max-w-full mx-auto bg-base-100 ">
        <div className="card-body pt-0">
          <input type="checkbox" id="my-modal-6" className="modal-toggle" />
          <div className="modal modal-bottom sm:modal-middle">
            <div className="modal-box">
              <label
                onClick={() => setL(!l)}
                htmlFor="my-modal-6"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <span>Login</span>
              <div className="divider"></div>

              <form onSubmit={handleLogin}>
                <div className="form-control w-full max-w-full mx-auto mb-4">
                  <input
                    type="email"
                    name="email"
                    required
                    placeholder="Enter your Email"
                    className="input input-bordered w-full max-w-full"
                  />
                  {/* <label className="label">
                <span className="label-text-alt">Alt label</span>
              </label> */}
                </div>
                <div className="form-control w-full max-w-full mx-auto relative">
                  <input
                    type={showPassword ? "password" : "text"}
                    name="password"
                    placeholder="Enter Your Password"
                    className="input input-bordered w-full max-w-full"
                  />
                  <span className="label-text-alt absolute top-3 right-2">
                    <label className="swap">
                      <input
                        type="checkbox"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                      {/* <!-- Password on icon --> */}
                      <AiOutlineEye className="text-xl swap-on" />
                      {/* <!-- Password off icon --> */}
                      <AiOutlineEyeInvisible className="text-xl swap-off" />
                    </label>
                  </span>

                  <div className="my-4">
                    <ReCAPTCHA
                      sitekey="6LfL2u0kAAAAAKmLq_8F2ns2CxsYa9TM4jNn2vN8"
                      onChange={onChange}
                    />
                  </div>
                  <label className="cursor-pointer label justify-start gap-2 mt-10 mb-2">
                    <input
                      onChange={() => setChecked(!checked)}
                      type="checkbox"
                      className="checkbox checkbox-secondary checkbox-sm"
                    />
                    <span className="label-text">Keep me logged in</span>
                  </label>
                </div>

                <div className=" ">
                  <button
                    type="submit"
                    disabled={!checked || !recaptchaValue}
                    style={{ padding: "0 50px" }}
                    className="btn btn-secondary px-6 btn-xs sm:btn-sm md:btn-md  max-w-xs mx-auto"
                  >
                    Login
                  </button>
                </div>
              </form>

              <div className="divider md:max-w-full sm:max-w-sm">OR</div>
              <Social signInWithFacebook={signInWithFacebook} />
              <div className="divider"></div>
              <div>
                <p className="text-center">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptatum cumque dicta quo soluta odio culpa!
                  <span className="text-accent cursor-pointer">
                    Terms & condition
                  </span>
                </p>
              </div>
              <div className="divider mb-0"></div>
              <div className="flex flex-col w-full lg:flex-row">
                <div className="">
                  Not a member?
                  <Link
                    to="/login"
                    onClick={() => setL(!l)}
                    className="text-accent cursor-pointer"
                  >
                    Signup Now
                  </Link>
                </div>
                <div
                  style={{ marginTop: "-10px", marginBottom: "-21px" }}
                  className="divider lg:divider-horizontal mb-[-20px]"
                ></div>
                <div className="text-accent cursor-pointer">
                  <Link to="/recover_password" onClick={() => setL(!l)}>
                    Reset Password
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginModal;
