import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useLocation } from "react-router-dom";
import EmailVerifyd from "../Authontection/EmailVerifyd";
import Loading from "../Loading/Loading";
import { auth } from "../../config/firebaseConfig/firebaseConfig";

const RequireAuth = ({ children }) => {
  const [user, loading] = useAuthState(auth);
  const location = useLocation();

  if (loading) {
    return <Loading />;
  }
  if (user) {
    if (!user.emailVerified) {
      return <EmailVerifyd />;
    }
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

export default RequireAuth;
