import React, { useContext, useEffect, useRef, useState } from "react";
import { useSignInWithFacebook } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { StepperContext } from "../../../context/StepContext";
import Social from "../Social";
import { auth } from "../../../config/firebaseConfig/firebaseConfig";
import Account from "./multStepForm/Steps/Account";
import Details from "./multStepForm/Steps/Details";
import Final from "./multStepForm/Steps/Final";
import Stepper from "./multStepForm/Stepper";
import StepperControl from "./multStepForm/StepperControl";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

// import { liveSiteUrl } from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";
// import { FcAlarmClock } from "react-icons/fc";

const RegisterModal = ({ setR, r }) => {
  const { firebaseUser, currentUser } = useContext(globalFunctionOneContext);
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState("");
  const [finalData, setFinalData] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const steps = ["Account Information", "Personal Details", "Complete"];

  const [signInWithFacebook, fUser, fLoading, fError] =
    useSignInWithFacebook(auth);
  const [err, setErr] = useState(false);
  const functionRunRef = useRef(false);

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <Account err={err} setErr={setErr} />;
      case 2:
        return <Details err={err} setErr={setErr} />;

      case 3:
        return (
          <Final
            // handleRegister={handleRegister}
            setR={setR}
            // loading={loading}
            // pUpdating={pUpdating}
            // user={user}
          />
        );
      default:
    }
  };

  const handleClick = (direction, e) => {
    //

    let newStep = currentStep;
    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };
  return (
    <>
      {/* <!-- The button to open modal --> */}

      {/* <!-- Put this part before </body> tag --> */}
      <div className="card max-w-full mx-auto bg-base-100 ">
        <div className="card-body pt-0">
          <input type="checkbox" id="my-modal-7" className="modal-toggle" />
          <div className="modal modal-bottom sm:modal-middle">
            <div className="modal-box">
              {!firebaseUser && (
                <label
                  onClick={() => setR(!r)}
                  htmlFor="my-modal-7"
                  className="btn btn-sm btn-circle absolute right-2 top-2"
                >
                  ✕
                </label>
              )}

              <h2 className="card-title text-center justify-center text-xl font-bold mb-5">
                {firebaseUser ? "Congratulation!" : "Join For a Good Start"}
              </h2>

              <p className="text-center justify-center text-md  mb-5">
                {firebaseUser ? "You are successfully registered." : ""}
              </p>

              <div>
                {/* stepper */}

                <Stepper steps={steps} currentStep={currentStep} />
                {/* Display Component */}
                <div className="mt-14">
                  <StepperContext.Provider
                    value={{
                      userData,
                      setUserData,
                      finalData,
                      setFinalData,
                    }}
                  >
                    {displayStep(currentStep)}
                  </StepperContext.Provider>
                </div>

                {/* Navigation controls */}
                {currentStep !== steps.length && (
                  <StepperControl
                    handleClick={handleClick}
                    currentStep={currentStep}
                    steps={steps}
                    err={err}
                  />
                )}
              </div>
              {currentStep === 1 && (
                <>
                  <div className="my-5">
                    <h2 className="text-center font-semibold text-xl mb-5">
                      Join Now With
                    </h2>
                    <p className="text-xs">
                      By creating an account or logging in, you understand and
                      agree to{" "}
                      <b className="text-[#6366F1]">
                        <Link to="/terms&condition" target="_blank">
                          Airepro Terms
                        </Link>{" "}
                      </b>
                      .
                      <br />
                      You also acknowledge our
                      <b className="text-[#6366F1]">
                        {" "}
                        <Link to="/cookieSetting" target="_blank">
                          Cookie
                        </Link>{" "}
                      </b>
                      and{" "}
                      <b className="text-[#6366F1]">
                        <Link to="/privacy&policy" target="_blank">
                          Privacy Policies
                        </Link>{" "}
                      </b>
                      . You will receive marketing messages from
                      <b className="text-[#6366F1]"> Airepro</b> and may opt out
                      at any time by following the
                      <b className="text-[#6366F1]"> unsubscribe</b>
                      <br /> link in our messages, or as detailed in our terms.
                    </p>
                  </div>
                  <Social signInWithFacebook={signInWithFacebook} setR={setR} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterModal;
