import React, { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineMessage } from "react-icons/ai";
import { BsFillFolderFill, BsMegaphoneFill } from "react-icons/bs";
import { GrSchedules } from "react-icons/gr";
import { ImOffice } from "react-icons/im";
import { IoMdPeople } from "react-icons/io";
import { MdPersonSearch, MdMoreTime } from "react-icons/md";
import { SiGoogleanalytics } from "react-icons/si";
import { TbLayoutDashboard } from "react-icons/tb";
import {} from "react-icons/md";

import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import JobPostProvider from "../../context/JobPostProvider";
import { globalFunctionOneContext } from "../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import PostJob from "../Client/PostJob/PostJob";
import Loading from "../Loading/Loading";

const GlobalDashboard = () => {
  const {
    currentUser,
    databaseSingleUserRefetch,
    windowLoading,
    currentUserAccountTypeClient,
    currentUserAccountTypeFreelancer,
    confirmAlertValue,
    setConfirmAlertValue,
  } = useContext(globalFunctionOneContext);
  const location = useLocation();
  const hasRunEffect = useRef(false);
  // const { data } = useProfileData();
  const navigate = useNavigate();
  const [post, setPost] = useState(false);
  const [dashboardNaveMenuActive, setDashboardNaveMenuActive] = useState(0);

  // user profile complete Alert function
  useEffect(() => {
    databaseSingleUserRefetch();
    if (!currentUser?.userProfileStats && currentUser) {
      // console.log(currentUser?.userProfileStats);
      if (!hasRunEffect.current) {
        if (
          location.pathname === "/dashboard" &&
          window.confirm("Please fill the details") === true
        ) {
          navigate("/user/profile/from");
        } else {
          setConfirmAlertValue(false);
        }
        hasRunEffect.current = true;
      }
    }
  }, [
    currentUser,
    location.pathname,
    databaseSingleUserRefetch,
    navigate,
    setConfirmAlertValue,
  ]);

  // Client Dashboard Nave Menu
  const clientDashboardNavMenu = [
    {
      id: 1,
      linkText: "Jobs",
      linkIcon: <ImOffice className="text-xl" />,
      linkPath: "/dashboard/client/jobs",
    },
    {
      id: 2,
      linkText: "Campaigns",
      linkIcon: <BsMegaphoneFill className="text-xl" />,
      linkPath: "/dashboard/client/campaigns",
    },
    {
      id: 3,
      linkText: "Contract",
      linkIcon: <IoMdPeople className="text-xl" />,
      linkPath: "/dashboard/client/contract",
    },
    // {
    //   id: 3,
    //   linkText: "Candidates",
    //   linkIcon: <IoMdPeople className="text-xl" />,
    //   linkPath: "/dashboard/client/candidates",
    // },
    {
      id: 4,
      linkText: "Search",
      linkIcon: <MdPersonSearch className="text-xl" />,
      linkPath: "/dashboard/client/search_resume",
    },
    {
      id: 5,
      linkText: "Interviews",
      linkIcon: <GrSchedules className="text-xl" />,
      linkPath: "/dashboard/client/Interviews",
    },
    {
      id: 6,
      linkText: "Analytics",
      linkIcon: <SiGoogleanalytics className="text-xl" />,
      linkPath: "/dashboard/client/Analytics",
    },
    {
      id: 7,
      linkText: "Tools",
      linkIcon: <BsFillFolderFill className="text-xl" />,
      linkPath: "/dashboard/client/Tools",
    },
  ];

  // loader
  if (windowLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <JobPostProvider>
        <div style={{ height: "calc(100vh - 64px)" }} className="drawer">
          {/* drawer-mobile */}
          <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
          <div className=" drawer-content scrollbar-hide container mx-auto">
            {/* <!-- Page content here --> */}

            <Outlet />
          </div>
          <div className="drawer-side">
            {/* <div> */}

            <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
            <ul className="menu p-4 overflow-y-auto gap-y-3 flex-nowrap w-52 md:w-60 bg-base-100 text-base-content">
              {/* <!-- Sidebar content here --> */}
              <li>
                <NavLink to="/">
                  <TbLayoutDashboard className="text-xl" /> <span>Home</span>
                </NavLink>
              </li>
              {/* client Dashboard Menu */}
              {currentUserAccountTypeClient && (
                <>
                  <li>
                    <label
                      onClick={() => {
                        if (currentUser?.userProfileStats === 1) {
                          setPost(!post);
                        }
                      }}
                      htmlFor="post_job"
                      className="btn btn-outline btn-primary">
                      Post a job
                    </label>
                  </li>
                  {clientDashboardNavMenu &&
                    clientDashboardNavMenu.map((list) => (
                      <li
                        key={list?.id}
                        onClick={() => {
                          if (confirmAlertValue) {
                            setDashboardNaveMenuActive(list?.id);
                          }
                        }}>
                        <Link
                          to={confirmAlertValue ? list?.linkPath : "#"}
                          className={
                            dashboardNaveMenuActive === list?.id
                              ? "bg-[#FDA4AF]"
                              : ""
                          }>
                          {list?.linkIcon} <span>{list?.linkText}</span>
                        </Link>
                      </li>
                    ))}
                </>
              )}
              {(currentUserAccountTypeFreelancer ||
                currentUserAccountTypeClient) && (
                <>
                  <li
                    onClick={() => {
                      if (confirmAlertValue) {
                        setDashboardNaveMenuActive(98);
                      }
                    }}>
                    <Link
                      to={confirmAlertValue ? "/dashboard/message" : "#"}
                      className={
                        dashboardNaveMenuActive === 98 ? "bg-[#FDA4AF]" : ""
                      }>
                      <AiOutlineMessage className="text-xl" />
                      <span>Message</span>
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      if (confirmAlertValue) {
                        setDashboardNaveMenuActive(99);
                      }
                    }}>
                    <Link
                      to={
                        confirmAlertValue
                          ? "/dashboard/time-sheet-dashboard"
                          : "#"
                      }
                      className={
                        dashboardNaveMenuActive === 99 ? "bg-[#FDA4AF]" : ""
                      }>
                      <MdMoreTime className="text-xl" />
                      <span>Time Sheet</span>
                    </Link>
                  </li>
                </>
              )}
            </ul>

            {/* Job post Modal component  */}
            {post && <PostJob post={post} setPost={setPost} />}
          </div>
        </div>
      </JobPostProvider>
    </>
  );
};

export default GlobalDashboard;
