import React, { useContext, useState } from "react";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { Link, useNavigate } from "react-router-dom";
import { HandelCurrentFreelancerAllSavedJobsDataGetFunctionWithoutLimit } from "../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import { serverUrl } from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { toast } from "react-toastify";
import { FaBriefcase, FaMapMarkerAlt } from "react-icons/fa";
import { MdWorkHistory } from "react-icons/md";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import WindowsLoadingSpinner from "../WindowsLoadingSpinner/WindowsLoadingSpinner";
import { SingleUserProfileGet } from "../../../hooks/useUser";

const JobCard = ({
  jobs,
  data,
  refetch,
  freelancerAllSavedJobDataGetRefetch,
  freelancerJobSavedJobDataCountRefetch,
}) => {
  const { currentUser, Loading, userCurrentLocationPath, sendNotification } =
    useContext(globalFunctionOneContext);
  const [currentClickJobId, setCurrentClickJobId] = useState("");
  const [currentBookmarkJobId, setCurrentBookmarkJobId] = useState("");
  const [currentBookmarkRemoveJobId, setCurrentBookmarkRemoveJobId] =
    useState("");
  const [applyData, setApplyData] = useState({
    heHasSeen: "no",
    id: 0,
    invited: "0",
    invitedPersonType: currentUser?.accountType,
    jobId: "0",
    shortlisted: "no",
    viewOffer: "",
    freelancerPersonId: "",
  });
  const navigate = useNavigate();

  // get single freelancer user profile
  const {
    data: userProfile,
    isLoading,
    error,
  } = SingleUserProfileGet(currentUser?.PersonID);

  // handel freelancer All job bookMark function
  const {
    data: CurrentFreelancerAllSavedJobsData,
    isLoading: FreelancerAllSavedJobsDataGetLoading,
    refetch: FreelancerAllSavedJobDataGetRefetch,
  } = HandelCurrentFreelancerAllSavedJobsDataGetFunctionWithoutLimit(
    currentUser?.PersonID
  );

  // job apply function
  const handelJobApply = async (job) => {
    if (currentUser && job) {
      if (job?.job_id) {
        setCurrentClickJobId(job?.jobId ? job?.jobId : job?.id);
        const applyData = {
          invited: job?.PersonID,
          invitedPersonType: currentUser?.accountType,
          jobId: job?.jobId ? job?.jobId : job?.job_id,
          shortlisted: "no",
          viewOffer: "",
          heHasSeen: "no",
          freelancerPersonId: currentUser?.PersonID,
        };

        if (
          applyData.invited &&
          applyData.invitedPersonType &&
          applyData.shortlisted
        ) {
          const inviteJob = {
            inviteFreelancerData: applyData,
          };

          const res = await fetch(`${serverUrl}/client/invited/Job`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              uid: currentUser?.PersonID,
            },
            body: JSON.stringify(inviteJob),
          });
          const data = await res.json();
          if (data?.status === "success") {
            setCurrentClickJobId("");
            refetch();
            toast.success("🦄 Your job apply successful !!!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.error("🦄 Your job apply failed!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
      }
    } else {
      navigate("/login");
    }
  };

  // handel freelancer job bookMark function
  const handelFreelancerJobBookMark = async (jobId) => {
    const bockMarkUserData = {
      jobId,
      freelancerId: currentUser?.PersonID,
    };
    // console.log(jobId, "bockMarkUserData");
    if (bockMarkUserData?.jobId && bockMarkUserData?.freelancerId) {
      setCurrentBookmarkJobId(jobId);
      const res = await fetch(`${serverUrl}/freelancer/bookMark/job`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // uid: PersonID,
        },
        body: JSON.stringify(bockMarkUserData),
      });
      const data = await res.json();
      // console.log(data);
      if (data?.status === "success") {
        FreelancerAllSavedJobDataGetRefetch();
        freelancerJobSavedJobDataCountRefetch();
        freelancerAllSavedJobDataGetRefetch();
        toast.success("your job saved successful !!!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setCurrentBookmarkJobId("");
        toast.error("your job saved fail !!!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setCurrentBookmarkJobId("");
    } else {
      navigate("/login");
    }
  };

  // Freelancer saved job unSaved function
  const handelUnsavedFreelancerSavedJob = async (id) => {
    if (id) {
      setCurrentBookmarkRemoveJobId(id);
      const res = await fetch(`${serverUrl}/freelancer/bookMark/job/unSaved`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          uid: currentUser?.PersonID,
          id,

          // authorization: `bearer ${localStorage.getItem("access_Token")}`,
        },
      });
      const data = await res.json();
      if (data?.status === "success") {
        FreelancerAllSavedJobDataGetRefetch();
        freelancerJobSavedJobDataCountRefetch();
        freelancerAllSavedJobDataGetRefetch();
        toast.success("your job unSaved successful !!!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setCurrentBookmarkRemoveJobId("");
        toast.error("your job unSaved failed !!!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setCurrentBookmarkRemoveJobId("");
    }
  };
  // console.log(CurrentFreelancerAllSavedJobsData);

  // This is page loading function set
  if (
    (currentUser && !data?.result) ||
    (currentUser && FreelancerAllSavedJobsDataGetLoading)
  ) {
    return (
      <div className="h-[455px]">
        <WindowsLoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      {jobs?.map((job, i) => (
        <div
          key={i}
          className="bg-white py-5 px-10 mb-4 rounded-lg shadow-md border"
        >
          {/* Job title start  */}
          <div className="flex items-center mb-4">
            <FaBriefcase className="mr-2 text-gray-600" />
            <Link
              to={`/freelancer/job/${job?.job_id}`}
              className="text-xl font-medium link link-secondary"
            >
              {job?.job_title}
            </Link>
          </div>
          {/* Job information details start  */}
          <div className="flex justify-between items-start gap-3">
            <div>
              <p className="font-medium flex justify-start items-center">
                <span>
                  <MdWorkHistory className="mr-2 text-gray-600" />
                </span>
                <span>
                  Experience:
                  <span className="font-bold ml-2">
                    {job?.years_of_experience_preferred} Yrs.
                  </span>
                </span>
              </p>

              <p className="text-base text-gray-600 mt-2">
                <span>Project type:</span>
                <span className="font-bold ml-2">
                  {job?.project_location_type}
                </span>
              </p>
              <p className="text-base text-gray-600 mt-2">
                <span>Job categories:</span>
                <span className="font-bold ml-2">{job?.job_categories}</span>
              </p>
              <p className="text-base text-gray-600 mt-2">
                <span>Freelancer type:</span>
                <span className="font-bold ml-2">{job?.freelancer_type}</span>
              </p>
            </div>
            <div>
              <p className="font-medium flex justify-start items-center">
                <span className="font-extrabold mr-1">₹</span>
                <span>Project Budget:</span>
                <span className="ml-2 font-bold">{job?.minimum_price}</span>
              </p>
              <p className="text-base text-gray-600 mt-2">
                <span>Job duration:</span>
                <span className="font-bold ml-2">{job?.job_duration}</span>
              </p>
              <p className="text-base text-gray-600 mt-2">
                <span>Project Level:</span>
                <span className="font-bold ml-2">{job?.project_level}</span>
              </p>
            </div>
            <div>
              <p className="font-medium flex justify-start items-center">
                <FaMapMarkerAlt className="mr-2 text-gray-600" />
                <span>Location:</span>
                <span className="ml-2 font-bold">{job?.JobLocation}</span>
              </p>

              <p className="text-base text-gray-600 mt-2">
                <span className="f mr-2">Language:</span>
                <span className="font-bold">{job?.language}</span>
              </p>
              <p className="text-base text-gray-600 mt-2">
                <span>English Level:</span>
                <span className="font-bold mr-2">{job?.english_level}</span>
              </p>
            </div>
          </div>
          {/* this is Required Skills map  */}
          <div className="mt-5">
            <h2 className="text-md font-medium mb-2">Required Skills</h2>
            {job?.skills_required && (
              <>
                {(() => {
                  if (typeof job?.skills_required === "string") {
                    const requiredSkills = JSON.parse(job?.skills_required);
                    return requiredSkills?.map((skill, i) => (
                      <button
                        key={i}
                        className="btn btn-secondary btn-sm mr-2 btn-outline"
                      >
                        {skill}
                      </button>
                    ));
                  } else {
                    return job?.skills_required?.map((skill, i) => (
                      <button
                        key={i}
                        className="btn btn-secondary btn-sm mr-2 btn-outline"
                      >
                        {skill}
                      </button>
                    ));
                  }
                })()}
              </>
            )}
          </div>
          {/* This is job description start  */}
          <div className="my-5">
            <h2 className="text-md font-medium mb-2">Job Description</h2>
            <p className="text-md">{job?.job_details}</p>
          </div>
          {/* This is job Apply and job saved function button start  */}
          <div className="flex justify-start items-center gap-4">
            {/* This is job apply button  */}
            <div className="mt-4">
              {userCurrentLocationPath === "/dashboard/my/Job/proposal" ? (
                <div className="flex justify-start items-center">
                  <button
                    className="btn btn-outline btn-sm btn-primary  capitalize ml-2"
                    onClick={async () => {
                      const data = await sendNotification({
                        receiverNotificationPersonId: job?.PersonID,
                        senderNotificationPersonId: currentUser?.PersonID,
                        senderProfilePicture: userProfile?.result?.profilePhoto,
                        senderName:
                          currentUser?.firstName + currentUser?.lastName,
                        NotificationContent: ` This ${job?.job_title} i am not accepting your offer.`,
                        NotificationType: "acceptingOffer",
                        heHasSeen: "No",
                      });
                      if (data?.status === "success") {
                        toast.success("I am not accepting your offer.", {
                          position: "top-center",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                      }
                    }}
                  >
                    Not interested
                  </button>
                  <button
                    className="btn btn-sm btn-primary capitalize ml-2"
                    onClick={async () => {
                      const data = await sendNotification({
                        receiverNotificationPersonId: job?.PersonID,
                        senderNotificationPersonId: currentUser?.PersonID,
                        senderProfilePicture: userProfile?.result?.profilePhoto,
                        senderName:
                          currentUser?.firstName + currentUser?.lastName,
                        NotificationContent: `This ${job?.job_title} job i am accepting your offer.`,
                        NotificationType: "acceptingOffer",
                        heHasSeen: "No",
                      });
                      if (data?.status === "success") {
                        toast.success("I am accepting your offer.", {
                          position: "top-center",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                      }
                    }}
                  >
                    Accept
                  </button>
                </div>
              ) : (
                <button
                  className="btn btn-sm btn-primary capitalize"
                  onClick={() => handelJobApply(job)}
                  disabled={
                    data?.result?.some((id) => id?.jobId === job?.job_id) ||
                    job?.job_id === currentClickJobId
                  }
                >
                  Apply Now
                </button>
              )}
            </div>

            {/* This is job saved button */}
            <div className="mt-3 cursor-pointer text-xl">
              {CurrentFreelancerAllSavedJobsData?.result && (
                <>
                  {(() => {
                    const freelancerJob =
                      CurrentFreelancerAllSavedJobsData.result.find(
                        (savJob) => savJob?.jobId === job?.job_id
                      );

                    return (
                      <div>
                        <button
                          data-tooltip={freelancerJob ? "Unsaved" : "Saved Job"}
                          onClick={() =>
                            freelancerJob
                              ? handelUnsavedFreelancerSavedJob(job?.job_id)
                              : handelFreelancerJobBookMark(job?.job_id)
                          }
                        >
                          {freelancerJob ||
                          currentBookmarkJobId === job?.job_id ||
                          currentBookmarkRemoveJobId === job?.job_id ? (
                            <BsBookmarkFill />
                          ) : (
                            <BsBookmark />
                          )}
                        </button>
                      </div>
                    );
                  })()}
                </>
              )}
              {/* This is gest mode button  */}
              {!currentUser && (
                <div>
                  <button
                    data-tooltip="Saved Job"
                    onClick={() => navigate("/login")}
                  >
                    <BsBookmark />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default JobCard;
