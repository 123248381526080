import React, { useContext, useEffect, useMemo, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";
import countryList from "react-select-country-list";
import { StepperContext } from "../../../../../context/StepContext";

const Details = ({ err, setErr }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [agree, setAgree] = useState(true);
  const { userData, setUserData } = useContext(StepperContext);
  const [recaptchaValue, setRecaptchaValue] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    console.log(userData);
  };
  const changeHandler = (value) => {
    setValue(value);
    setUserData({ ...userData, country: value.label });
  };
  const handleAgree = () => {
    setAgree(!agree);
    if (agree) {
      setUserData({ ...userData, rules: "agree" });
    } else {
      setUserData({ ...userData, rules: "" });
    }
  };

  useEffect(() => {
    if (userData) {
      const { country, password, accountType, rules, retypePassword, captcha } =
        userData;
      if (
        !country ||
        !password ||
        !accountType ||
        !rules ||
        !retypePassword ||
        retypePassword !== password ||
        !captcha
      ) {
        setErr(true);
      } else {
        setErr(false);
      }

      if (retypePassword) {
        if (retypePassword !== password) {
          setPasswordError("password didn't match");
        } else {
          setPasswordError("");
        }
      }
    }
  }, [setErr, userData]);
  // captcha
  const onChange = (value) => {
    if (value) {
      setUserData({ ...userData, captcha: value });
    }
  };
  return (
    <div className="flex flex-col">
      <Select
        onChange={changeHandler}
        name="country"
        options={options}
        type="text"
      />
      <div className="grid grid-cols-1 md:grid-cols-2 justify-between mt-6 gap-4">
        <div className="form-control w-full max-w-xs ">
          <input
            onChange={handleChange}
            value={userData["password"] || ""}
            name="password"
            required
            // type="password"
            type={showPassword ? "password" : "text"}
            placeholder="password "
            className="input input-bordered w-full max-w-xs focus:input-secondary"
          />
          {/* <label className="label">
            <span className="label-text-alt">Alt label</span>
          </label> */}
        </div>
        <div className="form-control w-full max-w-xs">
          <input
            onChange={handleChange}
            value={userData["retypePassword"] || ""}
            name="retypePassword"
            type="password"
            placeholder="Retype Password"
            className={`input input-bordered w-full max-w-xs focus:input-secondary`}
          />

          {passwordError && (
            <label className="label">
              <span className="label-text-alt text-error">{passwordError}</span>
            </label>
          )}
        </div>
      </div>
      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-4">Start as:</h2>
        <div>
          <div className="form-control input input-bordered justify-start my-3">
            <label className="label cursor-pointer justify-start gap-4">
              <input
                onClick={handleChange}
                value={userData["accountType"] || "Freelancer"}
                name="accountType"
                type="radio"
                className="radio radio-sm  checked:bg-blue-500"
              />
              <span className="label-text text-md font-semibold">
                Freelancer
                <span className="text-sm text-gray-300">
                  (Signup as Freelancer & get hired)
                </span>
              </span>
            </label>
          </div>
          <div className="form-control  input input-bordered overflow-hidden  my-3">
            <label className="label justify-start gap-4 cursor-pointer">
              <input
                onClick={handleChange}
                value={userData["accountType"] || "client"}
                name="accountType"
                type="radio"
                className="radio radio-sm checked:bg-blue-500"
              />
              <span className="label-text text-md font-semibold">
                Client
                <span className="text-sm text-gray-300">
                  ( Signup as a Client)
                </span>
              </span>
            </label>
          </div>
          <div className="form-control  input input-bordered overflow-hidden  my-3">
            <label className="label justify-start gap-4  cursor-pointer">
              <input
                onClick={handleChange}
                value={userData["accountType"] || "talent_manager"}
                name="accountType"
                type="radio"
                className="radio radio-sm checked:bg-blue-500"
              />
              <span className="label-text text-md font-semibold">
                Talent Manager
                <span className="text-sm text-gray-300">
                  ( Signup as a company Talent Manger)
                </span>
              </span>
            </label>
          </div>
        </div>
        <div>
          <div className="form-control">
            <div className="flex gap-3 items-center my-7">
              <input
                onClick={handleAgree}
                name="rules"
                type="checkbox"
                className="checkbox checkbox-sm checkbox-accent"
              />

              <p className="label-text text-xs ">
                Yes, I understand and agree to the
                <b className="text-[#6366F1]"> Hire Terms of Services</b>,
                including the <br />
                <b className="text-[#6366F1]"> User Agreement </b>
                and <b className="text-[#6366F1]">Privacy Policy</b>
                .You will receive marketing messages from{" "}
                <b className="text-[#6366F1]"> Hire Elite</b> and may opt out at
                any time by following the{" "}
                <b className="text-[#6366F1]">unsubscribe </b> link in our
                messages, or as detailed in our
                <b className="link text-accent"> Terms & Conditions.</b>
              </p>
            </div>
          </div>
          <div>
            <ReCAPTCHA
              sitekey="6LfL2u0kAAAAAKmLq_8F2ns2CxsYa9TM4jNn2vN8"
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
