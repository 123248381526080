import { useMemo } from "react";
import countryList from "react-select-country-list";
import Select from "react-select";
import { PhoneInput } from "react-international-phone";
import { useState } from "react";
import "react-international-phone/style.css";

const PaymentAccountInfo = ({
  selectedOption,
  handleCountryChange,
  phoneError,
  previewFormSubmitted,
}) => {
  const options = useMemo(() => countryList().getData(), []);
  const [phone, setPhone] = useState("");

  return (
    <div>
      <div className="form-control w-full ">
        <div className="mt-3">
          {" "}
          <h3 className="semibold text-lg">Account number</h3>
          <label className="label">
            <span className="label-text">
              Your account number may includes and numbers
            </span>
          </label>
          <input
            name="accountNumber"
            required
            type="text"
            placeholder="Enter your account number"
            className="input input-bordered w-full"
          />
        </div>

        <div className="mt-3">
          {" "}
          <h3 className="semibold text-lg">Account Type</h3>
          <label className="label">
            <span className="label-text">Type of account</span>
          </label>
          <select
            name="bankAccountType"
            className="select select-bordered w-full max-w-xs">
            <option disabled selected>
              Select option
            </option>
            <option>Savings</option>
            <option>lorem</option>
          </select>
        </div>

        <div className="mt-3">
          {" "}
          <h3 className="semibold text-lg">PAN Number</h3>
          <label className="label">
            <span className="label-text">
              PAN number (Permanent Account Number ), 10 characters in lenght
            </span>
          </label>
          <input
            type="number"
            name="panNumber"
            maxLength={10}
            required
            placeholder="Enter your Pan card number"
            className="input input-bordered w-full"
          />
        </div>

        <div className="mt-3">
          {" "}
          <h3 className="semibold text-lg">First Name</h3>
          <label className="label">
            <span className="label-text">
              First name 2 to 26 characters in length
            </span>
          </label>
          <input
            name="firstName"
            required
            type="text"
            minLength={2}
            maxLength={26}
            placeholder="First name"
            className="input input-bordered w-full"
          />
        </div>

        <div className="mt-3">
          {" "}
          <h3 className="semibold text-lg">Last Name</h3>
          <label className="label">
            <span className="label-text">
              Last name 2 to 26 characters in length
            </span>
          </label>
          <input
            name="lastName"
            minLength={2}
            maxLength={26}
            required
            type="text"
            placeholder="Last name"
            className="input input-bordered w-full"
          />
        </div>

        <div className="mt-3">
          {" "}
          <h3 className="semibold text-lg">Name on Account</h3>
          <input
            name="accountName"
            required
            type="text"
            placeholder="Enter your account name"
            className="input input-bordered w-full"
          />
        </div>

        <div className="mt-3">
          {" "}
          <h3 className="semibold text-lg">Address</h3>
          <label className="label">
            <span className="label-text">
              Please Enter the physical address associated with this account if
              the postal address where you receive mail is different then the
              physical address
            </span>
          </label>
          <input
            name="address"
            required
            type="text"
            placeholder="Enter your address"
            className="input input-bordered w-full"
          />
        </div>

        <div className="mt-3">
          {" "}
          <h3 className="semibold text-lg mb-3">City and State/Province</h3>
          <Select
            onChange={handleCountryChange}
            required
            value={selectedOption}
            name="country"
            options={options}
            type="text"
          />
        </div>

        <div className="mt-3">
          {" "}
          <h3 className="semibold text-lg mb-3">Phone Number</h3>
          <PhoneInput
            defaultCountry="in"
            value={phone}
            inputProps={{
              name: "phone",
              required: true,
              className:
                "w-full p-1 border rounded focus:outline-none focus:ring focus:border-blue-500",
            }}
            onChange={(phone) => setPhone(phone)}
          />
          {phoneError && !previewFormSubmitted && (
            <div style={{ color: "red" }}>{phoneError}</div>
          )}
          <div className="mt-5">
            <label className="label cursor-pointer">
              <input required type="checkbox" className="checkbox" />
              <span className="label-text">
                I attest that I am the owner and have full authorization to this
                bank account
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentAccountInfo;
