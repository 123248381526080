import React, { createContext, useState } from "react";

// Create Context
export const TimePeriodContext = createContext();

// Create Provider
export const TimePeriodProvider = ({ children }) => {
  const [activeItem, setActiveItem] = useState("day");

  const handleClick = (item) => {
    setActiveItem(item);
  };

  return (
    <TimePeriodContext.Provider value={{ activeItem, handleClick }}>
      {children}
    </TimePeriodContext.Provider>
  );
};
