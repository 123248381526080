import React, { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { AiOutlinePlus } from "react-icons/ai";
import { FaMoneyCheck, FaRegShareSquare } from "react-icons/fa";
import { GrPersonalComputer } from "react-icons/gr";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { IoBagCheckSharp } from "react-icons/io5";
import { MdOutlineCastForEducation } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Loading from "../../../Loading/Loading.js";
import { auth } from "../../../../config/firebaseConfig/firebaseConfig.js";
import { globalFunctionOneContext } from "../../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne.js";
import {
  serverImageUrl,
  serverUrl,
} from "../../../../hooks/AllServerUrlControl/useAllServerUrlControl.js";
import { SingleUserProfileGet } from "../../../../hooks/useUser.js";
import { Buffer } from "buffer";

const MyProfile = () => {
  // get data global context
  const {
    currentUser,
    windowLoading,
    currentUserAccountTypeFreelancer,
    currentUserAccountTypeClient,
    currentUserAccountTypeTalentManager,
  } = useContext(globalFunctionOneContext);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  const [profilePic, setProfilePic] = useState(false);

  // get single freelancer user profile
  const { data, isLoading, error, refetch } = SingleUserProfileGet(
    currentUser?.PersonID
  );

  if (
    !currentUser ||
    Object.keys(currentUser)?.length === 0 ||
    windowLoading ||
    isLoading
  ) {
    return <Loading />;
  }
  // console.log(data);
  //  const parse = data?.interestedRole;
  const skill = data?.result?.top_skill
    ? JSON.parse(data?.result?.top_skill)
    : false;
  const interestRole = data?.result?.interestedRole
    ? JSON.parse(data?.result?.interestedRole)
    : false;
  let skillKey = [];
  let skillValue = [];
  let intRoleValue = [];
  if (skill) {
    for (const [key, value] of Object.entries(skill)) {
      skillKey.push(key);
      skillValue.push(value);
    }
  }
  if (interestRole) {
    for (const [key, value] of Object.entries(interestRole)) {
      intRoleValue.push(value);
    }
  }
  if (data) {
    console.log(data.result, "single user profile");
  }

  // user profile cheange function
  const handelProfilePic = async (e) => {
    e.preventDefault();
    const profilePic = e.target.profilePic.files[0];
    console.log(profilePic);

    const formData = new FormData();
    formData.append("profilePic", profilePic);

    const res = await fetch(`${serverUrl}/userProfile/client`, {
      method: "POST",
      headers: {
        Accept: "multipart/form-data",
        uid: currentUser?.PersonID,
      },
      body: formData,
    });

    const data = await res.json();

    if (data?.status === "success") {
      setProfilePic(false);
      refetch();
    }
  };

  return (
    <div className="container mx-auto flex justify-center items-center min-h-[91vh]">
      <div className="user_profile_wraper relative max-w-lg bg-base-100 shadow-xl rounded-md pt-5">
        <div className="card-body">
          {currentUserAccountTypeFreelancer && (
            <div>
              <h2 className="card-title">
                {currentUser?.firstName + " " + currentUser?.lastName}
              </h2>
              <div className="flex justify-between gap-4">
                <div>
                  <div className="text-slate-500 flex gap-4 items-center">
                    <h2 className="flex gap-1 items-center">
                      <IoBagCheckSharp className="font-bold text-xl" />
                      <span>{data?.result?.full_time_job_experience}</span>
                    </h2>
                    <h2 className="flex gap-1 items-center mt-2">
                      <FaMoneyCheck className="font-bold text-xl" />
                      <span>
                        Salary Expectation: {data?.result?.currentCtc}
                      </span>
                    </h2>
                  </div>
                  <div className="flex justify-between ">
                    <ul className="flex flex-col justify-between gap-2 w-full mt-5">
                      <li className="flex justify-start items-start">
                        <span className="font-bold mr-1 whitespace-nowrap">
                          Email:
                        </span>
                        <span>{currentUser?.email}</span>
                      </li>
                      <li className="flex justify-start items-start">
                        <span className="font-bold mr-1 whitespace-nowrap">
                          Notice period:
                        </span>
                        <span>{data?.result?.longPeriod}</span>
                      </li>
                      <li className="flex justify-start items-start">
                        <span className="font-bold mr-1 whitespace-nowrap">
                          Current Location:
                        </span>
                        <span>{data?.result?.currentLocation}</span>
                      </li>
                      <li className="flex justify-start items-start">
                        <span className="font-bold mr-1 whitespace-nowrap">
                          Pref. Location:
                        </span>
                        <span>banglore noideya delhi</span>
                      </li>
                      <li className="flex justify-start items-start">
                        <span className="font-bold mr-1 whitespace-nowrap">
                          Team Mgmt:
                        </span>
                        <span>{data?.result?.report_people}</span>
                      </li>
                      <li className="flex justify-start items-start">
                        <span className="font-bold mr-1 whitespace-nowrap">
                          Current Ctc:{" "}
                        </span>
                        <span> ${data?.result?.currentCtc}</span>
                      </li>
                      <li className="flex justify-start items-start">
                        <span className="font-bold mr-1 whitespace-nowrap">
                          Interested roles:
                        </span>
                        <span>
                          {intRoleValue?.map((r, i) => (
                            <span key={i}>{r}</span>
                          ))}
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="flex justify-start items-start my-2">
                    <div className="text-slate-500 flex gap-2 items-center">
                      <HiOutlineOfficeBuilding />
                      <p className="font-bold mr-1 whitespace-nowrap">Jobs: </p>
                    </div>
                    <p className="ml-2">{data?.result?.profile_title}</p>
                  </div>

                  <div className="content-end">
                    <div className="text-slate-500 flex  gap-2 items-center my-2">
                      <MdOutlineCastForEducation />
                      <p className="font-bold mr-1 whitespace-nowrap">
                        Education--
                      </p>
                      <p>{data?.result?.addEducation}</p>
                    </div>

                    <div className="col-span-2">
                      <div className="text-slate-500 flex gap-2 items-center border-b-2  border-dashed">
                        <GrPersonalComputer />
                        <span>Tech Skill</span>
                      </div>
                      <div className=" flex justify-between  font-semibold text-slate-700 items-center cursor-pointer">
                        <div>
                          {skillKey.map((skill, i) => (
                            <p key={i}>{skill}</p>
                          ))}
                        </div>

                        <div className="mt-2">
                          {skillValue?.map((skill, i) => (
                            <p key={i}>{skill}</p>
                          ))}
                        </div>
                      </div>
                      <div className="divider"></div>
                    </div>
                  </div>
                </div>
                <div>
                  {data?.result?.profilePhoto ? (
                    <div className="avatar online placeholder">
                      <div className="w-24 rounded-full">
                        <img
                          src={
                            data?.result?.profilePhoto &&
                            ` ${serverImageUrl}/${data?.result?.profilePhoto}`
                          }
                          alt="profilePhoto"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="avatar online placeholder">
                      <div className="bg-neutral-focus text-neutral-content rounded-full w-16">
                        <span className="text-xl">
                          {currentUser?.firstName?.slice(0, 2).toUpperCase()}
                        </span>
                      </div>
                    </div>
                  )}

                  <p className="flex gap-4 text-slate-500 cursor-pointer">
                    <span>RESUME </span>
                    <FaRegShareSquare />
                  </p>
                </div>
              </div>
            </div>
          )}
          {currentUserAccountTypeClient && (
            <div className="min-h-[400px]">
              <h2 className="card-title">
                {currentUser?.firstName + " " + currentUser?.lastName}
              </h2>
              <div className="flex justify-between gap-4">
                {/* profile Field data text */}
                <div>
                  <div className="flex justify-between ">
                    <ul className="flex flex-col justify-between gap-2 w-full mt-4">
                      {currentUser?.email && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            User Name:
                          </span>
                          <span>{currentUser?.username}</span>
                        </li>
                      )}
                      {currentUser?.email && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            Email:
                          </span>
                          <span>{currentUser?.email}</span>
                        </li>
                      )}
                      {currentUser?.phonNumber && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            PhonNumber:
                          </span>
                          <span>{currentUser?.phonNumber}</span>
                        </li>
                      )}
                      {data?.result?.address && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            Address:
                          </span>
                          <span>{data?.result?.address}</span>
                        </li>
                      )}
                      {data?.result?.country && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            Current Location:
                          </span>
                          <span>{data?.result?.country}</span>
                        </li>
                      )}
                      {data?.result?.dateOfBirth && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            DatOf Birth:
                          </span>
                          <span>{data?.result?.dateOfBirth}</span>
                        </li>
                      )}

                      {data?.result?.companyName && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            Company Name:
                          </span>
                          <span>{data?.result?.companyName}</span>
                        </li>
                      )}
                      {data?.result?.companyEmail && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            Company Email:
                          </span>
                          <span>{data?.result?.companyEmail}</span>
                        </li>
                      )}
                      {data?.result?.addressRegisteredOffice && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            Address Registered Office:
                          </span>
                          <span> {data?.result?.addressRegisteredOffice}</span>
                        </li>
                      )}
                      {data?.result?.contact && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            Contact:
                          </span>
                          <span> {data?.result?.contact}</span>
                        </li>
                      )}
                      {data?.result?.companyDetailsRadioValue && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            Company Details:
                          </span>
                          <span>{data?.result?.companyDetailsRadioValue}</span>
                        </li>
                      )}
                      {data?.result?.numberOfEmployeesValue && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            No. Of Employees:
                          </span>
                          <span>{data?.result?.numberOfEmployeesValue}</span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                {/* Profile photo */}
                <form onSubmit={handelProfilePic}>
                  <label
                    htmlFor="profilePic"
                    onClick={() => setProfilePic(true)}
                  >
                    <div className="cursor-pointer">
                      {data?.result?.profilePhoto ? (
                        <div className="avatar online placeholder">
                          <div className="w-24 rounded-full">
                            {data?.result?.profilePhoto && (
                              <>
                                {data.result.profilePhoto && (
                                  // Assuming profilePhoto is a binary data object
                                  <img
                                    src={`data:${
                                      data.result.fileType
                                    };base64,${Buffer.from(
                                      data.result.profilePhoto
                                    ).toString("base64")}`}
                                    alt="profilePhoto"
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="avatar online placeholder">
                          <div className="bg-neutral-focus text-neutral-content rounded-full w-16">
                            <span className="text-xl">
                              {currentUser?.firstName
                                ?.slice(0, 2)
                                .toUpperCase()}
                            </span>
                          </div>
                        </div>
                      )}

                      <p className="flex gap-4 text-slate-500 cursor-pointer">
                        <span>RESUME </span>
                        <FaRegShareSquare />
                      </p>

                      {/* Assuming profilePic is a state variable */}
                      {profilePic && (
                        <button
                          type="submit"
                          className="btn btn-outline btn-primary btn-sm mt-2 px-5"
                        >
                          Submit
                        </button>
                      )}
                    </div>

                    {/* <div className="cursor-pointer">
                      {data?.result?.profilePhoto ? (
                        <div className="avatar online placeholder">
                          <div className="w-24 rounded-full">
                            {
                              data?.result?.profilePhoto && <>
                              const base64String = btoa(
              new Uint8Array(profilePhoto.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );

            const imageUrl = `data:${row.fileType};base64,${base64String}`;
                              </>
                            }
                            <img
                              src={
                                data?.result?.profilePhoto &&
                                ` ${serverImageUrl}/${data?.result?.profilePhoto}`
                              }
                              alt="profilePhoto"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="avatar online placeholder">
                          <div className="bg-neutral-focus text-neutral-content rounded-full w-16">
                            <span className="text-xl">
                              {currentUser?.firstName
                                ?.slice(0, 2)
                                .toUpperCase()}
                            </span>
                          </div>
                        </div>
                      )}

                      <p className="flex gap-4 text-slate-500 cursor-pointer">
                        <span>RESUME </span>
                        <FaRegShareSquare />
                      </p>
                      {profilePic && (
                        <button
                          type="submit"
                          className="btn btn-outline btn-primary btn-sm mt-2 px-5"
                        >
                          Submit
                        </button>
                      )}
                    </div> */}
                  </label>
                  <input
                    type="file"
                    name="profilePic"
                    id="profilePic"
                    className="opacity-0"
                  />
                </form>
              </div>
            </div>
          )}
          {currentUserAccountTypeTalentManager && (
            <div className="min-h-[400px]">
              <h2 className="card-title">
                {currentUser?.firstName + " " + currentUser?.lastName}
              </h2>
              <div className="flex justify-between gap-4">
                {/* profile Field data text */}
                <div>
                  <div className="flex justify-between ">
                    <ul className="flex flex-col justify-between gap-2 w-full mt-4">
                      {currentUser?.email && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            User Name:
                          </span>
                          <span>{currentUser?.username}</span>
                        </li>
                      )}
                      {currentUser?.email && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            Email:
                          </span>
                          <span>{currentUser?.email}</span>
                        </li>
                      )}
                      {currentUser?.phonNumber && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            PhonNumber:
                          </span>
                          <span>{currentUser?.phonNumber}</span>
                        </li>
                      )}
                      {currentUser?.address && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            Address:
                          </span>
                          <span>{currentUser?.address}</span>
                        </li>
                      )}
                      {currentUser?.country && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            Current Location:
                          </span>
                          <span>{currentUser?.country}</span>
                        </li>
                      )}
                      {data?.result?.stateName && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            Company Details:
                          </span>
                          <span>{data?.result?.stateName}</span>
                        </li>
                      )}
                      {data?.result?.cityName && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            No. Of Employees:
                          </span>
                          <span>{data?.result?.cityName}</span>
                        </li>
                      )}
                      {data?.result?.dateOfBirth && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            DatOf Birth:
                          </span>
                          <span>{data?.result?.dateOfBirth}</span>
                        </li>
                      )}

                      {data?.result?.companyName && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            Company Name:
                          </span>
                          <span>{data?.result?.companyName}</span>
                        </li>
                      )}
                      {data?.result?.companyEmail && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            Company Email:
                          </span>
                          <span>{data?.result?.companyEmail}</span>
                        </li>
                      )}
                      {data?.result?.empId && (
                        <li className="flex justify-start items-start">
                          <span className="font-bold mr-1 whitespace-nowrap">
                            Company Email:
                          </span>
                          <span>{data?.result?.empId}</span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                {/* Profile photo */}
                <div>
                  {data?.result?.profilePhoto ? (
                    <div className="avatar online placeholder">
                      <div className="w-24 rounded-full">
                        <img
                          src={
                            data?.result?.profilePhoto &&
                            ` ${serverImageUrl}/${data?.result?.profilePhoto}`
                          }
                          alt="profilePhoto"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="avatar online placeholder">
                      <div className="bg-neutral-focus text-neutral-content rounded-full w-16">
                        <span className="text-xl">
                          {currentUser?.firstName?.slice(0, 2).toUpperCase()}
                        </span>
                      </div>
                    </div>
                  )}

                  <p className="flex gap-4 text-slate-500 cursor-pointer">
                    <span>RESUME </span>
                    <FaRegShareSquare />
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-center my-4">
          <button
            className="btn btn-warning btn-sm"
            onClick={() => {
              navigate(-1);
            }}
          >
            back
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
