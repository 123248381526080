import { useContext } from "react";
import { globalFunctionOneContext } from "../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { useQuery } from "react-query";
import {
  chatApplicationUrl,
  serverUrl,
} from "../AllServerUrlControl/useAllServerUrlControl";

export const AllJobInvitedData = (url, uniqueKey) => {
  const { currentUser, Loading } = useContext(globalFunctionOneContext);

  // `${serverUrl}/client/all/invited/Jobs?invited=${invited}&invitedPersonType=${invitedPersonType}&jobId=${jobId}&shortlisted=${shortlisted}&viewOffer=${viewOffer}&freelancerPersonId=${freelancerPersonId}`

  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: [uniqueKey, currentUser?.PersonID, url],
    queryFn: async () => {
      if (currentUser?.PersonID) {
        const res = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            uid: currentUser?.PersonID,
            // authorization: `bearer ${localStorage.getItem("access_Token")}`,
          },
        });
        const data = await res.json();
        return data;
      }
    },
  });

  return { data: data || [], refetch, isLoading, error };
};

export const ClientInviteAllFreelancerDataCount = () => {
  const { currentUser } = useContext(globalFunctionOneContext);
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["ClientInviteAllFreelancerDataCount", currentUser],
    queryFn: async () => {
      if (currentUser?.PersonID) {
        const res = await fetch(
          `${serverUrl}/client/invite/all/freelancer/data/count`,
          {
            headers: {
              "Content-Type": "application/json",
              uid: currentUser?.PersonID,
              // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            },
          }
        );
        const data = await res.json();
        return data;
      }
    },
  });

  return { data, refetch, isLoading, error };
};

export const ClientInviteAllFreelancer = (id, type) => {
  const { currentUser, Loading } = useContext(globalFunctionOneContext);
  const {
    data = [],
    isLoading,
    refetch,
    error,
  } = useQuery(["ClientInviteAllFreelancer", currentUser], async () => {
    if (currentUser?.PersonID) {
      const res = await fetch(
        `${serverUrl}/client/invite/all/freelancer?invited=${currentUser?.PersonID}&invitedPersonType=${currentUser?.accountType}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            uid: currentUser?.PersonID,
            // authorization: `bearer ${localStorage.getItem("access_Token")}`,
          },
        }
      );
      const data = await res.json();
      return data;
    }
  });
  return { data, refetch, isLoading, error };
};

// All freelancer Proposals data
export const AllProposalsData = (
  invited,
  invitedPersonType,
  shortlisted,
  freelancerPersonId,
  freelancerDecline,
  limit
) => {
  const {
    data = [],
    isLoading,
    refetch,
    error,
  } = useQuery(
    [
      invited,
      invitedPersonType,
      shortlisted,
      freelancerPersonId,
      freelancerDecline,
      limit,
    ],
    () =>
      fetch(
        `${serverUrl}/client/all/proposals?invited=${invited}&invitedPersonType=${invitedPersonType}&shortlisted=${shortlisted}&freelancerPersonId=${freelancerPersonId}&freelancerDecline=${freelancerDecline}&limit=${limit}`,
        {
          method: "GET",
        }
      ).then((res) => {
        return res.json();
      })
  );
  return { data, refetch, isLoading, error };
};

// all freelancer proposal data getting
export const FreelancerAllProposal = (id, limit) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery(["FreelancerAllProposals", id, limit], async () => {
    if (id) {
      const res = await fetch(
        `${serverUrl}/client/all/freelancer/job/proposals`,
        {
          headers: {
            "Content-Type": "application/json",
            uid: id,
            limit,
            // authorization: `bearer ${localStorage.getItem("access_Token")}`,
          },
        }
      );
      const data = await res.json();
      return data;
    }
  });
  return { data, refetch, isLoading, error };
};

// all save freelancer Id data
export const AllJobProposalsData = (id, limit) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery(["AllJobProposalClient", id, limit], async () => {
    if (id) {
      const res = await fetch(`${serverUrl}/client/all/job/proposals`, {
        headers: {
          "Content-Type": "application/json",
          uid: id,
          limit,
          // authorization: `bearer ${localStorage.getItem("access_Token")}`,
        },
      });
      const data = await res.json();
      return data;
    }
  });
  return { data, refetch, isLoading, error };
};

// all save freelancer Id data
export const AllSaveFreelancerId = (id, limit) => {
  const {
    data = [],
    isLoading,
    refetch,
    error,
  } = useQuery(["AllSaveFreelancerId", id, limit], async () => {
    if (id) {
      const res = await fetch(`${serverUrl}/client/all/save/freelancer/id`, {
        headers: {
          "Content-Type": "application/json",
          uid: id,
          limit,
          // authorization: `bearer ${localStorage.getItem("access_Token")}`,
        },
      });
      const data = await res.json();
      return data;
    }
  });
  return { data, refetch, isLoading, error };
};

// all save freelancer data
export const AllSaveFreelancer = (id, limit) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["AllSaveFreelancer", id, limit],
    queryFn: async () => {
      if (id) {
        const res = await fetch(`${serverUrl}/client/all/save/freelancer`, {
          headers: {
            "Content-Type": "application/json",
            uid: id,
            limit,
            // authorization: `bearer ${localStorage.getItem("access_Token")}`,
          },
        });
        const data = await res.json();
        return data;
      }
    },
  });

  return { data, refetch, isLoading, error };
};
// all save freelancer data count
export const AllSaveFreelancerDataCount = (uid) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["AllSaveFreelancer", uid],
    queryFn: async () => {
      if (uid) {
        const res = await fetch(
          `${serverUrl}/client/all/save/freelancer/data/count`,
          {
            headers: {
              "Content-Type": "application/json",
              uid,

              // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            },
          }
        );
        const data = await res.json();
        return data;
      }
    },
  });

  return { data, refetch, isLoading, error };
};

// Current Client Hire Freelancer Data data
export const CurrentClientHireFreelancerData = (jobId, freelancerId, limit) => {
  const {
    data = [],
    isLoading,
    refetch,
    error,
  } = useQuery(["CurrentClientHireFreelancerData", jobId, limit], () => {
    if ((jobId, freelancerId)) {
      fetch(
        `${serverUrl}/client/current/client/hire/freelancer?jobId=${jobId}&freelancerId=${freelancerId}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            uid: jobId,
          },
        }
      ).then((res) => {
        return res.json();
      });
    }
  });
  return { data: data, refetch, isLoading, error };
};
// Current Client Hire Freelancer Data data
export const CurrentClientHireAllFreelancerData = (uid, limit) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["CurrentClientHireAllFreelancerData", uid, limit],
    queryFn: async () => {
      if (uid) {
        const res = await fetch(
          `${serverUrl}/client/current/client/hire/all/freelancer?limit=${limit}`,
          {
            headers: {
              "Content-Type": "application/json",
              uid,
              // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            },
          }
        );
        const data = await res.json();
        return data;
      }
    },
  });

  return { data, refetch, isLoading, error };
};

// Current Client Hire Freelancer Data data
export const CurrentClientHireAllData = (uid) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery(["CurrentClientHireAllData", uid], async () => {
    if (uid) {
      const res = await fetch(
        `${serverUrl}/client/current/client/hire/all/freelancers`,
        {
          headers: {
            "Content-Type": "application/json",
            uid,
            // authorization: `bearer ${localStorage.getItem("access_Token")}`,
          },
        }
      );
      const data = await res.json();
      return data;
    }
  });
  return { data, refetch, isLoading, error };
};

// All Decline Freelancer Data
export const AllDeclineFreelancerData = (PersonID, limit) => {
  const {
    data = [],
    isLoading,
    refetch,
    error,
  } = useQuery(["AllDeclineFreelancerData", PersonID], async () => {
    if (PersonID) {
      const res = await fetch(
        `${serverUrl}/client/all/decline/freelancer?limit=${limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            uid: PersonID,
          },
        }
      );
      const data = await res.json();
      return data;
    }
  });
  return { data, refetch, isLoading, error };
};

// This is client proposal all count get database function
export const HandelClientProposalAllDataCount = (PersonID) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["clientAllProposalCount", PersonID],
    queryFn: async () => {
      if (PersonID) {
        const res = await fetch(`${serverUrl}/client/all/proposal/count`, {
          headers: {
            "Content-Type": "application/json",
            uid: PersonID,
            // authorization: `bearer ${localStorage.getItem("access_Token")}`,
          },
        });
        const data = await res.json();
        return data;
      }
    },
  });
  return { data, refetch, isLoading, error };
};

// This is client hire all count get database function
export const HandelClientFreelancerHireAllDataCount = (PersonID) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["ClientFreelancerHireAllDataCount", PersonID],
    queryFn: async () => {
      if (PersonID) {
        const res = await fetch(`${serverUrl}/client/freelancer/hire/count`, {
          headers: {
            "Content-Type": "application/json",
            uid: PersonID,
            // authorization: `bearer ${localStorage.getItem("access_Token")}`,
          },
        });
        const data = await res.json();
        return data;
      }
    },
  });
  return { data, refetch, isLoading, error };
};

// This is freelancer save job  all count get database function
export const HandelFreelancerSavedJobAllDataCount = (PersonID) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["FreelancerSavedJobAllDataCount", PersonID],
    queryFn: async () => {
      if (PersonID) {
        const res = await fetch(
          `${serverUrl}/freelancer/saved/all_jobs/count`,
          {
            headers: {
              "Content-Type": "application/json",
              uid: PersonID,
              // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            },
          }
        );
        const data = await res.json();
        return data;
      }
    },
  });
  return { data, refetch, isLoading, error };
};

// This is freelancer save job apply  all count get database function
export const HandelFreelancerAllJobsApplyDataCount = (PersonID) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["FreelancerAllJobsApplyDataCount", PersonID],
    queryFn: async () => {
      if (PersonID) {
        const res = await fetch(
          `${serverUrl}/freelancer/all_jobs/apply/count`,
          {
            headers: {
              "Content-Type": "application/json",
              uid: PersonID,
              // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            },
          }
        );
        const data = await res.json();
        return data;
      }
    },
  });
  return { data, refetch, isLoading, error };
};

// This is freelancer job apply  all get database function
export const HandelCurrentFreelancerGetAllJobsApplyData = (PersonID, limit) => {
  const {
    data = [],
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["CurrentFreelancerAllJobApplyData", PersonID],
    queryFn: async () => {
      if (PersonID) {
        const res = await fetch(
          `${serverUrl}/freelancer/all/apply/jobs?limit=${limit ? limit : 10}`,
          {
            headers: {
              "Content-Type": "application/json",
              uid: PersonID,
              // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            },
          }
        );
        const data = await res.json();
        return data;
      }
    },
  });
  return { data, refetch, isLoading, error };
};
// This is freelancer save job all get database function
export const HandelCurrentFreelancerSavedAllJobsGetApplyData = (
  PersonID,
  limit
) => {
  const {
    data = [],
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["CurrentFreelancerAllSavedJobsGetData", PersonID],
    queryFn: async () => {
      if (PersonID) {
        const res = await fetch(
          `${serverUrl}/freelancer/all/bookMark/jobs?limit=${
            limit ? limit : 10
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              uid: PersonID,
              // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            },
          }
        );
        const data = await res.json();
        return data;
      }
    },
  });
  return { data, refetch, isLoading, error };
};
// This is freelancer save job all get database function Without Limit
export const HandelCurrentFreelancerAllSavedJobsDataGetFunctionWithoutLimit = (
  PersonID
) => {
  const {
    data = [],
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["CurrentFreelancerAllSavedJobsGetDataWithoutLimit", PersonID],
    queryFn: async () => {
      if (PersonID) {
        const res = await fetch(
          `${serverUrl}/freelancer/all/bookMark/jobs/without/limit`,
          {
            headers: {
              "Content-Type": "application/json",
              uid: PersonID,
              // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            },
          }
        );
        const data = await res.json();
        return data;
      }
    },
  });
  return { data, refetch, isLoading, error };
};

// This is current user all job summary milestone get function
export const HandelCurrentClientMilestonesJobList = (PersonID, dataOrder) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["HandelCurrentClientMilestonesJobList", PersonID, dataOrder],
    queryFn: async () => {
      if (PersonID) {
        const res = await fetch(
          `${serverUrl}/client/all/job/milestoneList?dataOrder=${dataOrder}`,
          {
            headers: {
              "Content-Type": "application/json",
              uid: PersonID,
              // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            },
          }
        );
        const data = await res.json();
        return data;
      }
    },
  });
  return { data, refetch, isLoading, error };
};
// This is one job all milestone get function
export const JobAllMilestoneGet = (jobId, dataOrder) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["singleJobAllMilestoneGet", jobId, dataOrder],
    queryFn: async () => {
      if (jobId) {
        const res = await fetch(
          `${serverUrl}/milestone/list?dataOrder=DESC
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              jobid: jobId,
              // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            },
          }
        );
        const data = await res.json();
        return data;
      }
    },
  });
  return { data, refetch, isLoading, error };
};
// This is TheClientInvitedTheFreelancer
export const TheClientInvitedTheFreelancer = (PersonID) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["TheClientInvitedTheFreelancer", PersonID],
    queryFn: async () => {
      if (PersonID) {
        const res = await fetch(
          `${serverUrl}/freelancer/theClientInvitedTheFreelancer`,
          {
            headers: {
              "Content-Type": "application/json",
              uid: PersonID,
              // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            },
          }
        );
        const data = await res.json();
        return data;
      }
    },
  });
  return { data, refetch, isLoading, error };
};
// This is SendCredentialProfileSendDatabase
export const SendCredentialProfileSendDatabase = (user, uid) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["PostCredentialUserProfile", user, uid],
    queryFn: async () => {
      if (user && uid) {
        const res = await fetch(`${serverUrl}/userProfile/credential`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            uid,
            // authorization: `bearer ${localStorage.getItem("access_Token")}`,
          },
          body: JSON.stringify(user),
        });
        const data = await res.json();
        return data;
      }
    },
  });

  return { data, refetch, isLoading, error };
};
// This is GetCredentialProfileSendDatabase
export const GetCredentialProfileSendDatabase = (uid) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["credentialUserProfileGet", uid],
    queryFn: async () => {
      if (uid) {
        const res = await fetch(`${serverUrl}/userProfile/credential`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            uid,
            // authorization: `bearer ${localStorage.getItem("access_Token")}`,
          },
        });
        const data = await res.json();
        return data;
      }
    },
  });

  return { data, refetch, isLoading, error };
};
// This is Single job hire all freelancer get function
export const SingleJobHireAllFreelancerGet = (uid, jobId) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["SingleJobHireAllFreelancer", uid, jobId],
    queryFn: async () => {
      if (uid) {
        const res = await fetch(
          `${serverUrl}/client/single/job/hire/all/freelancer`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              uid,
              jobid: jobId,
              // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            },
          }
        );
        const data = await res.json();
        return data;
      }
    },
  });

  return { data, refetch, isLoading, error };
};
// This is current freelancer all hire job data get function
export const CurrentFreelancerAllHireJobs = (uid, dataOrder) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["CurrentFreelancerAllHireJobsGet", uid],
    queryFn: async () => {
      if (uid) {
        const res = await fetch(
          `${serverUrl}/freelancer/all/hire/jobs?dataOrder=${dataOrder}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              uid,

              // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            },
          }
        );
        const data = await res.json();
        return data;
      }
    },
  });

  return { data, refetch, isLoading, error };
};
// This is current freelancer single milestone details
export const FreelancerContractMilestoneDetails = (id) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["FreelancerContractMilestoneDetails", id],
    queryFn: async () => {
      if (id) {
        const res = await fetch(
          `${serverUrl}/milestone/freelancer/single/milestone/details/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              id,
              // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            },
          }
        );
        const data = await res.json();
        return data;
      }
    },
  });

  return { data, refetch, isLoading, error };
};
// This function work freelancer request single job all milestone getting
export const FreelancerRequestSingleJobMilestoneGet = (personId, jobId) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["FreelancerRequestSingleJobMilestoneGet", personId, jobId],
    queryFn: async () => {
      if (personId && jobId) {
        const res = await fetch(
          `${serverUrl}/milestone/request/get/single/freelancer/${personId}/${jobId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",

              // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            },
          }
        );
        const data = await res.json();
        return data;
      }
    },
  });

  return { data, refetch, isLoading, error };
};
// This is single milestone Work review all data getting function
export const SingleMilestoneReviewWorkData = (id) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["FreelancerRequestSingleJobMilestoneGet", id],
    queryFn: async () => {
      if (id) {
        const res = await fetch(`${serverUrl}/milestone/review/work/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",

            // authorization: `bearer ${localStorage.getItem("access_Token")}`,
          },
        });
        const data = await res.json();
        return data;
      }
    },
  });

  return { data, refetch, isLoading, error };
};
/*
<========================================>
chat app data fetch all function
<========================================>
*/
// current user all message friend list get
export const CurrentUserAllFriendListGet = (uid) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["currentUserAllMessageFriendListGet", uid],
    queryFn: async () => {
      if (uid) {
        const res = await fetch(
          `${chatApplicationUrl}/single/user/chatting/friend/list`,
          {
            method: "GET",
            headers: {
              uid,
              "Content-Type": "application/json",
              // authorization: `bearer ${localStorage.getItem("access_Token")}`,
            },
          }
        );
        const data = await res.json();
        return data;
      }
    },
  });

  return { data, refetch, isLoading, error };
};
// user select message room then all current select room message getting function
// export const CurrentUserSelectMessageRoomAllMessageGetFromDB = (roomId) => {
//   const {
//     data = {},
//     isLoading,
//     refetch,
//     error,
//   } = useQuery({
//     queryKey: ["CurrentUserSelectMessageRoomAllMessageGetFromDB", roomId],
//     queryFn: async () => {
//       if (roomId) {
//         const res = await fetch(
//           `${chatApplicationUrl}/all/conversion/message`,
//           {
//             method: "GET",
//             headers: {
//               roomId,
//               "Content-Type": "application/json",
//               // authorization: `bearer ${localStorage.getItem("access_Token")}`,
//             },
//           }
//         );
//         const data = await res.json();
//         return data;
//       }
//     },
//   });

//   return { data, refetch, isLoading, error };
// };
// this is user typing message send fromDB
export const UserTypingMessageSendFromServer = (userMessage) => {
  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["CurrentUserSelectMessageRoomAllMessageGetFromDB", userMessage],
    queryFn: async () => {
      if (
        userMessage?.messageUserId &&
        userMessage?.messageRoomId &&
        userMessage?.messageContent
      ) {
        const res = await fetch(`${chatApplicationUrl}/send/message`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // authorization: `bearer ${localStorage.getItem("access_Token")}`,
          },
          body: JSON.stringify(userMessage),
        });
        const data = await res.json();
        return data;
      }
    },
  });

  return { data, refetch, isLoading, error };
};
