const CustomTooltip = ({ payload }) => {
  if (payload && payload.length) {
    const convertToHoursAndMinutes = (time) => {
      const hours = Math.floor(time);
      const minutes = Math.round((time - hours) * 60);
      return `${hours}h ${minutes}m`;
    };

    return (
      <div className="custom-tooltip bg-white bg-opacity-25 backdrop-blur-md p-4 rounded-md">
        <p className="label">{`Day : ${payload[0].payload.day}`}</p>
        <p className="intro">{`Work Hours : ${convertToHoursAndMinutes(
          payload[0].payload.workHours
        )}`}</p>
        <p className="intro">{`Leave : ${convertToHoursAndMinutes(
          payload[0].payload.leave
        )}`}</p>
      </div>
    );
  }

  return null;
};
export default CustomTooltip;
