import React, { useContext, useState } from "react";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const JobDescription = ({ register }) => {
  const {
    project_start_date,
    setProject_start_date,
    project_expire_date,
    setProject_expire_date,
    project_deadline_date,
    setProject_deadline_date,
    isValidDate,
    setIsValidDate,
  } = useContext(globalFunctionOneContext);

  const JobDescriptionSelectOption = [
    {
      defaultOption: "Select project level",
      options: ["basic level", "expensive", " medium level"],
      name: "project_level",
    },
    {
      defaultOption: "Select job duration",
      options: [
        "01 to 03 months",
        "03 to 06 months",
        "more than 6 months",
        "Less than a month",
        "Less than a weak",
      ],
      name: "job_duration",
    },
    {
      defaultOption: "select freelancer type",
      options: [
        "Agency freelancer",
        "Independent freelancer",
        "New rising talent",
      ],
      name: "freelancer_type",
    },
    {
      defaultOption: "Select english level",
      options: [
        " Basic",
        " Conversational",
        " Professional",
        " Fluent",
        " Native or Bilingual",
      ],
      name: "english_level",
    },
    {
      defaultOption: "project location type",
      options: ["onsite", " partial onsite", " remote"],
      name: "project_location_type",
    },
    {
      defaultOption: "Years of experience preferred",
      options: [" 1", " 2", " 3", "4.8"],
      name: "years_of_experience_preferred",
    },
  ];

  return (
    <div className="form-control grid grid-cols-2 justify-between gap-4 w-full ">
      <div className="col-span-2">
        <input
          {...register("job_title")}
          type="text"
          placeholder="Job title"
          className="input input-bordered  input-primary w-full"
        />
      </div>

      {JobDescriptionSelectOption.map((a, i) => (
        <div key={i + 1 * 20 + 1 - 4}>
          <select
            key={i}
            {...register(a.name, { required: true })}
            className="select select-primary select-bordered select-md w-full"
          >
            <option value="" selected>
              {a.defaultOption}
            </option>
            {a.options.map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      ))}
      {/* Date input one  */}
      <div>
        <input
          required
          type="text"
          value={project_start_date}
          onFocus={(e) => (e.target.type = "date")}
          onChange={(e) => setProject_start_date(e.target.value)}
          // date compare function
          onBlur={(e) => {
            e.target.type = "text";
            const today = new Date();
            const selectedDate1 = new Date(project_start_date);

            if (today.toDateString() === selectedDate1.toDateString()) {
              setIsValidDate({
                error: false,
                errorMessages: "",
                errorNumber: 0,
              });
              return;
            } else if (today < selectedDate1) {
              setIsValidDate({
                error: false,
                errorMessages: "",
                errorNumber: 0,
              });
              return;
            } else {
              setProject_start_date("");
              setIsValidDate({
                error: true,
                errorMessages: "The project start date cannot be a back date.",
                errorNumber: 1,
              });
            }
          }}
          placeholder="Project start Date"
          className="input input-bordered input-primary w-full"
        />
        {isValidDate.errorNumber === 1 && (
          <div style={{ color: "red" }}>{isValidDate.errorMessages}</div>
        )}
      </div>
      {/* Date input two */}
      <div>
        <input
          required
          type="text"
          onFocus={(e) => (e.target.type = "date")}
          value={project_expire_date}
          onChange={(e) => setProject_expire_date(e.target.value)}
          // date compare function
          onBlur={(e) => {
            e.target.type = "text";
            const selectedDate1 = new Date(project_start_date);
            const selectedDate2 = new Date(project_expire_date);

            if (selectedDate1 >= selectedDate2) {
              setProject_expire_date("");
              setIsValidDate({
                error: true,
                errorMessages:
                  "The project expire date should be after the start date.",
                errorNumber: 2,
              });
            } else {
              setIsValidDate({
                error: false,
                errorMessages: "",
                errorNumber: 0,
              });
            }
          }}
          placeholder="Project Expire Date (optional)"
          className="input input-bordered input-primary w-full"
        />
        {isValidDate.errorNumber === 2 && (
          <div style={{ color: "red" }}>{isValidDate.errorMessages}</div>
        )}
      </div>
      {/* Date input Three  */}
      <div>
        <input
          required
          type="text"
          value={project_deadline_date}
          onChange={(e) => setProject_deadline_date(e.target.value)}
          onFocus={(e) => (e.target.type = "date")}
          // date compare function
          onBlur={(e) => {
            e.target.type = "text";
            const selectedDate1 = new Date(project_start_date);
            const selectedDate2 = new Date(project_expire_date);
            const selectedDate3 = new Date(project_deadline_date);
            if (selectedDate2.toDateString() === selectedDate3.toDateString()) {
              setIsValidDate({
                error: false,
                errorMessages: "",
                errorNumber: 0,
              });
              return;
            } else if (selectedDate2 < selectedDate3) {
              setIsValidDate({
                error: false,
                errorMessages: "",
                errorNumber: 0,
              });
              return;
            } else if (selectedDate1 < selectedDate3) {
              setIsValidDate({
                error: false,
                errorMessages: "",
                errorNumber: 0,
              });
              return;
            } else {
              setProject_deadline_date("");
              setIsValidDate({
                error: true,
                errorMessages:
                  "The project deadline date should be after or till the expiry date.",
                errorNumber: 3,
              });
            }
          }}
          placeholder="Project deadline date (optional) "
          className="input input-bordered input-primary w-full"
        />
        {isValidDate.errorNumber === 3 && (
          <div style={{ color: "red" }}>{isValidDate.errorMessages}</div>
        )}
      </div>
    </div>
  );
};

export default JobDescription;
