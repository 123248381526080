import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsThreeDots, BsDownload, BsCurrencyRupee } from "react-icons/bs";
import { ImLocation2 } from "react-icons/im";
import { FaCalendarAlt } from "react-icons/fa";
import { BsPlusLg, BsLink45Deg } from "react-icons/bs";

import ModalBody from "../../Shared/ModalBody/ModalBody";
import DrawerBodyWLg from "../../Shared/DrawerBody/DrawerBodyWLg";
import { AiOutlineClose } from "react-icons/ai";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { CurrentFreelancerAllHireJobs } from "../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";
import {
  serverImageUrl,
  serverUrl,
} from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";

const MyJobsContract = () => {
  const { currentUser, setUserCurrentLocationPathParameter } = useContext(
    globalFunctionOneContext
  );
  const [myJobMenuActiveTracker, setMyJobMenuActiveTracker] = useState(1);
  const [handelSubmitWorkModal, setHandelSubmitWorkModal] = useState(false);
  const [handelAddMilestoneModal, setHandelAddMilestoneModal] = useState(false);
  const [subMenuOpenAndClose, setSubMenuOpenAndClose] = useState(false);
  const [selectForContractDataFilterFrom, setSelectForContractDataFilterFrom] =
    useState(false);
  const [singleJobAllMilestoneData, setSingleJobAllMilestoneData] = useState(
    []
  );
  const [milestoneDataFetchReference, setMilestoneDataFetchReference] =
    useState("");
  const [accordion, setAccordion] = useState(0);
  const [
    singleJobAllMilestoneDataLoading,
    setSingleJobAllMilestoneDataLoading,
  ] = useState(false);
  const [handelDataOrder, setHandelDataOrder] = useState("DESC");

  const navigate = useNavigate();

  // This is freelancer My jobs route menu list
  const menuItems = [
    {
      id: 1,
      url: "#",
      text: "Overview",
    },
    {
      id: 2,
      url: "#",
      text: "Message",
    },
    {
      id: 3,
      url: "#",
      text: "Details",
    },
  ];

  // This is all hire job data get function
  const {
    data: allHireJobData,
    refetch: allHireJobDataRefetch,
    isLoading: allHireJobDataLoading,
  } = CurrentFreelancerAllHireJobs(currentUser?.PersonID, handelDataOrder);

  //This is single job all milestone fetch function
  const getSingleJobMilestone = async (jobId) => {
    setSingleJobAllMilestoneDataLoading(true);
    const res = await fetch(
      `${serverUrl}/milestone/single/jobs/all/milestone`,
      {
        headers: {
          "Content-Type": "application/json",
          uid: currentUser?.PersonID,
          jobid: jobId,

          // authorization: `bearer ${localStorage.getItem("access_Token")}`,
        },
      }
    );
    const data = await res.json();
    if (data?.status === "success") {
      setSingleJobAllMilestoneData(data?.result);
      setSingleJobAllMilestoneDataLoading(false);
    } else {
      setSingleJobAllMilestoneDataLoading(false);
    }
  };

  // This is data order request send function
  const dataOrderValueSendFromServer = (e) => {
    const value = e.target.value;
    if (value === "Ascending") {
      setHandelDataOrder("ASC");
    } else {
      setHandelDataOrder("DESC");
    }
  };

  // this is auto call one single job milestone get
  const useReference = useRef();
  useEffect(() => {
    if (allHireJobData?.result?.length > 0 && !useReference.current) {
      getSingleJobMilestone(allHireJobData?.result[0]?.jobId);
      setMilestoneDataFetchReference(allHireJobData?.result[0]?.jobId);
      useReference.current = true;
    }
  }, [allHireJobData?.result]);

  if (!currentUser || allHireJobDataLoading) {
    return <div>Freelancer contract loading ....</div>;
  }

  return (
    <div>
      {/* This is component details  */}
      {/* <div className="card px-2 w-full bg-base-100 shadow-xl mt-10">
        <div className="card-body">
          <div className="flex justify-between items-center">
            <div className="flex justify-start items-start gap-4">
              <div className="avatar">
                <div className="w-14 rounded-full">
                  <img
                    src="https://i.ibb.co/MBkf6JK/My-Logo.jpg"
                    alt="My-Logo"
                  />
                </div>
              </div>
              <div>
                <h1 className="font-bold">Sk Mamun Khan</h1>
                <p>4.43 pm wed</p>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <ul className="flex justify-start items-center">
              {menuItems &&
                menuItems.map((li) => (
                  <li className="hover:bg-[#eef1fe]" key={li?.id}>
                    <Link
                      to={li?.url}
                      className={`flex flex-col px-2 mx-3 pb-3 cursor-pointer ${
                        myJobMenuActiveTracker === li?.id
                          ? "front-bold my_job_menu_list"
                          : ""
                      }`}
                      onClick={() => setMyJobMenuActiveTracker(li?.id)}
                    >
                      <p className={`${li?.hidden ? "opacity-0" : ""}`}>
                        {li?.subText}
                      </p>
                      <p>{li?.text}</p>
                    </Link>
                  </li>
                ))}
            </ul>
            <hr className="mb-5 border-t-2 border-gray-300" />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
            <div className="col-span-2 border border-gray-300 rounded-lg p-5">
              <h1 className="text-2xl font-medium">Milestone Timeline</h1>
              <div className="milestone_timeline_container_wrapper border-l border-gray-400 mx-5 mt-5 py-5 pl-10 relative">
                <p className="text-md font-medium">
                  First milestone was created as part of release testing by Hire
                  QA on Tue Aug
                </p>
                <p className="text-md font-medium">22 04.50 14 UTC 2023</p>
                <p className="text-md font-bold my-3">$ 38,156.39</p>
                <p className="flex justify-start items-center">
                  <FaCalendarAlt /> <span className="ml-2">Due Oct 21</span>
                </p>
                <button
                  className="btn btn-outline btn-primary btn-sm my-5"
                  onClick={() => setHandelSubmitWorkModal(true)}
                >
                  Submit Work
                </button>
                <button className="btn btn-circle btn-outline btn-primary btn-sm absolute top-0 -left-4 bg-white">
                  1
                </button>
                <div className="absolute bottom-0 -left-4 flex justify-start items-center">
                  <button
                    className="btn btn-circle btn-primary btn-sm"
                    onClick={() => setHandelAddMilestoneModal(true)}
                  >
                    <BsPlusLg className="text-lg font-bold" />
                  </button>
                  <p className="text-primary ml-4">Propose a new milestone</p>
                </div>
              </div>
            </div>
            <div className="border border-gray-300 rounded-lg p-5">
              <h1 className="text-2xl font-medium">Earnings</h1>
              <div className="h-3 w-full bg-gray-200 rounded-full my-5">
                <div
                  className="h-3 bg-primary rounded-l-full"
                  style={{ width: "10%" }}
                ></div>
              </div>
              <ul className="pl-5 mt-3">
                <li className="flex justify-between items-center w-full gap-4 my-1 relative">
                  <span className="absolute top-2 -left-5 bg-green-500 w-3 h-3 rounded-full"></span>
                  <p>Received</p>
                  <p className="text-right">$ 0.00 </p>
                </li>
                <li className="flex justify-between items-center w-full gap-4 my-1 relative">
                  <p>Funded (Escrow Protect on) Project</p>
                  <p className="text-right whitespace-nowrap">$ 38.156.39</p>
                  <span className="absolute top-2 -left-5 bg-gray-600 w-3 h-3 rounded-full"></span>
                </li>
                <li className="flex justify-between items-center w-full gap-4 my-1 relative">
                  <p>Project Price</p>
                  <p className="text-right">$ 5590,637.25</p>
                  <span className="absolute top-2 -left-5 bg-gray-200 w-3 h-3 rounded-full"></span>
                </li>
              </ul>
            </div>
          </div>
          <hr className="my-3 border-t border-gray-300" />
        </div>
      </div> */}
      <div className="w-[1300px] mx-auto mt-20">
        <form className="px-5">
          <div className="flex justify-between items-center">
            <div className="flex justify-center items-center relative w-full">
              <input
                type="text"
                className="input input-bordered w-full focus:outline-none focus:border-[#fd03dc] pr-14 rounded-md"
              />
              <span
                className={`bg-primary text-white absolute top-0 right-0 w-12 h-full text-lg rounded-r-lg flex justify-center items-center border-r-2 cursor-pointer`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </span>
            </div>
            <button
              className="btn btn-primary btn-outline btn-sm ml-3"
              type="button"
              onClick={() => setHandelAddMilestoneModal(true)}
            >
              Search Milestone
            </button>
          </div>

          <div className="flex justify-between items-center mt-5">
            <div className="flex justify-start items-center relative">
              <h6 className="text-lg font-medium">Short By</h6>
              <select
                onChange={dataOrderValueSendFromServer}
                className="select border-gray-300 max-w-xs focus:outline-none focus:border-primary mx-3"
              >
                <option disabled selected>
                  Sort by Order
                </option>
                <option>Ascending</option>
                <option>Descending</option>
              </select>
              <button
                type="button"
                className="select border-gray-300 max-w-xs focus:outline-none focus:border-primary flex justify-center items-center"
                onClick={() =>
                  setSelectForContractDataFilterFrom(
                    !selectForContractDataFilterFrom
                  )
                }
              >
                Select Date
              </button>
              {selectForContractDataFilterFrom && (
                <div className="absolute top-[105%] left-[55%] p-5 bg-gray-100 z-30 rounded">
                  <div className="my-2">
                    <h1 className="text-md font-medium my-1">
                      Select start date
                    </h1>
                    <input
                      type="date"
                      name="startDate"
                      className="cursor-pointer input input-bordered w-full focus:outline-none focus:border-[#fd03dc] pr-14 rounded-md"
                    />
                  </div>
                  <div className="my-2">
                    <h1 className="text-md font-medium my-1">
                      Select end date
                    </h1>
                    <input
                      type="date"
                      name="endDate"
                      className="cursor-pointer input input-bordered w-full focus:outline-none focus:border-[#fd03dc] pr-14 rounded-md"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="flex justify-start items-center cursor-pointer">
              <p className="text-primary mr-3">
                <BsDownload />
              </p>
              <p className="text-primary">Download Csv</p>
            </div>
          </div>
        </form>
        <div className="card px-2 w-full bg-base-100 shadow-xl my-10">
          <div className="accordion-body">
            <div className="accordion">
              <ul>
                {allHireJobData.result &&
                  allHireJobData.result.map((job, i) => (
                    <li
                      onClick={() => {
                        setAccordion(i);
                        if (milestoneDataFetchReference !== job?.jobId) {
                          getSingleJobMilestone(job?.jobId);
                        }
                        setMilestoneDataFetchReference(job?.jobId);
                      }}
                    >
                      <div
                        className={`container ${accordion === i && "active"}`}
                      >
                        <div className="label">
                          <div className="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                            <div className="flex justify-start items-start gap-4">
                              <div className="avatar">
                                <div className="w-14 rounded-full">
                                  <img
                                    src={`${serverImageUrl}/${job?.profilePhoto}`}
                                    alt="My-Logo"
                                  />
                                </div>
                              </div>
                              <div>
                                <h1 className="font-bold">
                                  {job?.firstName + job?.lastName}
                                </h1>
                                <p className="flex justify-start items-center">
                                  <ImLocation2 />
                                  <span>{job?.country}</span>
                                </p>
                              </div>
                            </div>
                            <div>
                              <h1 className="font-bold">{job?.job_title}</h1>
                              <p className="font-medium">
                                Project Price {job?.minimum_price}
                              </p>
                            </div>
                            <div>
                              <p className="font-medium">
                                Project Start Date {job?.project_start_date}
                              </p>
                              <p className="font-medium">
                                Project Expire Date {job?.project_expire_date}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="content">
                          {singleJobAllMilestoneDataLoading &&
                          singleJobAllMilestoneDataLoading &&
                          accordion === i ? (
                            <p>loading accordion</p>
                          ) : (
                            <>
                              {singleJobAllMilestoneData.length > 0 &&
                              !singleJobAllMilestoneDataLoading ? (
                                <div className="my-5 p-5">
                                  <ol
                                    type="1"
                                    style={{ listStyleType: "decimal" }}
                                  >
                                    {singleJobAllMilestoneData &&
                                      singleJobAllMilestoneData.map(
                                        (milestone, i) => (
                                          <li key={i}>
                                            <div className="flex justify-between items-center">
                                              <div className="flex justify-between items-end w-[50%]">
                                                <div>
                                                  <p>
                                                    <span>
                                                      {
                                                        milestone?.milestoneDescription
                                                      }
                                                    </span>

                                                    {milestone?.milestoneState &&
                                                      milestone?.milestoneState ===
                                                        "noActive" && (
                                                        <span className="btn btn-outline btn-primary btn-xs ml-3">
                                                          No Active
                                                        </span>
                                                      )}
                                                    {milestone?.milestoneState &&
                                                      milestone?.milestoneState ===
                                                        "complete" && (
                                                        <span className="btn btn-outline btn-primary btn-xs ml-3">
                                                          Complete
                                                        </span>
                                                      )}
                                                    {milestone?.milestoneState &&
                                                      milestone?.milestoneState ===
                                                        "active" && (
                                                        <span className="btn btn-outline btn-primary btn-xs ml-3">
                                                          Active
                                                        </span>
                                                      )}
                                                    {milestone?.milestoneState &&
                                                      milestone?.milestoneState ===
                                                        "workSubmitted" && (
                                                        <span className="btn btn-outline btn-primary btn-xs ml-3">
                                                          Active
                                                        </span>
                                                      )}
                                                  </p>
                                                  <p className="flex justify-start items-center">
                                                    <BsCurrencyRupee />
                                                    <span>
                                                      {milestone?.depositAmount}
                                                    </span>
                                                    <span className="ml-1">
                                                      (founded)
                                                    </span>
                                                  </p>
                                                </div>
                                                <div>
                                                  <p className="ml-5">
                                                    {(() => {
                                                      const date = new Date(
                                                        milestone?.dateLine
                                                      );
                                                      const monthNames = [
                                                        "Jan",
                                                        "Feb",
                                                        "Mar",
                                                        "Apr",
                                                        "May",
                                                        "Jun",
                                                        "Jul",
                                                        "Aug",
                                                        "Sep",
                                                        "Oct",
                                                        "Nov",
                                                        "Dec",
                                                      ];
                                                      const year =
                                                        date.getFullYear() %
                                                        100;
                                                      const month =
                                                        monthNames[
                                                          date.getMonth()
                                                        ];
                                                      const day =
                                                        date.getDate();

                                                      return `${month} ${day} ${year}`;
                                                    })()}
                                                  </p>
                                                </div>
                                              </div>
                                              <div>
                                                {milestone?.milestonePaymentState &&
                                                  milestone?.milestoneState &&
                                                  milestone?.milestonePaymentState ===
                                                    "No" &&
                                                  milestone?.milestoneState ===
                                                    "workSubmitted" && (
                                                    <button
                                                      className="btn btn-outline btn-primary btn-sm rounded-full"
                                                      onClick={() =>
                                                        navigate(
                                                          `/dashboard/my_jobs/contract/details/${milestone?.id}`
                                                        )
                                                      }
                                                    >
                                                      Work Submitted
                                                    </button>
                                                  )}
                                                {milestone?.milestonePaymentState &&
                                                  milestone?.milestoneState &&
                                                  milestone?.milestonePaymentState ===
                                                    "No" &&
                                                  milestone?.milestoneState ===
                                                    "active" && (
                                                    <button
                                                      className="btn btn-outline btn-primary btn-sm rounded-full"
                                                      onClick={() =>
                                                        navigate(
                                                          `/dashboard/my_jobs/contract/details/${milestone?.id}`
                                                        )
                                                      }
                                                    >
                                                      Submit Work
                                                    </button>
                                                  )}
                                                {milestone?.milestonePaymentState &&
                                                  milestone?.milestoneState &&
                                                  milestone?.milestonePaymentState ===
                                                    "No" &&
                                                  milestone?.milestoneState ===
                                                    "noActive" && (
                                                    <button className="btn btn-outline btn-primary btn-sm rounded-full">
                                                      Send Milestone
                                                    </button>
                                                  )}
                                                {milestone?.milestonePaymentState &&
                                                  milestone?.milestoneState &&
                                                  milestone?.milestonePaymentState ===
                                                    "yes" &&
                                                  milestone?.milestoneState ===
                                                    "complete" && (
                                                    <button className="btn btn-outline btn-primary btn-sm rounded-full">
                                                      Complete
                                                    </button>
                                                  )}
                                              </div>
                                            </div>
                                            <hr className="my-3 border-t border-gray-300" />
                                          </li>
                                        )
                                      )}
                                  </ol>
                                </div>
                              ) : (
                                <h1 className="flex justify-center text-2xl font-bold mt-5">
                                  No Milestone
                                </h1>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <hr className="my-3 border-t border-gray-300" />
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyJobsContract;
