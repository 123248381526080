import React from "react";

const FooterBanner = () => {
  return (
    <div className="my-32">
      <div className="bg-[url('https://work.elite-professionals.in/wp-content/uploads/2022/08/1920353.png')] py-32 bg-no-repeat bg-cover relative">
        <div className="absolute bg-slate-200 top-0 right-0 w-full h-full opacity-40"></div>
        <div>
          <h2 className="text-center text-4xl font-bold mb-2">
            Affordable <span className="text-primary">Packages</span>
          </h2>
          <p className="text-center font-semibold text-sm text-gray-600">
            To help you get started, we would like to offer you a plan
          </p>
        </div>
      </div>
    </div>
  );
};

export default FooterBanner;
