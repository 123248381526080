import React, { useContext, useEffect, useState } from "react";
import Details from "../Client/ClientProfile/ProfileForm/Details/Details";
import Intro from "../Freelancer/FreelancerProfile/Intro/Intro";
import { useProfileData } from "../../hooks/UseGetDatabaseSingleUser/UseGetDatabaseSingleUser";
import { globalFunctionOneContext } from "../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import TalentManagerPersonalDetailsForm from "../TalentManager/TalentManagerProfiel/TalentManagerPersonalDetailsForm";

const FormHome = () => {
  const {
    currentUser,
    currentUserAccountTypeClient,
    currentUserAccountTypeFreelancer,
    currentUserAccountTypeTalentManager,
    windowLoading,
    setWindowLoading,
  } = useContext(globalFunctionOneContext);

  // console.log(currentUser, windowLoading);
  if (windowLoading) {
    return <div>Hello window Loading ..........</div>;
  }
  return (
    <div className="">
      {currentUserAccountTypeClient && <Details />}
      {currentUserAccountTypeFreelancer && <Intro />}
      {currentUserAccountTypeTalentManager && (
        <TalentManagerPersonalDetailsForm />
      )}
    </div>
  );
};

export default FormHome;
