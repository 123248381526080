import React, { useContext, useEffect, useState } from "react";
import { BsCurrencyRupee, BsPlusLg } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";
import { Link, useParams } from "react-router-dom";
import { globalFunctionOneContext } from "../../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";
import { serverUrl } from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import { SingleMilestoneReviewWorkData } from "../../../hooks/AllServerDataGetQueryFunction/AllServerDataGetQueryFunction";

const ReviewWork = () => {
  const parameter = useParams();
  const {
    currentUser,
    setUserCurrentLocationPathParameter,
    downloadAnyBufferFileForFrontEnd,
    bufferAnyFileOpenImgTagLinkGenerate,
  } = useContext(globalFunctionOneContext);

  const [myJobMenuActiveTracker, setMyJobMenuActiveTracker] = useState(1);
  const [url, setUrl] = useState("");

  // This is Client review work route menu list
  const menuItems = [
    {
      id: 1,
      url: "#",
      text: "Overview",
    },
    {
      id: 2,
      url: "#",
      text: "Message",
    },
    {
      id: 3,
      url: "#",
      text: "Details",
    },
  ];

  // this is single milestone work data getting function
  const {
    data: singleMilestoneWorkData,
    refetch: singleMilestoneWorkDataRefetch,
    isLoading: singleMilestoneDtaLoading,
  } = SingleMilestoneReviewWorkData(parameter?.id);

  useEffect(() => {
    setUserCurrentLocationPathParameter(parameter);
  }, [parameter, setUserCurrentLocationPathParameter]);

  // this is page loading
  if (!currentUser || singleMilestoneDtaLoading) {
    return <div>This is review work loading......</div>;
  }

  return (
    <section>
      <button
        onClick={async () => {
          const url = await bufferAnyFileOpenImgTagLinkGenerate(
            singleMilestoneWorkData?.result?.freelancerSubmitWorkFile,
            singleMilestoneWorkData?.result?.freelancerSubmitWorkFileType
          );
          setUrl(url);
        }}
      >
        click me
      </button>

      <img src={url} alt="" srcset="" />
      <div className="mt-5">
        <div className="max-w-[1200px] mx-auto">
          <div className="card px-2 w-full bg-base-100 shadow-xl mt-10">
            <div className="card-body">
              <div className="flex justify-between items-center">
                <div className="flex justify-start items-start gap-4">
                  <div className="avatar">
                    <div className="w-14 rounded-full">
                      <img
                        src="https://i.ibb.co/MBkf6JK/My-Logo.jpg"
                        alt="My-Logo"
                      />
                    </div>
                  </div>
                  <div>
                    <h1 className="font-bold">
                      {singleMilestoneWorkData?.result?.firstName +
                        singleMilestoneWorkData?.result?.lastName}
                    </h1>
                    <p className="flex justify-start items-center">
                      <ImLocation2 />
                      <span>
                        {singleMilestoneWorkData?.result?.JobLocation}
                      </span>
                    </p>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <ul className="flex justify-start items-center">
                  {menuItems &&
                    menuItems.map((li) => (
                      <li className="hover:bg-[#eef1fe]" key={li?.id}>
                        <Link
                          to={li?.url}
                          className={`flex flex-col px-2 mx-3 pb-3 cursor-pointer ${
                            myJobMenuActiveTracker === li?.id
                              ? "front-bold my_job_menu_list"
                              : ""
                          }`}
                          onClick={() => setMyJobMenuActiveTracker(li?.id)}
                        >
                          <p className={`${li?.hidden ? "opacity-0" : ""}`}>
                            {li?.subText}
                          </p>
                          <p>{li?.text}</p>
                        </Link>
                      </li>
                    ))}
                </ul>
                <hr className="mb-5 border-t-2 border-gray-300" />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
                <div className="col-span-2 border border-gray-300 rounded-lg p-5">
                  <h1 className="text-2xl font-medium">Milestone Timeline</h1>
                  <div className="milestone_timeline_container_wrapper border-l border-gray-400 mx-5 mt-5 py-5 pl-10 relative">
                    <p className="text-md font-medium">
                      {singleMilestoneWorkData?.result?.milestoneDescription}
                    </p>
                    {/* <p className="text-md font-medium">22 04.50 14 UTC 2023</p> */}
                    <p className="flex justify-start items-center text-md font-bold my-3">
                      <BsCurrencyRupee />
                      {singleMilestoneWorkData?.result?.depositAmount}
                    </p>
                    <p className="flex justify-start items-center">
                      <FaCalendarAlt />
                      <span className="ml-2">
                        {(() => {
                          const date = new Date(
                            singleMilestoneWorkData?.result?.dateLine
                          );
                          const monthNames = [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec",
                          ];
                          const year = date.getFullYear() % 100;
                          const month = monthNames[date.getMonth()];
                          const day = date.getDate();

                          return `${month} ${day} ${year}`;
                        })()}
                      </span>
                    </p>
                    <button
                      className="btn btn-outline btn-primary btn-sm my-5"
                      onClick={() =>
                        downloadAnyBufferFileForFrontEnd(
                          singleMilestoneWorkData?.result
                            ?.freelancerSubmitWorkFile,
                          singleMilestoneWorkData?.result
                            ?.freelancerSubmitWorkFileType
                        )
                      }
                    >
                      Review Work
                    </button>
                    <button className="btn btn-circle btn-outline btn-primary btn-sm absolute top-0 -left-4 bg-white">
                      1
                    </button>
                    <div className="absolute bottom-0 -left-4 flex justify-start items-center">
                      <button
                        type="button"
                        className="btn btn-circle btn-primary btn-sm"
                        //   onClick={() => setHandelAddMilestoneModal(true)}
                      >
                        <BsPlusLg className="text-lg font-bold" />
                      </button>
                      {/* <p
                    className="text-primary ml-4 cursor-pointer"
                    //   onClick={() => setHandelAddMilestoneModal(true)}
                  >
                    Propose a new milestone
                  </p> */}
                    </div>
                  </div>
                </div>
                <div className="border border-gray-300 rounded-lg p-5">
                  <h1 className="text-2xl font-medium">Earnings</h1>
                  <div className="h-3 w-full bg-gray-200 rounded-full my-5">
                    <div
                      className="h-3 bg-primary rounded-l-full"
                      style={{ width: "10%" }}
                    ></div>
                  </div>
                  <ul className="pl-5 mt-3">
                    <li className="flex justify-between items-center w-full gap-4 my-1 relative">
                      <span className="absolute top-2 -left-5 bg-green-500 w-3 h-3 rounded-full"></span>
                      <p>Received</p>
                      <p className="text-right">$ 0.00 </p>
                    </li>
                    <li className="flex justify-between items-center w-full gap-4 my-1 relative">
                      <p>Funded (Escrow Protect on) Project</p>
                      <p className="text-right whitespace-nowrap">
                        $ 38.156.39
                      </p>
                      <span className="absolute top-2 -left-5 bg-gray-600 w-3 h-3 rounded-full"></span>
                    </li>
                    <li className="flex justify-between items-center w-full gap-4 my-1 relative">
                      <p>Project Price</p>
                      <p className="text-right flex justify-start items-center">
                        <BsCurrencyRupee />
                        <span>
                          {singleMilestoneWorkData?.result?.minimum_price}
                        </span>
                      </p>

                      <span className="absolute top-2 -left-5 bg-gray-200 w-3 h-3 rounded-full"></span>
                    </li>
                  </ul>
                </div>
              </div>
              <hr className="my-3 border-t border-gray-300" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReviewWork;
