import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Loading from "../component/Loading/Loading";
import {
  liveSiteUrl,
  serverUrl,
} from "./AllServerUrlControl/useAllServerUrlControl";
import { globalFunctionOneContext } from "../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

// single user get function
export const useUser = () => {
  const [user, setUser] = useState({});

  const { id } = useParams();

  const {
    data: users,
    isLoading,
    refetch,
  } = useQuery(["userDetils", id], () => {
    if (id) {
      fetch(`${liveSiteUrl}/userProfile/singleUser/${id}`).then((res) => {
        // console.log(res);
        return res.json();
      });
    }
  });

  useEffect(() => {
    if (users) {
      setUser(users[0]);
    }
  }, [users]);
  if (isLoading || Object.keys(user).length === 0) {
    return <Loading />;
  }

  // console.log(users, `${liveSiteUrl}/userProfile/singleUser/${id}`);
  return { user };
};

// single user profile get
export const SingleUserProfileGet = (PersonID) => {
  const { currentUser } = useContext(globalFunctionOneContext);

  const { isLoading, error, data, refetch } = useQuery(
    ["SingleUserProfile", PersonID],
    async () => {
      if (currentUser?.accountType && currentUser?.userProfileStats === 1) {
        console.log(PersonID, currentUser?.accountType, "check it");
        const response = await fetch(
          `${serverUrl}/userProfile/single/user/profile`,
          {
            method: "GET",
            headers: {
              uid: PersonID,
              account_type: currentUser?.accountType,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      }
    }
  );

  return { data, isLoading, error, refetch };
};
