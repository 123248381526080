import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import Home from "../Client/ClientDashboard/Home/Home";
import Dashboard from "../Freelancer/Dashboard/Dashboard";
import TalentManagerHome from "../TalentManager/TalentManagerHome";
import { globalFunctionOneContext } from "../../context/GlobalFunctionProvider/GlobalFunctionProviderOne";

const GlobalHome = () => {
  const {
    currentUser,
    windowLoading,
    currentUserAccountTypeClient,
    currentUserAccountTypeFreelancer,
    currentUserAccountTypeTalentManager,
    confirmAlertValue,
    setConfirmAlertValue,
  } = useContext(globalFunctionOneContext);
  const location = useLocation();

  return (
    <div>
      {currentUserAccountTypeClient && <Home />}
      {currentUserAccountTypeFreelancer && <Dashboard />}
      {currentUserAccountTypeTalentManager && <TalentManagerHome />}
    </div>
  );
};

export default GlobalHome;
