import React from "react";

const Collapse = () => {
  return (
    <div>
      <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
        <input type="checkbox" className="peer" />
        <div className="collapse-title">
          Job Type: <span className="text-sm text-accent">(0 selected)</span>{" "}
        </div>
        <div className="collapse-content text-primary-content">
          <div className="form-control">
            <label className="cursor-pointer label justify-start gap-2">
              <input type="checkbox" className="checkbox checkbox-secondary" />
              <span className="label-text">onsite</span>
            </label>
            <label className="cursor-pointer label justify-start gap-2">
              <input type="checkbox" className="checkbox checkbox-secondary" />
              <span className="label-text">Partial onsite</span>
            </label>
            <label className="cursor-pointer label justify-start gap-2">
              <input type="checkbox" className="checkbox checkbox-secondary" />
              <span className="label-text">Remote</span>
            </label>
          </div>
        </div>
      </div>
      <div className="divider"></div>

      <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
        <input type="checkbox" className="peer" />
        <div className="collapse-title">Project Type</div>
        <div className="collapse-content text-primary-content">
          <div className="form-control">
            <label className="cursor-pointer label justify-start gap-2">
              <input
                type="radio"
                name="radio-3"
                className="radio radio-secondary"
              />
              <span className="label-text">All</span>
            </label>
            <label className="cursor-pointer label justify-start gap-2">
              <input
                type="radio"
                name="radio-3"
                className="radio radio-secondary"
              />
              <span className="label-text">Fixed project</span>
            </label>
            <label className="cursor-pointer label justify-start gap-2">
              <input
                type="radio"
                name="radio-3"
                className="radio radio-secondary"
              />
              <span className="label-text">Hourly based project</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collapse;
