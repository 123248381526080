import React from 'react';
import './placeorder.css';
import { useNavigate } from "react-router-dom";

const Paymentsucess = () => {
    const navigate = useNavigate();
    const redirect = () => {
        navigate("/");
    }
    return (
        <div className="payment-success-container">
            <div className="payment-success-content">
                <h1 className="payment-success-title">Payment Successful!</h1>
                <p className="payment-success-message">Thank you for your payment.</p>
                <p className="payment-success-message">Start Inviting Freelancer to Your Job</p>
                <buttton className="btn btn-success plr-2" onClick={() => {
                    navigate(`/dashboard/client/jobs/inviteFreelancer`);
                }} >OK</buttton>
            </div>
        </div>
    )
}

export default Paymentsucess;