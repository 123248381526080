import { SiVerizon } from "react-icons/si";
import { serverUrl } from "../../../hooks/AllServerUrlControl/useAllServerUrlControl";
import SingleMethod from "./SingleMethod";
import { useState } from "react";
import PaymentAccountInfo from "./PaymentAccountInfo";
import AccountBalanceCard from "./AccountBalnceCard";
const WithdrawalMethodsCard = ({
  accountBalance,
  withdrawModalEnable,
  addMethod,
  methods,
  refetch,
}) => {
  const [removedLoading, setRemovedLoading] = useState(false);
  const [removeId, setRemoveId] = useState("");

  // console.log(paymentModal);
  const removePMD = (id) => {
    setRemovedLoading(true);
    setRemoveId(id);
    fetch(`${serverUrl}/paymentInfo/${id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setRemovedLoading(false);
          refetch();
          setRemoveId("");
          console.log(data);
        }
      });
  };
  return (
    <div>
      <div>
        <AccountBalanceCard
          accountBalance={accountBalance}
          withdrawModalEnable={withdrawModalEnable}
        />
        <div className="mt-5 card border w-full bg-base-100 shadow-xl">
          <div className="card-body">
            <div>
              <div className="flex gap-4 items-end justify-between">
                <h2 className="text-2xl font-semibold">withdrawal methods</h2>
                <div>
                  <label
                    htmlFor="paymentAndBillingModal"
                    onClick={addMethod}
                    className="btn-sm  btn btn-primary btn-outline">
                    Add method
                  </label>
                </div>
              </div>

              {methods.map((m) => (
                <SingleMethod
                  m={m}
                  removeId={removeId}
                  removedLoading={removedLoading}
                  removePMD={removePMD}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WithdrawalMethodsCard;
